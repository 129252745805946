import { DIALOG_SHOW, DIALOG_HIDE, DIALOG_UPDATE_PERCENTAGE } from './types'

export const closeTopDialog = () => {
  return {
    type: DIALOG_HIDE,
  }
}

export const closeTopDialogWithResult = ({ result }) => {
  return {
    type: DIALOG_HIDE,
    result,
  }
}

export const showDialog = ({ data, onClose, onAltClose, onLinkClose }) => {
  return {
    type: DIALOG_SHOW,
    data,
    onClose,
    onAltClose,
    onLinkClose,
  }
}

export const showErrorDialog = ({ title, message, onClose }) => {
  return {
    type: DIALOG_SHOW,
    data: {
      red: title,
      teal: message,
      buttonText: 'Ok',
    },
    onClose,
  }
}

export const showProgressDialog = () => {
  return {
    type: DIALOG_SHOW,
    data: {
      showSpinner: true,
    },
  }
}

export const showClosableProgressDialog = () => {
  return {
    type: DIALOG_SHOW,
    data: {
      showSpinner: true,
      closeOnTap: true,
    },
  }
}

export const showPercentageDialog = () => {
  return {
    type: DIALOG_SHOW,
    data: {
      showPercentage: true,
    },
  }
}

export const updatePercentageDialog = ratioDone => ({
  type: DIALOG_UPDATE_PERCENTAGE,
  ratioDone,
})

export const showVideoDialog = ({ youtubeId, start, end }) => {
  return {
    type: DIALOG_SHOW,
    data: {
      youtubeId: youtubeId,
      start: start || 0,
      end: end,
    },
  }
}
