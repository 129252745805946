import * as React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { CheckBox } from 'react-native-web'
import s from 'styles'

interface CheckBoxItemProps {
  isChecked: boolean,
  label?: string
  name: string,
  onChange: Function,
  value: string,
  textStyle?: object | undefined
  viewStyle?: object | undefined
}

const CheckBoxItem: React.FC<CheckBoxItemProps> = ({
  label,
  name,
  value,
  isChecked,
  onChange,
  textStyle,
  viewStyle,
}: CheckBoxItemProps) => {
  const onPress = () => onChange({ [name]: [value] })

  return (
    <TouchableOpacity activeOpacity={1} onPress={onPress} style={[s.flexRow, s.alignCenter, viewStyle]}>
      <CheckBox color="#252932" value={isChecked} />
      {!!label && <Text style={[s.ml8, s.f16, textStyle]}>{label}</Text>}
    </TouchableOpacity>
  )
}

export default CheckBoxItem
