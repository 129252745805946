import React from 'react'
import { Text } from 'react-native'
import s from 'styles'

export interface SectionH2Props {
  text: string,
}

export const SectionH2: React.FC<SectionH2Props> = ({ text }: SectionH2Props) => {
  return (
    <Text style={[s.mb24, s.f16, s.textBlack, s.textBold]}>{text}</Text>
  )
}

export default SectionH2