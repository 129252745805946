
import React from 'react'
import {Text, View, Image } from 'react-native'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const LoadingLessons = () => {
  // FIXME: need "official" image
  const { isDesktop } = useDetectDeviceType()
  return (
    <View style={[s.flexGrow1, s.justifyCenter, s.alignSelfCenter, { maxWidth: isDesktop ? 700 : 600 }]}>
      <View style={[s.mlAuto, s.mrAuto]}>
        <Image
          source={require('images/bot-connected.svg')}
          style={[{ width: 200, height: 200 }]}
        />
      </View>
      <Text style={[s.textGray, s.px32, s.pt32, s.f18]}>
        {'Please wait...'}
      </Text>
    </View>
  )
}

export default LoadingLessons
