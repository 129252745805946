import { Platform } from 'react-native'

const activeLogTypesStr =
  Platform.OS === 'web'
    ? new URL(window.location.href).searchParams.get('logs')
    : null
const activeLogTypes = activeLogTypesStr ? activeLogTypesStr.split(',') : []

const emptyLogger = {
  active: false,

  log: () => {},
  warn: () => {},
  error: () => {},

  // shortcuts
  l: () => {},
  w: () => {},
  e: () => {},
}

function timestamp() {
  return new Date().toISOString()
}

function logHandler(type, label, ...args) {
  args = args.map(arg => {
    if (typeof arg !== 'string') {
      try {
        if (arg.length) {
          return `[${arg.map(b => b.toString()).join(', ')}]`
        } else {
          return JSON.stringify(arg)
        }
      } catch {
        return arg
      }
    } else {
      return arg
    }
  })

  console[type](timestamp(), label, ...args)
}

export default function(filter, tag = undefined) {
  var shouldLog = false
  if (activeLogTypes.length > 0 && filter) {
    if (activeLogTypes.includes('all')) {
      shouldLog = true
      tag = typeof filter === 'string' ? filter : filter[0]
    } else if (typeof filter === 'string' && activeLogTypes.includes(filter)) {
      shouldLog = true
      tag = tag || filter
    } else if (typeof filter !== 'string') {
      const flag = filter.find(flag => activeLogTypes.includes(flag))
      if (flag) {
        shouldLog = true
        tag = flag
      }
    }
  }

  if (shouldLog) {
    const label = `[${tag}]`
    const log = (...args) => {
      logHandler('log', label, ...args)
    }
    const warn = (...args) => {
      logHandler('warn', label, ...args)
    }
    const error = (...args) => {
      logHandler('error', label, ...args)
    }

    return {
      active: true,

      log,
      warn,
      error,

      l: log,
      w: warn,
      e: error,
    }
  } else {
    return emptyLogger
  }
}
