export function debugLog(...args) {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
  // TODO ship production logs somewhere
}

export function errorLog(...args) {
  if (process.env.NODE_ENV === 'development') {
    console.error(...args)
  }
  // TODO ship production errors somewhere
}
