import React, { Component } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'

function getActiveStyle(type, isActive) {
  if (type === 'border') {
    return isActive ? [s.bTeal] : [s.bGrayLighter]
  }

  if (type === 'text') {
    return isActive ? [s.textBold] : [s.textNormal]
  }
}

class Pagination extends Component {
  // show the first and last page options
  // show the currently selected page and the one before and one after
  renderPageNumbers() {
    const current = this.props.currentPage
    const last = this.props.pageAmount
    const delta = 1
    const left = current - delta
    const right = current + delta + 1
    let range = []
    let rangeWithEllipses = []
    let l

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i)
      }
    }
    // go through the range of page numbers that should appear
    // if two numbers are not adjacent, insert ellipsis
    // else, insert the number
    for (let i of range) {
      if (l) {
        if (i - l === 1) {
          rangeWithEllipses.push(this.pageNumberItem(i))
        } else if (i - l > 1) {
          rangeWithEllipses.push(this.pageNumberEllipsis(l))
          rangeWithEllipses.push(this.pageNumberItem(i))
        }
      } else {
        rangeWithEllipses.push(this.pageNumberItem(i))
      }
      l = i
    }
    return rangeWithEllipses
  }

  pageNumberItem(i) {
    return (
      <TouchableOpacity key={i} onPress={() => this.props.changePage(i)}>
        <View
          style={[
            s.b1,
            s.px8,
            s.py4,
            s.mx5,
            getActiveStyle('border', this.props.currentPage === i),
          ]}
        >
          <Text
            style={[
              s.f14,
              s.textBold,
              s.textCenter,
              getActiveStyle('text', this.props.currentPage === i),
            ]}
          >
            {i}
          </Text>
        </View>
      </TouchableOpacity>
    )
  }

  pageNumberEllipsis(key) {
    return (
      <View key={`ellipsis:${key}`} style={[s.px8, s.py4]}>
        <Text style={[s.f14, s.textBold, s.textCenter]}>...</Text>
      </View>
    )
  }

  render() {
    return (
      <View style={[s.alignCenter, s.row, s.mx32]}>
        {this.props.currentPage === 1 ? (
          <Image
            source={require('images/pagination-caret-left-gray.svg')}
            style={{ height: 14, width: 8, marginHorizontal: 10 }}
          />
        ) : (
          <TouchableOpacity
            onPress={() => this.props.changePage(this.props.currentPage - 1)}
          >
            <Image
              source={require('images/pagination-caret-left-teal.svg')}
              style={{ height: 14, width: 8, marginHorizontal: 10 }}
            />
          </TouchableOpacity>
        )}
        {this.renderPageNumbers()}
        {this.props.currentPage === this.props.pageAmount ? (
          <Image
            source={require('images/pagination-caret-right-gray.svg')}
            style={{ height: 14, width: 8, marginHorizontal: 10 }}
          />
        ) : (
          <TouchableOpacity
            onPress={() => this.props.changePage(this.props.currentPage + 1)}
          >
            <Image
              source={require('images/pagination-caret-right-teal.svg')}
              style={{ height: 14, width: 8, marginHorizontal: 10 }}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }
}

export default Pagination
