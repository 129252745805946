import React from 'react'
import { View, Text } from 'react-native'
import { Link } from 'components/Router'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

export default function SubmissionLabel({
  text,
  secondaryText,
  secondaryLink,
  hasError,
}) {
  const { isMobile } = useDetectDeviceType();
  return (
    <View style={[s.flexRow, s.alignEnd, s.mb8, s.alignCenter]}>
      <Text style={[s.mr20, s.f16, s.textBold, hasError && s.textRed]}>
        {text}
      </Text>
      {secondaryText && (
        <Text style={[s.f14, hasError ? s.textRed : s.textBlack, s.flex1]}>
          {secondaryText}
        </Text>
      )}
      {secondaryLink && (
        <Link to={secondaryLink.to} style={[ isMobile ? s.w60p : null]} >
          <Text style={[s.f14, s.textGreen, s.textUnderline]}>
            {secondaryLink.text}
          </Text>
        </Link>
      )}
    </View>
  )
}
