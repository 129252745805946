import { put, call, takeLeading } from 'redux-saga/effects'
import api from 'lib/api'
import {
  autocompleteRequest,
  autocompleteSuccess,
  autocompleteError,
} from 'store/autocomplete'

function* onFetchAutocomplete({ payload }) {
  try {
    const data = yield call(api.autocompleteService, payload)

    yield put(
      autocompleteSuccess({
        dataType: payload.dataType,
        results: data,
        query: payload.query,
      })
    )
  } catch (err) {
    yield put(autocompleteError(err))
  }
}

function* autocompleteSaga() {
  yield takeLeading(autocompleteRequest, onFetchAutocomplete)
}

export default autocompleteSaga
