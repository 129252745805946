import React from 'react'
import { View } from 'react-native'

interface TableProps {
    children?: React.ReactNode
}

/**
 * Simple Table component. It wraps different Views into Table-like
 * subcomponents.
 */
export default function Table({ children }: TableProps) {
  return <View style={{ flex: 1 }}>{children}</View>
}