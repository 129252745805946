import * as React from 'react'
import { ImageSourcePropType, Text, View } from 'react-native'
import { Column, Row } from 'react-native-web-ui-components'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { ButtonLinkProps, ButtonLinkBlack } from 'components/ButtonLink'

export interface CardProps extends ButtonLinkProps {
  image?: ImageSourcePropType,
  headline: string,
  text: string,
  overlayText?: string,
}

export function LeftCard ({ image, headline, text, ...buttonProps }: CardProps) {
  const { isDesktop } = useDetectDeviceType()

  const cardStyles = {
    padding: isDesktop ? 24 : 16,
    marginBottom: 60,
    marginTop: 16,
  }

  const imageStyles = {
    height: 500,
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  }

  return (
    <Column xs={12} sm={12} md={12} style={cardStyles}>
      <View style={[
        s.br10,
        s.bTeal,
        s.bgGrayMoreLighter,
        s.bShadow,
        isDesktop ? s.w90p : s.w100p,
        s.mAuto,
      ]}>
        {image && <Image source={image} style={imageStyles} resizeMode="cover" />}
        <Row>
          <Column xs={12} sm={12} md={12} style={[s.justifyCenter, isDesktop ? s.p24 : s.p16]}>
            <Text style={[s.mb4, isDesktop ? s.f20 : s.f16, s.textBold]}>{headline}</Text>
            <Text style={[s.mb16, isDesktop ? s.f20 : s.f16 ]}>{text}</Text>
            <View style={[s.alignSelfCenter]}>
              <ButtonLinkBlack {...buttonProps} />
            </View>
          </Column>
        </Row>
      </View>
    </Column>
  )
}