import React from 'react'
import { View } from 'react-native'

export default ({ percent, width = 140, height = 14, borderRadius = 10 }) => {
  const progress = percent > 0 ? Math.max(0, percent / 100) : 0
  const currentWidth = progress * (width - height) + height
  return (
    <View
      style={[
        {
          height,
          width,
          backgroundColor: '#ced8de',
          borderRadius,
        },
      ]}
    >
      <View
        style={[
          {
            height,
            width: currentWidth,
            backgroundColor: '#417afc',
            borderRadius,
          },
        ]}
      ></View>
    </View>
  )
}
