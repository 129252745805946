import React, { useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import Emoji from 'components/Emoji'
import { Button2 as Button } from 'components/Form'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const Circle = (props: { isSelected: boolean }) => (
  <View
    style={[
      s.b2,
      s.bTeal,
      s.h24,
      s.w24,
      s.br12,
      s.justifyCenter,
      s.alignCenter,
      props.isSelected && s.bgTeal,
    ]}
  >
    <Image
      source={require('images/check-white.svg')}
      style={{ height: 12, width: 12 }}
    />
  </View>
)

const emojis = [
  {
    label: '😢',
    value: 1,
  },
  {
    label: '😟',
    value: 2,
  },
  {
    label: '😐',
    value: 3,
  },
  {
    label: '🙂',
    value: 4,
  },
  {
    label: '😄',
    value: 5,
  },
]

export default function RatingSelection(props: {
  text: string,
  submittedRating: number | undefined,
  lockOnSubmit?: boolean | undefined,
  onSubmit: (rating: number) => void
}) {
  const { text, submittedRating, lockOnSubmit, onSubmit } = props
  const [rating, setRating] = useState(undefined)
  const displayedRating = rating || submittedRating

  const submitRating = () => onSubmit(rating)

  const locked = lockOnSubmit && !!submittedRating
  const showSubmitButton = lockOnSubmit
    ? !submittedRating
    : !displayedRating || (rating && (rating !== submittedRating))

  const { isMobile } = useDetectDeviceType();
 
  return (
    <View>
      <View style={[s.mb24]}>
        <Text style={[s.f20, s.textCenter, s.textBlackLighter]}>{text}</Text>
      </View>
      <View style={[ isMobile ? s.mb40 : s.mb20, s.flexRow, s.justifyCenter]}>
        {emojis.map(emoji => (
          <TouchableOpacity
            key={emoji.value}
            onPress={() => setRating(emoji.value)}
            style={[isMobile ? s.mx16 : s.mx24, s.alignCenter]}
            disabled={locked}
          >
            <Text style={[s.mb8, s.f28]}>
              <Emoji emoji={emoji.label} />
            </Text>
            <Circle isSelected={displayedRating === emoji.value} />
          </TouchableOpacity>
        ))}
      </View>
      <View style={[s.alignCenter]}>
        {showSubmitButton
          ? (<Button onPress={submitRating} text="Submit" disabled={!rating}/>)
          : (<Text style={[s.f20, s.textBlackLighter]}>Thank you!</Text>)
        }
      </View>
    </View>
  )
}
