import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'

export default function RemoveX({ text = 'REMOVE', onPress }) {
  return (
    <TouchableOpacity style={[s.flexRow, s.alignCenter]} onPress={onPress}>
      {!!text && (
        <Text style={[s.mr4, s.bBottom1, s.bGrayDarkest, s.f14]}>{text}</Text>
      )}
      <Image
        source={require('images/icon-x.svg')}
        style={{ height: 16, width: 16 }}
      />
    </TouchableOpacity>
  )
}
