import * as React from 'react'
import { Text, View } from 'react-native'
import { Link } from 'components/Router'
import s from 'styles'

const ErrorMessages = ({ errorMessages }: typeof errorMessages) => {
  // Handle case where copy for existing email address sends user to login
  // There's probably a nicer way to handle links in error messages that scales.
  const messages = errorMessages.reduce((acc, curr) => {
    // Only show one error per field
    const msg = curr[0]
    if (msg === 'That email already exists.') {
      acc.push(<Text key={msg} style={[s.textRed, s.f14, s.textBold]}>{msg} Sign in <Text style={s.textUnderline}><Link to='/account/login'>here</Link></Text>.</Text>)
    } else {
      acc.push(<Text key={msg} style={[s.textRed, s.f14, s.textBold]}>{msg}</Text>)
    }
    return acc
  }, [])

  return (
    <View style={[s.flex1, s.mb16, s.w100p]}>
      {messages}
    </View>
  )
}

export default ErrorMessages
