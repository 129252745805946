import React from 'react'
import { useDispatch } from 'react-redux'
import { View, Text } from 'react-native'
import Button from 'components/Form/Button2'
import { ModalFrame } from 'components/modal'
import { modalHide } from 'store/modal'
import s from 'styles'

export interface NotificationModalProps {
  title: string,
  text: string,
  buttonText?: string,
  onPress?: (res: boolean) => void
}

const NotificationModal = (props: NotificationModalProps) => {
  const dispatch = useDispatch()
  const onButtonPressed = () => {
    dispatch(modalHide())
    props.onPress && props.onPress(true)
  }
  return (
    <ModalFrame title={props.title} size="medium">
      <View style={[s.mb20]}>
        <Text style={[s.f16, s.textCenter]}>{props.text}</Text>
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        <View style={s.mr16}>
          <Button
            text={props.buttonText || 'OK'}
            role="secondary"
            onPress={onButtonPressed}
          />
        </View>
      </View>
    </ModalFrame>
  )
}

export default NotificationModal

export const makeNotificationModal = (props: NotificationModalProps) => {
  return (<NotificationModal {...props} />)
}
