import * as React from 'react'
import { View } from 'react-native'
import TextMarkdown from 'components/Markdown/TextMarkdown'
import s from 'styles'

interface SubHeaderProps {
  text: string,
}

/**
 * Markdown in header with CTA
 *
 * <SubHeader
 *  text="Markdown **text** and [link](https://ozobot.com)"
 * />
 */
const SubHeader: React.FC<SubHeaderProps> = ({ text }: SubHeaderProps) => {
  return (
    <View style={[s.pt16]}>
      <TextMarkdown text={text} />
    </View>
  )
}

export default SubHeader
