import * as React from 'react'
import { View, Text } from 'react-native'
import Button from 'components/Form/Button2'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import s from 'styles'

interface SubHeaderWithButtonProps {
  text: string,
  buttonText: string,
  onPress: () => void,
}

/**
 * Markdown in header with a button
 *
 * <SubHeaderWithButton
 *  text="Markdown **text** and [link](https://ozobot.com)"
 *  buttonText=""
 *  onPress={someCallback}
 * />
 */
const SubHeaderWithButton: React.FC<SubHeaderWithButtonProps> = ({ text, buttonText, onPress }: SubHeaderWithButtonProps) => {
  const { isDesktop } = useDetectDeviceType()
  return isDesktop
    ? (<View style={[s.flexRow, s.justifyBetween, s.alignCenter, s.mb16]}>
      <Text style={[s.f20, s.mr20, s.textLight]}>
        {text}
      </Text>
      <View style={[s.mb16]}>
        <Button
          text={buttonText}
          onPress={onPress}
        />
      </View>
    </View>
    )
    : (<View style={[s.flexColumn, s.justifyStart, s.alignStart, s.mb16, s.mt16]}>
      <Text style={[s.f16, s.textLight]}>
        {text}
      </Text>
      <View style={[s.my16]}>
        <Button
          text={buttonText}
          onPress={onPress}
        />
      </View>
    </View>
    )
}

export default SubHeaderWithButton
