import * as React from 'react'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import Input from 'components/Form/Input2'
import OzobotBackground from './components/OzobotBackground'
import { userPasswordResetRequest } from 'store/user'
import s from 'styles'
import { useDispatch } from 'react-redux'
import validate from 'validate.js'
import validations from 'lib/validations'
import ErrorMessages from 'components/Form/ErrorMessages'

const Reset: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = React.useState('')
  const [token, setToken] = React.useState('')
  const [errors, setErrors] = React.useState([])
  const url = new URL(window.location.href)
  const currToken = url.searchParams.get('token')
  if (currToken) {
    setToken(currToken)
    window.history.replaceState(
      window.history.state,
      '',
      window.location.href.replace(`?token=${currToken}`, ''),
    )
  }

  const submit = () => {
    const error = validate.single(password, validations.password) || []
    if (error.length) {
      // put in array so we can reuse error message component
      // that expects and array of arrays
      setErrors([error])
    } else {
      dispatch(userPasswordResetRequest({
        password: password,
        token: token,
      }))
    }
  }

  return (
    <OzobotBackground>
      <View style={[s.flex1, { width: 260 }]}>
        <>
          <ErrorMessages errorMessages={errors}/>
          <Text style={[s.textCenter, s.f24, s.textTeal, s.textBold, s.mb20]}>
            Reset Your Password
          </Text>
          <Input
            value={password}
            setValue={setPassword}
            label='New Password (6 or more characters)'
            secureTextEntry={true}
            isError={!!errors.length}
            onPressEnter={submit}
          />
          <View style={[s.alignSelfCenter, { width: 'fit-content'}]}>
            <Button 
              text='Reset'
              onPress={submit}
            />
          </View>
        </>
      </View>
    </OzobotBackground>
  )
}

export default Reset
