import React, { Children, useEffect, useState, useRef } from 'react'
import { Modal, Image, Text, TouchableOpacity, View, TextInput, Keyboard } from 'react-native'
import Tippy from '@tippyjs/react/headless'
import { GRADES, SUBJECTS } from 'lib/utils.js'
import { Button, CheckBoxItem } from 'components/Form'
import Input from 'components/Form/Input2'
import { Column, Row } from 'react-native-web-ui-components'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const topSearches = [
  'Geometry',
  'Ozoblockly',
  'Evo',
  'Color Codes',
  'Intro',
]

const PopupMenu = ({
  visible,
  targetNode,
  top = 0,
  ...props
}) => {

  return (
    <Tippy
      interactive={true}
      placement="bottom-start"
      visible={visible}
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
            },
          },
        ],
      }}
      render={() => (
        <View style={[s.bgWhite, s.br25, { marginTop: top }, s.bShadow]}>
          <View
            style={[
              s.p16,
              s.bGrayLighter,
              s.w300,
              { maxHeight: 500 },
            ]}
          >
            <View style={[s.m16]}>
              {props.children}
            </View>
          </View>
        </View>
      )}
    >
      <span>{targetNode}</span>
    </Tippy>
  )
}

const FilterButtonMenu = ({
  label,
  placeholder,
  isSelected,
  setSelected,
  width,
  menuTop,
  additionalButtonComponent=undefined,
  ...props
}) => {

  const { isDesktop } = useDetectDeviceType()

  const FormattedButton = ({
    label,
    isSelected,
    width,
    onPress,
    placeholder,
    additionalButtonComponent=undefined
  }) => {
    return (
      <TouchableOpacity
        data-role={'tab'}
        onPress={onPress}
      >
          <View
            style={[
              s.flexRow,
              isDesktop ? s.pl40 : s.pl24,
              s.br32,
              { width },
              s.justifyBetween,
              s.alignCenter,
              isSelected
                ? [s.bgWhite, s.bShadow]
                : [s.bgGrayLessLighter]
            ]}
          >
            <View style={[s.flexColumn, s.alignStart, s.py8, s.justifyCenter, { height: 66 }]}>
              <Text
                style={[
                  s.f16,
                  s.textBold,
                  s.textBlackLighter,
                ]}
              >
                {label}
              </Text>
              <Text
                numberOfLines={1}
                ellipsizeMode={'tail'}
                style={[
                  s.f14,
                  s.textLight,
                  s.alignSelfStart,
                ]}
              >
                {placeholder || <View/>}
              </Text>
            </View>
            {additionalButtonComponent}
          </View>
      </TouchableOpacity>
    )
  }

  return (
    <PopupMenu
      visible={isSelected}
      top={menuTop}
      targetNode={(
        <FormattedButton
          label={label}
          placeholder={placeholder}
          isSelected={isSelected}
          width={width}
          onPress={() => setSelected(!isSelected)}
          additionalButtonComponent={additionalButtonComponent}
        />
      )}
    >
      {props.children}
    </PopupMenu>
  )
}

const MobileFilterButtonMenu = ({ expanded, text, subtext, onPress, style=[], ...props }) => (
  expanded
    ? <View style={[s.p32, s.bgWhite, s.br20, s.w100p, s.bShadow, s.mt16, ...style]}>
        <View style={[s.mb16]}>
          <Text style={[s.f18, s.textBold]}>{text}</Text>
          {!!subtext && <Text style={[s.f14, s.textGray]}>{subtext}</Text>}
        </View>
        {props.children}
      </View>
  : <TouchableOpacity
      style={[
        s.h60,
        s.p32,
        s.bgWhite,
        s.br20,
        s.bShadow,
        s.w100p,
        s.mt16,
        s.justifyCenter,
      ]}
      activeOpacity={1}
      onPress={onPress}
    >
      <Text style={[s.f16]}>{text}</Text>
    </TouchableOpacity>
)

const RecentSearchesMenuContent = ({ previousSearches, onSelection, isMobile=false }) => (
  <View style={[s.alignStart]}>
    <Text style={[s.textBold, isMobile ? s.f14 : s.f18, s.my4]}>{'Top / recent searches'}</Text>
    {previousSearches.map((text, index) => (
      <View key={index} style={[s.my4, s.alignSelfStart, { width: isMobile ? 150 : 232 }]}>
        <TouchableOpacity onPress={() => onSelection(text)}>
          <Text
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={[s.textLight, isMobile ? s.f14 : s.f16, s.alignSelfStart]}
          >
            {text}
          </Text>
        </TouchableOpacity>
      </View>
    ))}
  </View>
)

export default function SearchFilters({
  filter,
  query,
  visibleFilter,
  onSetVisibleFilter,
  onFilterChange,
  onSave,
  moreFilters,
  recentQueries,
  popupMenuTop,
}) {
  // TODO: update local state until user hits save
  // const [prop, setProp] = React.useState(prop)

  const previousSearches = [...recentQueries, ...topSearches].slice(0, 5)

  const { isDesktop, isMobile, browserWidowWidth } = useDetectDeviceType()

  const handleSearchClick = async() => {
    if (!isMobile) {
      // If on desktop, apply filter immediately
      applyFilterAll();
    } else {
      // If not on desktop, set search visibility and execute search
      setVisibleForFilter('search')(true);
    }
  }

  const shouldShowMobileSearchFilters = isMobile || (browserWidowWidth < 740)
  const expandedButtonsView = !shouldShowMobileSearchFilters && !!visibleFilter

  const isOpenModalFilter = () => {
    return shouldShowMobileSearchFilters && !!visibleFilter
  }

  const setVisibleForFilter = name => {
    return visible => {
      onSetVisibleFilter(visible ? name : undefined)
    }
  }

  const hideFilterModal = () => {
    onSetVisibleFilter(undefined)
  }

  const clearFilterAll = () => {
    onSetVisibleFilter(undefined)
  }

  const applyFilterAll = () => {
    hideFilterModal();
    onSave()
  }

  const onTextUpdate = text => {
    text = text || ''
    onFilterChange({ query: text })
  }

  const SearchButton = ({size, handleSearchClick}) => {
    return (
      <TouchableOpacity onPressIn={handleSearchClick}>
        <Image source={require('images/lesson-search-glass-icon.svg')} style={[{ width: size, height: size }, s.mx4, s.my4]}/>
      </TouchableOpacity>
    );
  };

  const CollapsedFilterButton = ({
    text,
    isMobile,
    filter,
    style=[]
  }) => (
    <View>
      <TouchableOpacity activeOpacity={1} onPress={setVisibleForFilter(filter)}>
        <Text style={[
          isMobile ? s.f14 : s.f18,
          isMobile ? s.px16 : s.px24,
          s.my8,
          ...style
        ]}>
          {text}
        </Text>
      </TouchableOpacity>
    </View>
  )

  const searchPlaceholderText = 'Geometry, Blockly, functions, etc.'

  return (
    <View style={[s.flexRow, s.alignCenter, s.justifyCenter]}>
      {!expandedButtonsView && <View style={[
        s.bShadow,
        s.flexRow,
        isMobile ? s.br16 : s.br25,
        s.justifyCenter,
        s.alignCenter,
        { height: isMobile ? 40 : 48 },
      ]}>
        <CollapsedFilterButton
          text={'Grade'}
          filter={'grades'}
          isMobile={isMobile}
          style={[s.textBold]}
        />
        <CollapsedFilterButton
          text={'Subject'}
          filter={'subjects'}
          isMobile={isMobile}
          style={[s.bGray, s.bLeft1, s.textBold]}
        />
        <CollapsedFilterButton
          text={'Search'}
          filter={'search'}
          isMobile={isMobile}
          style={[s.bGray, s.bLeft1]}
        />
        <View style={{ marginRight: isMobile ? 0 : 4 }}>
          <SearchButton size={32}/>
        </View>
      </View>}

      {expandedButtonsView && <View style={[s.bShadow, s.flexRow, s.br32, s.bgGrayLessLighter]}>
        <FilterButtonMenu
          label={'Grade'}
          placeholder={'Select Grades'}
          isSelected={visibleFilter === 'grades'}
          setSelected={setVisibleForFilter('grades')}
          width={isDesktop ? 160 : 141}
          menuTop={popupMenuTop}
        >
          <Row>
            {GRADES.map(({ label, value }) => (
              <Column key={value} xs={6}>
                <View style={[s.my4]}>
                  <CheckBoxItem
                    name="grade"
                    label={label}
                    value={value}
                    isChecked={filter.grade.includes(value)}
                    onChange={onFilterChange}
                    textStyle={[s.f18, s.textBold]}
                  />
                </View>
              </Column>
            ))}
          </Row>
        </FilterButtonMenu>
        <FilterButtonMenu
          label={'Subject'}
          placeholder={'Select Subjects'}
          isSelected={visibleFilter === 'subjects'}
          setSelected={setVisibleForFilter('subjects')}
          width={isDesktop ? 160 : 141}
          menuTop={popupMenuTop}
        >
          {SUBJECTS.map(({ label, value }) => (
            <View key={value} style={[s.my4]}>
              <CheckBoxItem
                name="subject"
                label={label}
                value={value}
                isChecked={filter.subject.includes(value)}
                onChange={onFilterChange}
                textStyle={[s.f18, s.textBold]}
              />
            </View>
          ))}
        </FilterButtonMenu>
        <FilterButtonMenu
          label={'Search'}
          placeholder={visibleFilter === 'search' ? '' : (query || searchPlaceholderText)}
          isSelected={visibleFilter === 'search'}
          setSelected={setVisibleForFilter('search')}
          width={isDesktop ? 370 : 309}
          menuTop={popupMenuTop}
          additionalButtonComponent={(
            <View style={{ marginRight: 6 }}>
              <SearchButton size={48} handleSearchClick={handleSearchClick}/>
            </View>
          )}
        >
          <View>
            <View style={isDesktop ? {
              position: 'absolute',
              left: 8,
              top: -75 - popupMenuTop,
              width: 250,
            } : {
              position: 'absolute',
              left: -8,
              top: -75 - popupMenuTop,
              width: 216,
            }}>
              <TextInput
                placeholder={searchPlaceholderText}
                placeholderTextColor={'#95abb9'}
                value={query || ''}
                autoFocus={true}
                onChangeText={onTextUpdate}
                style={[
                  s.textBlack,
                  s.f16,
                  s.bBlackLighter,
                ]}
              />
            </View>
            <RecentSearchesMenuContent
              previousSearches={previousSearches}
              onSelection={onTextUpdate}
            />
          </View>
        </FilterButtonMenu>
      </View>}

      <TouchableOpacity
        style={[s.mx16]}
        data-role={"secondary"}
        onPress={moreFilters}
      >
        <View
          style={[
            s.flexRow,
            s.alignCenter,
            s.justifyCenter,
            s.br12, s.b1, s.bGray,
            { height: isMobile ? 40 : 48, width: isMobile ? 40 : 90 }
          ]}
        >
          <Image
            source={require('images/lesson-search-filter-icon.svg')}
            style={{
              height: 13,
              width: 13,
              marginRight: 5,
            }}
            resizeMode="contain"
          />
          {!isMobile && (
            <Text
              style={[
                s.f16,
                s.textBold,
              ]}
            >
              {"Filters"}
            </Text>
          )}

        </View>
      </TouchableOpacity>

      <Modal animationType="fade" transparent={true} visible={isOpenModalFilter()}>
        <View style={[s.flex1, { marginTop: '60px', backgroundColor: '#ebebeb'}]}>
          <View style={[s.mx16]}>
            <TouchableOpacity style={[s.mt16]} onPress={hideFilterModal}>
              <Image
                source={require('images/small-close.svg')}
                style={{ height: 40, width: 40 }}
              />
            </TouchableOpacity>
            <MobileFilterButtonMenu
              expanded={visibleFilter === 'grades'}
              text={'Grade'}
              subtext={'Select Grades'}
              onPress={setVisibleForFilter('grades')}
            >
              <Row>
                {GRADES.map(({ label, value }) => (
                  <Column key={value} xs={3}>
                    <View style={[s.mb16]}>
                      <CheckBoxItem
                        name="grade"
                        label={label}
                        value={value}
                        textStyle={[s.f14, s.textBold]}
                        isChecked={filter.grade.includes(value)}
                        onChange={onFilterChange}
                      />
                    </View>
                  </Column>
                ))}
              </Row>
            </MobileFilterButtonMenu>
            <MobileFilterButtonMenu
              expanded={visibleFilter === 'subjects'}
              text={'Subject'}
              subtext={'Select Subjects'}
              onPress={setVisibleForFilter('subjects')}
            >
              <Row>
                {SUBJECTS.map(({ label, value }) => (
                  <Column key={value} xs={6}>
                    <View style={[s.mb16]}>
                      <CheckBoxItem
                        name="subject"
                        label={label}
                        value={value}
                        textStyle={[s.f12, s.textBold]}
                        viewStyle={[s.alignStart]}
                        isChecked={filter.subject.includes(value)}
                        onChange={onFilterChange}
                      />
                    </View>
                  </Column>
                  ))}
              </Row>
            </MobileFilterButtonMenu>
            <MobileFilterButtonMenu
              expanded={visibleFilter === 'search'}
              text={'Search'}
              subtext={''}
              onPress={setVisibleForFilter('search')}
              style={[s.pb8]}
            >
              <View style={[s.w100p, s.alignStart]}>
                <View style={[s.h40, s.flex1, s.flexRow, s.w100p, s.b1, s.bGray, s.br32, s.px16, s.alignCenter]}>
                  <Image
                    source={require('images/icon-search-search.svg')}
                    style={[s.mr16, { width: 14, height: 14 }]}
                  />
                  <TextInput
                    placeholderTextColor={'#95abb9'}
                    style={[
                      s.h40,
                      s.f16,
                      s.w100p,
                    ]}
                    onChangeText={onTextUpdate}
                    value={query || ''}
                    placeholder={'Geometry, functions, etc.'}
                  />
                </View>
                <View style={[s.p20, s.bgWhite, s.br20, s.bShadow, s.w200, s.mt4]}>
                  <RecentSearchesMenuContent
                    previousSearches={previousSearches}
                    onSelection={onTextUpdate}
                    isMobile={true}
                  />
                </View>
              </View>
            </MobileFilterButtonMenu>
          </View>

          <View style={[s.positionAbsolute, s.bottom0, s.p20, s.w100p, s.bgWhite, s.h80, s.justifyCenter]}>
            <View>
              <Row style={[s.w100p, s.justifyBetween, s.alignCenter]}>
                  <TouchableOpacity onPress={clearFilterAll}>
                    <Text style={[s.textBold, s.textUnderline, s.f14]}>Clear All</Text>
                  </TouchableOpacity>
                  <View style={[s.mr60]}>
                    <Button text="Search"
                      rounded={true}
                      role='third'
                      onPress={applyFilterAll}
                    />
                  </View>
              </Row>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}
