import React, { useEffect } from 'react'
import {Text, View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { lessonsActions, lessonsSavedSelector } from 'store/lessons'
import { Content, Layout, PageH1, PageHeader } from 'components/Layout'
import { Link } from 'components/Router'
import s from 'styles'
import Button from 'components/Form/Button2'
import SearchResults from './components/SearchResults'
import { LessonType } from 'components/LessonTypes'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import NoLessons from './components/NoLessons'

interface PartialState {
  lessons: {
    isFetching: boolean
  }
}

export default function Saved(): JSX.Element {
  const isFetching = useSelector<PartialState, boolean>(state => state.lessons.isFetching)
  const lessons = useSelector<PartialState, LessonType[]>(state => lessonsSavedSelector(state))
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lessonsActions.listSavedRequest())
  }, [])

  const { isDesktop } = useDetectDeviceType()

  return (
    <>
      <Layout isFetching={isFetching}>
        <PageHeader>
          <PageH1 text="Saved Lessons" />
          <View style={[s.py16]}>
            <Text style={[
              isDesktop ? s.f20 : s.f16,
              isDesktop ? s.w50p : s.w100p,
              s.textLight,
            ]}>
              Looking for new lessons to save? Ozobot Pacing Guides make it easy to get students started and ensure ongoing success.
            </Text>
          </View>
          <View style={[s.w240]}>
            <Link to="/help/pacing-guides">
              <Button
                rounded={true}
                role="secondary"
                text="Explore Pacing Guides"
              />
            </Link>
          </View>
        </PageHeader>
        <Content>
          <View style={[s.mt32]}>
            <SearchResults
              lessons={lessons}
              total={lessons.length}
              saved={lessons.map(l => l.id)}
              noResult={<NoLessons
                image={require('images/saved_lessons_no_lesson.svg')}
                text='You haven’t saved any lessons...yet!'
                link='/lessons'
                linkText='Explore Lessons'
              />}
            />
          </View>
        </Content>
      </Layout>
    </>
  )
}

// This will use when work on My Lessons
// function confirmRemove(callback: () => void) {
//   return modalShow(<ConfirmModal
//     title="Remove from Saved Lessons"
//     heading="Are you sure?"
//     description="Once you remove this lesson from the Saved Lessons, you can’t undo it."
//     confirmText="Remove"
//     onConfirm={callback}
//   />)
// }
