import React from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import { some, xor } from 'lodash'
import { Button } from 'components/Form'
import DropDown from 'components/DropDown'
import { Image } from 'components/ReactNative'
import RemoveX from './RemoveX'
import StandardsAutoComplete from './StandardsAutoComplete'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const SelectedItem = ({ text, onRemove }) => {
  return (
    <View
      style={[
        s.flexRow,
        s.px8,
        s.py4,
        s.mb8,
        s.br100,
        s.mr8,
        s.b1,
        s.bGrayLighter,
      ]}
    >
      <Text style={[s.f14, s.textCenter, s.mr8, s.textBold, s.textGrayDarker]}>
        {text}
      </Text>
      <TouchableOpacity onPress={onRemove}>
        <Image
          source={require(`images/remove-icon-xs.svg`)}
          style={{
            height: 16,
            width: 16,
          }}
        />
      </TouchableOpacity>
    </View>
  )
}

const StandardsGroup = ({ field = 'standards', text, secondaryText, form }) => {
  const [category, setCategory] = React.useState('')
  const [otherStandard, setOtherStandard] = React.useState('')
  const { isMobile } = useDetectDeviceType();

  const removeItem = idx => {
    if (idx < form.academicStandards.length) {
      form.updateForm({
        academicStandards: form.academicStandards.filter((s, i) => i !== idx),
      })
    } else {
      form.updateForm({
        otherAcademicStandards: form.otherAcademicStandards.filter(
          (s, i) => i !== idx - form.academicStandards.length
        ),
      })
    }
  }

  const onAddOther = () => {
    if (!otherStandard) return
    form.updateForm({
      otherAcademicStandards: xor(form.otherAcademicStandards, [otherStandard]),
    })
    setOtherStandard('')
  }

  return (
    <View style={[s.mb20]}>
      <SubmissionLabel
        text={text}
        secondaryText={secondaryText}
        hasError={some(form.errors, { field })}
      />
      <View style={[s.row, s.zIndex1]}>
        <View style={[ isMobile ? s.col6 : s.col3, s.px16]}>
          <DropDown
            title={category || 'Choose a Category'}
            width="100%"
            field="category"
            fn={(field, option) => setCategory(option)}
            options={['CCSS.MATH', 'CCSS.ELA', 'NGSS', 'CSTA', 'ISTE', 'Other']}
            errors={some(form.errors, { field })}
          />
        </View>
        <View style={[s.col6, s.px16]}>
          {category !== 'Other' ? (
            <StandardsAutoComplete
              field="academicStandards"
              category={category}
            />
          ) : (
            <View style={[s.flexRow]}>
              <View style={[s.w500, s.b1, s.bGrayLighter, s.br5, s.mr20]}>
                <TextInput
                  value={otherStandard}
                  onChangeText={text => setOtherStandard(text)}
                  style={[s.h40, s.w100p, s.pl16, s.pr16, s.f16, s.br5]}
                  onSubmitEditing={onAddOther}
                  placeholder="Eg. <Name>.<Subject>.<Details>: CCSS.MATH.A.01"
                />
                {otherStandard !== '' && (
                  <View style={[s.positionAbsolute, { top: 12, right: 15 }]}>
                    <RemoveX text="" onPress={() => setOtherStandard('')} />
                  </View>
                )}
              </View>
              <Button text="Add" icon="plus" onPress={onAddOther} />
            </View>
          )}
        </View>
      </View>

      <View style={[s.mt20, s.flexRow, s.flexWrap]}>
        {[...form.academicStandards, ...form.otherAcademicStandards].map(
          (item, i) => {
            // FIXME: API returns standards as an object
            if (typeof item === 'object') {
              item = item.label
            }
            return (
              <SelectedItem
                key={i}
                text={item}
                onRemove={() => removeItem(i)}
              />
            )
          }
        )}
      </View>
    </View>
  )
}

export default withSubmissionForm(StandardsGroup)
