import React from 'react'
import { View, Text } from 'react-native'
import s from 'styles'

export default function Alert({ mt = '0', mb = '0', color = 'Red', children }) {
  return (
    <View style={[s['bg' + color], s['mt' + mt], s['mb' + mb], s.px32, s.py8]}>
      <Text style={[s.textWhite, s.f16]}>{children}</Text>
    </View>
  )
}
