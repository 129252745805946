import React from 'react'
// import LargeIconButton from '../../../components/LessonSubmission/LargeIconButton'
import SubmissionAttachments from './SubmissionAttachments'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'

const SubmissionAttachmentsGroup = ({ text, field, secondaryText, form }) => {
  return (
    <>
      <SubmissionLabel text={text} secondaryText={secondaryText} />
      <SubmissionAttachments
        attachments={form[field]}
        attachmentsFolder={form.attachmentsFolder}
        onChange={attachments => form.updateForm({ [field]: attachments })}
      />
    </>
  )
}

export default withSubmissionForm(SubmissionAttachmentsGroup)
