import React from 'react'
import { useDropzone } from 'react-dropzone'

const FilePicker = ({
  children,
  onFiles,
  types = undefined,
  maxFileSizeForMIME = undefined,
  onError = undefined
}) => {
  const onDrop = files => onFiles(files)

  const onDropRejected = fileRejections => {
    for (const rejection of fileRejections) {
      for (const error of rejection.errors) {
        console.warn(error.message)
        onError && onError(error)
      }
    }
  }

  const validator = file => {
    if(maxFileSizeForMIME) {
      const maxSize = maxFileSizeForMIME(file.type)
      if (file.size > maxSize) {
        const MB = 1048576
        return {
          message: `The file size of "${file.name}" exceeds ${Math.floor(maxSize / MB)} MB`,
          code: 'file-too-large',
        }
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: types ? types.join(',') : '*',
    multiple: false,
    onDrop,
    onDropRejected,
    validator,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

export default FilePicker
