import { createSlice, createSelector } from '@reduxjs/toolkit'
import { persist } from '../persist'
import { OZOBOT_AUTHOR_ID } from '../../lib/utils.js'

const initialState = {
  firstName: '',
  initials: '',
  lastName: '',
  profileImageUrl: '',
  email: '',
  phone: '',
  jobTitle: '',
  school: '',
  license: '',
  products: [],
  emailNotifications: ['emailUpdates'],
  receiveEmails: false,
  progress: {},
  misc: {},
  hideCommunicatorReminder: false,
  bots: [],
  communicator: '',
  pdCredits: [],
  lessonRatings: [],
  isSignedIn: false,
  isVerified: false,
  isOnboarded: false,
  simplifiedOzobotConnect: false,

  roles: [],

  // devices info TODO: should be moved to some sort of settings table, eventually
  evoCount: 0,
  bitCount: 0,
  occCount: 0,
  showProductSurvey: true,
  isExploring: false,
  isStudentRemindedToUpdate: true,

  recentLessonSearches: [],
}

function getUserInitials(names) {
  return names.reduce((prev, curr) => {
    return prev + curr.charAt(0).toUpperCase()
  }, '')
}

function formattedUser(user) {
  return {
    ...user,
    initials: getUserInitials([user.firstName, user.lastName]),
  }
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userViewRequest: state => {},
    userViewSuccess: (state, { payload }) => {
      return formattedUser({
        ...state,
        ...payload,
      })
    },

    userCreateRequest: (state, { payload }) => {},
    userCreateSuccess: (state, { payload }) => {
      return formattedUser({
        ...state,
        ...payload,
        accountWasCreated: true,
      })
    },
    userCreateFailure: (state, { payload }) => {},

    // Return all info about user
    userUpdateRequest: (state, { payload }) => {},
    userUpdateSuccess: (state, { payload }) => {
      return formattedUser({
        ...state,
        ...payload,
      })
    },

    userDeleteRequest: (state, { payload }) => {},
    userDeleteSuccess: (state, { payload }) => {
      return initialState
    },

    userAuthEmailSignInRequest: (state, { payload }) => {},
    userAuthEmailSignInSuccess: (state, { payload }) => {
      state.isSignedIn = true
      state.isVerified = payload.providerIsVerified      
    },
    userAuthGoogleSignInRequest: (state, { payload }) => {},
    userAuthGoogleSignInSuccess: (state, { payload }) => {
      state.isSignedIn = true
      state.isVerified = payload.providerIsVerified
    },
    userAuthGoogleSignInFailure: (state, { payload }) => {},
    userAuthGoogleLinkRequest: (state, { payload }) => {},
    userAuthGoogleLinkSuccess: (state, { payload }) => {
      state.providers = payload
    },
    userAuthGoogleLinkFailure: (state, { payload }) => {},

    userAuthUnlinkRequest: (state, { payload }) => {},
    userAuthUnlinkSuccess: (state, { payload }) => {
      state.providers = payload
    },
    userAuthGoogleRedirectedToURL: (state, { payload }) => {},

    userAuthCodeRequest: state => {},
    userAuthCodeSuccess: (state, { payload }) => {},

    // Log user out
    userAuthSignoutRequest: state => {},

    userAuthCheckIfSignedOut: (state, { payload }) => {},
    userAuthCheckIfSignedIn: state => {},
    userAuthCheckIfVerified: state => {},

    userEventRequest: (state, { payload }) => {},
    userError: (state, { payload }) => {
      // Error: 400 Bad Request
      // Regex message
      // Bad license
      // Bad settings
      // Bad password
    },

    // USER_CODE_LOGIN_REQUEST: code,
    userCodeLoginRequest: (state, { payload }) => {},

    // TODO this only save the state localy, add to DB
    userSetOnboarded: (state, { payload }) => {
      return {
        ...state,
        isOnboarded: payload,
      }
    },

    // TODO this only save the state localy, add to DB
    userSetSimplifiedOzobotConnect: (state, { payload }) => {
      return {
        ...state,
        simplifiedOzobotConnect: payload,
      }
    },

    // USER_PASSWORD_FORGOT_REQUEST: email,
    userPasswordForgotRequest: (state, { payload }) => {},
    userPasswordForgotSuccess: (state, { payload }) => {
      state.email = payload.email
    },

    // USER_PASSWORD_NEW_REQUEST: token, password,
    userPasswordResetRequest: (state, { payload }) => {},
    userPasswordResetSuccess: (state, { payload }) => {},

    // USER_PASSWORD_CHANGE_REQUEST: oldPassword, newPassword,
    userPasswordChangeRequest: (state, { payload }) => {},
    userPasswordChangeSuccess: (state, { payload }) => {},

    userVerificationStatusUpdated: (state, { payload }) => {
      state.isVerified = payload.providerIsVerified
      state.roles = payload.roles || state.roles
    },

    userVerifyEmailRequest: (state, { payload }) => {},
    userVerifyEmailSuccess: (state, { payload }) => {},

    useSendVerificationEmailRequest: (state, { payload }) => {},
    useSendVerificationEmailSuccess: (state, { payload }) => {},

    userProductUnlockRequest: (state, { payload }) => {
      state.unlockingStatus = 'processing'
    },
    userProductUnlockSuccess: (state, { payload }) => {
      state.unlockingStatus = 'success'
    },
    userProductUnlockFailure: (state, { payload }) => {
      state.unlockingStatus = 'error'
    },

    // USER_UPDATE_PROGRESS_REQUEST: namespace, progress,
    userUpdateProgress: (state, { payload }) => {},

    userSetMiscValuesRequest: (state, { payload }) => {
      state.misc = {
        ...state.misc,
        ...payload,
      }
    },
    userSetMiscValuesSuccess: (state, { payload }) => {},
    userSetMiscValuesFailure: (state, { payload }) => {},

    googleClassroomRequest: (state, { payload }) => {},
    googleClassroomSuccess: (state, { payload }) => {
      state.googleClassroom = payload.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
    },
    importGoogleCoursesRequest: (state, { payload }) => {},

    userAuthEmailSettingsRequest: (state, { payload }) => {},
    userAuthEmailSettingsSuccess: (state, { payload }) => {
      state.providers = payload
    },
    userAuthEmailSettingsFailure: (state, { payload }) => {},

    userAwardPDCreditRequest: (state, { payload }) => {},
    userAwardPDCreditSuccess: (state, { payload }) => {
      state.pdCredits.push(payload)
    },
    userAwardPDCreditFailure: (state, { payload }) => {},

    userAddRecentSearchQuery: (state, { payload }) => {
      const query = payload.query
      if(query) {
        const previous = state.recentLessonSearches.filter(s => s !== query)
        state.recentLessonSearches = [query, ...previous].slice(0, 32)
      }
    },
  },
})

export const {
  userViewRequest,
  userViewSuccess,
  userCreateRequest,
  userCreateSuccess,
  userCreateFailure,
  userUpdateRequest,
  userUpdateSuccess,
  userDeleteRequest,
  userDeleteSuccess,
  userAuthEmailSignInRequest,
  userAuthEmailSignInSuccess,
  userAuthCodeRequest,
  userAuthCodeSuccess,
  userAuthSignoutRequest,
  userAuthCheckIfSignedOut,
  userAuthCheckIfSignedIn,
  userAuthCheckIfVerified,
  userEventRequest,
  userError,

  userEmailSignIn,
  userAuthGoogleSignInRequest,
  userAuthGoogleSignInSuccess,
  userAuthGoogleSignInFailure,
  userAuthGoogleLinkRequest,
  userAuthGoogleLinkSuccess,
  userAuthGoogleLinkFailure,
  userAuthEmailSettingsRequest,
  userAuthEmailSettingsSuccess,
  userAuthEmailSettingsFailure,
  userAuthUnlinkRequest,
  userAuthUnlinkSuccess,
  userAuthGoogleRedirectedToURL,
  userCodeLoginRequest,
  userPasswordForgotRequest,
  userPasswordForgotSuccess,
  userPasswordResetRequest,
  userPasswordResetSuccess,
  userPasswordChangeRequest,
  userPasswordChangeSuccess,
  userVerificationStatusUpdated,
  userVerifyEmailRequest,
  userVerifyEmailSuccess,
  useSendVerificationEmailRequest,
  useSendVerificationEmailSuccess,
  userProductUnlockRequest,
  userProductUnlockSuccess,
  userProductUnlockFailure,
  userSetOnboarded,
  userSetSimplifiedOzobotConnect,
  userUpdateProgress,
  userSetMiscValuesRequest,
  userSetMiscValuesSuccess,
  userSetMiscValuesFailure,
  googleClassroomSuccess,
  googleClassroomRequest,
  importGoogleCoursesRequest,
  userAwardPDCreditRequest,
  userAwardPDCreditSuccess,
  userAwardPDCreditFailure,
  userAddRecentSearchQuery,
} = user.actions

export const userSelector = s => s.user
export const nameSelector = s => [s.user.firstName, s.user.lastName].join(' ')
export const licenseSelector = s => s.user.license
export const accountIdSelector = s => s.user.accountId
export const signedInSelector = s => s.user.isSignedIn
export const signedInAndVerifiedSelector = s => s.user.isSignedIn && s.user.isVerified
export const ozobotAuthorSignedInSelector = s => s.user.accountId === OZOBOT_AUTHOR_ID
export const hasGoogleProviderSelector = s =>
  s.user.providers &&
  s.user.providers.length &&
  s.user.providers.some(provider => provider.providerType === 'google')
export const hasProvider = (s, providerId) =>
  s.user.providers &&
  s.user.providers.length &&
  s.user.providers.some(provider => provider.providerId === providerId)
export const userMiscValuesSelector = s => s.user.misc
export const userBasicTrainingCompletedSelector = createSelector(
  userMiscValuesSelector,
  misc => {
    const progress = misc.basicTrainingProgress
      ? Object.values(misc.basicTrainingProgress)
      : []
    // Note: For new users, basicTrainingProgress was empty so it evaluated true
    return progress.length === 3 && Object.values(progress).every(Boolean)
  }
)
export const userPDCreditsSelector = s =>
  s.user.pdCredits.filter(x => x.certificateUrl)
export const userBasicTrainingPDSelector = s =>
  s.user.pdCredits.find(pd => pd.event === 'basicTraining')

export const userEmailsAreVerifiedSelector = s => {
  return !s.user.providers.find(p => !p.isVerified)
}

export const userUnverifiedEmailsSelector = s => {
  return s.user.providers.filter(p => !p.isVerified).map(p => p.email)
}

export const userUnlockingStatusSelector = s => s.user.unlockingStatus

export const userCanViewORALessonsSelector = s => s.user.roles?.includes('ora')

export const userMightNeedToUpdateABotSelector = s => {
  const assetCRC = s.botz?.assetsInfo?.evoAssetsCRC
  return assetCRC && assetCRC !== s.user.misc?.lastBotUpdateAssetCRC
}

export const userRecentLessonSearchesSelector = s => s.user.recentLessonSearches

// we need a special root migration because the previous persist was handled at the root.
// We want to handle persist here, so that we can easily blacklist / whitelist what we need,
// and gracefully handle migrations locally.
export const migrateUserFromRootPersist = state => {
  return {
    // copy over new fields from initialState
    ...initialState,
    ...state,
  }
}

const migrations = {
  0: state => ({
    ...state,

    // devices info
    occCount: 0,
    evoCount: 0,
    bitCount: 0,
    isExploring: false,
  }),
  1: state => ({
    ...state,
    pdCredits: Object.values(state.pdCredits),
  }),
  2: state => ({
    ...state,
    recentLessonSearches: state.recentLessonSearches || [],
  }),
  3: state => ({
    ...state,
    receiveEmails: state.receiveEmails || !!state.emailPreferences?.length,
    emailPreferences: undefined,
  }),
}

export default persist({
  key: 'user',
  version: 3,
  migrations,
  reducer: user.reducer,
})
