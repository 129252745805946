import React from 'react'
import { Picker, Text, TextInput, View } from 'react-native'
import { Button } from 'components/Form'
import NumberChooser from 'components/NumberChooser/NumberChooser'
import RemoveX from './RemoveX'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const MaterialItem = ({ grouping, item, count, update, onRemove }) => {
  const [input, setInput] = React.useState(item)

  const onChangeText = input => setInput(input)
  const onBlur = () => update({ item: input })

  const { isDesktop } = useDetectDeviceType();
  const flexStyles = !isDesktop ? [s.flexColumn, s.gap15] : [s.flexRow];

  return (
    <View
      style={[s.p20, s.mb20, s.justifyBetween, s.bgWhite, s.bShadow, ...flexStyles ]}
    >
      <View style={[...flexStyles]}>
        <View style={[ isDesktop ? s.px16 : null ]}>
          <Text style={[s.mb4, s.textCenter, s.textBlackLighter, s.f14]}>
            ITEM NAME
          </Text>
          <TextInput
            placeholderTextColor="#313847"
            style={[
              s.w100p,
              s.h40,
              s.bBottom1,
              s.bBlackLighter,
              s.f16,
              s.textCenter,
              s.textBold,
              s.outlineStyleNone,
            ]}
            onChangeText={onChangeText}
            value={input}
            onBlur={onBlur}
            placeholder="Type your item here"
          />
        </View>
        <View style={[s.px16, s.alignCenter]}>
          <Text style={[s.mb4, s.textBlack, s.f14]}>QUANTITY</Text>
          <NumberChooser
            name="count"
            count={count}
            max="999"
            onChange={obj => update(obj)}
          />
        </View>
        <View style={[s.px16, s.alignCenter]}>
          <Text style={[s.mb4, s.textBlack, s.f14]}>GROUPING</Text>
          <Picker
            style={[s.h40, s.px16, s.b1, s.bBlackLighter, s.br5]}
            selectedValue={grouping}
            onValueChange={grouping => update({ grouping })}
          >
            {['Per Group', 'Per Class', 'Per Student'].map(item => (
              <Picker.Item key={item} label={item} value={item} />
            ))}
          </Picker>
        </View>
      </View>
      <RemoveX onPress={onRemove} />
    </View>
  )
}

const SubmissionMaterialsGroup = ({
  field,
  form,
  formAddItem,
  formUpdateItem,
  formRemoveItem,
}) => {
  return (
    <View>
      {form[field].map((item, i) => (
        <MaterialItem
          key={`${field}:${i}:${item.item}`}
          {...item}
          update={obj => formUpdateItem(i, obj)}
          onRemove={() => formRemoveItem(i)}
        />
      ))}
      <View style={s.alignEnd}>
        <Button rounded={true} icon="plus" text="Add" onPress={formAddItem} />
      </View>
    </View>
  )
}

export default withSubmissionForm(SubmissionMaterialsGroup)
