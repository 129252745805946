import * as React from 'react'
import { View, Text } from 'react-native'
import { RouteComponentProps } from 'react-router-dom'
import { Content, Layout } from 'components/Layout'
import Button from 'components/Form/Button2'
import LottieView from 'react-native-web-lottie'
import s from 'styles'

const MissingPage: React.FC<RouteComponentProps> = ({ history }: RouteComponentProps) => {
  const onBack = () => {
    // New tabs have a history length of 1
    if (history.length !== 1) {
      return history.goBack()
    }

    return history.push('/')
  }

  return (
    <Layout hasLoggedInHeader={false} hasSidebar={false}>
      <Content>
        <View style={[s.flexRow, s.alignCenter, s.justifyCenter]}>
          <View style={[s.flex1, s.px45]}>
            <Text style={[s.f35, s.textTeal, s.textBold, s.mb20]}>
              Oops!
            </Text>
            <Text style={[s.f16, s.textBold, s.textGrayDarkest, s.mb20]}>
              {'We couldn\'t find what you\'re looking for.'}
            </Text>
            <Text style={[s.f16, s.textGrayDarkest, s.mb20]}>
              {'Page not found\nError code: 404'}
            </Text>
            <View style={{ width: 'fit-content'}}>
              <Button text='Go Back' onPress={onBack}/>
            </View>
          </View>
          <View style={s.flex1}>
            <View style={{ alignSelf: 'center', justifyContent: 'center' }}>
              <LottieView
                style={{ width: 440, height: 409 }}
                source={require('images/lottie-404.json')}
                autoPlay
                loop
              />
            </View>
          </View>
        </View>
      </Content>
    </Layout>
  )
}

export default MissingPage
