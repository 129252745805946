import React from 'react'
import { Text, TextStyle, ViewStyle } from 'react-native'

/**
 * Init array with given value.
 * 
 * @param length Length.
 * @param value  Value.
 * @returns Array filled with the same value.
 */
export function initArrayWith<T>(length: number, value: T): T[] {
  return Array(length).fill(value);
}

/**
 * For given N creates array [1, 2, .. N]
 * 
 * @param n Length.
 * @returns Array with ascending values representing index+1.
 */
export function createArray1toN(n: number): number[] {
  return [...(Array(n).keys() as unknown as number[])];
}

export interface TextStyleOptions {
  style?: (ViewStyle | TextStyle)[]
}


/**
 * Return text component from string or the same value if parameter is
 * a component.
 * 
 * @param val String or component
 * @param opts Text options.
 * @returns Component.
 */
export function textToComponent(val: React.ReactNode, opts?: TextStyleOptions): React.ReactNode {
  const {style} = opts

  if (typeof val === 'number' || typeof val === 'boolean') {
    val = String(val)
  }

  if (typeof val === 'string') {
    val = <Text style={style}>{val}</Text>
  }

  return val
}
