import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import { Platform } from 'react-native'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import reduxReset from 'redux-reset'
import { initSagas } from './rootSaga'
import { migrations } from './rootMigrations'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware({ context: {} })
const trackingMiddleware = store => next => action => {
  if (action.type === 'user/userViewSuccess') {
    const { accountId, hasGoogleClassroom } = action.payload

    try {
      window.ga('set', {
        userId: accountId,
        dimension1: hasGoogleClassroom ? 'google' : 'email', // loginMethod
      })
    } catch (err) {
      console.error('GA has not yet loaded')
    }
  }

  return next(action)
}
export const history = Platform.select({
  web: () => createBrowserHistory(),
  ios: () => createMemoryHistory(),
})()

// Add new reducers alphabetically
const rootReducer = combineReducers({
  autocomplete: require('./autocomplete').default,
  botz: require('./botz/reducer').default,
  dialogBox: require('./dialogBox/reducer').default,
  global: require('./global').default,
  lessons: require('./lessons').default.reducer,
  modal: require('./modal').default,
  notifications: require('./notifications').default.reducer,
  router: connectRouter(history),
  user: require('./user').default,
})

// to Persist specific keys in a reducer, use ../persist.js. To whitelist a whole reducer,
// use this persistConfig whitelist
const persistConfig = {
  version: 0,
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [], // Don't persist anything at root, please
  migrate: createMigrate(migrations),
}

const pr = persistReducer(persistConfig, rootReducer)

export default function configStore(initialState) {
  const composeWithDevTools =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    pr,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        sagaMiddleware,
        trackingMiddleware,
        routerMiddleware(history)
      ),
      reduxReset()
    )
  )

  initSagas(sagaMiddleware)

  return { store, persistor: persistStore(store) }
}
