import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import { ModalFrame } from 'components/modal'
import ProgressBar from 'components/ProgressBar'
import { Link } from 'components/Router'
import s from 'styles'

const durationStr = (duration: number) => {
  const minutes = Math.floor(duration / 60000)
  const seconds = Math.floor((duration - minutes * 60000) / 1000)
  return minutes > 0
    ? `${minutes} minutes and ${seconds} seconds`
    : `${seconds} seconds`
}

type OnCloseFunc = (obj?: { retry: boolean }) => void

const Wait = (props: { title: string, text: string }) => {
  return (
    <ModalFrame noCloseButton={true} title={props.title} size="medium">
      <View style={[s.alignCenter, s.py45]}>
        <Text style={[s.textBold, s.f24]}>Please wait...</Text>
        <Text style={[s.f22, s.py8]}>{props.text}</Text>
      </View>
    </ModalFrame>
  )
}

const FileSync = (props: { title: string, text: string, timeLeft: number, percent: number }) => {
  const timeString = props.timeLeft ? `, ${durationStr(props.timeLeft)} remaining` : ''
  return (
    <ModalFrame noCloseButton={true} title={props.title} size="medium">
      <View style={[s.alignCenter]}>
        <Text style={[s.textBold, s.f24]}>Please wait...</Text>
        <Text style={[s.f16, s.py16]}>{`${props.text}${timeString}`}</Text>
        <ProgressBar percent={props.percent} width={162} />
        <Text style={[s.f16, s.py20, s.textCenter]}>
          Make sure your Ozobot is fully charged or remain in charge.{'\n'}
          Please stay on this window until the update process is completed.
        </Text>
      </View>
    </ModalFrame>
  )
}

const RePair = (props: { title: string, text: string, onPress?: () => void }) => {
  const [isRePairing, setIsRePairing] = useState(false)
  const onOK = () => {
    setIsRePairing(true)
    props.onPress && props.onPress()
  }
  return isRePairing ? (
    <View />
  ) : (
    <ModalFrame noCloseButton={true} title={props.title} size="medium">
      <View style={[s.alignCenter]}>
        <Text style={[s.f24]}>{'✅'}</Text>
        <Text style={[s.textBold, s.f24]}>{props.text}</Text>
        <Text style={[s.f18, s.py8]}>Please pair your Ozobot Evo</Text>
        <View style={[s.py20]} ><Button text={'Pair'} onPress={onOK} /></View>
      </View>
    </ModalFrame>
  )
}

const Success = (props: { onClose: OnCloseFunc }) => {
  return (
    <ModalFrame noCloseButton={true} title={'Ozobot Evo is Updated!'} size="medium">
      <View style={[s.alignCenter]}>
        <Text style={[s.f24]}>{'✅'}</Text>
        <Text style={[s.textBold, s.f24]}>{'Your Ozobot Evo is updated!'}</Text>
        <View style={[s.py20]} ><Button text={'Ok'} onPress={props.onClose} /></View>
      </View>
    </ModalFrame>
  )
}

const Error = (props: { onClose: OnCloseFunc, isStudentView?: boolean }) => {
  const onPress = () => props.onClose({ retry: true })
  return (
    <ModalFrame title={'Error Reported'} onClose={props.onClose}>
      <View style={[s.alignCenter, s.py45]}>
        <Text style={[s.f24, s.textRed]}>{'‼️'}</Text>
        <Text style={[s.textBold, s.f24]}>Oops! Something went wrong.</Text>
        {props.isStudentView ? (
          <Text style={[s.f22, s.py8]}>
            Please try again or contact your educator.
          </Text>
        ) : (
          <Text style={[s.f22, s.py8]}>
            Please try again or access{' '}
            <Link to={'mailto:support@ozobot.com'}>
              <Text style={[s.textGreen, s.textUnderline]}>Help</Text>
            </Link>{' '}
            for support
          </Text>
        )}
        <View style={[s.flexRow, s.py20]}>
          <View style={[s.px32]}>
            <Button
              role={'secondary'}
              text={'Cancel'}
              onPress={props.onClose}
            />
            <Button text={'Try Again'} onPress={onPress} />
          </View>
        </View>
      </View>
    </ModalFrame>
  )
}

const genericPleaseWaitTitle = 'Please Wait...'
const genericPleaseWaitText = 'While we get everything setup'
const fileSyncTitle = 'File Synchronizing'

const phaseInfo = {
  starting: {
    component: Wait,
    title: genericPleaseWaitTitle,
    text: genericPleaseWaitText,
  },
  analyzing: {
    component: Wait,
    title: genericPleaseWaitTitle,
    text: genericPleaseWaitText,
  },
  scanning: {
    component: FileSync,
    title: fileSyncTitle,
    text: 'Scanning',
  },
  cleaning: {
    component: FileSync,
    title: fileSyncTitle,
    text: 'Cleaning',
  },
  uploading: {
    component: FileSync,
    title: fileSyncTitle,
    text: 'Uploading',
  },
  verifying: {
    component: FileSync,
    title: fileSyncTitle,
    text: 'Verifying',
  },
  sealing: {
    component: FileSync,
    title: fileSyncTitle,
    text: 'Sealing',
  },
  firmware: {
    component: Wait,
    title: genericPleaseWaitTitle,
    text: genericPleaseWaitText,
  },
  ble: {
    component: Wait,
    title: genericPleaseWaitTitle,
    text: genericPleaseWaitText,
  },
  finished: {
    component: Wait,
    title: genericPleaseWaitTitle,
    text: genericPleaseWaitText,
  },
  unknown: {
    component: Wait,
    title: genericPleaseWaitTitle,
    text: genericPleaseWaitText,
  },
}

const rePairInfo = {
  firmware: {
    title: 'Evo Firmware Updated',
    text: 'Your firmware has been updated!',
  },
  ble: {
    title: 'Evo BlueNRG Updated',
    text: 'Your Ozobot Bluetooth firmware\nhas been updated!',
  },
  unknown: {
    title: 'Evo Updated',
    text: 'Your Evo has been updated!',
  },
}

interface GlobalStore {
  botz: {
    updateProgressInfo?: {
      data?: {
        phase: string
      },
      percent: number,
      timeLeft: number,
    }
  }
}

const botProgressSelector = (s: GlobalStore) => {
  const progress = s.botz.updateProgressInfo
  return {
    phase: progress?.data?.phase,
    percent: progress?.percent,
    timeLeft: progress?.timeLeft,
  }
}

export interface BotUpdateProgressModalProps {
  hasError?: boolean,
  isFinished?: boolean,
  isRePairing?: boolean,
  onRePair?: () => void,
  isStudentView: boolean,
  closeModal: () => void,
}

// NOTE: the onRePair button should perform some action to reset the isRePairing flag (or close the modal)
export const BotUpdateProgressModal = (props: BotUpdateProgressModalProps) => {

  const { phase, percent, timeLeft } = useSelector(botProgressSelector)

  if (props.isFinished) {
    return <Success onClose={props.closeModal} />
  } else if (props.hasError) {
    return <Error onClose={props.closeModal} isStudentView={props.isStudentView} />
  } else if (props.isRePairing) {
    const { title, text } = rePairInfo[phase] || rePairInfo.unknown
    return <RePair title={title} text={text} onPress={props.onRePair} />
  } else {
    // return (<RePair title={'Evo Firmware Updated'} text={'Your firmware has been updated!'}/>)
    // return <FileSync phase={'Scanning'} timeRemaining={343787} percent={20} />
    // return (<Wait title={'File Synchronization'} text={'Please wait while we get everything setup.'}/>)
    // return (<Error onClose={closeModal} onRetry={closeModal} />)

    const { title, text, component } = phaseInfo[phase] || phaseInfo.unknown
    return React.createElement(component, {
      title,
      text,
      percent,
      timeLeft,
    })
  }
}

export default BotUpdateProgressModal

export const makeBotUpdateProgressModal = (props: BotUpdateProgressModalProps) => {
  return (<BotUpdateProgressModal {...props}/>)
}

