import { Image } from 'components/ReactNative';
import * as React from 'react';

interface IconArrowProps {
  color: 'teal' | 'grey';
  rotation: string;
}

function IconArrow({ rotation, color }: IconArrowProps) {
  return <Image
    style={{
      height: 9,
      width: 10,
      tintColor: color === 'teal' ? '#00B1B9' : null,
      transform: [
        {
          rotate: rotation,
        },
      ],
    }}
    resizeMode="contain"
    source={require('images/icon-arrow-grey.svg')} />;
}

export function SortArrowGreyUp() {
  return <IconArrow color="grey" rotation="0" />;
}

export function SortArrowGreyDown() {
  return <IconArrow color="grey" rotation="180deg" />;
}

export function SortArrowTealUp() {
  return <IconArrow color="teal" rotation="0" />;
}

export function SortArrowTealDown() {
  return <IconArrow color="teal" rotation="180deg" />;
}

export function TripleDot() {
  return <Image
    source={require('images/triple-dot.svg')}
    style={{ width: 23, height: 5 }} />;
}

export function TripleDotTeal() {
  return <Image
    source={require('images/triple-dot.svg')}
    style={{ width: 23, height: 5, tintColor: '#00B1B9' }} />;
}

export function IconRightArrowSmall() {
  return <Image
    source={require('images/icon-right-arrow-small.svg')}
    style={{width: 14, height: 8}}
  />
}
