import * as React from 'react'
import { View } from 'react-native'
import s from 'styles'

export interface ContentProps {
  children: React.ReactNode
}

const Content: React.FC<ContentProps> = ({ children }: ContentProps) => {
  return (
    <View style={[s.alignCenter, s.flex1]}>
      <View style={[s.w100p, s.flex1]}>{children}</View>
    </View>
  )
}

export default Content
