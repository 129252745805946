import React from 'react'
import { Column, Row } from 'react-native-web-ui-components'
import { Layout, Content } from 'components/Layout'
import { LeftCard } from './components/DevicePagesModal'
import { View } from 'react-native'
import s from 'styles'
import PageHeaderWithTitleAndDescription from 'components/Layout/PageHeaderWithTitleAndDescription'

const Devices = () => {
  return (
    <Layout>
      <PageHeaderWithTitleAndDescription
        title='Devices'
        description='Updating Evo is easy! Choose your update path below for single Evo bots; Classroom Kit and multi-bot users, select Evo App.'
      />
      <Content>
        <View style={[s.mx24]}>
          <Row>
            <Column xs={12} sm={6} md={4}>
              <LeftCard
                image={require('images/devices/Evo-App_Illustration-Classroom_Devices.png')}
                headline="Multi-Bot Update & Rename with Evo App"
                text="Single Bot or Multi-Bot"
                buttonText="Learn more"
                buttonLink="https://ozobot.com/multi-bot-update-renaming-using-the-evo-app"
              />
            </Column>
            <Column xs={12} sm={6} md={4}>
              <LeftCard
                image={require('images/devices/Blockly_Illustration-Classroom-Devices.png')}
                headline="Update Your Evo Robots with Ozobot Blockly"
                text="Single Bot"
                buttonText="Learn more"
                buttonLink="https://ozobot.com/updating-your-evo-robots-with-ozobot-blockly-2022/"
              />
            </Column>
            <Column xs={12} sm={6} md={4}>
              <LeftCard
                image={require('images/devices/Ozobot_Python_Illustration.png')}
                headline="Update Your Evo Robots with Python Beta"
                text="Single Bot"
                buttonText="Learn more"
                buttonLink="https://ozobot.com/updating-your-ozobot-evo-robots-with-python-beta-2022"
              />
            </Column>
          </Row>
        </View>
      </Content>
    </Layout>
  )
}

export default Devices
