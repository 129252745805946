import React, { Component } from 'react'
import { View, Text, TouchableOpacity, ScrollView } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'

// IMPLEMENTATION
// <DropDown
//   title="Add Selected to List"
//   options={this.props.listNames}
//   width={250}
//   selectedValue={this.props.selectedList}
//   fn={option =>
//     this.props.studentStringUpdate({
//       field: 'selectedList',
//       value: option,
//     })
//   }
// />

const delay = async milliseconds =>
  new Promise(resolve => {
    if (milliseconds > 0) {
      setTimeout(resolve, milliseconds)
    } else {
      resolve()
    }
  })

export default class Dropdown extends Component {
  static defaultProps = {
    lostFocusHandler: () => {},
    onFocus: () => {},
  }

  state = {
    isSelected: this.props.isSelected || false,
    itemInFocus: false,
  }

  onToggle = () => {
    if (this.state.isSelected) {
      this.props.lostFocusHandler()
    } else {
      this.props.onFocus()
    }
    this.setState({
      isSelected: !this.state.isSelected,
    })
  }

  collapse() {
    this.setState({ isSelected: false })
  }

  render() {
    const { options, field, fn, lostFocusHandler, style } = this.props
    const { isSelected } = this.state
    const { errors } = this.props

    const onBlur = async () => {
      // HACK: for race condition
      // onBlur is processed before (significantly, by 100's of ms) onPress events
      // to avoid a race condition where this onBlur kills off any pending button presses
      // on the actual drop-down items, we capture onFocus/onBlur events in the drop down items,
      // and use that to set a flag indicating that a button is in a "itemInFocus" state. If an
      // item is inFocus, then we know that a button press is probably pending, and we should
      // not kill our button items by setting "isSelected" to false.
      //
      // NOTE: I've kept commented-out timing console logs, in-case we need to revisit and inspect again

      // console.log('ON BLURR 1', (new Date()).toISOString())

      // Insert a short delay, so that any subsequent drop down item onFocus events are processed first,
      // allowing the itemInFocus value to be preemptively set (onFocus events seem to be processed in a
      // more or less synchronous batch, but rendering order dictate which are called first)
      await delay(1)

      // console.log('ON BLURR 2', (new Date()).toISOString())

      if (!this.state.itemInFocus) {
        this.setState({ isSelected: false, justClicked: false })
        lostFocusHandler()
      }
    }

    return (
      <View style={[s.positionRelative, s.zIndex2, style]}>
        <View style={[s.positionRelative]}>
          <TouchableOpacity
            onBlur={onBlur}
            onPress={this.onToggle}
            style={[
              s.flexRow,
              s.justifyBetween,
              s.alignCenter,
              s.px8,
              s.py8,
              s.b1,
              s.bTeal,
              isSelected
                ? {
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }
                : s.br5,
              isSelected ? s.bgTeal : s.bgWhite,
              {
                height: this.props.height,
                width: this.props.width,
              },
              errors && [s.bRed, s.bgOzoRed],
            ]}
          >
            <Text
              style={[
                s.f14,
                s.mr8,
                isSelected ? s.textWhite : s.textBlack,
                errors && s.textWhite,
                s.textBold,
                s.flex1,
              ]}
            >
              {this.props.title}
            </Text>
            <Image
              style={{ height: 10, width: 18 }}
              source={
                isSelected
                  ? errors
                    ? require('images/drop-down-arrow-faded-gray-inverted.svg')
                    : require('images/dropdown-arrow-down-gray-dark.svg')
                  : errors
                  ? require('images/drop-down-arrow-faded-gray.svg')
                  : require('images/classes-group-closed.svg')
              }
            />
          </TouchableOpacity>
          {isSelected && (
            <ScrollView
              style={[
                s.positionAbsolute,
                s.left0,
                s.right0,
                s.px8,
                s.bgWhite,
                s.b1,
                s.bTeal,
                {
                  maxHeight: 140,
                  top: 37,
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                },
              ]}
              contentContainerStyle={{ paddingTop: 15 }}
            >
              {options.map((option, i) => (
                <TouchableOpacity
                  key={i}
                  onFocus={() => {
                    // console.log('ON ITEM FOCUS', (new Date()).toISOString())
                    this.setState({ itemInFocus: true })
                  }}
                  onBlur={() => {
                    // console.log('ON ITEM BLURR', (new Date()).toISOString())
                    this.setState({ itemInFocus: false })
                  }}
                  onPress={() => {
                    // console.log('ON PRESS', (new Date()).toISOString())
                    fn(field, option) || this.setState({ isSelected: false })
                    this.setState({ itemInFocus: false })
                  }}
                >
                  <View style={[s.overflowHidden, s.mb8]}>
                    <Text numberOfLines={1} style={[s.f14, s.textBlack]}>
                      {option}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </ScrollView>
          )}
        </View>
      </View>
    )
  }
}
