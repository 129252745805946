import { some } from 'lodash'
import React, { useState, useCallback, useRef } from 'react'
import { View } from 'react-native'
import { useDropzone } from 'react-dropzone'
import ReactCrop from 'react-image-crop'
// import ImagePicker from 'react-native-image-crop-picker'
import { Button } from 'components/Form'
import { Image } from 'components/ReactNative'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'
import 'react-image-crop/dist/ReactCrop.css'

const arrayBufferToBase64 = buffer => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

const ImagePicker = ({ onPick, children }) => {
  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.onerror = () => console.warn(`Error: Cannot read ${file.name}`)
      reader.onload = () => {
        onPick({
          name: file.name,
          uri: `data:${file.type};base64,${arrayBufferToBase64(reader.result)}`,
          buffer: reader.result,
        })
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const onDropRejected = () => {
    console.warn(`Error: File exceeds 10 MB`)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: 10485760, // 10 MB
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

const pixelRatio = 2

const SubmissionCoverImageUploader = ({ field, form }) => {
  const [crop, setCrop] = useState({
    unit: '%',
    height: 100,
    aspect: 1,
  })
  const [completedCrop, setCompletedCrop] = useState(null)
  const [pickedImage, setPickedImage] = useState()
  const imgRef = useRef(null)
  const hasError = some(form.errors, { field })

  const addImage = imageResult => {
    setPickedImage(imageResult.uri)
  }

  const onImageLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const onSave = () => {
    // FIXME: Need RN Friendly
    const canvas = document.createElement('canvas')

    const image = imgRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = false

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    form.updateForm({ [field]: canvas.toDataURL() })
    setPickedImage(null)
  }

  // Picker, Picked, Default
  return (
    <View style={[s.alignCenter]}>
      {pickedImage ? (
        <View style={[s.alignCenter]}>
          <ReactCrop
            src={pickedImage}
            onImageLoaded={onImageLoad}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}
            onComplete={newCrop => setCompletedCrop(newCrop)}
            imageStyle={{ maxHeight: 400, objectFit: 'contain' }}
          />
          <View style={[s.mt20, s.flexRow, s.alignCenter]}>
            <Button
              style={[s.mx8]}
              role="secondary"
              text="Cancel"
              onPress={() => setPickedImage(null)}
            />
            <Button style={[s.mx8]} text="Save" onPress={onSave} />
          </View>
        </View>
      ) : (
        <ImagePicker onPick={addImage}>
          <View style={[s.alignCenter]}>
            {form[field] ? (
              <Image source={{ uri: form[field] }} style={[s.w400, s.h400]} />
            ) : (
              <View
                style={[
                  s.w400,
                  s.h400,
                  s.alignCenter,
                  s.justifyCenter,
                  s.br5,
                  s.b1,
                  hasError ? s.bRed : s.bGrayLighter,
                ]}
              >
                <Image
                  source={require('images/image-empty.svg')}
                  style={[
                    s.w200,
                    s.h200,
                    { tintColor: hasError ? '#fa7272' : null },
                  ]}
                />
              </View>
            )}
            <View style={[s.mt20]}>
              <Button
                presentationOnly={true}
                style={[s.alignStart]}
                text={completedCrop ? 'Change Image' : 'Upload Image'}
                role={hasError ? 'error' : 'primary'}
              />
            </View>
          </View>
        </ImagePicker>
      )}
    </View>
  )
}

export default withSubmissionForm(SubmissionCoverImageUploader)
