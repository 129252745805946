import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import { BOTS, CODING_STYLES, AUTHORS } from 'lib/utils.js'
import s from 'styles'

const FilterItem = ({ value, onRemove }) => (
  <TouchableOpacity
    onPress={onRemove}
    style={[
      s.flexRow,
      s.alignCenter,
      s.py4,
      s.br5,
      s.mr16,
      s.mb8,
      s.px8,
      s.bgWhiteDarkest,
    ]}
  >
    <Text style={[s.f16, s.textCenter, s.mr8, s.textBold]}>{value}</Text>
    <Image
      source={require(`images/icon-x.svg`)}
      style={{
        height: 12,
        width: 12,
      }}
    />
  </TouchableOpacity>
)

// The filter handling situation is so complicated that I don't want to mess with it for fear of breaking something.
// We'll just do a label mapping here (far from ideal), so that the filter items display labels instead of values.
const createValueMapping = entries => {
  const res = {}
  for(const entry of entries) {
    res[entry.value] = entry.label
  }
  return res
}

const filterMap = {
  ...createValueMapping(BOTS),
  ...createValueMapping(CODING_STYLES),
  ...createValueMapping(AUTHORS)
}

const getLabelFromValue = value => filterMap[value] || value

export default function FilterChoices({
  selected,
  isCategorizing,
  removeFilter,
  clearAll,
  searchTotal,
  query
}) {
  const shouldShowTotal = (selected.length > 0 || !!query || !isCategorizing) && !!searchTotal
  const selectionFiltersPresent = selected.length > 0 || !!query || !isCategorizing
  return (
    <View style={[s.flexColumn, selectionFiltersPresent && s.mb20]}>
      <View style={[s.flexRow, s.flexWrap, s.alignCenter]}>
        {selectionFiltersPresent && (
          <>
          {selected.map(({ field, value }, i) => (
              <FilterItem
                key={i}
                value={getLabelFromValue(value)}
                onRemove={() => removeFilter({ field, value })}
              />
            ))}
          </>
        )}
      </View>
      <View style={[s.flexRowReverse, s.justifyBetween, s.alignCenter]}>
        {shouldShowTotal &&
          <View style={[s.mlAuto]}>
            <Text style={[s.textBold]}>{searchTotal} lessons match your search</Text>
          </View>
        }
        {selectionFiltersPresent &&
          <Text
            onPress={clearAll}
            style={[s.py4, s.f16, s.textGreen, s.textUnderline, s.alignCenter]}
          >
              Clear All Filters
          </Text>
        }
      </View>
    </View>
  )
}
