import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom'

export { Router, Switch, Redirect, Route, useLocation, useHistory }
export { default as Link } from './Link'
export { default as PrivateRoute } from './PrivateRoute'
export { default as UnverifiedRoute } from './UnverifiedRoute'
export { default as PublicRoute } from './PublicRoute'
