import React from 'react'
import { ImageSourcePropType, View, Text, ImageBackground } from 'react-native'
import { Column, Row } from 'react-native-web-ui-components'
import { ModalFrame } from 'components/modal'
import { Dispatch } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { openURL } from 'lib/utils'
import { saveFileFromURL } from 'store/global'
import { modalShow, modalHide } from 'store/modal'
import {
  userAwardPDCreditRequest,
  userMiscValuesSelector,
  userBasicTrainingPDSelector,
  userSetMiscValuesRequest,
} from 'store/user'
import Button from 'components/Form/Button2'
import { Content, Layout } from 'components/Layout'
import { Image, CheckBox } from 'components/ReactNative'
import { Link } from 'components/Router'
import TextMarkdown from 'components/Markdown/TextMarkdown'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

interface UserMiscValues {
  basicTrainingProgress: {},
}

export interface PDCredit {
  id: number,
  accountId: string,
  seconds: number,
  title: string,
  event: string,
  eventType: string,
  certificateUrl: string,
  startedAt: string,
  endedAt: string,
  createdAt: string,
}

const Card = (props: {
  title: string,
  image: ImageSourcePropType,
  minutes: number,
  url: string,
  buttonText: string,
  isCompleted?: boolean,
  onChecked?: (checked: boolean) => void
}) => {

  const { isTablet, isDesktop, isMobile } = useDetectDeviceType();

  return (
    <View style={[
      s.br10,
      s.bTeal,
      s.bgGrayMoreLighter,
      s.bShadow,
      s.mt20,
      { width: isDesktop ? '30%' : ( isTablet ? '48%' : '100%') },
    ]}>
      {props.image && <Image source={props.image} style={{ 
        height: isMobile ? 200 : 250, 
        width: '100%', 
        borderTopLeftRadius: 10, 
        borderTopRightRadius: 10,
      }} resizeMode="cover" />}

      <View style={[s.m16]}>
        <View style={[s.flexRow, s.justifyBetween]}>
          <Text style={[ isMobile ? s.f14 : s.f16, s.textBold, s.textBlackLighter, s.mt20, s.mb8, s.textCenter]}>{props.title} | {`${props.minutes} min`}</Text>
          { isDesktop && <View style={[s.my16, s.alignCenter]}><Button rounded={true} role='secondary' text={props.buttonText} onPress={() => openURL(props.url, false)}/></View> }
        </View>
        {!!props.onChecked && (
          <View style={[s.flexRow, s.justifyBetween, s.alignCenter]}>
            <View style={[s.flexRow, isDesktop ? s.mb20 : null]}>
              <CheckBox style={{ marginRight: 10, backgroundColor: '#00b1b9' }}
                value={props.isCompleted}
                onValueChange={props.onChecked}
              />
              <Text style={[s.f12, s.textBlackLighter]}>{props.isCompleted ? 'Completed' : 'Check when complete'}</Text>
            </View>
            { !isDesktop && <View style={[s.alignCenter]}><Button small={true} rounded={true} role='secondary' text={props.buttonText} onPress={() => openURL(props.url, false)}/></View> }
          </View>
        )}
      </View>
    </View>
  )
}

const BasicTrainingCompletedModal = () => {
  const pdCredit = useSelector(userBasicTrainingPDSelector) as PDCredit
  const url = pdCredit?.certificateUrl
  const dispatch = useDispatch()
  const onPress = () => {
    dispatch(saveFileFromURL({ url, fileName: 'Ozobot Classroom Basic Training PD Credit.pdf' }))
    dispatch(modalHide())
  }
  return (
    <ModalFrame title="PD Certificate" size="medium">
      <View style={[s.alignCenter]}>
        <Text style={[s.f18, s.textBold, s.textBlackLighter, s.mb8, s.textCenter]}>
        Congrats! You completed Basic Training.
        </Text>
        <Text style={[s.f16, s.textBlackLighter, s.mb20, s.textCenter]}>
        Find your PD certificate in{' '}
          <Link to={'/settings/profile'}><Text style={[s.textGreen, s.textUnderline, s.textBold]}>Settings</Text></Link>.
        </Text>
        {url
          ? (<Button text={'Download Now'} onPress={onPress} />)
          : (<Text style={[s.f14, s.textGray, s.textItalic, s.textCenter]}>Please wait...</Text>)
        }
      </View>
    </ModalFrame>
  )
}

const btLessons = [
  {
    title: 'Get to Know Evo',
    image: require('images/educator-training/Get-to-know-Evo.jpg'),
    lessonID: 'lnHsHKD0kXTgueqAiT7Pg7jQT3',
  },
  {
    title: 'Color Codes Basic Training',
    image: require('images/educator-training/CC-basic-training.jpg'),
    lessonID: 'lnq1oGHjt9QgqluYp0AUlIWAyU',
  },
  {
    title: 'Blockly Basic Training',
    image: require('images/educator-training/Blockly-BasicTraining.jpg'),
    lessonID: 'lnJqEMMaSwTmiGMf2CHjRW6gWM',
  },
]

const delay = async (dur: number) =>
  new Promise(resolve => setTimeout(resolve, dur))

const setFinished = (
  lessonID: string,
  val: boolean,
  basicTrainingProgress: {},
  hasPDCredit: boolean,
  dispatch: Dispatch,
) => {
  const newProgress = {
    ...basicTrainingProgress,
    [lessonID]: val,
  }

  // set new progress
  dispatch(userSetMiscValuesRequest({
    basicTrainingProgress: newProgress,
  }))
  // show dialog if needed

  const hasALLLessons = (idSet: {}) => {
    return !btLessons.find(l => !idSet[l.lessonID])
  }

  const prevHasAllSet = hasALLLessons(basicTrainingProgress)
  const currHasAllSet = hasALLLessons(newProgress)

  if(currHasAllSet && !prevHasAllSet) {
    if(!hasPDCredit) {
      dispatch(userAwardPDCreditRequest({
        event: 'basicTraining',
        eventType: 'general',
        title: 'Educator Basic Training',
        seconds: 60 * 60,
        endedAt: (new Date()).toISOString(),
      }))
    }

    // delay slightly, so that the check mark can be rendered visibly and distinctly, before the "side effect"
    // of the dialog box is realized
    delay(500).then(() => dispatch(modalShow(<BasicTrainingCompletedModal/>)))
  }
}

const SectionTitle = ({ text, subtext }: { text: string, subtext: string }) => (
  <View style={[]}>
    <Text style={[s.f18, s.textBold]}>{text}</Text>
    <Text>{subtext}</Text>
  </View>
)

const BasicTraining = () => {
  const { basicTrainingProgress = {} } = useSelector(userMiscValuesSelector) as UserMiscValues
  const pdCredit = useSelector(userBasicTrainingPDSelector) as PDCredit

  // const hasPDF = false
  const dispatch = useDispatch()
  const { isMobile, isTablet, isDesktop } = useDetectDeviceType();

  return (
    <Layout>
      <Content>
        <View style={[isDesktop ? s.w33p : ( isTablet ? { width: '60%' } : s.w90p )]}>
          <View>
            <ImageBackground source={require('images/educator-training/Top-Header.png')} resizeMode="cover" imageStyle={isMobile ? { height: 50} : { height: 90 }}>
              { isMobile ?
                <Text style={[ s.f24, s.textBold, s.textItalic, s.textWhite, s.pl16, { paddingVertical: 10 } ]}>Educator Training</Text>
                : 
                <Text style={[ s.f35, s.textBold, s.textItalic, s.textWhite, s.p24, s.textCenter, s.w500]}>Educator Training</Text>
              }
            </ImageBackground>
          </View>
          <Text style={[isMobile ? s.px16 : s.px24, s.py8, s.f18]}>NEW Basic Training</Text>
        </View>
        <View style={[ isMobile ? s.m16 : s.m32]}>
          <SectionTitle text='Basic Training' subtext='Match student’s lessons, so you’ll finish training ready to teach your first class!'/>
          <Row style={[s.justifyBetween]}>
            {btLessons.map((l, i) => (
              <Card
                key={i}
                title={l.title}
                url={`/lessons/${l.lessonID}/public`}
                image={l.image}
                minutes={20}
                buttonText="Start Lesson"
                isCompleted={!!basicTrainingProgress[l.lessonID]}
                onChecked={val => setFinished(l.lessonID, val, basicTrainingProgress, !!pdCredit, dispatch)}
              />
            ))}
          </Row>
          <Row style={[s.mxn16, s.mb32, s.mt32]}>
            <Column style={[s.px16]}>
              <TextMarkdown text="Find Basic Training lessons for students in the [Lesson Library](/lessons)." />
            </Column>
          </Row>
        </View>
      </Content>
    </Layout>
  )
}

export default BasicTraining
