import * as React from 'react'
import { View, Text } from 'react-native'
import { RouteComponentProps } from 'react-router-dom'
import { Content, Layout } from 'components/Layout'
import s from 'styles'

const LmsNotice: React.FC<RouteComponentProps> = () => {
  return (
    <Layout hasLoggedInHeader={false} hasSidebar={false}>
      <Content>
        <View style={[s.flexRow, s.alignCenter, s.justifyCenter]}>
          <View style={[s.flex1, s.px45]}>
            <Text style={[s.f16, s.textBold, s.textGrayDarkest, s.mb20, s.textCenter]}>
              {'Authentication completed, Please close this tab and go to LMS app on previous tab!'}
            </Text>
          </View>
        </View>
      </Content>
    </Layout>
  )
}

export default LmsNotice
