const machineOkay =
  window.location.host === 'dev.classroom.ozobot.com' ||
  window.location.host === 'stg.classroom.ozobot.com' ||
  window.location.host === 'localhost:8080'

const url = new URL(window.location.href)

const enableVideoSubmission = url.searchParams.get('enable-video-submission')
const noOCCUpdate = url.searchParams.get('no-occ-update')
const occParams = url.searchParams.get('occ-params')
const botUpdateFALTASTest = url.searchParams.get('bot-update-faltas-test')
const canAssignAll = url.searchParams.get('assign-all')

if (noOCCUpdate) {
  console.log('Disabling OCC Update')
}

if (enableVideoSubmission) {
  console.log('Enable Video Submission')
}

if (occParams) {
  console.log('OVERRIDING OCC PARAMS', occParams)
}

if (botUpdateFALTASTest) {
  console.warn('USING BOT UPDATE FALTAS TEST (Things may not work correctly)')
}

const forceUSB = url.searchParams.get('force-usb') === 'true'
if (forceUSB) {
  console.log('FORCE USB CONNECTION')
}

const forceFirmware =
  machineOkay && url.searchParams.get('force-firmware') === 'true'

export const modes = {
  forceUSB,
  forceFirmware,
  enableVideoSubmission,
  noOCCUpdate,
  occParams,
  botUpdateFALTASTest,
  canAssignAll,
}
