import * as React from 'react'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import OzobotBackground from './components/OzobotBackground'
import { HeaderEducator } from 'components/Header'
import Markdown from '@ozobot/react-native-simple-markdown'
import s, { styles } from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import { useSendVerificationEmailRequest, userSelector } from 'store/user'

const Verify: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  const [isEmailSent, setIsEmailSent] = React.useState(false)

  const user = useSelector(userSelector)

  const submit = () => {
    dispatch(useSendVerificationEmailRequest({ emails: [ user.email ] }))
    setIsEmailSent(true)
  }

  return (
    <>
      <HeaderEducator/>
      <View style={[{ height: '80%' }]}>
        <OzobotBackground>
          <View style={[s.flex1, { width: 260 }]}>
            <>
              <Text style={[s.textCenter, s.f24, s.textGreen, s.textBold, s.mb20]}>
                {isEmailSent ? 'Check Your Inbox!': 'Verify Your Account!'}
              </Text>
              <View style={s.mb20}>
                <Markdown styles={{text: {...styles.f14, ...styles.textBlack}}}>
                  {`We sent a message to **${user.email}** to verify your email address. If you don’t receive it within a few minutes, check your email’s spam and junk folders.`}
                </Markdown>
              </View>
              <View style={[s.alignSelfCenter, { width: 'fit-content'}]}>
                <Button
                  text={'Resend Email'}
                  onPress={submit}
                  disabled={isEmailSent}
                />
              </View>
            </>
          </View>
        </OzobotBackground>
      </View>
    </>
  )
}

export default Verify
