import * as React from 'react'
import { Text, View, Image, TouchableOpacity } from 'react-native'
import s from 'styles';

export type ButtonRoles = 'primary' | 'secondary' | 'error' | 'justText' | 'greenBgWhiteText' | 'callout';

export interface ButtonProps {
  text: string,
  role?: ButtonRoles,
  rounded?: boolean,
  straight?: boolean,
  icon?: string,
  presentationOnly?: boolean,
  small?: boolean,
  narrow?: boolean,
  disabled?: boolean,
  onPress?: () => void,
}

const getIconSource = icon => {
  const icons = {
    plus: require('images/lessons-plus.svg'),
    plusFat: require('images/icon-plus-fat-white.svg'),
    play: require('images/icon-play.svg'),
    playContinue: require('images/icon-play-continue.svg'),
    greyCheckCircle: require('images/icon-greyCheckCircle.svg'),
    question: require('images/OC-Student_ask-teacher.svg'),
  }
  return icons[icon]
}

const getRoleStyle = (role, isDisabled) => {
  const styles = {
    primary: {
      view: [s.bgGreen, s.br25],
      text: [s.textBlackLighter],
    },
    secondary: {
      view: [s.b1, s.bGreenDark],
      text: [s.textGreenDark],
    },
    greenBgWhiteText: {
      view: [s.bgGreenDark, s.br25],
      text: [s.textWhite],
    },
    justText: {
      view: [],
      text: [],
    },
    error: {
      view: [s.bgOzoRed],
      text: [s.textWhite],
    },
    primaryDisabled: {
      view: [s.bgGrayLighter, s.br25],
      text: [s.textWhite],
    },
    secondaryDisabled: {
      view: [s.b1, s.bGrayLighter],
      text: [s.textGray],
    },
    errorDisabled: {
      view: [s.bgGrayLighter],
      text: [s.textWhite],
    },
    callout: {
      view: [s.b1, s.bBlueDark],
      text: [s.textBlueDark],
    },
  }

  return styles[role + (isDisabled ? 'Disabled' : '')]
}

export const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const {
    text,
    role = 'primary',
    rounded,
    straight,
    icon,
    presentationOnly,
    small,
    narrow,
    disabled = false,
    onPress,
  } = props
  return (
    <TouchableOpacity
      onPress={onPress}
      data-role={role}
      disabled={presentationOnly || disabled}
    >
      <View
        style={[
          s.flexRow,
          s.alignCenter,
          s.justifyCenter,
          rounded ? s.br25 : s.br5,
          straight && s.br0,
          small ? [s.py4, s.px8] : narrow ? [s.py8, s.px8] : [s.py8, s.px20],
          getRoleStyle(role, disabled).view,
        ]}
      >
        {icon && (
          <Image
            source={getIconSource(icon)}
            style={{
              height: 15,
              width: 15,
              marginRight: 5,
            }}
            resizeMode="contain"
          />
        )}
        <Text
          style={[
            small ? s.f14 : s.f16,
            s.textBold,
            getRoleStyle(role, disabled).text,
          ]}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export default Button
