import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

const s = StyleSheet.create({
  view: {
    backgroundColor: '#F7F7F7',
    borderRadius: 5,
    paddingHorizontal: 25,
    paddingVertical: 30,
  },
})

export default class Well extends Component {
  render() {
    return <View style={s.view}>{this.props.children}</View>
  }
}
