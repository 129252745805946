import React from 'react'
import Tippy from '@tippyjs/react/headless'
import { TouchableOpacity, View, Text } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { Link } from 'components/Router'
import { useRouteMatch } from 'react-router'

const SubMenuIcon = () => {
  return (
    <TouchableOpacity activeOpacity={1}>
      <View style={[s.flexRow, s.justifyStart, s.alignEnd, s.w24, s.h24, s.mb16]}>
        <Image style={{ height: 8, width: 12 }} source={require('images/icon-down-arrow.png')}/>
      </View>
    </TouchableOpacity>
  )
}

interface SubMenuLink {
  url: string,
  text: string,
}

const SubMenu = ({links} : { links: SubMenuLink[] }) => {
  const { path } = useRouteMatch();

  const selectedMenuStyles = [s.textBold, s.textGreenDark];

  return (
    <View style={[s.bgGrayMoreLighter, s.bShadow, s.br10, s.w200, s.p10]}>
      <View style={[s.flexColumn]}>
        {links.map(
          ({ url, text }) => {
            return (
              <View key={url} style={[s.mb8, s.mx16]}>
                <Link to={url}>
                  <View style={[s.justifyCenter]}>
                    <Text style={[s.f16, (path == url) ? selectedMenuStyles : null]}>{text}</Text>
                  </View>
                </Link>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}
export default function DesktopSubMenu({links} : { links: SubMenuLink[] }) {
  return (
    <Tippy
      interactive={true}
      appendTo={document.body}
      placement={'bottom'}
      theme={'light'}
      arrow={true}
      zIndex={9999}
      trigger="click"
      render={() => <SubMenu links={links}/>}
    >
      <View style={[s.ml8, s.mt4]}><SubMenuIcon /></View>
    </Tippy>
  )
}
