import React from 'react'
import { FormContext } from 'components/Form'
import { Content, Layout, PageH1, PageHeader } from 'components/Layout'
import SubmissionFooter from './SubmissionFooter'
import SubmissionProgressButtons from './SubmissionProgressButtons'
import { View } from 'react-native'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

// TODO: FIXME: Most of the logic of lesson submission can be factored into this component as well,
// but my first attempt produced some errors which I don't have time to fix...
const SubmissionLayout = ({
  step,
  steps,
  isAdminUser,
  text,
  formProviderValue,
  scrollView,
  isFetching,
  errorMessage,
  goTo,
  onSubmit,
  onSave,
}) => {

  const { isDesktop } = useDetectDeviceType()
  const { component, ...props } = steps[step - 1]

  const ProgressButtons = () => (<SubmissionProgressButtons
    currentStep={step}
    steps={steps}
    to={goTo}
  />)

  return (
    <Layout ref={scrollView} isFetching={isFetching}>
      <PageHeader>
        <PageH1 text={text} />
      </PageHeader>
      <Content>
        {!isDesktop && <View style={[s.mt20, s.mb20]}>
          <ProgressButtons/>
        </View>}
        <View
          style={[
            isDesktop
              ? [
                s.w80p, s.px100, s.py30, s.mb120, s.mt32
              ]
              : [
                s.mx16, s.px32, s.pb16, s.pt32, s.mb72, s.mt8
              ],
            s.mAuto,
            s.bgGrayMoreLighter,
            s.br20,
            s.bShadow,
          ]}
        >
          {isDesktop && <ProgressButtons/>}
          <FormContext.Provider
            value={formProviderValue}
          >
            {React.createElement(component, {...props, isAdminUser})}
          </FormContext.Provider>
          <SubmissionFooter
            currentStep={step}
            isLastStep={steps.length === step}
            save={onSave}
            submit={onSubmit}
            to={goTo}
            errorMessage={errorMessage}
          />
        </View>
      </Content>
    </Layout>
  )
}

export default SubmissionLayout
