import React from 'react'
import { View, Text } from 'react-native'
import { Link } from 'components/Router'
import s from 'styles'

export interface TabProps {
  data: {
    url: string,
    text: string,
  }[],
  pathname: string,
}

const Tabs: React.FC<TabProps> = ({ pathname, data = [] }: TabProps) => {
  return (
    <View style={[s.mt8, s.flexRow]}>
      {data.map(({ url, text }, i) => {
        const isActive = pathname === url
        return (
          <Link key={i} to={url}>
            <View
              style={[
                s.bBottom3,
                isActive ? s.bTeal : s.bTransparent,
                s.pb4,
                s.mr32,
              ]}
            >
              <Text style={[s.f16, isActive && s.textGreen]}>{text}</Text>
            </View>
          </Link>
        )
      })}
    </View>
  )
}

export default Tabs
