import * as React from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import { clamp } from 'lodash'
import s from 'styles'

interface CounterButtonType {
  direction: string,
  onUpdate: (mystr: string) => void,
}

const CounterButton = ({ direction, onUpdate }: CounterButtonType) => {
  return (
    <TouchableOpacity onPress={() => onUpdate(direction)}>
      <Text
        style={[
          s.f20,
          s.textWhite,
          s.textBold,
          s.bgGreen,
          s.br5,
          s.textCenter,
          {
            fontSize: 26,
            paddingVertical: 3,
            width: 45,
            height: 40,
          },
        ]}
      >
        {direction}
      </Text>
    </TouchableOpacity>
  )
}

export type NumberChooserChangeCallback = (myobj: { [name: string]: number }) => void
interface NumberChooserType {
  name: string,
  count: number,
  onChange: NumberChooserChangeCallback
}

export function NumberChooser ({name, count, onChange}: NumberChooserType)
{
  const max = 99

  function onUpdate(direction: string) {
    onChange({
      [name]: clamp(
        direction === '-' ? count - 1 : count + 1,
        0,
        max,
      ),
    })
  }

  function updateFromTextInput( val) {
    val = Number(val) ? val : '0'
    onChange({
      [name]: clamp(val, 0, max),
    })
  }

  return (
    <View style={[s.flexRow]}>
      <CounterButton direction="-" onUpdate={onUpdate} />
      <TextInput
        keyboardType="numeric"
        maxLength={3}
        multiline={false}
        onChangeText={updateFromTextInput}
        style={[
          s.f18,
          s.bTeal,
          s.bBottom1,
          s.bTop1,
          {
            width: 50,
            marginLeft: -5,
            marginRight: -5,
            fontWeight: '400',
            color: '#333',
            backgroundColor: '#fff',
            textAlign: 'center',
            zIndex: 1,
          }]}
        value={String(count)}
      />
      <CounterButton direction="+" onUpdate={onUpdate} />
    </View>
  )
}
