import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

/**
 * Listens to dimension changes of "dim".
 *
 * When a dimension changes, schedule this instantly. The other option is to
 * schedules the update inside an animation frame (useDimensions) or to wait
 * for an idle frame / until animations are done (useDimensionsIdle).
 *
 * @param dim screen or window, defaults to window
 * @returns the ScaledSize of the dim
 */
export function useDimensionsSync(dim = 'window') {
  const [dimensions, setDimensions] = useState(() => Dimensions.get(dim))

  // Start listening to changes
  useEffect(() => {
    let callback = true

    const updateDimensions = nextDimensions => {
      callback && setDimensions(nextDimensions[dim])
    }

    Dimensions.addEventListener('change', updateDimensions)

    return () => {
      callback = false
      Dimensions.removeEventListener('change', updateDimensions)
    }
  }, [dim, setDimensions])

  return dimensions
}

export function useWindowDimensions() {
  return useDimensionsSync('window')
}
