import React, { Component } from 'react'
import { View, Text, TouchableWithoutFeedback, TextInput } from 'react-native'
import s from 'styles'

export default class TimePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFocused: false,
      isSecure: true,
    }

    this.formInput = React.createRef()
    this.formInputClear = this.formInputClear.bind(this)
    this.formInputFocus = this.formInputFocus.bind(this)
    this.onFormInputBlur = this.onFormInputBlur.bind(this)
    this.onFormInputChangeText = this.onFormInputChangeText.bind(this)
    this.onFormInputFocus = this.onFormInputFocus.bind(this)
    this.toggleSecure = this.toggleSecure.bind(this)
  }

  formInputClear() {
    this.formInput.current.clear()
  }

  formInputFocus() {
    this.formInput.current.focus()
  }

  onFormInputBlur() {
    this.setState({
      isFocused: false,
    })

    if (this.props.validate) {
      this.props.validate(this.props.name || this.props.label)
    }
  }

  onFormInputChangeText(text, name) {
    this.props.onFormInputChangeText({
      [name || this.props.label]: text,
    })
  }

  onFormInputFocus() {
    this.setState({ isFocused: true })
  }

  toggleSecure() {
    this.setState({ isSecure: !this.state.isSecure })
  }

  render() {
    const {
      fields,
      type,
      errorMessage,
      inputStyle,
      isClearable,
      label,
      labelTextStyle,
      onFormInputBlur,
      onFormInputChangeText,
      onFormInputFocus,
      onPressEnter,
      validate,
      width = '100p',
      ...props
    } = this.props

    return (
      <View style={[s.positionRelative, s[width]]}>
        {label && (
          <TouchableWithoutFeedback
          // accessible={false}
          // onPress={this.formInputFocus}
          >
            <View>
              <Text
                style={[
                  s.mb4,
                  s.f16,
                  s.textBold,
                  !!errorMessage && s.textRed,
                ].concat(labelTextStyle)}
              >
                {label}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )}
        <View
          style={[
            s.flexRow,
            s.alignCenter,
            s.mb20,
            !this.props.disabled && s.b1,
            !!errorMessage
              ? s.bRed
              : this.state.isFocused
              ? s.bGrayLighter
              : s.bGrayLighter,
            s.br5,
            { width: 'fit-content' },
          ]}
        >
          {this.props.fields.map((field, i, a) => (
            <React.Fragment key={i}>
              <TextInput
                value={this.props[field]}
                name={field}
                {...props}
                data-hj-whitelist
                ref={this.formInput}
                onBlur={this.onFormInputBlur}
                onChangeText={text => this.onFormInputChangeText(text, field)}
                onFocus={this.onFormInputFocus}
                onSubmitEditing={onPressEnter}
                disabled={this.props.disabled}
                style={[
                  { width: 70 },
                  s.h40,
                  s.px16,
                  s.f16,
                  s.outlineStyleNone,
                  s.textCenter,
                ].concat(inputStyle)}
              />
              {i < a.length - 1 && (
                <Text>
                  {type === 'date' ? '/' : type === 'time' ? ':' : ''}
                </Text>
              )}
            </React.Fragment>
          ))}
        </View>
      </View>
    )
  }
}
