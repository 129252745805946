import React from 'react'
import { Platform } from 'react-native'
import { connect } from 'react-redux'
import { Route, Redirect } from 'components/Router'
import { userAuthCheckIfSignedIn } from 'store/user'

// Sort of the opposite of PrivateRoute -- this ensures that the user is signed-OUT. The main purpose of this
// is a multi-tab situation, where the user signs in on one tab, and we want to make sure that the other
// tabs reflect this.
const PublicRoute = ({
  component: Component,
  isSignedIn,
  userAuthCheckIfSignedIn,
  ...rest
}) => {
  // fire off a check to see if we've been signed in.
  const onFocus = React.useCallback(() => userAuthCheckIfSignedIn(), [
    userAuthCheckIfSignedIn,
  ])
  React.useEffect(() => {
    // NOTE: we do the Platform check here to avoid React Hook lint errors
    if (Platform.OS === 'web') {
      window.addEventListener('focus', onFocus)
      return () => {
        window.removeEventListener('focus', onFocus)
      }
    }
  }, [])

  return (
    /**
     * FIXME 6/25/21: /account/login should not directly redirect to /dashboard when logged in
     * */
    <Route
      {...rest}
      render={props =>
        isSignedIn ? (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

const mapStateToProps = ({ user }) => ({
  isSignedIn: user.isSignedIn,
})
export default connect(mapStateToProps, { userAuthCheckIfSignedIn })(
  PublicRoute
)
