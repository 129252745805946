import capitalize from 'lodash/capitalize'
import { openURL } from 'lib/utils.web.js'

export function formatStatus(status: string) {
  if (status === 'pending') {
    return 'Pending Approval';
  }

  return capitalize(status);
}

export function shortUSDate(date: string | number) {
  try {
    const d = new Date(Date.parse(String(date)))
    return d.toLocaleDateString('en-us', {year: '2-digit', month: '2-digit', day: '2-digit'})
  } catch (e) {
    return 'NaN'
  }
}

export function reallyShortUSDate(date: string | number) {
  try {
    const d = new Date(Date.parse(String(date)))
    const now = new Date(Date.now())
    return d.getFullYear() === now.getFullYear()
      ? d.toLocaleDateString('en-us', {month: '2-digit', day: '2-digit'})
      : d.toLocaleDateString('en-us', {year: 'numeric', month: '2-digit'})
  } catch (e) {
    return 'NaN'
  }
}

export function openRoute(url: string) {
  openURL(url, false, { forceCurrentWindow: true })
}
