
import React from 'react'
import { useDetectDeviceType } from './useDetectDeviceType';

export function withDeviceHook(Component) {
  return function WrappedComponent(props) {
    const { isDesktop, isMobile, isTablet, browserWidowWidth } = useDetectDeviceType();
    return <Component {...props} isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} browserWidowWidth={browserWidowWidth} />;
  }
}
