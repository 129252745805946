import { Linking } from 'react-native'

/**
 * @param {string} url
 * @param {boolean} targetBlank Platform web only. Sets target=_blank to open URL in new tab (depending on browser)
 * @param {object} opts
 * @param {boolean} opts.forceCurrentWindow Platform web only. Force external links to open in current window.
 *                                          Comment from Peter 4/21/2021:
 *                                          This looks similar to `targetBlank`, but Linking.openURL will
 *                                          always open external url in new tab. So I had to come with the
 *                                          `location.href` hack which is called with `forceCurrentWindow`.
 */
export const openURL = (url, targetBlank = false, options = undefined) => {
  const { forceCurrentWindow = false } = options || {}
  if (targetBlank) {
    // See https://github.com/necolas/react-native-web/commit/0ee3310290f7df59ed6e4910e4af1412fc17afe1#diff-0c47410eb62575d7965c5e87dddb1a53
    const anchor = document.createElement('a')
    anchor.target = '_blank' // :(
    anchor.rel = 'noopener'
    anchor.href = url
    const body = document.body
    if (body) {
      body.appendChild(anchor)
      anchor.click()
      body.removeChild(anchor)
    }
    return Promise.resolve()
  }

  if (forceCurrentWindow) {
    window.location.href = url
    return Promise.resolve()
  }

  return Linking.openURL(url)
}

// Object utils
export const map = (obj, fn) => Object.keys(obj).map(prop => fn(obj[prop]))
export const filter = (obj, fn) =>
  Object.keys(obj)
    .filter(prop => fn(obj[prop]))
    .map(prop => obj[prop])
export const reduce = (obj, fn, initial) =>
  Object.keys(obj).reduce((curr, prop) => fn(curr, obj[prop]), initial)
export const empty = obj => Object.keys(obj).length === 0

export const timeStr = time => {
  if (time > 0) {
    const secondsDiv = 1000
    const minutesDiv = 60 * secondsDiv
    const hoursDiv = 60 * minutesDiv
    const hours = Math.floor(time / hoursDiv)
    const minutes = Math.floor((time - hours * hoursDiv) / minutesDiv)
    const seconds = Math.floor(
      (time - hours * hoursDiv - minutes * minutesDiv) / secondsDiv
    )
    const hoursStr = hours > 0 ? `${hours}:` : ''
    const minutesStr =
      minutes > 0
        ? `${minutes < 10 && hoursStr ? '0' : ''}${minutes}:`
        : hoursStr
        ? `00:`
        : `0:`
    const secondsStr =
      seconds > 0 ? `${seconds < 10 ? '0' : ''}${seconds}` : `00`
    return `${hoursStr}${minutesStr}${secondsStr}`
  }
}
