import React, { Component } from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import s from 'styles'
import { closeTopDialog, closeTopDialogWithResult } from 'store/dialogBox'
import { Image } from 'components/ReactNative'
import LottieView from 'react-native-web-lottie'
export const dialogCancelledText = 'Cancelled'

const checkedBox = require('images/CheckedBox.svg')

class DialogBox extends Component {
  static defaultProps = {}

  constructor(props) {
    super(props)

    this.notificationDialog = this.notificationDialog.bind(this)
    this.progressDialog = this.progressDialog.bind(this)
    this.dialogContent = this.dialogContent.bind(this)
  }

  notificationDialog() {
    return (
      <View
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: 2,
          minHeight: 300,
          minWidth: 600,
          maxWidth: 600,
          alignSelf: 'center',
          padding: 50,
          justifyContent: 'center',
        }}
      >
        {'checkedBox' in this.props.data && (
          <Image
            style={{
              alignSelf: 'center',
              resizeMode: 'contain',
              height: 100,
              width: 100,
            }}
            source={checkedBox}
          />
        )}
        {'red' in this.props.data && (
          <Text style={[s.textCenter, s.textRed, s.f35]}>
            {this.props.data.red}
          </Text>
        )}
        {'teal' in this.props.data && (
          <Text style={[s.textCenter, s.textTeal, s.f35]}>
            {this.props.data.teal}
          </Text>
        )}
        {'gray' in this.props.data && (
          <Text style={[s.textCenter, s.textGray, s.f35]}>
            {this.props.data.gray}
          </Text>
        )}
        {'gray2' in this.props.data && (
          <Text style={[s.textCenter, s.textGray, s.f35]}>
            {this.props.data.gray2}
          </Text>
        )}
        {'smallTeal' in this.props.data && (
          <View style={{ paddingTop: 10 }}>
            <Text style={[s.textCenter, s.textBold, s.textTeal, s.f18]}>
              {this.props.data.smallTeal}
            </Text>
          </View>
        )}
        {'smallGray' in this.props.data && (
          <View style={{ paddingTop: 10 }}>
            <Text style={[s.textCenter, s.textGray, s.f18]}>
              {this.props.data.smallGray}
            </Text>
          </View>
        )}
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          {'altButtonText' in this.props.data && (
            <TouchableOpacity
              onPress={() => {
                if (this.props.onAltClose) {
                  this.props.onAltClose()
                }
                this.props.closeTopDialogWithResult({
                  result: this.props.data.altButtonText,
                })
              }}
            >
              <View
                style={{
                  backgroundColor: 'white',
                  margin: 20,
                  padding: 20,
                  borderColor: '#00b1b9',
                  borderWidth: 1,
                  borderRadius: 10,
                  minHeight: 40,
                  minWidth: 140,
                }}
              >
                <Text style={[s.textCenter, s.textTeal, s.textBold]}>
                  {this.props.data.altButtonText}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          {'buttonText' in this.props.data && (
            <TouchableOpacity
              onPress={() => {
                if (this.props.onClose) {
                  this.props.onClose()
                }
                this.props.closeTopDialogWithResult({
                  result: this.props.data.buttonText,
                })
              }}
            >
              <View
                style={{
                  backgroundColor: '#00B1B9',
                  margin: 20,
                  padding: 20,
                  borderRadius: 10,
                  minHeight: 40,
                  minWidth: 140,
                }}
              >
                <Text style={[s.textCenter, s.textWhite, s.textBold]}>
                  {this.props.data.buttonText}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        </View>

        {'linkText' in this.props.data && (
          <TouchableOpacity
            onPress={() => {
              if (this.props.onLinkClose) {
                this.props.onLinkClose()
              }
              this.props.closeTopDialogWithResult({
                result: this.props.data.linkText,
              })
            }}
          >
            <View
              style={{
                padding: 10,
              }}
            >
              <Text style={[s.textCenter, s.textGray, s.f18]}>
                {this.props.data.linkText}
              </Text>
            </View>
          </TouchableOpacity>
        )}

        {'linkTextTeal' in this.props.data && (
          <TouchableOpacity
            onPress={() => {
              if (this.props.onLinkClose) {
                this.props.onLinkClose()
              }
              this.props.closeTopDialogWithResult({
                result: this.props.data.linkTextTeal,
              })
            }}
          >
            <View
              style={{
                padding: 10,
              }}
            >
              <Text
                style={[
                  s.textCenter,
                  s.textTeal,
                  s.textBold,
                  s.textUnderline,
                  s.f18,
                ]}
              >
                {this.props.data.linkTextTeal}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  progressDialog() {
    return (
      <TouchableOpacity
        onPress={() => {
          if (this.props.data.closeOnTap) {
            this.props.closeTopDialogWithResult({
              result: dialogCancelledText,
            })
          }
        }}
      >
        <View style={{ alignSelf: 'center', justifyContent: 'center' }}>
          <LottieView
            style={{ width: 120, height: 120 }}
            source={require('images/lottie-spinner.json')}
            autoPlay
            loop
          />
        </View>
      </TouchableOpacity>
    )
  }

  percentageDialog() {
    const percentage = this.props.progress
      ? Math.floor(this.props.progress.ratioDone * 100)
      : 0
    const timeLeft =
      ((this.props.progress && this.props.progress.timeLeft) || 0) / 1000
    const text =
      this.props.progress && this.props.progress.timeLeft
        ? timeLeft < 60
          ? `${Math.ceil(timeLeft)} seconds left`
          : `${Math.ceil(timeLeft / 60)} minutes left`
        : `Please wait...`
    return (
      <View style={[s.alignCenter]}>
        <View style={[s.flexRow, s.px16, s.alignCenter, s.mb45]}>
          <View
            style={[s.bgDarkGray, s.br10, s.b1, s.bGrayLighter, s.w400, s.h40]}
          >
            <View
              style={[
                s.bgTeal,
                s.brLeft5,
                s.mx5,
                s.my4,
                {
                  width: `${percentage + '%'}`,
                  height: 30,
                  position: 'relative',
                },
              ]}
            />
          </View>
        </View>
        <Text style={[s.textGray, s.f18]}>{text}</Text>
      </View>
    )
  }

  dialogContent() {
    return this.props.data.showSpinner
      ? this.progressDialog()
      : this.props.data.showPercentage
      ? this.percentageDialog()
      : this.notificationDialog()
  }

  render() {
    return (
      <Modal animationType="none" transparent={true} visible={this.props.show}>
        <View
          style={{
            flex: 1,
            backgroundColor: '#252932CC',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {this.dialogContent()}
        </View>
      </Modal>
    )
  }
}

const mapStateToProps = ({ dialogBox }) => {
  return {
    show: dialogBox.show,
    data: dialogBox.data,
    onClose: dialogBox.onClose,
    onAltClose: dialogBox.onAltClose,
    onLinkClose: dialogBox.onLinkClose,
    progress: dialogBox.progress,
  }
}

export default connect(mapStateToProps, {
  closeTopDialog,
  closeTopDialogWithResult,
})(DialogBox)
