export default {
  captcha: {
    presence: {
      allowEmpty: false,
      message: `Captcha can't be blank`,
    },
    length: {
      minimum: 1,
      maximum: 2,
      message: 'Please enter the captcha',
    },
  },
  name: {
    presence: {
      allowEmpty: false,
      message: `First and last name can't be blank`,
    },
    length: {
      minimum: 2,
      message: 'Please enter your first and last name',
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
      message: `First Name can't be blank`,
    },
    length: {
      minimum: 2,
      message: 'Please enter your full First Name',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: `Last Name can't be blank`,
    },
    length: {
      minimum: 2,
      message: 'Please enter your full Last Name',
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: `Email can't be blank`,
    },
    format: {
      pattern: /[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
      message: 'Please enter a valid email address',
    },
  },
  password: {
    length: {
      minimum: 6,
      message: 'Password must contain at least 6 characters.',
    },
  },
  phone: {
    format: {
      pattern: /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
      message: 'Please enter a valid phone number',
    },
  },
}
