import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { modalHide, modalSelector } from 'store/modal'
import {
  Image,
  KeyboardAvoidingView,
  Modal as ReactModal,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import s from 'styles'

export interface FullscreenProps {
  children: Array<JSX.Element> | JSX.Element,
}

export function Fullscreen({ children }: FullscreenProps) {
  const { isOpen } = useSelector(modalSelector)
  const isArray = Array.isArray(children)
  return (
    <ReactModal visible={!isOpen} transparent={true}>
      <View
        style={[
          s.flexGrow1,
          s.justifyCenter,
          s.alignCenter,
          { backgroundColor: '#252932CC' },
        ]}
      >
        <View>
          <span>{isArray ? (<View>{children}</View>) : children}</span>
        </View>
      </View>
    </ReactModal>
  )
}

export type ModalSizes = 'small' | 'medium' | 'large'
export interface ModalFrameProps {
  title: string,
  size?: ModalSizes,
  noMargins?: boolean,
  noCloseButton?: boolean,
  onClose?: () => void,
  children: Array<JSX.Element> | JSX.Element,
}

// Standard modal frame -- use as root element of modal components
export const ModalFrame = (props: ModalFrameProps) => {
  const sizes = {
    large: 900,
    medium: 700,
    small: 350,
  }
  const size = props.size ? sizes[props.size] : sizes.medium
  const dispatch = useDispatch()
  const onClose = () => {
    props.onClose
      ? props.onClose()
      : dispatch(modalHide())
  }
  const isArray = Array.isArray(props.children)

  return (
    <View style={[s.bgWhite, s.br16, s.mAuto, s.w100p, { maxWidth: size }]}>
      <View style={[s.p16, s.flexRow, s.alignCenter, s.bgWhiteDarker, s.brTop16]}>
        {!props.noCloseButton && (
          <TouchableOpacity onPress={onClose} style={s.pr16}>
            <Image
              source={require('images/modalX.svg')}
              style={{ width: 16, height: 16 }}
            />
          </TouchableOpacity>
        )}
        <Text style={[s.f24, s.textBold, s.textCenter, s.flex1]}>{props.title}</Text>
      </View>
      <ScrollView style={[!props.noMargins && s.p32 ]}>
        {isArray ? (<View>{props.children}</View>) : props.children}
      </ScrollView>
    </View>
  )
}

export const Modal = () => {
  const dispatch = useDispatch()
  const { isOpen, content } = useSelector(modalSelector)

  return (
    <ReactModal
      transparent={true}
      visible={isOpen}
      onRequestClose={() => dispatch(modalHide())}
    >
      <KeyboardAvoidingView behavior={'padding'} style={s.flex1}>
        <View
          style={[
            s.flex1,
            { backgroundColor: '#252932CC' },
          ]}
        >
          {isOpen && (content)}
        </View>
      </KeyboardAvoidingView>
    </ReactModal>
  )
}

