import React from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'react-native'
import Button from 'components/Form/Button2'
import { ModalFrame, ModalSizes } from 'components/modal'
import { modalHide } from 'store/modal'
import s from 'styles'

// TODO: This was a placeholder transitioning from modal1 to modal2.
// We can might consider factoring this out
export const DynamicModal = (props: {
  title: string,
  size?: ModalSizes,
  closeButton: string,
  children: JSX.Element | Array<JSX.Element>,
}) => {
  const dispatch = useDispatch()
  const isArray = Array.isArray(props.children)
  return (
    <ModalFrame title={props.title} size={props.size}>
      <span>{isArray ? (<View>{props.children}</View>) : props.children}</span>
      {props.closeButton && (
        <View style={[s.flexRow, s.justifyCenter]}>
          <Button text={props.closeButton} onPress={() => dispatch(modalHide())} />
        </View>
      )}
    </ModalFrame>
  )
}

