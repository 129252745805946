// @ts-nocheck
import { StyleSheet, Platform } from 'react-native'

const COLOR = {
  white: '#ffffff',
  whiteBluish: '#FAFCFD',
  whiteDarker: '#f5f8fa',
  whiteDarker2: '#f0f5f7',
  whiteDarkest: '#ecf1f4',
  grayUltraLight: '#E0EAEE',
  grayLightest: '#d9e6ed',
  grayLighter: '#cadde2',
  grayEvenLessLighter: '#E0E0E0', // 3.0
  grayLessLighter: '#f2f2f2', // 3.0
  grayMoreLighter: '#f9f9f9', // 3.0
  gray: '#95abb9',
  grayDarker: '#3b475c',
  grayDarkest: '#313847',
  blackLighter: '#252932',
  blackLightest: '#313847',
  teal: '#00b1b9',
  tealDarker: '#0d8d97',
  red: '#fa7272',
  blue: '#3967e5',
  blueDark: '#003799',
  yellow: '#ffc20e',
  pink: '#FA7272',
  // green: '#00d16d',
  green: '#16D8B2', // 3.0
  greenDark: "#027963", // 3.0
  greenLighter: '#89D386',
  blueLigher: '#16d8b2',
  purple: '#48308f',
  black: '#000000'
}

export const styles = {
  imageContain: { resizeMode: 'contain' },
  imageCover: { resizeMode: 'cover' },
  col1: { flexBasis: '8.33%', width: '100%' },
  col2: { flexBasis: '16.66%', width: '100%' },
  col3: { flexBasis: '25%', width: '100%' },
  col4: { flexBasis: '33.33%', width: '100%' },
  col5: { flexBasis: '41.66%', width: '100%' },
  col6: { flexBasis: '50%', width: '100%' },
  col7: { flexBasis: '58.33%', width: '100%' },
  col8: { flexBasis: '66.66%', width: '100%' },
  col9: { flexBasis: '75%', width: '100%' },
  col10: { flexBasis: '83.33%', width: '100%' },
  col11: { flexBasis: '91.66%', width: '100%' },
  col12: { flexBasis: '100%', width: '100%' },
  positionAbsolute: { position: 'absolute' },
  positionRelative: { position: 'relative' },
  top0: { top: 0 },
  top25: { top: 25 },
  bottom0: { bottom: 0 },
  left0: { left: 0 },
  left22p: { left: '22%' },
  right0: { right: 0 },
  container: { minHeight: 500 },
  minWidth600: { minWidth: 600 },
  minHeight45: { minHeight: 45 },
  minHeight60: { minHeight: 60 },
  minHeight70: { minHeight: 70 },
  minHeight120: { minHeight: 120 },
  flexRow: { flexDirection: 'row' },
  flexRowReverse: { flexDirection: 'row-reverse' },
  flexColumn: { flexDirection: 'column' },
  flexColumnReverse: { flexDirection: 'column-reverse' },
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flex3: { flex: 3 },
  flex4: { flex: 4 },
  flex5: { flex: 5 },
  flex6: { flex: 6 },
  flexGrow0: { flexGrow: 0 },
  flexGrow1: { flexGrow: 1 },
  flexShrink0: { flexShrink: 0 },
  flexShrink1: { flexShrink: 1 },
  flexWrap: { flexWrap: 'wrap' },
  alignSelfCenter: { alignSelf: 'center' },
  alignSelfStart: { alignSelf: 'flex-start' },
  alignSelfEnd: { alignSelf: 'flex-end' },
  justifyStart: { justifyContent: 'flex-start' },
  justifyCenter: { justifyContent: 'center' },
  justifyEnd: { justifyContent: 'flex-end' },
  justifyBetween: { justifyContent: 'space-between' },
  justifyAround: { justifyContent: 'space-around' },
  justifyEvenly: { justifyContent: 'space-evenly' },
  alignBaseline: { alignItems: 'baseline' },
  alignStart: { alignItems: 'flex-start' },
  alignCenter: { alignItems: 'center' },
  alignEnd: { alignItems: 'flex-end' },
  alignStretch: { alignItems: 'stretch' },
  row: { flexDirection: 'row', marginHorizontal: -15 },
  w25p: { width: '25%' },
  w33p: { width: '33.33%' },
  w40p: { width: '40%' },
  w50p: { width: '50%' },
  w60p: { width: '60%' },
  w67p: { width: '66.66%' },
  w75p: { width: '75%' },
  w70p: { width: '70%' },
  w80p: { width: '80%' },
  w90p: { width: '90%' },
  w100p: { width: '100%' },
  h100p: { height: '100%' },
  w6: {width: 6 },
  w10: { width: 10 },
  w12: { width: 12},
  w15: { width: 15 },
  w20: { width: 20 },
  w24: { width: 24 },
  w25: { width: 25 },
  w32: { width: 32 },
  w35: { width: 35 },
  w40: { width: 40 },
  w50: { width: 50 },
  w60: { width: 60 },
  w65: { width: 65 },
  w80: { width: 80 },
  w100: { width: 100 },
  w120: { width: 120 },
  w150: { width: 150 },
  w200: { width: 200 },
  w240: { width: 240 },
  w270: { width: 270 },
  w300: { width: 300 },
  w320: { width: 320 },
  w400: { width: 400 },
  w450: { width: 450 },
  w500: { width: 500 },
  w600: { width: 600 },
  w700: { width: 700 },
  w800: { width: 800 },
  w900: { width: 900 },
  mw160: { maxWidth: 160 },
  mw900: { maxWidth: 900 },
  mw1200: { maxWidth: 1200 },
  h5: { height: 5 },
  h6: { height: 6 },
  h10: { height: 10 },
  h12:{ height: 12 },
  h15: { height: 15 },
  h20: { height: 20 },
  h24: { height: 24 },
  h25: { height: 25 },
  h32: { height: 32 },
  h35: { height: 35 },
  h40: { height: 40 },
  h50: { height: 50 },
  h55: { height: 55 },
  h60: { height: 60 },
  h65: { height: 65 },
  h80: { height: 80 },
  h100: { height: 100 },
  h120: { height: 120 },
  h142: { height: 142 },
  h150: { height: 150 },
  h200: { height: 200 },
  h240: { height: 240 },
  h300: { height: 300 },
  h350: { height: 350 },
  h400: { height: 400 },
  h450: { height: 450 },
  h500: { height: 500 },
  h600: { height: 600 },
  mAuto: { margin: 'auto' },
  mlAuto: { marginLeft: 'auto' },
  mrAuto: { marginRight: 'auto' },
  mxn16: { marginHorizontal: -16 },
  mxl5: {marginLeft: -5},
  mxl20: {marginLeft: -10},
  mxl70: { marginLeft: -70},
  ml0: {marginLeft: 0},
  mr2: { marginRight: 2 },
  m2: { margin: 2},
  mb4: { marginBottom: 4 },
  ml4: { marginLeft: 4 },
  mr4: { marginRight: 4 },
  mt4: { marginTop: 4 },
  mx4: { marginHorizontal: 4 },
  my2: { marginVertical: 2 },
  my4: { marginVertical: 4 },
  my8: {marginVertical : 8},
  m8: { margin: 8 },
  mt8: { marginTop: 8 },
  mb8: { marginBottom: 8 },
  ml8: { marginLeft: 8 },
  mr8: { marginRight: 8 },
  mx8: { marginHorizontal: 8 },
  my8: { marginVertical: 8 },
  m16: { margin: 16 },
  mt16: { marginTop: 16 },
  mb16: { marginBottom: 16 },
  ml16: { marginLeft: 16 },
  mr16: { marginRight: 16 },
  mx16: { marginHorizontal: 16 },
  my16: { marginVertical: 16 },
  mx20: { marginHorizontal: 20 },
  my20: { marginVertical: 20 },
  mb20: { marginBottom: 20 },
  ml20: { marginLeft: 20 },
  mr20: { marginRight: 20 },
  mt20: { marginTop: 20 },
  ml24: { marginLeft: 24 },
  mb24: { marginBottom: 24 },
  mx24: { marginHorizontal: 24 },
  my24: { marginVertical: 24 },
  mt24: { marginTop: 24 },
  m32: { margin: 32 },
  mb32: { marginBottom: 32 },
  ml32: { marginLeft: 32 },
  ml64: { marginLeft: 64 },
  mr32: { marginRight: 32 },
  mt32: { marginTop: 32 },
  mx32: { marginHorizontal: 32 },
  my32: { marginVertical: 32 },
  mr40: { marginRight: 40 },
  mb40: { marginBottom: 40 },
  mb45: { marginBottom: 45 },
  ml45: { marginLeft: 45 },
  mr45: { marginRight: 45 },
  mt45: { marginTop: 45 },
  my45: { marginVertical: 45 },
  mx50: { marginHorizontal: 50 },
  mb60: { marginBottom: 60 },
  mr60: { marginRight: 60 },
  mx64: { marginHorizontal: 64 },
  mr64: { marginRight: 64 },
  mb72: { marginBottom: 72 },
  mr120: { marginRight: 120 },
  mb120: { marginBottom: 120 },
  pb100p: { paddingBottom: '100%' },
  py2: { paddingVertical: 2 },
  p4: { padding: 4 },
  pb4: { paddingBottom: 4 },
  pl4: { paddingLeft: 4 },
  px4: { paddingHorizontal: 4 },
  py4: { paddingVertical: 4 },
  pt4: { paddingTop: 4 },
  p8: { padding: 8 },
  pr8: { paddingRight: 8 },
  pl8: { paddingLeft: 8 },
  pt8: { paddingTop: 8 },
  pb8: { paddingBottom: 8 },
  px8: { paddingHorizontal: 8 },
  py8: { paddingVertical: 8 },
  p10: { padding: 10 },
  p16: { padding: 16 },
  px16: { paddingHorizontal: 16 },
  py16: { paddingVertical: 16 },
  pt16: { paddingTop: 16 },
  pb16: { paddingBottom: 16 },
  pl16: { paddingLeft: 16 },
  pr16: { paddingRight: 16 },
  p20: { padding: 20 },
  p24: { padding: 24 },
  pb20: { paddingBottom: 20 },
  pt20: { paddingTop: 20 },
  pr20: { paddingRight: 20 },
  px20: { paddingHorizontal: 20 },
  py20: { paddingVertical: 20 },
  px24: { paddingHorizontal: 24 },
  py24: { paddingVertical: 24 },
  pl24: { paddingLeft: 24 },
  pt24: { paddingTop: 24 },
  pb24: { paddingBottom: 24 },
  p32: { padding: 32 },
  px32: { paddingHorizontal: 32 },
  pr32: { paddingRight: 32 },
  py30: { paddingVertical: 32 },
  py32: { paddingVertical: 32 },
  pl32: { paddingLeft: 32 },
  pt32: { paddingTop: 32 },
  pt50: { paddingTop: 50 },
  pr50: { paddingRight: 50 },
  pl40: { paddingLeft: 40 },
  px40: { paddingHorizontal: 40 },
  pl45: { paddingLeft: 45 },
  px45: { paddingHorizontal: 45 },
  py45: { paddingVertical: 45 },
  p46: { padding: 46 },
  p56: { padding: 56 },
  px56: { paddingHorizontal: 56 },
  py56: { paddingVertical: 56 },
  px60: { paddingHorizontal: 60 },
  py60: { paddingVertical: 60 },
  pt60: { paddingTop: 60 },
  pl64: { paddingLeft: 64 },
  px100: { paddingHorizontal: 100 },
  px120: { paddingHorizontal: 120 },
  bgWhite: { backgroundColor: COLOR.white },
  bgWhiteDarker: { backgroundColor: COLOR.whiteDarker },
  bgWhiteDarker2: { backgroundColor: COLOR.whiteDarker2 },
  bgWhiteDarkest: { backgroundColor: COLOR.whiteDarkest },
  bgGrayLighter: { backgroundColor: COLOR.grayLighter },
  bgGrayMoreLighter: { backgroundColor: COLOR.grayMoreLighter },
  bgGrayLessLighter: { backgroundColor: COLOR.grayLessLighter },
  bgGrayEvenLessLighter: { backgroundColor: COLOR.grayEvenLessLighter },
  bgGrayLightest: { backgroundColor: COLOR.grayLightest },
  bgGray: { backgroundColor: COLOR.gray },
  bgGrayDarker: { backgroundColor: COLOR.grayDarker },
  bgGrayDarkest: { backgroundColor: COLOR.grayDarkest },
  bgTeal: { backgroundColor: COLOR.teal },
  bgTealDarker: { backgroundColor: COLOR.tealDarker },
  bgYellow: { backgroundColor: COLOR.yellow },
  bgRed: { backgroundColor: '#ff0000' },
  bgOzoRed: { backgroundColor: COLOR.red },
  bgOzoBlue: { backgroundColor: COLOR.blue },
  bgPink: { backgroundColor: COLOR.pink },
  bgGreen: { backgroundColor: COLOR.green },
  bgPurple: { backgroundColor: COLOR.purple },
  bgGreenLighter: { backgroundColor: COLOR.greenLighter },
  bgGreenLighter: { backgroundColor: COLOR.greenLighter },
  bgBlueLighter: { backgroundColor: COLOR.blueLigher },
  br0: { borderRadius: 0 },
  br2: { borderRadius: 2 },
  br3: { borderRadius: 3 },
  br4: { borderRadius: 4 },
  br5: { borderRadius: 5 },
  br8: { borderRadius: 8 },
  br10: { borderRadius: 10 },
  br12: { borderRadius: 12 },
  br16: { borderRadius: 16 },
  br20: { borderRadius: 20 },
  br25: { borderRadius: 25 },
  br32: { borderRadius: 32 },
  br100: { borderRadius: 100 },
  br50p: { borderRadius: '50%' },
  br10Left: { borderBottomLeftRadius: 10, borderTopLeftRadius: 10 },
  brLeft5: { borderTopLeftRadius: 5, borderBottomLeftRadius: 5 },
  brTop5: { borderTopLeftRadius: 5, borderTopRightRadius: 5 },
  brRight0: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  brRight5: { borderTopRightRadius: 5, borderBottomRightRadius: 5 },
  brLeft10: { borderTopLeftRadius: 10, borderBottomLeftRadius: 10 },
  brTop10: { borderTopLeftRadius: 10, borderTopRightRadius: 10 },
  brTop16: { borderTopLeftRadius: 16, borderTopRightRadius: 16 },
  brRight10: { borderTopRightRadius: 10, borderBottomRightRadius: 10 },
  brBottom5: { borderBottomLeftRadius: 5, borderBottomRightRadius: 5 },
  brBottom10: { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 },
  brBottom20: { borderBottomLeftRadius: 20, borderBottomRightRadius: 20 },
  bDashed: { borderStyle: 'dashed' },
  b1: { borderWidth: 1 },
  b2: { borderWidth: 2 },
  bBottom1: { borderBottomWidth: 1 },
  bBottom2: { borderBottomWidth: 2 },
  bBottom3: { borderBottomWidth: 3 },
  bLeft1: { borderLeftWidth: 1 },
  bRight1: { borderRightWidth: 1 },
  bTop1: { borderTopWidth: 1 },
  bTop2: { borderTopWidth: 2 },
  bWhite: { borderColor: COLOR.white },
  bgBlackLighter: { backgroundColor: COLOR.blackLighter },
  bgBlackLightest: { backgroundColor: COLOR.blackLightest },
  bgWhiteBluish: { backgroundColor: COLOR.whiteBluish },
  bgGreen: { backgroundColor: COLOR.green },
  bgGreenDark: { backgroundColor: COLOR.greenDark },
  bgPurple: { backgroundColor: COLOR.purple },
  bWhiteDarker: { borderColor: COLOR.whiteDarker },
  bWhiteDarkest: { borderColor: COLOR.whiteDarkest },
  bGrayUltraLight: { borderColor: COLOR.grayUltraLight },
  bGrayLightest: { borderColor: COLOR.grayLightest },
  bGrayLighter: { borderColor: COLOR.grayLighter },
  bGray: { borderColor: COLOR.gray },
  bGreen: { borderColor: COLOR.green },
  bGreenDark: { borderColor: COLOR.greenDark },
  bPurple: { borderColor: COLOR.purple },
  bBlackLighter: { borderColor: COLOR.blackLighter },
  bYellow: { borderColor: COLOR.yellow },
  bTeal: { borderColor: COLOR.teal },
  bTealDark: { borderColor: COLOR.tealDarker },
  bBlueDark: { borderColor: COLOR.blueDark },
  bRed: { borderColor: COLOR.red },
  bTransparent: { borderColor: 'transparent' },
  bTopTransparent: { borderTopColor: 'transparent' },
  bBottomTransparent: { borderBottomColor: 'transparent' },
  bShadow: {
    shadowColor: 'rgba(0,0,0,0.20)',
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
    shadowOpacity: 1.0,
  },
  bShadowLight: {
    shadowColor: 'rgba(0,0,0,0.10)',
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
    shadowOpacity: 1.0,
  },
  bSolidTeal: {
    borderStyle: 'solid',
    borderColor: COLOR.teal,
    borderRadius: 100,
    borderWidth: 2,
  },
  opacity90: { opacity: 0.9 },
  outlineStyleNone:
    Platform.OS === 'web' ? { outlineStyle: 'none' } : undefined,
  f10: { fontFamily: 'Lato', fontSize: 10 },
  f12: { fontFamily: 'Lato', fontSize: 12 },
  f14: { fontFamily: 'Lato', fontSize: 14 },
  f16: { fontFamily: 'Lato', fontSize: 16 },
  f18: { fontFamily: 'Lato', fontSize: 18 },
  f20: { fontFamily: 'Lato', fontSize: 20 },
  f22: { fontFamily: 'Lato', fontSize: 22 },
  f24: { fontFamily: 'Lato', fontSize: 24 },
  f25: { fontFamily: 'Lato', fontSize: 25 },
  f28: { fontFamily: 'Lato', fontSize: 28 },
  f35: { fontFamily: 'Lato', fontSize: 35 },
  f45: { fontFamily: 'Lato', fontSize: 45 },
  textLineHeight15: { lineHeight: '1.5' },
  textLight: { fontWeight: '300' },
  textNormal: { fontWeight: '400' },
  textBold: { fontWeight: '700' },
  textItalic: { fontStyle: 'italic' },
  textTeal: { color: COLOR.teal },
  textTealDark: { color: COLOR.tealDarker },
  textRed: { color: COLOR.red },
  textPink: { color: COLOR.pink },
  textPurple: { color: COLOR.purple },
  textGreenLighter: { color: COLOR.greenLighter },
  textBlue: { color: COLOR.blue },
  textBlueDark: { color: COLOR.blueDark },
  textYellow: { color: COLOR.yellow },
  textGrayLighter: { color: COLOR.grayLighter },
  textGreen: { color: COLOR.green },
  textGreenDark: { color: COLOR.greenDark },
  textGray: { color: COLOR.gray },
  textGrayDarker: { color: COLOR.grayDarker },
  textGrayDarkest: { color: COLOR.grayDarkest },
  textWhite: { color: COLOR.white },
  textWhiteDarker: { color: COLOR.whiteDarker },
  textBlack: { color: COLOR.black },
  textBlackLighter: { color: COLOR.blackLighter },
  textLeft: { textAlign: 'left' },
  textCenter: { textAlign: 'center' },
  textRight: { textAlign: 'right' },
  textUnderline: { textDecorationLine: 'underline', textUnderlinePosition: 'under' },
  overflowScroll: { overflow: 'scroll' },
  overflowHidden: { overflow: 'hidden' },
  overflowVisible: { overflow: 'visible' },
  zIndex0: { zIndex: 0 },
  zIndex1: { zIndex: 1 },
  zIndex2: { zIndex: 2 },
  zIndex3: { zIndex: 3 },
  zIndex4: { zIndex: 4 },
  zIndex10: { zIndex: 10 },
  gap10: { gap: 10 },
  gap15: { gap: 15 },
  gap20: { gap: 20 },
}

export default StyleSheet.create(styles)
