import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'

const getIconSource = icon => {
  const icons = {
    plus: require('images/lessons-plus.svg'),
    plusFat: require('images/icon-plus-fat-white.svg'),
    play: require('images/icon-play.svg'),
    playContinue: require('images/icon-play-continue.svg'),
    greyCheckCircle: require('images/icon-greyCheckCircle.svg'),
    question: require('images/OC-Student_ask-teacher.svg'),
  }
  return icons[icon]
}

const getRoleStyle = (role, isDisabled) => {
  const styles = {
    primary: {
      view: [s.bgGreen, s.br25],
      text: [s.textBlackLighter],
    },
    secondary: {
      view: [s.b1, s.bGreenDark],
      text: [s.textGreenDark],
    },
    third: {
      view: [{ backgroundColor: '#275DE1' }],
      text: [s.textWhite],
    },
    fourth:{
      view:[{backgroundColor: '#0000FF',  borderRadius: 20}],
      text:[s.textWhite],
    },
    fifth:{
      text:[s.textBlack, { textDecoration: 'underline' }]
    },
    link: {
      view: [],
      text: [],
    },
    tab: {
      view: [s.bgWhiteDarker, s.bShadow],
      text: [s.textBlackLighter],
    },
    error: {
      view: [s.bgOzoRed],
      text: [s.textWhite],
    },
    primaryDisabled: {
      view: [s.bgGrayLighter],
      text: [s.textWhite],
    },
    secondaryDisabled: {
      view: [s.b1, s.bGrayLighter],
      text: [s.textGray],
    },
    errorDisabled: {
      view: [s.bgGrayLighter],
      text: [s.textWhite],
    },
  }

  return styles[role + (isDisabled ? 'Disabled' : '')]
}

export default function Button({
  text,
  role = 'primary',
  rounded,
  notRounded,
  icon,
  presentationOnly,
  small = false,
  disabled = false,
  ...props
}) {
  return (
    <TouchableOpacity
      {...props}
      data-role={role}
      disabled={presentationOnly || disabled}
    >
      <View
        style={[
          s.flexRow,
          s.alignCenter,
          s.justifyCenter,
          rounded ? s.br25 : s.br5,
          notRounded && s.br0,
          small ? [s.py4, s.px8] : [s.py8, s.px20],
          getRoleStyle(role, disabled).view,
        ]}
      >
        {icon && (
          <Image
            source={getIconSource(icon)}
            style={{
              height: 15,
              width: 15,
              marginRight: 5,
            }}
            resizeMode="contain"
          />
        )}
        <Text
          style={[
            small ? s.f14 : s.f16,
            s.textBold,
            getRoleStyle(role, disabled).text,
          ]}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}
