import { useState, useEffect } from "react";``
import { useWindowDimensions } from "react-native";
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from "../constants";

export const useDetectDeviceType = () => {

    const { width, height } = useWindowDimensions();

    const isMobile = (width <= MAX_MOBILE_WIDTH);
    const isTablet = (width > MAX_MOBILE_WIDTH && width <= MAX_TABLET_WIDTH);
    const isDesktop = (width > MAX_TABLET_WIDTH);

    return { isMobile, isTablet, isDesktop, browserWidowWidth: width, browserWidowHeight: height };
};
