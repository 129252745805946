import React from 'react'
import { Text, TextInput, View } from 'react-native'
import { some } from 'lodash'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'

const SubmissionTextarea = ({
  field,
  maxLength,
  hasSeparator,
  form,
  ...labelProps
}) => {
  const isMultiline = !(maxLength < 81)
  const [value = '', setValue] = React.useState(form[field])
  const onChangeText = text => setValue(text)
  const onBlur = () => {
    form.updateForm({ [field]: value })
  }

  const getRemaining = () => {
    if (maxLength && value.length > 0) {
      return `${value.length}/${maxLength} characters.`
    }

    return `Max ${maxLength} characters`
  }

  return (
    <View
      style={[s.mb20, hasSeparator && [s.pb20, s.bBottom1, s.bGrayLighter]]}
    >
      <SubmissionLabel
        {...labelProps}
        hasError={some(form.errors, { field })}
      />
      {/* Input */}
      <View style={[s.b1, s.bGrayLighter, s.br5]}>
        <TextInput
          data-hj-whitelist
          multiline={isMultiline}
          maxLength={maxLength}
          onBlur={onBlur}
          onChangeText={onChangeText}
          value={value}
          style={[
            s.py8,
            s.px16,
            s.f16,
            s.outlineStyleNone,
            isMultiline && s.minHeight120,
          ]}
        />
      </View>
      {maxLength && (
        <Text style={[s.f14, s.textGray, s.textRight]}>{getRemaining()}</Text>
      )}
    </View>
  )
}

export default withSubmissionForm(SubmissionTextarea)
