import React from 'react'
import Tippy from '@tippyjs/react/headless'
import { TouchableOpacity, View, Text } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { Link } from 'components/Router'
import { useRouteMatch } from 'react-router'
import { userAuthSignoutRequest, userSelector } from 'store/user'
import { useDispatch, useSelector } from 'react-redux'
import { Instance as TippyInstance } from 'tippy.js';
import FreeDemoButton from './FreeDemoButton'

const MenuIcon = () => {
  return (
    <TouchableOpacity activeOpacity={1}>
      <Image style={{ height: 25, width: 25 }} source={require('images/Hamburger-Menu.png')}/>
    </TouchableOpacity>
  )
}

const DemoArea = (props: {onPress?: () => void}) => (
  <View style={[s.flexRow, s.w80p, s.bTop1, s.bBottom1, s.bGray, s.mx24, s.py24, s.my16]}>
    <FreeDemoButton onPress={props.onPress}/>
  </View>
)

const Menu = ({instance}: { instance: TippyInstance }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const onLogout = () => dispatch(userAuthSignoutRequest())

  const user = useSelector(userSelector)

  const links = user.isVerified 
    ? [
      {
        url: '/dashboard',
        text: 'Dashboard',
      },
      {
        url: '/lessons',
        text: 'Lessons',
      },
      {
        url: '/lessons/mine',
        text: 'My Lessons',
      },
      {
        url: '/lessons/saved',
        text: 'Saved Lessons',
      },
      {
        url: 'demo-area',
        component: <DemoArea onPress={() => instance.hide()}/>,
      },
      {
        url: '/devices',
        text: 'Devices',
      },
      {
        url: '/settings/profile',
        text: 'Settings',
      },
      {
        url: '/help',
        text: 'Help',
      },
    ]
    : [
      {
        url: '/settings/profile',
        text: 'Settings',
      },
    ]

  const selectedMenuStyles = [s.textBold, s.textGreenDark];

  return (
    <View style={[s.bgGrayMoreLighter, s.bShadow, { width: 300, left: 10, borderTopLeftRadius: 10, borderBottomLeftRadius: 10, top: 8 }]}>
      <TouchableOpacity style={[s.alignEnd]} onPress={() => instance.hide()}>
        <Image
          source={require('images/close-icon-blue.png')}
          style={{ height: 15, width: 15, left: -30, top: 16 }}
        />
      </TouchableOpacity>
      <View style={[s.flexColumn, s.mt16]}>
        {links.map(
          ({ url, text, component }) => {
            return component
              ? (<View key={url}>
                {component}
              </View>)
              : (
                <View key={url} style={[s.my8, s.mx24]}>
                  <Link to={url}>
                    <View style={[s.justifyCenter, s.py4]}>
                      <Text style={[s.f16, (path == url) ? selectedMenuStyles : null]}>{text}</Text>
                    </View>
                  </Link>
                </View>
              )
          })
        }
        <View style={[s.mx24, s.mb16]}>
          <View style={[s.justifyCenter, s.py8]}>
            <TouchableOpacity onPress={onLogout}>
              <Text style={[s.f16]}>Log Out</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  )
}

export default function ToolTip() {
  return (
    <Tippy
      interactive={true}
      appendTo={document.body}
      placement={'bottom'}
      theme={'light'}
      arrow={true}
      zIndex={9999}
      trigger="click"
      render={(data, content, instance) => {
        return <Menu instance={instance}/>
      }}
    >
      <span><MenuIcon /></span>
    </Tippy>
  )
}
