import React from 'react'
import { View } from 'react-native'
import s from 'styles'

const LessonViewLayout = ({
  useDesktopLayout,
  imageContent,
  mainRollContent,
  secondaryRollContent,
} : {
  useDesktopLayout: boolean,
  imageContent: JSX.Element,
  mainRollContent: JSX.Element,
  secondaryRollContent: JSX.Element
}) => {

  const borderedSectionStyles = [s.flexColumn, s.alignStretch, s.mt20, s.pt20, s.bTop1, s.bGrayLighter]
  const rollViewMarginX = !useDesktopLayout && s.mx16

  return useDesktopLayout
    ? (
      <View style={[s.flexRow, s.justifyBetween, s.px40]}>
        <View style={[s.flexColumn, s.alignStretch, s.flex1]}>
          {imageContent}
          {mainRollContent}
        </View>
        <View style={[s.alignStretch, s.flex1, {maxWidth: 500, minWidth: 300}, s.ml45]}>
          {secondaryRollContent}
        </View>
      </View>
    )
    : (
      <View style={[s.flexColumn, s.alignStretch, s.w100p]}>
        {imageContent}
        {mainRollContent}
        <View style={[...borderedSectionStyles, rollViewMarginX]}>
          {secondaryRollContent}
        </View>
      </View>
    )
}

export default LessonViewLayout
