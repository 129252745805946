import * as React from 'react'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import Input from 'components/Form/Input2'
import ServiceButton from 'components/Provider/ServiceButton'
import OzobotBackground from './components/OzobotBackground'
import OrSeparator from 'components/Form/OrSeparator'
import ErrorMessages from 'components/Form/ErrorMessages'
import s from 'styles'
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {
  userCreateRequest,
  userAuthGoogleSignInRequest,
} from 'store/user'
import { notificationsActions } from 'store/notifications'
import validate from 'validate.js'
import validations from 'lib/validations'
import { pick, isEmpty } from 'lodash'
import { Link } from 'components/Router'

// The following interfaces allow you to use this.props.match.*
// Type whatever you expect in 'this.props.match.params.*'
interface PathParamsType {
  role: string,
}

// Then we can get route props types
// Seems to need to be a type
interface PropsType extends RouteComponentProps<PathParamsType> {
  placeholder?: string,
}

interface GlobalStore {
  notifications: {
    list: Array<{
      id: number,
      message: string,
      timestamp: number,
      type: string,
    }>,
  }
}

const Join: React.FunctionComponent<PropsType> = (props: PropsType) => {

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState([])

  const dispatch = useDispatch()

  const googleSignIn = () => {
    const postLoginRedirect =
      (props.location.state && props.location.state['redirect']) || '/dashboard'
    dispatch(userAuthGoogleSignInRequest({ postLoginRedirect }))
  }

  const submit = () => {
    const postLoginRedirect = props.location.state
      ? props.location.state['redirect']
      : undefined

    // clear errors before revalidating
    setErrors([])
    dispatch(notificationsActions.removeType('auth/join'))

    const errors = validate(
      { firstName, lastName, email, password },
      pick(validations, ['firstName', 'lastName', 'email', 'password']),
      { fullMessages: false },
    )

    if (!isEmpty(errors)) {
      setErrors(errors)
    } else {
      dispatch(userCreateRequest({
        postLoginRedirect,
        firstName: firstName.trim(),
        lastName: lastName ? lastName.trim() : '',
        email,
        provider: 'email',
        credential: {
          email,
          password: password.trim(),
        },
      }))
    }
  }

  React.useEffect(() => {
    // Make sure notifications are cleared for this view
    return () => {
      dispatch(notificationsActions.removeType('auth/join'))
    }
  }, []);

  // Pull errors from notification store, and add to our errors list
  const notificationErrors = useSelector<GlobalStore, Array<Array<string>>>(({ notifications }) =>
    notifications.list
      .filter(n => n.type === 'auth/join')
      .map(n => [n.message])) // Format into array used in other errors

  const allErrors = [...Object.values(errors || {}), ...notificationErrors]

  return (
    <OzobotBackground
      hasStudentLink
      footerText={'Compatible with most computers (including Chromebooks) with the Chrome or Edge browser.'}
      footerLinkText={'Learn more'}
      footerLink='https://static.ozobot.com/assets/7743bc40-2023-ozobot-evo-compatibility-chart.pdf'
    >
      <ErrorMessages errorMessages={Object.values(allErrors)} />
      <Text style={[s.textCenter, s.f24, s.textBlack, s.textBold, s.mb16]}>
        Educator and Parent Sign Up
      </Text>
      <ServiceButton
        icon="google"
        onPress={googleSignIn}
      />
      <OrSeparator />
      <View style={[s.mb8, { width: 300 }]}>
        <Input
          label="First Name"
          name="firstName"
          isError={Object.keys(errors).includes('firstName')}
          value={firstName}
          setValue={setFirstName}
          autoCapitalize="words"
          returnKeyType="next"
          enablesReturnKeyAutomatically
        />
        <Input
          label="Last Name"
          name="lastName"
          isError={Object.keys(errors).includes('lastName')}
          value={lastName}
          setValue={setLastName}
          autoCapitalize="words"
          returnKeyType="next"
          enablesReturnKeyAutomatically
        />
        <Input
          label="Email"
          name="email"
          isError={Object.keys(errors).includes('email')}
          value={email}
          setValue={setEmail}
          keyboardType="email-address"
          autoCompleteType="email"
          autoCapitalize="none"
          returnKeyType="next"
          enablesReturnKeyAutomatically
          // TODO: figure out ref types to allow
          // onPressEnter={() =>
          //   passwordInput.current.focus()
          // }
        />
        <Input
          value={password}
          setValue={setPassword}
          isError={Object.keys(errors).includes('password')}
          // ref={passwordInput}
          label="Password (6 or more characters)"
          secureTextEntry={true}
          returnKeyType="go"
          enablesReturnKeyAutomatically
          onPressEnter={submit}
        />
      </View>
      <View style={[s.mb16]}>
        <Button text='Sign Up for Free' onPress={submit} />
      </View>
      <View style={[s.row]}>
        <Text style={[s.textBlackLighter, s.f16]}>Already have an account?{' '}</Text>
        <Link to='/account/login' >
          <Text style={[s.textBlue, s.textBold, s.f16]}>Sign in</Text>
        </Link>
      </View>
    </OzobotBackground>
  )
}

export default Join
