// single page for all settings pages to
// avoid flashing of assets on page toggle
import React, { Component } from 'react'
import { View, KeyboardAvoidingView } from 'react-native'
import { Content, Layout, PageH1, PageHeader } from 'components/Layout'
import { connect } from 'react-redux'
import { userUpdateRequest, userPasswordChangeRequest, userViewRequest } from 'store/user'
import Devices from './devices'
import Profile from './profile'
import Account from './account'
import ToggleNav from 'components/Navigation/ToggleNav'
import { RouteComponentProps } from 'react-router-dom';
import s from 'styles'
import MissingPage from 'pages/404'
import { withDeviceHook } from 'hooks/withDeviceHook'

interface User {
  firstName: string,
  lastName: string,
  jobTitle: string,
  school: string,
  email: string,
  phone: string,
  accountId: string,
  evoCount: number,
  bitCount: number,
  occCount: number,
  receiveEmails: boolean,
  userUpdateRequest: (myobj: { [name: string]: string }) => void,
}

interface Props extends RouteComponentProps {
  props,
  user: User,
  isDesktop?: boolean,
}

interface State {
  component: string | undefined,
}

class SettingsPage extends Component<Props, State> {
  components = {
    'profile': Profile,
    'account': Account,
    'devices': Devices,
  }
  constructor(props) {
    super(props)
    this.state = {
      component: this.props.match.params['page'],
    }
  }

  resolveComponent() {
    if (this.components[this.props.match.params['page']] !== undefined) {
      return React.createElement(this.components[this.state.component], {
        ...this.props.user,
        userUpdateRequest,
      })
    } else {
      return this.props.history.push('/settings/profile')
    }
  }

  componentDidUpdate(prevProps) {
    // handles URL changes
    if (this.props.match?.params['page'] !== this.state.component) {
      this.setState({
        component: this.props.match.params['page'],
      })
    }
    // handles loading page when history.goBack() is used
    if (prevProps.match && (prevProps.match.params !== this.props.match.params)) {
      this.setState({
        component: this.props.match.params['page'],
      })
    }
  }

  render() {
    if (!this.components[this.props.match.params['page']]) return <MissingPage {...this.props} />
    return (
      <KeyboardAvoidingView behavior={'padding'} style={s.flex1}>
        <Layout>
          <PageHeader>
            <PageH1 text="Settings" hasMarginBottom={true} />
          </PageHeader>
          <Content>
            <ToggleNav
              options={[
                { url: '/settings/profile', text: 'Profile' },
                { url: '/settings/account', text: 'Account' },
              ]}
            />
            <View style={[s.flex1, s.justifyBetween, this.props.isDesktop ? s.w60p : s.w90p, s.mAuto]}>
              {this.resolveComponent()}
              <View />
            </View>
          </Content>
        </Layout>
      </KeyboardAvoidingView>
    )
  }
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default withDeviceHook(connect(mapStateToProps, {
  userUpdateRequest,
  userPasswordChangeRequest,
  userViewRequest,
})(SettingsPage))

