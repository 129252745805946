import * as React from 'react'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import Input from 'components/Form/Input2'
import OzobotBackground from './components/OzobotBackground'
import { userPasswordForgotRequest } from 'store/user'
import Markdown from '@ozobot/react-native-simple-markdown'
import s, { styles } from 'styles'
import { useDispatch } from 'react-redux'
import validate from 'validate.js'
import validations from 'lib/validations'
import { pick, isEmpty } from 'lodash'
import Link from 'components/Router/Link'
import { RouteComponentProps } from 'react-router-dom';

const Forgot: React.FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState([])
  const [isEmailSent, setIsEmailSent] = React.useState(false)

  const submit = () => {
    const errorArr = validate(
      { email },
      pick(validations, ['email']),
      { fullMessages: false },
    )
    if (isEmpty(errorArr)) {
      dispatch(userPasswordForgotRequest({ email }))
      setIsEmailSent(true)
    } else {
      setErrors(errorArr)
    }
  }

  return (
    <OzobotBackground
      footerLinkText={!isEmailSent && 'Back to login'}
      footerLink={'/account/login'}
    >
      <View style={[s.flex1, { width: 260 }]}>
        <>
          <Text style={[s.textCenter, s.f24, s.textGreen, s.textBold, s.mb20]}>
            {isEmailSent ? 'Check Your Inbox!': 'Forgot Your Password?'}
          </Text>
          <View style={s.mb20}>
            <Markdown styles={{text: {...styles.f14, ...styles.textBlack}}}>
              {isEmailSent ? `We sent a message to **${email}** to reset your password. If you don’t receive it within a few minutes, check your email’s spam and junk folders.` :
                'Enter the email address associated with your account, and we’ll send you a link to reset your password.'}
            </Markdown>
          </View>
          {isEmailSent ?
            <>
              <Text style={[s.f14, s.textBold, s.textGrayDarkest]}>
                Questions?
              </Text>
              <Text style={[s.f14, s.mb20]}>Contact <Link to='mailto:support@ozobot.com'>
                <Text style={[s.textGreen, s.textUnderline]}>
                  support@ozobot.com
                </Text>
              </Link></Text>
            </> :
            <Input
              value={email}
              setValue={setEmail}
              label='Email Address'
              isError={Object.keys(errors).includes('email')}
              onPressEnter={submit}
            />
          }
          <View style={[s.alignSelfCenter, { width: 'fit-content'}]}>
            <Button
              text={isEmailSent ? 'Back to login' : 'Reset Password'}
              onPress={isEmailSent ? () => props.history.push('/account/login') : submit}
            />
          </View>
        </>
      </View>
    </OzobotBackground>
  )
}

export default Forgot
