import React, { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
} from 'react-native'
import s from 'styles'

export default class Input extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFocused: false,
      isSecure: true,
    }

    this.formInput = React.createRef()
    this.formInputClear = this.formInputClear.bind(this)
    this.formInputFocus = this.formInputFocus.bind(this)
    this.onFormInputBlur = this.onFormInputBlur.bind(this)
    this.onFormInputChangeText = this.onFormInputChangeText.bind(this)
    this.onFormInputFocus = this.onFormInputFocus.bind(this)
    this.toggleSecure = this.toggleSecure.bind(this)
  }

  formInputClear() {
    this.formInput.current.clear()
  }

  formInputFocus() {
    this.formInput.current.focus()
  }

  onFormInputBlur() {
    this.setState({
      isFocused: false,
    })

    if (this.props.validate) {
      this.props.validate(this.props.name || this.props.label)
    }
  }

  onFormInputChangeText(text) {
    this.props.onFormInputChangeText({
      [this.props.name || this.props.label]: text,
    })
  }

  onFormInputFocus() {
    this.setState({ isFocused: true })
  }

  toggleSecure() {
    this.setState({ isSecure: !this.state.isSecure })
  }

  render() {
    const {
      errorMessage,
      inputStyle,
      isClearable,
      label,
      labelTextStyle,
      onFormInputBlur,
      onFormInputChangeText,
      onFormInputFocus,
      onPressEnter,
      validate,
      width = 'w100p',
      barStyle,
      ...props
    } = this.props

    return (
      <View style={[s.positionRelative, s[width]]}>
        {label && (
          <TouchableWithoutFeedback
            accessible={false}
            onPress={this.formInputFocus}
          >
            <View>
              <Text
                style={[
                  s.mb4,
                  s.f16,
                  s.textBold,
                  !!errorMessage && s.textRed,
                ].concat(labelTextStyle)}
              >
                {label}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )}
        <View
          style={[
            s.flexRow,
            s.alignCenter,
            s.mb20,
            s.bgWhite,
            !this.props.disabled && s.b1,
            !!errorMessage
              ? s.bRed
              : this.state.isFocused
              ? s.bGrayLighter
              : s.bGrayLighter,
            s.br5,
            barStyle,
          ]}
        >
          <TextInput
            {...props}
            data-hj-whitelist
            ref={this.formInput}
            onBlur={this.onFormInputBlur}
            onChangeText={this.onFormInputChangeText}
            onFocus={this.onFormInputFocus}
            onSubmitEditing={onPressEnter}
            value={this.props.value}
            /*secureTextEntry={
              this.props.secureTextEntry ? this.state.isSecure : false
            }*/
            disabled={this.props.disabled}
            style={[s.w100p, s.h40, s.px16, s.f16, s.outlineStyleNone].concat(
              inputStyle
            )}
          />
          <View style={[s.flexRow]}>
            {isClearable && (
              <TouchableOpacity
                accessible={false}
                onPress={this.formInputClear}
                style={[s.pl4]}
              >
                <Text style={[s.f16]}>Clear</Text>
                {/* <Image
                  style={{ height: '9px', width: '9px' }}
                  source={src} />*/}
              </TouchableOpacity>
            )}
            {/* {this.props.secureTextEntry && (
              <TouchableOpacity
                accessible={false}
                onPress={this.toggleSecure}
                style={[s.pl4]}
              >
                <Text style={[s.f16]}>View</Text>
              </TouchableOpacity>
            )} */}
          </View>
        </View>
        {!!errorMessage && (
          <Text style={[s.positionAbsolute, s.f14, s.textRed, { bottom: 2 }]}>
            {errorMessage}
          </Text>
        )}
      </View>
    )
  }
}
