import * as React from 'react'
import { View, Text } from 'react-native'
import { CheckBox } from 'components/ReactNative'
import Button from 'components/Form/Button2'
import { ModalFrame } from 'components/modal'
import s from 'styles'
import { Link } from '../../components/Router'
import { useDispatch } from 'react-redux'
import { modalHide } from 'store/modal'

export type CancelSignUpOnPress = () => void

export const CancelSignUpModal = (props: { onPress: CancelSignUpOnPress | undefined }) => {
  const { onPress } = props
  const dispatch = useDispatch()
  return (
    <ModalFrame title="Agree to Terms to Sign Up" size="medium">
      <View style={[s.justifyBetween, s.mx64]}>
        <Text style={[s.f24, s.mx16, s.textBold, s.textCenter]}>Are you sure? Closing Terms and Privacy will cancel your account creation.</Text>
        <View style={[s.flexRow, s.my24, s.justifyAround]}>
          <Button text="Cancel Sign Up" onPress={() => dispatch(modalHide())} />
          <Button text="Review Terms and Privacy" onPress={() => {
            onPress && onPress()
            dispatch(modalHide())
          }
          } />
        </View>
      </View>
    </ModalFrame>
  )
}

export const showCancelSignUp = (onPress: CancelSignUpOnPress | undefined) => {
  return (<CancelSignUpModal onPress={onPress} />)
}

const BulletPoint = ({ text }: { text: string; }) => (
  <View style={[s.flexRow, s.alignCenter, s.mb8]}>
    <View
      style={[
        s.justifyCenter,
        s.h10,
        s.w10,
        s.br10,
        s.bgTeal,
        s.mr20,
      ]}
    />
    <Text style={[s.f16]}>{text}</Text>
  </View>
)

export type TermsPrivacyModalAccept = { subscribe: boolean }
export type TermsPrivacyModalOnPress = (res: TermsPrivacyModalAccept) => void

export const TermsPrivacyModal = (props: { onPress: TermsPrivacyModalOnPress | undefined }) => {
  const { onPress } = props
  const dispatch = useDispatch()
  const [join, setJoin] = React.useState(false)

  return (
    <ModalFrame title="Ozobot Terms and Privacy" size="medium">
      <View style={[s.flex1, s.mx16]}>
        <Text style={[s.f24, s.textBold, s.textCenter]}>Your privacy is our priority.</Text>
        <View style={[s.flex1, s.justifyBetween, s.mt32, s.mx64]} >
          <BulletPoint text='We never sell your data.' />
          <BulletPoint text='When you submit content like lessons in Ozobot Classroom, you own those lessons but grant us the right to share them.' />
        </View>
        <View style={[s.mt16, s.mx64]} >
          <Text style={[s.f16]}>
            By continuing, you agree to Ozobot’s{' '}
            <Link to='https://ozobot.com/terms-of-use' >
              <Text style={[s.f16, s.textGreen, s.textBold, s.textUnderline]}>Terms of Use</Text>
            </Link>
            {'\nand '}
            <Link to='https://ozobot.com/privacy' >
              <Text style={[s.f16, s.textGreen, s.textBold, s.textUnderline]}>Privacy Policy</Text>
            </Link>
            .
          </Text>
          <View style={[s.flexRow, s.mt20]}>
            <CheckBox style={{ margin: 2, backgroundColor: '#00b1b9' }}
              value={join}
              onValueChange={val => setJoin(val)}
            />
            <View style={[s.ml8]}>
              <Text style={[s.f16, s.textLeft]} >Get updates, lesson ideas, and more!</Text>
            </View>
          </View>
          <View style={[s.alignCenter, s.mt32]}>
            <Button text="Agree and Continue" onPress={() => {
              onPress && onPress({ subscribe: join })
              dispatch(modalHide())
            }} />
          </View>
        </View>
      </View>
    </ModalFrame>
  )
}

export const showTermsPrivacyModal = (onPress: TermsPrivacyModalOnPress | undefined) => {
  return (<TermsPrivacyModal onPress={onPress} />)
}

