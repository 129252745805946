import * as React from 'react'
import { TouchableOpacity, Text, ViewStyle, TextStyle, ImageStyle } from 'react-native'
import castArray from 'lodash/castArray'
import s from 'styles'

type Styles = ViewStyle | TextStyle | ImageStyle

interface LinkProps {
  children?: React.ReactNode
  onPress?: () => void
  style?: Styles | Styles[]
  outerStyle?: Styles | Styles[]
}

/**
 * Create clickable text links with teal colored and underlined text.
 * 
 * <Link onPress={() => {Linking.openURL('google.com')}>Google</Link>
 */
function TextLink({ children, onPress, style, outerStyle }: LinkProps) {
  style = castArray(style)
  outerStyle = castArray(outerStyle)

  return (
    <TouchableOpacity onPress={onPress} style={[...outerStyle]}>
      <Text style={[s.f14, s.textGreenDark, s. textBold, s.textUnderline, s.alignSelfCenter, ...style]}>
        {children}
      </Text>
    </TouchableOpacity>
  )
}

export default TextLink