import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  content: undefined,
  isOpen: false,
}

const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    modalShow(state, { payload }) {
      return {
        content: payload,
        isOpen: true,
      }
    },
    modalHide() {
      return initialState
    },
  },
  extraReducers: {
    '@@router/LOCATION_CHANGE': () => {
      return initialState
    },
  },
})

export const { modalShow, modalHide } = modal.actions
export const modalSelector = s => s.modal

export default modal.reducer
