import { DIALOG_SHOW, DIALOG_HIDE, DIALOG_UPDATE_PERCENTAGE } from './types'

const initialState = {
  show: false,
  data: {},
  progress: undefined,
}

function updatedPercentage(state, ratioDone) {
  const now = Date.now()
  const updateTime =
    (state.progress && now - state.progress.lastTime > 10000) || ratioDone > 0.5
  return state.progress
    ? {
        ...state.progress,
        ratioDone,
        timeLeft: updateTime
          ? ((now - state.progress.timeBegan) / Math.max(ratioDone, 0.01)) *
            (1 - ratioDone)
          : state.progress.timeLeft,
        lastTime: updateTime ? now : state.progress.lastTime,
      }
    : {
        ratioDone,
        timeBegan: now,
        lastTime: now,
        timeLeft: undefined,
      }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DIALOG_SHOW: {
      return {
        ...state,
        show: true,
        data: action.data,
        onClose: action.onClose,
        onAltClose: action.onAltClose,
        onLinkClose: action.onLinkClose,
        closeOnTap: action.closeOnTap,
      }
    }
    case DIALOG_HIDE: {
      return {
        ...state,
        show: false,
        onClose: undefined,
        onAltClose: undefined,
        onLinkClose: undefined,
        progress: undefined,
        closeOnTap: undefined,
      }
    }
    case DIALOG_UPDATE_PERCENTAGE: {
      return {
        ...state,
        progress: updatedPercentage(state, action.ratioDone),
      }
    }

    default:
      return state
  }
}
