import autocomplete from './autocomplete/sagas'
import botz from './botz/sagas'
import global from './global/sagas'
import lessons from './lessons/sagas'
import user from './user/sagas'

const sagas = [autocomplete, botz, global, lessons, user]

export function initSagas(sagaMiddleware) {
  sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware))
}
