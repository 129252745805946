import * as React from 'react'
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import useModal from 'hooks/useModal'
import { userAuthSignoutRequest, userSelector } from 'store/user'
import BotConnectionDropdown from 'components/BotConnectionDropdown'
import { Image } from 'components/ReactNative'
import { Link } from 'components/Router'
import ToolTip from 'components/ToolTip'
import { ModalTopRight } from 'components/UseModal'
import s from 'styles'
import { useDimensionsSync } from 'hooks/useWindowDimensions'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import MobileMenu from 'components/Header/MobileMenu'
import DesktopSubMenu from 'components/Header/DesktopSubMenu'
import { useRouteMatch } from 'react-router'
import FreeDemoButton from './FreeDemoButton'

interface Bot {
  connected: boolean
}
interface Botz {
  byIds: {
    [uuid: string]: Bot
  }
}
interface RootUrlProps {
  rootUrl?: string | null
}

const botImage = {
  connected: require('images/bot-connected.svg'),
  disconnected: require('images/bot-disconnected.svg'),
}

function HeaderLogo({ rootUrl }: RootUrlProps) {
  const { isDesktop }  = useDetectDeviceType();

  const image = <Image
    style={isDesktop ? { height: 30, width: 300 } : { height: 25, width: 200 }}
    source={require('images/ozobot-classroom-logo-line-color.png')}
  />

  if (typeof rootUrl === 'string') {
    return (
      <Link to={rootUrl}>
        {image}
      </Link>
    )
  }

  return image
}

function PortalMenu({ isPublic }: { isPublic: boolean }) {
  return(
    <ToolTip
      componentName="headerPortalMenu"
      isPublic={isPublic}
      placement="bottom"
    />
  )
}

export function BotConnection() {
  const { setModal, unSetModal } = useModal()
  const isConnected = useSelector(({ botz }: { botz: Botz}) =>
    Object.values(botz.byIds).some(bot => bot.connected),
  )

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() =>
        setModal(
          <ModalTopRight>
            <BotConnectionDropdown closeModal={() => unSetModal()} />
          </ModalTopRight>,
        )
      }
    >
      <Image
        style={{ height: 32, width: 32 }}
        source={isConnected ? botImage.connected : botImage.disconnected}
      />
    </TouchableOpacity>
  )
}

function TopMenu() {
  const { path } = useRouteMatch()
  const { browserWidowWidth } = useDetectDeviceType()

  const links = [
    {
      url: '/dashboard',
      text: 'Dashboard',
    },
    {
      url: '/lessons',
      text: 'Lessons',
      sublinks: [
        {
          url: '/lessons/saved',
          text: 'Saved Lessons',
        },
        {
          url: '/lessons/mine',
          text: 'My Lessons',
        },
      ],
    },
    {
      url: '/devices',
      text: 'Devices',
    },
    {
      url: '/help',
      text: 'Help',
    },
  ]

  return (
    <View style={[s.flexRow, s.justifyBetween, s.alignCenter, browserWidowWidth > 1400 && s.w500]}>
      {links.map(
        ({ url, text, sublinks }) => {
          return (
            <View key={url} style={[
              s.m8,
              s.p8,
              s.flexRow,
              s.alignCenter,
            ]}>
              <Link to={url}>
                <View style={[s.alignCenter, s.justifyCenter, s.py8]}>
                  <Text style={[s.f16, (path == url) ? [s.textBold, s.textGreenDark] : null]}>{text}</Text>
                </View>
              </Link>
              { sublinks && <DesktopSubMenu links={sublinks} /> }
            </View>
          )
        })
      }
    </View>
  )
}

interface HeaderWrapperProps extends RootUrlProps {
  children: JSX.Element | JSX.Element[],
}

export function HeaderWrapper({ rootUrl = '/dashboard', children }: HeaderWrapperProps) {

  const { isDesktop }  = useDetectDeviceType();
  const user = useSelector(userSelector)

  return (
    <View
      style={[
        s.h60,
        s.flexRow,
        s.alignCenter,
        s.justifyBetween,
        s.px16,
        s.bBottom1,
        s.bGrayLighter,
        s.zIndex1,
      ]}
    >
      <HeaderLogo rootUrl={rootUrl} />
      { isDesktop ? (
        <>
          {user.isVerified && (<TopMenu />)}
          <View style={[s.flexRow]}>{children}</View>
        </>
      ): <MobileMenu />}
    </View>
  )
}

function HeaderEducatorMenuText({ text }: { text: string }) {
  return (
    <Text style={[s.px32, s.my8, s.f16, s.textRight]}>{text}</Text>
  )
}

function HeaderEducatorMenu({ text, uri }: { text: string, uri: string }) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleMenu = React.useCallback(() => setIsOpen(v => !v), [])
  const onLogout = () => dispatch(userAuthSignoutRequest())
  return (
    <View>
      <TouchableOpacity activeOpacity={1} onPress={toggleMenu}>
        <View style={[s.flexRow, s.alignCenter]}>
          <ImageBackground
            source={{ uri }}
            style={[
              s.h32,
              s.w32,
              s.overflowHidden,
              s.justifyCenter,
              s.bgGrayLightest,
              s.br16,
            ]}
          >
            {!uri && (
              <Text style={[s.f14, s.textBold, s.textCenter]}>
                {text}
              </Text>
            )}
          </ImageBackground>
        </View>
      </TouchableOpacity>
      {isOpen && ( // use Tippy?
        <View
          style={[
            s.positionAbsolute,
            s.right0,
            s.py4,
            s.pt16,
            s.bgWhite,
            s.b1,
            s.br16,
            s.bWhiteDarkest,
            { top: 45 },
          ]}
        >
          <Link to="/settings/profile">
            <HeaderEducatorMenuText text="Settings" />
          </Link>
          <TouchableOpacity onPress={onLogout}>
            <HeaderEducatorMenuText text="Log Out" />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

export function HeaderEducator() {
  const user = useSelector(userSelector)
  return (
    <HeaderWrapper>
      {user.isVerified && (<FreeDemoButton />)}
      <HeaderEducatorMenu text={user.initials} uri={user.profileImageUrl} />
      {user.isVerified && (<PortalMenu isPublic={false} />)}
    </HeaderWrapper>
  )
}

export function HeaderPublic() {
  const MAX_DEVICE_WIDTH = 1024
  const dimension = useDimensionsSync();

  return (
    <HeaderWrapper rootUrl={null}>
      { dimension.width > MAX_DEVICE_WIDTH ? <BotConnection /> : null }
      <PortalMenu isPublic={true} />
    </HeaderWrapper>
  )
}
