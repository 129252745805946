import * as React from 'react'
import { View, Text, ViewStyle, TextStyle } from 'react-native'
import castArray from 'lodash/castArray'
import s from 'styles'

interface TdataProps {
  children?: React.ReactNode
  style?: ViewStyle | ViewStyle[]
  textStyle?: TextStyle | TextStyle[]
}

export default function Tdata({children, style, textStyle}: TdataProps) {
  style = castArray(style)

  if (typeof children === 'string') {
    children = <Text style={textStyle}>{children}</Text>
  }

  return (
    <View style={[s.flexRow, ...style]}>
      {children}
    </View>
  )
}