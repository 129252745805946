import storage from 'redux-persist/lib/storage'
import { persistReducer, createMigrate } from 'redux-persist'
export function persist({
  key,
  reducer,
  whitelist = undefined,
  blacklist = undefined,
  version = undefined,
  migrations = undefined,
}) {
  return persistReducer(
    {
      key,
      storage,
      whitelist,
      blacklist,
      version,
      migrate: migrations ? createMigrate(migrations) : undefined,
    },
    reducer
  )
}
