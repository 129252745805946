import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Text, View, TouchableOpacity, Modal } from 'react-native'
import ReactPlayer from 'react-player'
import LinearGradient from 'react-native-web-linear-gradient'
import s from 'styles'
import { Dimensions } from 'react-native'
import { Image } from 'components/ReactNative'
import ColumnSlider from 'components/ColumnSlider'
import Tippy from '@tippyjs/react/headless'
import RangeSlider from 'components/RangeSlider'
import Thumb from 'components/RangeSlider/Thumb'
import RailSelected from 'components/RangeSlider/RailSelected'
import Rail from 'components/RangeSlider/Rail'
import Notch from 'components/RangeSlider/Notch'
import Label from 'components/RangeSlider/Label'

const urlFromVideo = (video) => {
  const id = video.videoID
  let url = 'https://cloudflarestream.com/' // should point to custom missing video
  if (video.provider === 'cloudflare') {
    url = 'https://cloudflarestream.com/' + id + '/manifest/video.m3u8'
  } else if (video.provider === 'youtube') {
    url = 'https://www.youtube.com/embed/' + id
  } else if (video.provider === 'url') {
    url = id
  }
  return url
}

export const VideoPreview = ({ video, width=960, height=540 }) => {
  return (<Image
    source={
      video.provider === 'cloudflare'
        ? { uri: `https://cloudflarestream.com/${video.videoID}/thumbnails/thumbnail.jpg?time=4s&width=${width}&height=${height}` }
        : require('images/video-play2.svg')
    }
    style={{
      width:'100%',
      height:'100%'
    }}
  />)
}

function displayTime(totalseconds) {
  var sec_num = parseInt(totalseconds, 10) // don't forget the second param
  var minutes = Math.floor(sec_num / 60)
  var seconds = sec_num - minutes * 60

  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return minutes + ':' + seconds
}

const isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

const playIcon = (
  <Image
    source={require('images/video-play2.svg')}
    style={{
      width: 40,
      height: 40,
      resizeMode: 'contain',
      tintColor: '#ffffff',
    }}
  />
)

const Fullscreen = props => {
  if (props.fullscreen === true) {
    return (
      <Modal transparent={true}>
        <View
          style={[
            s.flexGrow1,
            s.justifyCenter,
            s.alignCenter,
            { backgroundColor: 'rgba(0,0,0,1.0)' },
          ]}
          onLayout={props.measureViewSize}
        >
          <View>
            <span>{props.children}</span>
          </View>
        </View>
      </Modal>
    )
  } else {
    return (
      <View
        style={[
          s.alignCenter,
          s.justifyCenter,
          s.w100p,
          s.h100p,
          props.background,
        ]}
        onLayout={props.measureViewSize}
      >
        <View>
          <span>{props.children}</span>
        </View>
      </View>
    )
  }
}

const ToolTipTouchableOpacity = props => {
  return (
    <TouchableOpacity {...props}>
      { isTouchDevice ? ( <span>{props.children}</span> ) : (
        <Tippy
          render={attrs => (
            <View style={[s.br5, s.bgWhite]} {...attrs}>
              {props.mini ? (
                <Text style={[s.px4, s.py2, s.f12, s.textBold]}>
                  {props.tooltip}
                </Text>
              ) : (
                <Text style={[s.p4, s.f14, s.textBold]}>{props.tooltip}</Text>
              )}
            </View>
          )}
        >
          <span>{props.children}</span>
        </Tippy>
      )}
    </TouchableOpacity>
  )
}

const ChapterPlayControl = props => {
  return (
    <View style={[s.flexRow, s.justifyCenter, s.alignCenter]}>
      {props.onChapter && (
        <ToolTipTouchableOpacity
          mini={props.mini}
          tooltip={'Previous Chapter'}
          onPress={() => {
            if (props.onChapter) {
              props.onChapter(props.chapterNumber - 1)
            }
          }}
        >
          <Image
            source={require('images/video-prev-chapter.svg')}
            style={{
              width: 16,
              height: 16,
              margin: 5,
              resizeMode: 'contain',
              tintColor: '#ffffff',
            }}
          />
        </ToolTipTouchableOpacity>
      )}

      <ToolTipTouchableOpacity
        mini={props.mini}
        tooltip={props.playing ? 'Pause' : 'Play'}
        onPress={props.onTogglePlay}
      >
        <Image
          source={
            props.playing
              ? require('images/video-pause.svg')
              : require('images/video-play2.svg')
          }
          style={{
            width: 20,
            height: 20,
            margin: 5,
            resizeMode: 'contain',
            tintColor: '#ffffff',
          }}
        />
      </ToolTipTouchableOpacity>
      {props.onChapter && (
        <ToolTipTouchableOpacity
          mini={props.mini}
          tooltip={'Next Chapter'}
          onPress={() => {
            if (props.onChapter) {
              props.onChapter(props.chapterNumber + 1)
            }
          }}
        >
          <Image
            source={require('images/video-next-chapter.svg')}
            style={{
              width: 16,
              height: 16,
              margin: 5,
              resizeMode: 'contain',
              tintColor: '#ffffff',
            }}
          />
        </ToolTipTouchableOpacity>
      )}
    </View>
  )
}

const Timeline = props => {
  const renderThumb = useCallback(() => <Thumb />, [])
  const renderRail = useCallback(() => <Rail />, [])
  const renderRailSelected = useCallback(() => <RailSelected />, [])
  const renderLabel = useCallback(value => <Label text={value} />, [])
  const renderNotch = useCallback(() => <Notch />, [])

  return (
    <View style={{ width: props.length, height: 20 }}>
      <RangeSlider
        style={{ width: props.length, height: 10, marginTop: 4 }}
        min={0}
        max={props.duration}
        low={props.playedSeconds}
        high={props.playedSeconds}
        step={1}
        disableRange={true}
        floatingLabel={true}
        renderThumb={renderThumb}
        renderLabel={renderLabel}
        renderRail={renderRail}
        renderRailSelected={renderRailSelected}
        renderNotch={renderNotch}
        onValueChanged={props.onValueChanged}
        onRelease={props.onRelease}
      />
    </View>
  )
}

const VolumeControl = props => {
  if (props.displayVolume)
    return (
      <View style={[s.alignCenter, s.justifyCenter, { width: 24, height: 20 }]}>
        <View
          style={[
            s.bgBlackLighter,
            s.br12,
            s.alignCenter,
            s.positionAbsolute,
            s.flexColumnReverse,
            { width: 24, height: 130, left: 0, bottom: 0 },
          ]}
        >
          <ToolTipTouchableOpacity
            mini={props.mini}
            tooltip={'Volume'}
            onPress={props.onToggleVolume}
          >
            <Image
              source={require('images/video-volume.svg')}
              style={{
                width: 12,
                height: 12,
                resizeMode: 'contain',
                tintColor: '#ffffff',
                marginBottom: 6,
              }}
            />
          </ToolTipTouchableOpacity>

          <ColumnSlider
            style={{ margin: 6 }}
            height={72}
            width={4}
            borderRadius={2}
            min={0}
            max={1}
            minimumTrackTintColor="#00B1B9"
            maximumTrackTintColor="#313847"
            value={props.volume}
            onChange={value => props.onSetVolume(value)}
            onComplete={value => props.onSetVolume(value)}
          />
          <ToolTipTouchableOpacity
            mini={props.mini}
            tooltip={props.muted ? 'Unmute' : 'Mute'}
            onPress={props.onToggleMuted}
          >
            <Image
              source={
                props.muted
                  ? require('images/video-mute.svg')
                  : require('images/video-volume.svg')
              }
              style={{
                width: 12,
                height: 12,
                resizeMode: 'contain',
                tintColor: '#ffffff',
                margin: 6,
              }}
            />
          </ToolTipTouchableOpacity>
        </View>
      </View>
    )
  else
    return (
      <View
        style={[
          s.bgBlackLighter,
          s.br12,
          s.alignCenter,
          s.justifyCenter,
          { width: 24, height: 20 },
        ]}
      >
        <ToolTipTouchableOpacity
          mini={props.mini}
          tooltip={'Volume'}
          onPress={props.onToggleVolume}
        >
          <Image
            source={
              props.muted
                ? require('images/video-mute.svg')
                : require('images/video-volume.svg')
            }
            style={{
              width: 12,
              height: 12,
              resizeMode: 'contain',
              tintColor: '#ffffff',
            }}
          />
        </ToolTipTouchableOpacity>
      </View>
    )
}

export default function VideoPlayer(props) {
  const [playOnRelease, setPlayOnRelease] = useState(false)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [duration, setDuration] = useState(0)

  const [playing, setPlaying] = useState(false)
  const [posterOff, setPosterOff] = useState(false)
  const [volume, setVolume] = useState(0.666)
  const [muted, setMuted] = useState(false)
  const [displayControls, setDisplayControls] = useState(false)
  const [displayVolume, setDisplayVolume] = useState(false)
  const [prevVideo, setPrevVideo] = useState({})

  const loaded = useRef(false)
  const useProgress = useRef(false)
  const videoProgress = useRef([])
  const timelineJustPressed = useRef(false)
  const timer = useRef(null)
  const videoRef = useRef()

  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  const [windowHeight, setWindowHeight] = useState(
    Dimensions.get('window').height
  )
  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)
  const [fullscreen, setFullscreen] = useState(false)

  useEffect(() => {
    setPlaying(props.requestPlaying)
  }, [props.requestPlaying])

  useEffect(() => {
    setPlaying(props.requestPlaying)
  }, [props.requestPlaying])

  useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      Dimensions.removeEventListener('change', onChange)
      clearTimer()
    }
  }, [])

  useEffect(() => {
    if (props.video) {
      if (props.video.id) {
        window.alert('use videoID, not id')
      }
      if (props.video.videoID !== prevVideo.videoID) {
        useProgress.current = false
        loaded.current = false
        setPlayedSeconds(0)
        setPrevVideo(props.video)
      }
    } else {
      console.error('video is undefined')
    }
  }, [props.video, props.video?.videoID, prevVideo.videoID])

  function onMouseEnter() {
    if (displayControls === false) {
      setDisplayControls(true)
    }
    clearTimer()
  }

  function onMouseLeave() {
    //console.log('exit')
  }

  function onMouseMove() {
    if (displayControls === false) {
      setDisplayControls(true)
    }
    setTimer(3)
  }

  function setTimer(seconds) {
    clearTimer()
    timer.current = setTimeout(() => {
      setDisplayControls(false)
    }, seconds * 1000)
  }

  function clearTimer() {
    if (timer.current) {
      clearTimeout(timer.current)
    }
  }

  function onTogglePlay() {
    setPlaying(prevValue => !prevValue)
    setPosterOff(true)
  }

  function onToggleVolume() {
    setDisplayVolume(preValue => !preValue)
  }

  function onSetVolume(value) {
    setVolume(value)
  }

  function onToggleMuted() {
    setMuted(preValue => !preValue)
  }

  function onValueChanged(low, high, user) {
    if (user) {
      if (!isNaN(low)) videoRef.current.seekTo(low)
      if (timelineJustPressed.current === true) {
        timelineJustPressed.current = false
        setPlayedSeconds(low)
      }
    }
  }

  function onRelease(low, high, released) {
    if (released) {
      if (playOnRelease === true) {
        setPlaying(true)
        setPlayOnRelease(false)
      }
      setPlayedSeconds(low)
      videoProgress.current[props.video.videoID] = low
    } else {
      timelineJustPressed.current = true
      if (playing === true) {
        setPlaying(false)
        setPlayOnRelease(true)
      }
    }
  }

  function handleReady() {
    if (loaded.current === false) {
      const video = props.video
      loaded.current = true

      const progress = videoProgress.current[video.videoID]
        ? videoProgress.current[video.videoID]
        : 0

      setPlayedSeconds(progress)
      videoRef.current.seekTo(progress)
    }
  }

  function handlePlay() {
    if (playing === false) {
      setPlaying(true)
    }

    //fix
    if (props.onPlay) {
      props.onPlay(true)
    }
    useProgress.current = true
  }

  function handlePause() {
    setPlaying(false)

    //fix
    if (props.onPlay) {
      props.onPlay(false)
    }
  }

  function handleProgress(props) {
    if (useProgress.current !== true) return

    // need to optimize this before turning back on
    //  if (props.onProgress)
    //    props.onProgress({ id: video.id, progress: state.playedSeconds })

    videoProgress.current[video.videoID] = props.playedSeconds
    setPlayedSeconds(props.playedSeconds)
  }

  function handleOnEnded() {
    if (props.onEnded) {
      props.onEnded()
    }

    if(videoProgress.current[video.videoID]) {
      videoProgress.current[video.videoID] = 0
    }

    setPlayedSeconds(0)
  }

  function handleDuration(value) {
    setDuration(value)
  }

  function measureViewSize({ nativeEvent }) {
    const maxHeight =
      nativeEvent.layout.height < windowHeight && props.autoHeight === false
        ? nativeEvent.layout.height
        : windowHeight
    let width =
      nativeEvent.layout.width < windowWidth
        ? nativeEvent.layout.width
        : windowWidth
    let height = (width / 16) * 9
    if (height > maxHeight) {
      height = maxHeight
      width = (maxHeight / 9) * 16
    }

    setVideoWidth(width)
    setVideoHeight(height - 1)

    if (props.onHeight)
      props.onHeight(height - 1)


  }

  function onChange({ window, screen }) {
    setWindowWidth(window.width)
    setWindowHeight(window.height)
  }

  if (!props.video) {
    return null
  }
  // these are also used by helper functions above
  const video = props.video
  const url = urlFromVideo(video)

  function isRenderControls() {
    return isTouchDevice || (displayControls && (props.poster === undefined || posterOff === true));
  }

  let miniHeight = displayControls && !fullscreen ? 222 : 186

  return (
    <Fullscreen measureViewSize={measureViewSize} fullscreen={fullscreen}>
      <View
        style={[
          {
            height: props.mini && !fullscreen ? miniHeight : 'auto',
          },
        ]}
      >
        <View
          style={[
            {
              width: videoWidth,
              height: videoHeight,
              overflow: 'hidden',
            },
            s.br5,
          ]}
        >
          <ReactPlayer
            style={{ position: 'absolute', top: 0, left: 0 }}
            light={!posterOff && props.poster}
            onClick={onTogglePlay}
            playIcon={playIcon}
            progressInterval={50}
            ref={videoRef}
            url={url}
            playing={playing}
            volume={volume}
            muted={muted}
            onReady={handleReady}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleOnEnded}
            onError={(e, data) => console.log('onError', e, data)} // change to message box
            onProgress={handleProgress}
            onDuration={handleDuration}
            width={'100%'}
            height={'100%'}
            config={{
              file: {
                attributes: {
                  disablePictureInPicture: true,
                  controls: false,
                  controlsList: 'nodownload noremoteplayback',
                },
              },
              playerVars: {
                rel: 0,
                showinfo: 0,
                modestbranding: 1,
                cc_load_policy: 1,
                controls: 0,
                autohide: 1,
              },
            }}
          />
        </View>

        <View style={{ position: 'absolute', top: 0, left: 0 }}>
          <TouchableOpacity
            onMouseEnter={onMouseEnter}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            onPress={onTogglePlay}
          >
            <View
              style={[
                s.justifyCenter,
                s.alignCenter,
                {
                  width: videoWidth,
                  height: videoHeight,
                  backgroundColor: 'rgba(0,0,0,0.0)',
                },
              ]}
            >
              {!playing && (
                <View
                  style={[
                    s.justifyCenter,
                    s.alignCenter,
                    {
                      width: videoWidth,
                      height: videoHeight,
                      backgroundColor: 'rgba(0,0,0,0.0)',
                    },
                  ]}
                >
                  <Image
                    source={require('images/video-play2.svg')}
                    style={{
                      width: 40,
                      height: 40,
                      resizeMode: 'contain',
                      tintColor: '#ffffff',
                    }}
                  />
                </View>
              )}
            </View>
          </TouchableOpacity>
        </View>
        { isRenderControls() && RenderTopControls(props)}
        { isRenderControls() && RenderBottomControls(props)}
        <View></View>
      </View>
    </Fullscreen>
  )

  function RenderBottomControls(props) {
    if (props.mini && !fullscreen) return RenderBottomControlsMini(props)
    return (
      <TouchableOpacity
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        activeOpacity={1}
      >
        <View
          style={[
            s.w100p,
            {
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          ]}
        >
          <LinearGradient
            colors={['rgba(49, 56, 71, 0.33)', 'rgba(37, 41, 50, 1.0)']}
            style={[
              {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
              },
            ]}
          >
            <View style={[s.mx8, s.flexRow, s.alignCenter, s.justifyBetween]}>
              <ChapterPlayControl
                playing={playing}
                mini={props}
                onTogglePlay={onTogglePlay}
                chapterNumber={props.chapterNumber}
                onChapter={props.onChapter}
              />
              <View style={[s.flexRow]}>
                <Timeline
                  duration={duration}
                  playedSeconds={playedSeconds}
                  onValueChanged={onValueChanged}
                  onRelease={onRelease}
                  length={videoWidth - 300}
                />
                <Text style={[s.textWhite, s.textCenter, s.f12, s.mx20]}>
                  {displayTime(duration)}
                </Text>
              </View>
              <View style={[s.flexRow, s.mr20, s.alignCenter]}>
                <VolumeControl
                  volume={volume}
                  muted={muted}
                  displayVolume={displayVolume}
                  onToggleMuted={onToggleMuted}
                  onToggleVolume={onToggleVolume}
                  onSetVolume={onSetVolume}
                />
                {!fullscreen && (
                  <View style={[s.mx20, s.alignCenter]}>
                    <ToolTipTouchableOpacity
                      mini={props.mini}
                      tooltip={fullscreen ? 'Normal' : 'Fullscreen'}
                      onPress={() => {
                        loaded.current = false
                        setFullscreen(preValue => !preValue)
                      }}
                    >
                      <Image
                        source={require('images/video-enlarge.svg')}
                        style={{
                          width: 12,
                          height: 12,
                          resizeMode: 'contain',
                          tintColor: '#ffffff',
                        }}
                      />
                    </ToolTipTouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </LinearGradient>
        </View>
      </TouchableOpacity>
    )
  }

  function RenderTopControls(props) {
    if (props.mini && !fullscreen) return RenderTopControlsMini(props)
    if (fullscreen === false) return null
    const video = props.video
    let title,
      header = ''
    if (video && video.title) {
      title = video.title
    } else {
      title = props.title
    }

    if (props.chapterNumber >= 0) {
      header = props.chapterNumber + 1 + '. '
    }
    if (title) {
      header += title
    }

    if (header) {
      return (
        <View
          style={[
            {
              position: 'absolute',
              top: 0,
              left: 0,
            },
            s.w100p,
          ]}
        >
          <TouchableOpacity
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            activeOpacity={1}
          >
            <View
              style={[
                s.flexRow,
                s.alignCenter,
                s.justifyBetween,
                { height: 56, backgroundColor: 'rgba(37, 41, 50, .5)' },
              ]}
            >
              <Text style={[s.mx20, s.f18, s.textWhite]}>{header}</Text>

              <ToolTipTouchableOpacity
                mini={props.mini}
                tooltip={fullscreen ? 'Normal' : 'Fullscreen'}
                onPress={() => {
                  loaded.current = false
                  setFullscreen(preValue => !preValue)
                }}
              >
                <View
                  style={[
                    s.w24,
                    s.h24,
                    s.mr8,
                    s.br12,
                    s.bgBlackLighter,
                    s.alignCenter,
                    s.justifyCenter,
                  ]}
                >
                  <Image
                    source={require('images/icon-x-white.svg')}
                    style={{
                      width: 12,
                      height: 12,
                      resizeMode: 'contain',
                      tintColor: '#ffffff',
                    }}
                  />
                </View>
              </ToolTipTouchableOpacity>
            </View>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={[{ position: 'absolute', top: 0, right: 0 }]}>
          <TouchableOpacity
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onPress={() => {
              loaded.current = false
              setFullscreen(preValue => !preValue)
            }}
          >
            <View style={[s.mt8, s.mr8, s.flexRow, s.justifyEnd]}>
              <View
                style={[
                  s.h24,
                  s.w24,
                  s.br12,
                  s.bgBlackLighter,
                  s.alignCenter,
                  s.justifyCenter,
                ]}
              >
                <Image
                  source={require('images/icon-x-white.svg')}
                  style={{
                    width: 12,
                    height: 12,
                    resizeMode: 'contain',
                    tintColor: '#ffffff',
                  }}
                />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      )
    }
  }

  function RenderBottomControlsMini(props) {
    return (
      <TouchableOpacity
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        activeOpacity={1}
      >
        <View
          style={[
            s.w100p,
            s.flexColumnReverse,
            s.bgBlackLightest,
            {
              position: 'absolute',
              bottom: -36,
              left: 0,
            },
          ]}
        >
          <ChapterPlayControl
            playing={playing}
            mini={props}
            onTogglePlay={onTogglePlay}
            chapterNumber={props.chapterNumber}
            onChapter={props.onChapter}
          />
          <View style={[s.mx8, s.flexRow, s.alignCenter]}>
            <Timeline
              duration={duration}
              playedSeconds={playedSeconds}
              onValueChanged={onValueChanged}
              onRelease={onRelease}
              length={270}
            />

            <View style={[s.ml8, s.flexRow, s.alignCenter]}>
              <VolumeControl
                volume={volume}
                muted={muted}
                displayVolume={displayVolume}
                onToggleMuted={onToggleMuted}
                onToggleVolume={onToggleVolume}
                onSetVolume={onSetVolume}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  function RenderTopControlsMini(props) {
    return (
      <View style={[{ position: 'absolute', top: 0, left: 0 }, s.w100p]}>
        <TouchableOpacity
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          activeOpacity={1}
        >
          <View style={[s.mt4, s.mr4, s.flexRow, s.justifyEnd]}>
            <View
              style={[
                s.h24,
                s.w24,
                s.br12,
                s.bgBlackLighter,
                s.alignCenter,
                s.justifyCenter,
              ]}
            >
              <ToolTipTouchableOpacity
                mini={props.mini}
                tooltip={'Fullscreen'}
                onPress={() => {
                  loaded.current = false
                  setFullscreen(preValue => !preValue)
                }}
              >
                <Image
                  source={require('images/video-enlarge.svg')}
                  style={{
                    width: 12,
                    height: 12,
                    resizeMode: 'contain',
                    tintColor: '#ffffff',
                  }}
                />
              </ToolTipTouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}
