import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { Column, Row } from 'react-native-web-ui-components'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import s from 'styles'

const ProgressButton = ({
  isActive,
  stepIndex,
  text,
  to,
  isMobile,
  isTablet,
}) => {
  const onStep = () => to(stepIndex + 1)

  return (
    <TouchableOpacity
      onPress={onStep}
      style={[s.bBottom3, s.pb8, isActive ? s.bGreen : s.bGrayLightest, s.flexColumn, s.flex1]}
    >
      <Text
        style={[
          isMobile
            ? s.f10
            : isTablet
              ? s.f12
              : s.f14,
          s.textCenter,
          isActive
            ? [s.textBold]
            : s.textBlack
        ]}
        numberOfLines={2}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}

export default function ProgressButtons({ currentStep, steps, to }) {
  const { isDesktop, isMobile, isTablet } = useDetectDeviceType()
  return (
    <Row style={[s.flexRow, s.flex1, isDesktop && s.mb32, s.justifyBetween, s.alignStretch]}>
      {steps.map(({ slug }, i) => (
        <ProgressButton
          key={i}
          isActive={currentStep === i + 1}
          stepIndex={i}
          text={slug}
          to={to}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      ))}
    </Row>
  )
}
