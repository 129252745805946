import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { signedInAndVerifiedSelector } from 'store/user'
import './chatbase.css'

const ChatbaseButton = (props: {chatbotId: string}) => {

  const isSignedIn = useSelector(signedInAndVerifiedSelector)

  React.useEffect(() => {
    if(isSignedIn) {
      window['chatbaseConfig'] = {
        chatbotId: props.chatbotId,
      }
      const script = document.createElement('script');
      script.src = 'https://www.chatbase.co/embed.min.js';
      script.id = props.chatbotId
      document.body.appendChild(script)

      return () => {
        console.log('UNINSTALLING')

        // FIXME: This is *incredibly* unstable! Find out the "official" way of disabling the chatbot!
        const ids = [props.chatbotId]
        const chatbaseSelector = document.querySelectorAll('[id*="chatbase-"]')
        for(let i = 0; i < chatbaseSelector.length; ++i) {
          ids.push(chatbaseSelector[i].id)
        }
        for(const id of ids) {
          document.getElementById(id).remove()
        }
        // clear cache
      }
    }
  }, [isSignedIn])

  return (<View/>)
}

export default ChatbaseButton
