import * as React from 'react'
import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'components/Router'
import { userAuthCheckIfSignedOut, userSelector } from 'store/user'
import { RouteComponentProps } from 'react-router'

export interface PrivateRouteProps {
  component: React.FunctionComponent<RouteComponentProps> | React.ComponentClass<RouteComponentProps>,
  allowUnverified?: boolean,
}

const PrivateRoute = ({
  component: Component,
  allowUnverified,
  ...props
}: PrivateRouteProps) => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  // fire off a check to ensure we've been signed out
  const onFocus = React.useCallback(() => dispatch(userAuthCheckIfSignedOut({})), [
    userAuthCheckIfSignedOut,
  ])
  React.useEffect(() => {
    // NOTE: we do the Platform check here to avoid React Hook lint errors
    if (Platform.OS === 'web') {
      window.addEventListener('focus', onFocus)
      return () => {
        window.removeEventListener('focus', onFocus)
      }
    }
  }, [])

  return (
    <Route
      {...props}
      render={routeProps => {
        if (!user.isSignedIn) {
          return <Redirect
            to={{
              pathname: '/account/login',
              state: {
                redirect: `${routeProps.location.pathname}${routeProps.location.search}`,
              },
            }}
          />
        }

        if (!user.isVerified && !allowUnverified) {
          return <Redirect
            to={{
              pathname: '/account/unverified',
              state: {
                redirect: `${routeProps.location.pathname}${routeProps.location.search}`,
              },
            }}
          />          
        }

        return <Component {...routeProps} />
      }
      }
    />
  )
}

export default PrivateRoute
