type Callback = (...args: any) => any
type Queue = {[key: string]: Callback[]}

/**
 * Simple PubSub service.
 *
 * Example:
 *
 *   import pubsub from './pubsub'
 *
 *   const unsubscribe = pubsub.sub('event', (one, two) => {
 *     console.log(`hello from event with ${one}, ${two}`)
 *     unsubscribe()
 *   })
 *
 *   pubsub.pub('event', 1, 2)
 */
export class PubSub {
  queue: Queue

  constructor() {
    this.queue = {}
  }

  /**
   * Subscribe to event with callback.
   *
   * @param event event name
   * @param callback callback
   * @returns unsubscribe callback
   */
  sub(event: string, callback: Callback) {
    if (typeof this.queue[event] === 'undefined') {
      this.queue[event] = []
    }

    this.queue[event].push(callback)

    return () => {
      this.queue[event] = this.queue[event].filter(c => c !== callback)
    }
  }

  /**
   * Publish an event and call callbacks.
   *
   * Callbacks are called outside the function (with
   * setTimeout(..., 0)), i. e. the callbacks will be called after
   * the pub function will end (and current callbacks in JS event
   * loop).
   *
   * @param event event name
   * @param args argumentts to pass to callbacks
   */
  pub(event: string, ...args: any): void {
    const eventQueue = this.queue[event]

    if (typeof eventQueue === 'undefined') {
      return
    }

    setTimeout(() => {
      eventQueue.forEach(callback => callback(...args))
    })
  }
}

/**
 * Global PubSub service
 */
export default new PubSub();
