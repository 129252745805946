import React from 'react'
import { View } from 'react-native'
import SubmissionLabel from './SubmissionLabel'
import s from 'styles'

const SubmissionCheckboxesGroup = ({
  field,
  values,
  hasSeparator,
  components,
  ...labelProps
}) => {
  return (
    <View
      style={[s.mb20, hasSeparator && [s.pb20, s.bBottom1, s.bGrayLighter]]}
    >
      <SubmissionLabel {...labelProps} />
      <View style={[s.flexRow, s.flexWrap]}>
        {components &&
          components.map(({ component, ...props }, i) => (
            <View key={i} style={[s.mb8, s.col3]}>
              {React.createElement(component, props)}
            </View>
          ))}
      </View>
    </View>
  )
}

export default SubmissionCheckboxesGroup
