import * as React from 'react'
import { Link } from 'components/Router'
import { Text, View } from 'react-native'
import s from 'styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  userUnverifiedEmailsSelector,
  userAuthEmailSettingsRequest,
  userMightNeedToUpdateABotSelector,
} from 'store/user'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const textStyle = [s.f16, s.textBold, s.textWhite]
const linkStyle = [s.f16, s.textBold, s.textGreen, s.textUnderline]

// For now, just turn off all notifications, since none of them are used - we'll keep the code here in case we need to add any
const disableNotifications = true

export interface CapsuleType {
  children: JSX.Element,
}
export function Capsule({ children }: CapsuleType) {

  const { isMobile } = useDetectDeviceType();

  return (
    <View style={[s.flexRow]}>
      <View
        style={[
          s.py8,
          s.bgGrayDarkest,
          s.justifyCenter,
          s.br16,
          s.mb8,
          isMobile ? s.w100p : null,
        ]}
      >
        <View style={[
          s.mx16,
        ]}>
          {children}
        </View>
      </View>
    </View>
  )
}

export function DashboardNotifications() {

  const unverifiedEmails = useSelector(userUnverifiedEmailsSelector)
  const needsBotUpdate = useSelector(userMightNeedToUpdateABotSelector)
  const dispatch = useDispatch()


  function ResendEmails() {
    dispatch(userAuthEmailSettingsRequest({ email: unverifiedEmails[0], verifyEmails: unverifiedEmails }))
  }

  function EmailNotification() {
    return (
      <Capsule>
        <Text style={textStyle} >Check your email to verify your account. <Text style={linkStyle} onPress={ResendEmails} >Resend Email</Text></Text>
      </Capsule>
    )
  }

  function FirmwareNotification() {
    return (
      <Capsule>
        <Text style={textStyle} >⚠️ New Firmware available for your bots. <Link to='/devices' ><Text style={linkStyle} >Update Now</Text></Link></Text>
      </Capsule>
    )
  }

  if(disableNotifications) {
    return null
  }

  return (
    <View style={[s.px16]}>
      {(unverifiedEmails?.length > 0) && <EmailNotification />}
      {!!needsBotUpdate &&  (<FirmwareNotification />)}
    </View>
  )
}
