import React from 'react'
import { PanResponder, StyleSheet, View } from 'react-native'
import PropTypes from 'prop-types'

export default class ColumnSlider extends React.PureComponent {
  static propTypes = {
    /**
     * Initial value of the slider. The value should be between minimumValue
     * and maximumValue, which default to 0 and 1 respectively.
     * Default value is 0.
     *
     * *This is not a controlled component*, e.g. if you don't update
     * the value, the component won't be reset to its inital value.
     */
    value: PropTypes.number,

    /**
     * If true the user won't be able to move the slider.
     * Default value is false.
     */
    disabled: PropTypes.bool,

    /**
     * Initial minimum value of the slider. Default value is 0.
     */
    min: PropTypes.number,

    /**
     * Initial maximum value of the slider. Default value is 1.
     */
    max: PropTypes.number,

    /**
     * Step value of the slider. The value should be between 0 and
     * (maximumValue - minimumValue). Default value is 0.
     */
    step: PropTypes.number,

    /**
     * The bottom color. Default color is white
     */
    minimumTrackTintColor: PropTypes.string,

    /**
     * The top color. Default color is grey
     */
    maximumTrackTintColor: PropTypes.string,

    /**
     * Callback continuously called while the user is dragging the slider.
     */
    onChange: PropTypes.func,

    /**
     * Callback called when the user finishes changing the value (e.g. when
     * the slider is released).
     */
    onComplete: PropTypes.func,

    /**
     * The style applied to the slider container.
     */
    // Throws TS compiler error, isn't currently needed to function/style
    // style: ViewPropTypes.styles,

    /**
     * The bottom icon
     */
    icon: PropTypes.node,

    /**
     * The with of component. Default value is 100
     */
    width: PropTypes.number,

    /**
     * The border radius of component.
     */
    borderRadius: PropTypes.number,
  }

  static defaultProps = {
    value: 0,
    min: 0,
    max: 1,
    step: 0,
    minimumTrackTintColor: '#fff',
    maximumTrackTintColor: '#eee',
    borderRadius: 0,
  }

  constructor(props) {
    super(props)
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: this._handleStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: this._handleMoveShouldSetPanResponder,
      onPanResponderGrant: this._handlePanResponderGrant,
      onPanResponderMove: this._handlePanResponderMove,
      onPanResponderRelease: this._handlePanResponderEnd,
      onPanResponderTerminationRequest: this._handlePanResponderRequestEnd,
      onPanResponderTerminate: this._handlePanResponderEnd,
    })
    this.state = {
      value: props.value,
      prevValue: props.value,
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.prevValue) {
      return {
        value: props.value,
        prevValue: props.value,
      }
    }
    return null
  }

  _handleStartShouldSetPanResponder = () => true

  _handleMoveShouldSetPanResponder = () => false

  _handlePanResponderGrant = (e, gestureState) => {
    /*
     * Find click location and record the initial value at the beginning for subsequent calculation
     */
    this._moveStartValue = 0
    const val = this._getValue({
      dy: -(this.props.height - e.nativeEvent.locationY),
    })
    this._setCurrentValue(val)
    this._moveStartValue = val
  }

  _handlePanResponderMove = (e, gestureState) => {
    if (this.props.disabled || this.mounted === false) {
      return
    }
    this._setCurrentValue(this._getValue(gestureState))
    this._fireChangeEvent('onChange')
  }

  // allow another component to take over this pan
  _handlePanResponderRequestEnd = () => true

  _handlePanResponderEnd = (e, gestureState) => {
    if (this.props.disabled || this.mounted === false) {
      return
    }
    this._setCurrentValue(this._getValue(gestureState))
    this._fireChangeEvent('onComplete')
  }

  _fireChangeEvent = event => {
    if (this.props[event]) {
      this.props[event](this._getCurrentValue())
    }
  }

  _getCurrentValue() {
    return this.state.value
  }

  _setCurrentValue = value => {
    this.setState({
      value,
    })
  }

  _getValue = gestureState => {
    const { min, max, step, height } = this.props
    const ratio = -gestureState.dy / height
    const diff = max - min
    if (step) {
      return Math.max(
        min,
        Math.min(
          max,
          this._moveStartValue + Math.round((ratio * diff) / step) * step
        )
      )
    }
    const value = Math.max(
      min,
      Math.min(max, this._moveStartValue + ratio * diff)
    )
    return Math.floor(value * 100) / 100
  }

  _getInnerHeight() {
    const { min, max, height } = this.props
    const { value } = this.state
    return ((value - min) * height) / (max - min)
  }

  render() {
    const {
      height,
      width,
      minimumTrackTintColor,
      maximumTrackTintColor,
      icon,
      style,
      borderRadius,
    } = this.props

    const outerStyle = [
      styles.outer,
      { height, backgroundColor: maximumTrackTintColor, borderRadius },
    ]
    const innerStyle = [
      styles.inner,
      {
        height: this._getInnerHeight(),
        backgroundColor: minimumTrackTintColor,
        borderRadius,
      },
    ]

    const thumbStyle = [
      styles.thumb,
      {
        width: width * 2,
        height: width * 2,
        bottom: this._getInnerHeight() - width,
        left: -width / 2,
        borderRadius: width,
      },
    ]

    return (
      <View style={[styles.slider, style]}>
        <View
          style={[
            styles.shadow,
            { backgroundColor: maximumTrackTintColor, width, borderRadius },
          ]}
        >
          <View style={outerStyle} {...this._panResponder.panHandlers}>
            <View style={innerStyle} pointerEvents="none" />
            <View style={thumbStyle} pointerEvents="none" />
          </View>
        </View>
        {icon && <View style={styles.iconContainer}>{icon}</View>}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  slider: {
    alignItems: 'center',
  },
  thumb: {
    backgroundColor: '#00B1B9',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  inner: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
  },
  iconContainer: {
    marginTop: 16,
    alignItems: 'center',
  },
})
