import React from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { xor } from 'lodash'
import { AUTHORS, BOTS, CODING_STYLES, DURATION } from 'lib/utils.js'
import { Button, CheckBoxItem, ToggleSwitch } from 'components/Form'
import { ModalFrame } from 'components/modal'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

class LessonsFilter extends React.Component {
  state = {
    filter: {
      ...this.props.currentState,
    },
  }

  onFilterChange = obj => {
    const [[key, value]] = Object.entries(obj)

    // supports_ar is a bool
    if (key === 'supports_ar') {
      const filter = {
        ...this.state.filter,
        [key]: value ? true : undefined,
      }
      this.setState({ filter })
      return this.props.changeFilter(obj)
    }

    const filter = {
      ...this.state.filter,
      [key]: xor(this.state.filter[key], value),
    }
    this.setState({ filter })
    return this.props.changeFilter(obj)
  }

  render() {
    return (
      <ModalFrame title="Filters" size="medium">
        <View style={[s.pb8, s.mb32]}>
          <View style={[s.mb20]}>
            <Text
              style={[
                s.f18,
                s.textBold,
                s.mb16,
                s.pb4,
                s.bGrayLighter,
                { maxWidth: '100%' }
              ]}
            >
             Coding Method
             </Text>
             <View style={[s.flexRow, { flexWrap: 'wrap' }]}>
                {CODING_STYLES.map(({ label, value }) => (
                  <View key={value} style={[s.mb8, s.col6, { maxWidth: '50%' }]}>
                    <CheckBoxItem
                      isChecked={this.state.filter.coding.includes(value)}
                      label={label}
                      name="coding"
                      onChange={this.onFilterChange}
                      value={value}
                      style={[
                        s.px8,
                        s.textCenter,
                        { whiteSpace: 'normal' },
                      ]}
                    />
                  </View>
                ))}
            </View>
          </View>
          <View style={[s.mb20]}>
            <Text
              style={[
                s.f18,
                s.textBold,
                s.mb16,
                s.pb4,
                s.bGrayLighter,
              ]}
            >
              Robot
            </Text>
            <View style={[s.flexRow]}>
              {BOTS.map(({ label, value }) => (
                <View key={value} style={[s.mb8, s.col4]}>
                  <CheckBoxItem
                    isChecked={this.state.filter.bots.includes(value)}
                    label={label}
                    name="bots"
                    onChange={this.onFilterChange}
                    value={value}
                  />
                </View>
              ))}
            </View>
          </View>
          <View style={[s.mb20]}>
            <Text
              style={[
                s.f18,
                s.textBold,
                s.mb16,
                s.pb4,
                s.bGrayLighter,
              ]}
            >
              Author
            </Text>
            <View style={[s.flexRow]}>
              {AUTHORS.map(({ label, value }) => (
                <View key={value} style={[s.mb8, s.col4]}>
                  <CheckBoxItem
                    isChecked={this.state.filter.author.includes(value)}
                    label={label}
                    name="author"
                    onChange={this.onFilterChange}
                    value={value}
                  />
                </View>
              ))}
            </View>
          </View>
          <View style={[s.mb20]}>
            <Text
              style={[
                s.f18,
                s.textBold,
                s.mb16,
                s.pb4,
                s.bGrayLighter,
              ]}
            >
              Duration
            </Text>
            <View style={[s.flexRow]}>
              {DURATION.map(({ label, value }) => (
                <View key={value} style={[s.mb8, s.col6, { maxWidth: '40%' }]}>
                  <CheckBoxItem
                    isChecked={this.state.filter.duration.includes(value)}
                    label={label}
                    name="duration"
                    onChange={this.onFilterChange}
                    value={value}
                  />
                </View>
              ))}
            </View>
          </View>
          <View style={[s.mb20]}>
            <View style={[s.flexRow, s.alignCenter]}>
              <Text
                style={[
                  s.f18,
                  s.textBold,
                  s.pb4,
                  s.bGrayLighter,
                  { flex: 1 },
                  { marginTop: 10 },
                ]}
              >
                MetaBot Compatible
              </Text>
              <ToggleSwitch
                value={this.state.filter.supports_ar}
                onChange={(newValue) => {
                  this.onFilterChange({ supports_ar: newValue });
                }}
                style={{ marginTop: -8 }}
              />
            </View>
          </View>
        </View>
        <View style={[s.flexRow, s.justifyCenter]}>
        <Button
            text="Clear All"
            role="fifth"
            onPress={this.props.clearFilters}
            style={[s.mx8 ,{ marginRight: 70 }]}
          />

          <Button
            text="Show Lessons"
            role ="fourth"
            onPress={() => this.props.onSave()}
            style={s.mx8}
          />
        </View>
      </ModalFrame>
    )
  }
}

const LessonsFilterWrapper = (props) => {
  const { isDesktop, isMobile, isTablet } = useDetectDeviceType();

  const commonStyles = {
    marginLeft: isMobile ? 20 : (isTablet ? 40 : 'auto'),
    marginRight: isMobile ? 20 : (isTablet ? 40 : 'auto'),
    marginTop: isMobile ? 20 : (isTablet ? 40 : (isDesktop ? 40 : 0)),
    height: isMobile ? 'calc(100vh - 100px)' : 'auto',
    overflowY: isMobile || isTablet ? 'scroll' : 'visible',
    display: isDesktop ? 'flex' : 'block',
    justifyContent: isDesktop ? 'center' : 'flex-start',
    alignItems: isDesktop ? 'center' : 'flex-start',
    borderRadius: 28,
  };

  return (
    <div style={commonStyles}>
      <LessonsFilter {...props} isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet} />
    </div>
  );
};




// export default connect(null, null)(LessonsFilter)
export default connect(null, null)(LessonsFilterWrapper)
