import * as React from 'react'
import { Text, View } from 'react-native'
import OzobotBackground from './components/OzobotBackground'
import { userVerifyEmailRequest } from 'store/user'
import s from 'styles'
import { useDispatch } from 'react-redux'

const Verify: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const url = new URL(window.location.href)
  const currToken = url.searchParams.get('token')
  if (currToken) {
    window.history.replaceState(
      window.history.state,
      '',
      window.location.href.replace(`?token=${currToken}`, ''),
    )
    dispatch(userVerifyEmailRequest({ token: currToken }))
  }

  return (
    <OzobotBackground>
      <View style={[s.flex1, { width: 260 }]}>
        <>
          <Text style={[s.textCenter, s.f24, s.textBlack, s.textBold, s.mb20]}>
            Please wait...
          </Text>
        </>
      </View>
    </OzobotBackground>
  )
}

export default Verify
