import React from 'react'
import { PageHeader, PageH1 } from 'components/Layout'
import { View, Text } from 'react-native'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const PageHeaderWithTitleAndDescription = ({ title, description } : { title: string, description: string }) => {
  const { isDesktop } = useDetectDeviceType()
  return (
    <PageHeader>
      <PageH1 text={title} />
      <View style={[s.py16]}>
        <Text style={[
          isDesktop ? s.f20 : s.f16,
          isDesktop ? s.w50p : s.w100p,
          s.textLight,
        ]}>
          {description}
        </Text>
      </View>
    </PageHeader>
  )
}

export default PageHeaderWithTitleAndDescription
