import React, { useEffect } from 'react'
import { View, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { userAuthGoogleRedirectedToURL } from 'store/user'
import s from 'styles'

const OAuthRedirect = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (Platform.OS === 'web') {
      dispatch(userAuthGoogleRedirectedToURL({ url: window.location.href }))
    }
  }, [])

  return (<View style={[s.bgGrayDarker, s.flex1]} />)
}

export default OAuthRedirect
