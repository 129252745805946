import * as React from 'react'
import { useDispatch } from 'react-redux'
import { modalShow } from 'store/modal'
import SubHeaderWithButton from './SubHeaderWithButton'

interface SubHeaderWithCTAProps {
  text: string,
  buttonText: string,
  Modal?: React.FunctionComponent,
}

/**
 * Markdown in header with CTA
 *
 * <SubHeaderWithCTA
 *  text="Markdown **text** and [link](https://ozobot.com)"
 *  buttonText=""
 *  Modal={SomeModalComponent}
 * />
 */
const SubHeaderWithCTA: React.FC<SubHeaderWithCTAProps> = ({ text, buttonText, Modal }: SubHeaderWithCTAProps) => {
  const dispatch = useDispatch()
  return (
    <SubHeaderWithButton
      text={text}
      buttonText={buttonText}
      onPress={() => Modal && dispatch(modalShow(<Modal/>)) }
    />
  )
}

export default SubHeaderWithCTA
