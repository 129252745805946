import React from 'react'
import TableRow, { TableRowStyle } from './TableRow'
import Tdata from './Tdata'

interface TheadProps {
  styles?: TableRowStyle,
  columns: React.ReactNode[],
  width: (number|string)[]
}

export default function Thead({ columns = [], styles, width }: TheadProps) {
  return (
    <TableRow styles={styles}>
      {columns.map((column, i) => (
        <Tdata key={i} style={{width: width[i]}}>
          {column}
        </Tdata>
      ))}
    </TableRow>
  )
}
