import api from 'lib/api'
import axios from 'axios'
import { isEmpty } from 'lodash'

let nextHandleID = 0
let assets = {}
let binaries = {}

const downloadBinary = async url => {
  const { data } = await axios.get(url, {
    responseType: 'arraybuffer',
    crossDomain: true,
  })
  return data
}

let curRefresh = undefined

export const AssetsMgr = {
  get hasAssets() {
    return !isEmpty(assets)
  },

  // returns a deep copy of the assets
  get assets() {
    return Object.values(assets).map(asset => ({
      ...asset,
      metaData: { ...asset.metaData },
    }))
  },

  // WARNING: refreshing invalidates all handles, and so those binaries will not be fetch-able any more
  async refresh() {
    if (!curRefresh) {
      curRefresh = new Promise(async (resolve, reject) => {
        try {
          assets = (await api.getAssets()).reduce((newAssets, asset) => {
            const handle = ++nextHandleID
            newAssets[handle] = {
              ...asset,
              handle,
            }
            return newAssets
          }, {})
          // just clear all binaries, just to be safe
          binaries = {}
          resolve()
        } catch (e) {
          reject(e)
        } finally {
          curRefresh = undefined
        }
      })
    }

    return curRefresh
  },

  hasBinaryForAsset(handle) {
    return !!binaries[handle]
  },

  async binaryForAsset(handle) {
    let binary = binaries[handle]
    if (!binary) {
      const asset = assets[handle]
      if (!asset) {
        throw new Error(`No asset for handle ${handle}`)
      }
      binary = await downloadBinary(asset.url)
      binaries[handle] = binary
    }
    return binary
  },
}
