import * as React from 'react';
import Tippy from '@tippyjs/react/headless';
import { TextStyle, ViewStyle, View, TouchableOpacity } from 'react-native';

import {textToComponent} from 'components/DataTable/utils'

import s from 'styles'

export interface Action {
  title: React.ReactNode
  callback: (...args: unknown[]) => void
}

interface ContextMenuProps {
  render: () => React.ReactNode;
  onClickOutside: () => void;
  visible: boolean;
  children: React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>;
}

/**
 * Clickable context menu with Tippy.
 */
export function ContextMenu({ children, visible, render, onClickOutside }: ContextMenuProps) {
  return (
    <Tippy
      interactive={true}
      visible={visible}
      onClickOutside={onClickOutside}
      render={render}
      placement="bottom-start"
    >
      {children}
    </Tippy>
  );
}

interface ContextMenuContentProps {
  actions: Action[]
  payload: unknown
  textStyle?: (ViewStyle | TextStyle)[]
  wrapStyle?: (ViewStyle)[]
}

export function ContextMenuContent({actions, payload, textStyle, wrapStyle = []}: ContextMenuContentProps) {
  const hasMultipleActions = actions.length > 1
  let actionStyleBorder = []

  if (open && hasMultipleActions) {
    actionStyleBorder = [s.bBottom2, s.bWhiteDarkest]
  }

  return (
    <View style={[s.px16, s.pt16, s.pb8, s.bgWhite, s.bgWhite,
      s.b1,
      s.bWhiteDarkest,
      s.br5,
      ...wrapStyle,
    ]}>
      {actions.map((action, i) => (
        <View key={i} style={[s.pb4, s.px4, ...actionStyleBorder]}>
          <TouchableOpacity
            onPress={() => action.callback(payload)}
          >
            {textToComponent(action.title, {
              style: textStyle,
            })}
          </TouchableOpacity>
        </View>
      ))}
    </View>
  )
}
