import * as React from 'react'
import { useState, useRef, useCallback } from 'react'
import { Text, View } from 'react-native'
import { Image } from './ReactNative'
import Button from 'components/Form/Button2'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import s from 'styles'
import { Fullscreen, ModalFrame } from 'components/modal'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import api from 'lib/api'
import { showProgressDialog, closeTopDialog } from 'store/dialogBox'
import { toByteArray } from 'base64-js'

const dataURLToBytes = (dataURL: string) => {
  const b64Start = dataURL.indexOf(',')
  if(b64Start < 0) {
    throw new Error('Invalid data URL')
  }
  return new Uint8Array(toByteArray(dataURL.slice(b64Start+1)))
}

const arrayBufferToBase64 = buffer => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

interface OnPickType {
  name: string,
  uri: string,
  buffer: string | ArrayBuffer,
}

export interface ImagePickerType {
  children: JSX.Element,
  onPick: (obj: OnPickType) => void,
}

const ImagePicker = ({ onPick, children }: ImagePickerType) => {
  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.onerror = () => console.warn(`Error: Cannot read ${file.name}`)
      reader.onload = () => {
        onPick({
          name: file.name,
          uri: `data:${file.type};base64,${arrayBufferToBase64(reader.result)}`,
          buffer: reader.result,
        })
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const onDropRejected = () => {
    console.warn('Error: File exceeds 10 MB')
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: 10485760, // 10 MB
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

const pixelRatio = 2

interface EditPictureType {
  accountId: string,
  userUpdateRequest: (myobj: { [name: string]: string }) => {},
  imageUrl: string,
}

export function EditPicture({
  accountId,
  userUpdateRequest,
  imageUrl,
}: EditPictureType) {

  const [crop, setCrop] = useState({
    unit: '%',
    height: 100,
    aspect: 1,
  })
  const [completedCrop, setCompletedCrop] = useState(null)
  const [pickedImage, setPickedImage] = useState()
  const [showModal, setShowModal] = useState(false)
  const imgRef = useRef(null)
  const hasError = false // some(form.errors, { field })
  const dispatch = useDispatch()
  const addImage = imageResult => {
    setPickedImage(imageResult.uri)
  }

  const onImageLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  const onSave = (dispatch: (obj: { }) => void) => {

    const canvas = document.createElement('canvas')

    const image = imgRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = false

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    dispatch(showProgressDialog())
    api
      .uploadDataToStorage({
        namespace: 'users',
        folder: accountId,
        fileName: 'profile.png',
        data: dataURLToBytes(canvas.toDataURL()),
        mime: 'image/png',
      })
      .then(url => {
        console.log(url)
        dispatch(closeTopDialog())

        dispatch(userUpdateRequest({
          profileImageUrl: url,
          successMessage: 'Your Profile Photo has been updated.',
        }))
      })
      .catch(err => {
        console.error('Could not upload file', err)
        dispatch(closeTopDialog())
      })

    setPickedImage(null)
    setShowModal(false)
  }

  if (showModal)
    return (
      <Fullscreen>
        <ModalFrame
          title={'Profile Photo'}
          onClose={() => setShowModal(false)}
          size={'large'}
        >
          <View style={[s.alignCenter]}>
            <Text style={[s.f18, s.textBold, s.m16, s.mb8]}>Upload and crop your Profile Photo</Text>
            <ReactCrop
              src={pickedImage}
              onImageLoaded={onImageLoad}
              crop={crop}
              onChange={newCrop => setCrop(newCrop)}
              onComplete={newCrop => setCompletedCrop(newCrop)}
              imageStyle={{ maxHeight: 300, objectFit: 'contain' }}
              circularCrop={true}
            />
            {pickedImage ? (
              <View style={[s.mt20, s.flexRow, s.alignCenter]}>
                <Button
                  role="secondary"
                  text="Cancel"
                  onPress={() => setPickedImage(null)}
                />
                <View style={s.w32} />
                <Button text="Add Image" onPress={()=> onSave(dispatch)}/>
              </View>) : (
              <View>
                <Image
                  source={imageUrl ? imageUrl : require('images/image-empty.svg')}
                  style={{
                    width: 200,
                    height: 200,
                    resizeMode: 'contain',
                    marginBottom: 16,
                  }}
                />
                <ImagePicker onPick={addImage}>
                  <Button
                    presentationOnly={true}
                    text={'Upload Image'}
                    role={hasError ? 'error' : 'primary'}
                  />
                </ImagePicker></View>)}
          </View>
        </ModalFrame>
      </Fullscreen>
    )
  else
    return (
      <View style={[s.alignCenter, s.mt16]}>
        <Button role='secondary' rounded={true} text="Edit" onPress={() => setShowModal(true)}/>
      </View>
    )
}
