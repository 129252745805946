import React from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { openURL } from 'lib/utils'

export default function({
  to = '',
  forceCurrentWindow = false,
  children,
  ...props
}) {
  const { Link } =
    Platform.OS === 'ios'
      ? require('react-router-native')
      : require('react-router-dom')
  return to.charAt(0) === '/' ? (
    <View {...props}>
      <Link to={to}>{children}</Link>
    </View>
  ) : (
    <TouchableOpacity
      onPress={() => openURL(to, !forceCurrentWindow, { forceCurrentWindow })}
      {...props}
    >
      {children}
    </TouchableOpacity>
  )
}
