import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import Button from 'components/Form/Button2'
import s from 'styles'
import { modalHide } from 'store/modal'
import { ModalFrame } from 'components/modal'
import { openLink } from 'pages/util'
import { useDispatch } from 'react-redux'


export const NotificationSendModal = (props: { title: string, headline: string, text: string }) => {
  const dispatch = useDispatch()
  return (
    <ModalFrame title={props.title} size="medium">
      <View style={[s.mb32]}>
        <Text
          style={[s.mb8, s.f24, s.textBold, s.textGrayDarker, s.textCenter]}
        >
          {props.headline}
        </Text>
        <View style={[s.mx32]}>
          <Text style={[s.f16, s.textCenter, s.textLineHeight15, s.mb8]}>
            {props.text}
          </Text>
        </View>
        <TouchableOpacity onPress={() => openLink('mailto:support@ozobot.com', dispatch)}>
          <Text style={[s.textGreen, s.textUnderline, s.textCenter]}>support@ozobot.com</Text>
        </TouchableOpacity>
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        <Button
          text={'Ok'}
          onPress={() => {
            dispatch(modalHide())
          }}
        />
      </View>
    </ModalFrame>
  )
}

export const emailNotificationSentModal = (props: { title: string, headline: string, text: string }) => (
  <NotificationSendModal
    title={props.title}
    headline={props.headline}
    text={props.text}
  />
)

