import { createSlice } from '@reduxjs/toolkit'
import { levelFromBattery } from 'store/botz/util'

const initialState = {
  occConnected: false,
  lastListId: 0,
  list: [],
}

const hasNotificationType = (notifications, type) =>
  notifications.some(n => n.type === type)

const addNotification = (state, notification) => {
  // make sure unique, but use "fresh" version of notification
  state.list = state.list.filter(
    n => n.message !== notification.message || n.type !== notification.type
  )
  state.list.push({
    ...notification,
    timestamp: Date.now(),
    id: ++state.lastListId,
  })
}

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    add(state, { payload }) {
      addNotification(state, {
        showUI: true,
        ...payload,
      })
    },
    remove(state, { payload }) {
      state.list = state.list.filter(
        notification => notification.id !== payload
      )
    },
    removeType(state, { payload }) {
      const types = Array.isArray(payload) ? payload : [payload]
      state.list = state.list.filter(
        notification => !types.includes(notification.type)
      )
    },
  },
  extraReducers: {
    BOTZ_OCC_STATE_CHANGED: (state, action) => {
      if (typeof action.stateDelta.connected === 'boolean') {
        state.occConnected = action.stateDelta.connected
      }
    },
    // Listens for disconnect event. Instead of waiting for reconnect tries to end, display notification immediately
    BOTZ_BOT_STATE_CHANGED: (state, action) => {
      if (
        !state.occConnected &&
        action.stateDelta.connecting &&
        !action.stateDelta.connected
      ) {
        addNotification(state, {
          message: 'Evo has disconnected',
          type: 'disconnected',
          showUI: true,
        })
      }
    },
    // Listens for battery update event
    // TODO: this code should be heavily refactored for the 3.0 protocol
    BOTZ_BOT_EVENT_RECEIVED: (state, action) => {
      if (
        !state.occConnected &&
        // No other battery notifications
        !hasNotificationType(state.list, 'battery') &&
        action.data?.valueType === 'REMAINING_BATTERY' &&
        levelFromBattery(action.data.value) === 'low'
      ) {
        addNotification(state, {
          message: 'Evo’s battery is low! Charge now.',
          type: 'battery',
          showUI: true,
        })
      }
    },
    'user/userAuthGoogleLinkFailure': (state, action) => {
      addNotification(state, {
        type: 'auth/link',
        message:
          action?.payload?.status === 409
            ? 'Cannot link a Google account already in use.'
            : 'You cannot not link this Google account at this time.',
        showUI: false,
      })
    },
    'user/userCreateFailure': (state, action) => {
      if (action?.payload?.emailExists) {
        addNotification(state, {
          type: 'auth/join',
          message: 'That email already exists.',
          showUI: false,
        })
      }
    },
  },
})

export const notificationsSelector = ({ notifications }) => notifications.list

export const notificationsActions = notifications.actions

export default notifications
