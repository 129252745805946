import { isEmpty } from 'lodash'
import {
  _BOTZ_CLEAR_ALL_LAST_ERRORS,
  _BOTZ_SET_INLINE_UPDATING,
  BOTZ_MANIFEST_RETRIEVED,
  BOTZ_ASSETS_RETRIEVED,
  BOTZ_BOT_STATE_CHANGED,
  BOTZ_DEVICE_PAGE_ENABLED,
  BOTZ_OCC_ALLOW_BOT_CONNECTION,
  BOTZ_OCC_BOT_SCAN_BEGIN,
  BOTZ_OCC_BOT_SCAN_ENDED,
  BOTZ_OCC_SET_BOT_CONNECTION_FILTER,
  BOTZ_OCC_STATE_CHANGED,
  BOTZ_OCC_UPLOAD_PROGRESS,
  BOTZ_PERIPHERAL_DISCONNECT_ALL_SUCCESS,
  BOTZ_UPDATE_BOT_CANCEL,
  BOTZ_UPDATE_BOT_FAILURE,
  BOTZ_UPDATE_BOT_INLINE_REQUEST,
  BOTZ_UPDATE_BOT_PROGRESS,
  BOTZ_UPDATE_BOT_SUCCESS,
  BOTZ_UPDATE_BOTS_REQUEST,
  BOTZ_UPDATE_OCC_FIRMWARE_FAILURE,
  BOTZ_UPDATE_OCC_FIRMWARE_REQUEST,
  BOTZ_UPDATE_OCC_FIRMWARE_SUCCESS,
} from './types'

const initialBot = {
  uuid: '',
  available: false,
  connecting: false,
  connected: false,
  name: '',
  battery: 0,
  charging: false,
  surfaceColor: '',
  isOCC: true,

  hasLegacyFirmware: false,
  lastUpdateError: '',
  updateIsNeeded: false,

  isSelectedByScan: false,
  isDiscardedByScan: false,

  stats: undefined,

  // this is used to maintain consistent ordering in lists
  order: 9999999999,
}

const initialOCCState = {
  serial: '',
  connecting: false,
  connected: false,
  ready: false,
  updateIsNeeded: false,
  lastUpdateError: '',
  allowBotConnection: false,
  isScanningForBots: false,
}

const initialState = {
  occ: { ...initialOCCState },
  occBotFilter: [],
  byIds: {},
  updateProgressInfo: undefined,
  botsToUpdate: {},
  botUpdateProgress: undefined,
  lastOCCBotUpdateTime: undefined,
  isInlineUpdating: false,
  manifest: undefined,

  assetsInfo: {
    occFirmwareVersion: '',
    evoFirmwareVersion: '',
    evoAssetsCRC: 0,
  },

  // Devices page stuff - should probably be its own store
  devicesPageEnabled: false,
}

const lerp = (a, b, s) => s * a + (1 - s) * b
const clamp01 = n => Math.max(Math.min(n, 100), 0)

const initProgressInfo = ({
  initialItemUpdateTimeGuess,
  updateType,
  itemTimeEstimationBias = 1,
}) => ({
  updateType,
  itemTimeEstimationBias,

  currItemId: undefined,
  currItemStartTime: undefined,
  currItemPercent: 0,
  currItemTimeLeft: 0,
  items: {},

  aveItemTime: initialItemUpdateTimeGuess,
  totalItemsProcessed: 0,
  totalTimeForAllItems: 0,

  timeLeft: 0,
  percent: 0,
})

const updateProgressInfoWithAddedItem = ({ prev, itemId }) => ({
  ...prev,
  items: {
    ...prev.items,
    [itemId]: true,
  },
})

const updateProgressInfoWithItem = ({
  prev,
  percent,
  text,
  itemId,
  timestamp,
  data = undefined,
}) => {
  let init = {}
  if (!prev.currItemStartTime) {
    init = {
      currItemId: itemId,
      currItemStartTime: timestamp,
      items: {
        ...prev.items,
        [itemId]: true,
      },
    }
  }
  let update = {}
  if (percent > 0) {
    const timeElapsed = timestamp - prev.currItemStartTime
    const ratio = clamp01(percent / 100)
    const staticApproxTimeLeft = Math.max(prev.aveItemTime - timeElapsed, 0)
    const dynamicApproxTimeLeft = timeElapsed * (1 / ratio) * (1 - ratio)
    const lerpRatio = Math.max(
      ratio,
      Math.min(timeElapsed / prev.aveItemTime, 1)
    )
    const t = Math.pow(lerpRatio, prev.itemTimeEstimationBias)
    const approxTimeLeft = lerp(dynamicApproxTimeLeft, staticApproxTimeLeft, t)
    const numItemsUpdating = Object.values(prev.items).filter(i => i).length
    const totalItems = Object.keys(prev.items).length
    update = {
      currItemTimeLeft: approxTimeLeft,
      currItemPercent: ratio * 100,
      timeLeft: approxTimeLeft + (numItemsUpdating - 1) * prev.aveItemTime,
      percent: ((totalItems - (numItemsUpdating - ratio)) / totalItems) * 100,
    }
  }
  return {
    ...prev,
    ...init,
    ...update,
    text,
    data,
  }
}

const finishProgressInfoForItem = ({ prev, hasError, itemId, timestamp }) => {
  if (itemId !== prev?.currItemId) {
    return prev
  }

  let tally = {}
  if (!hasError) {
    // track time used, so we can estimate next items better
    const totalTimeForAllItems =
      prev.totalTimeForAllItems + (timestamp - prev.currItemStartTime)
    const totalItemsProcessed = prev.totalItemsProcessed + 1
    tally = {
      totalTimeForAllItems,
      totalItemsProcessed,
      aveItemTime: totalTimeForAllItems / totalItemsProcessed,
      items: {
        ...prev.items,
        [itemId]: false,
      },
    }
  }
  const latestItems = tally.items || prev.items
  const latestAveTime = tally.aveItemTime || prev.aveItemTime
  const numItemsLeft = Object.values(latestItems).filter(i => i).length
  const totalItems = Object.keys(latestItems).length

  // reset curr item info
  return {
    ...prev,
    ...tally,
    currItemId: undefined,
    currItemStartTime: undefined,
    currItemProgress: 0,
    currItemTimeLeft: 0,
    timeLeft: numItemsLeft * latestAveTime,
    percent: clamp01(1 - numItemsLeft / totalItems) * 100,
  }
}

const updateBots = (state, updateForBot) =>
  Object.values(state.byIds).reduce((bots, bot) => {
    bots[bot.uuid] = {
      ...bot,
      ...updateForBot(bot),
    }
    return bots
  }, {})

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case BOTZ_PERIPHERAL_DISCONNECT_ALL_SUCCESS:
      // Clear out OCC state if we manually disconnect
      return {
        ...state,
        byIds: updateBots(state, () => ({ lastUpdateError: '' })),
        occ: { ...initialOCCState },
      }

    case BOTZ_OCC_STATE_CHANGED:
      return {
        ...state,
        occ: {
          ...state.occ,
          ...action.stateDelta,
        },
      }

    case BOTZ_BOT_STATE_CHANGED:
      const curState = state.byIds[action.uuid] || initialBot
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.uuid]: {
            ...curState,
            ...action.stateDelta,
            order: curState.order || Object.keys(state.byIds).length,
          },
        },
      }

    case BOTZ_OCC_BOT_SCAN_BEGIN:
      return {
        ...state,
        occ: {
          ...state.occ,
          isScanningForBots: true,
        },
        byIds: updateBots(state, () => ({
          isSelectedByScan: false,
          isDiscardedByScan: false,
        })),
      }

    case BOTZ_OCC_BOT_SCAN_ENDED:
      const botsPickedByScan = new Set(action.selectedBots)
      const botsSkippedByScan = new Set(action.discardedBots)
      return {
        ...state,
        occ: {
          ...state.occ,
          isScanningForBots: false,
        },
        byIds: updateBots(state, bot => ({
          isSelectedByScan: botsPickedByScan.has(bot.uuid),
          isDiscardedByScan: botsSkippedByScan.has(bot.uuid),
        })),
      }

    case BOTZ_UPDATE_BOT_INLINE_REQUEST:
    case BOTZ_UPDATE_BOTS_REQUEST: {
      const isQueued = action.type === BOTZ_UPDATE_BOTS_REQUEST
      const botList = isQueued ? action.uuids : [action.uuid]
      // NOTE: by request, the single bot update estimate is set to be greater than the
      // multi-bot, even though in practice the multi-bot is slower. This, for the purposes
      // of better user visual experience
      const initialItemUpdateTimeGuess = state.byIds[botList[0]]?.isOCC
        ? 7 * 60000
        : 4 * 60000 + 59 * 1000
      const updateProgress =
        state.updateProgressInfo ||
        initProgressInfo({
          initialItemUpdateTimeGuess,
          updateType: 'bot',
          itemTimeEstimationBias: 4,
        })
      const updateProgressInfo = botList.reduce(
        (prev, itemId) => updateProgressInfoWithAddedItem({ prev, itemId }),
        updateProgress
      )
      // clear out lastUpdateError from any bots used in update
      const cleanedBots = botList.reduce((bots, uuid) => {
        bots[uuid] = {
          ...state.byIds[uuid],
          lastUpdateError: '',
        }
        return bots
      }, {})
      return {
        ...state,
        byIds: {
          ...state.byIds,
          ...cleanedBots,
        },
        botsToUpdate: isQueued
          ? botList.reduce((uuids, uuid) => {
              uuids[uuid] = true
              return uuids
            }, {})
          : {},
        updateProgressInfo,
      }
    }

    case BOTZ_UPDATE_BOT_PROGRESS:
      return state.updateProgressInfo
        ? {
            ...state,
            updateProgressInfo: updateProgressInfoWithItem({
              prev: state.updateProgressInfo,
              percent: action.progress,
              text: action.text,
              itemId: action.uuid,
              timestamp: action.timestamp,
              data: action.data,
            }),
            lastOCCBotUpdateTime:
              action.occUpdateTime || state.lastOCCBotUpdateTime,
          }
        : state

    case BOTZ_UPDATE_BOT_SUCCESS:
    case BOTZ_UPDATE_BOT_FAILURE: {
      const botsToUpdate = { ...state.botsToUpdate }
      delete botsToUpdate[action.uuid]
      const hasBotsToProcess = !isEmpty(botsToUpdate)
      return {
        ...state,
        botsToUpdate,
        updateProgressInfo: hasBotsToProcess
          ? finishProgressInfoForItem({
              prev: state.updateProgressInfo,
              itemId: action.uuid,
              hasError: action.type === BOTZ_UPDATE_BOT_FAILURE,
              timestamp: action.timestamp,
            })
          : undefined,
      }
    }

    case BOTZ_UPDATE_BOT_CANCEL:
      return {
        ...state,
        botsToUpdate: {},
        updateProgressInfo: undefined,
      }

    case BOTZ_UPDATE_OCC_FIRMWARE_REQUEST:
      return {
        ...state,
        occ: {
          ...state.occ,
          lastUpdateError: '',
        },
        updateProgressInfo: initProgressInfo({
          initialItemUpdateTimeGuess: 5 * 60000,
          updateType: 'occ',
          itemTimeEstimationBias: 0.2,
        }),
      }

    case BOTZ_UPDATE_OCC_FIRMWARE_SUCCESS:
      return {
        ...state,
        updateProgressInfo: undefined,
      }

    case BOTZ_UPDATE_OCC_FIRMWARE_FAILURE:
      return {
        ...state,
        occ: {
          ...state.occ,
          lastUpdateError: action.error,
        },
        updateProgressInfo: undefined,
      }

    case BOTZ_OCC_UPLOAD_PROGRESS:
      return {
        ...state,
        updateProgressInfo: updateProgressInfoWithItem({
          prev: state.updateProgressInfo,
          percent: action.progress.progress,
          text: action.progress.text,
          itemId: 'occ',
          timestamp: action.timestamp,
        }),
      }

    case BOTZ_MANIFEST_RETRIEVED: {
      return {
        ...state,
        manifest: action.manifest,
      }
    }
    case BOTZ_ASSETS_RETRIEVED: {
      const assets = action.assets
      const occFirmware = assets.find(
        asset => asset.platform === 'occ' && asset.type === 'firmware'
      )
      const evoFirmware = assets.find(
        asset => asset.platform === 'evo' && asset.type === 'firmware'
      )
      const config = assets.find(
        asset => asset.platform === 'evo' && asset.type === 'config'
      )
      return {
        ...state,
        assetsInfo: {
          occFirmwareVersion: occFirmware?.metaData?.version,
          evoFirmwareVersion: evoFirmware?.metaData?.version,
          evoAssetsCRC: config?.metaData?.fileCRC,
        },
      }
    }

    case _BOTZ_CLEAR_ALL_LAST_ERRORS:
      return {
        ...state,
        occ: {
          ...state.occ,
          lastUpdateError: '',
        },
      }

    case _BOTZ_SET_INLINE_UPDATING:
      return {
        ...state,
        isInlineUpdating: action.updating,
      }

    case BOTZ_OCC_SET_BOT_CONNECTION_FILTER:
      return {
        ...state,
        occBotFilter: action.bots,
      }
    case BOTZ_OCC_ALLOW_BOT_CONNECTION:
      return {
        ...state,
        occ: {
          ...state.occ,
          allowBotConnection: action.allow,
        },
      }
    case BOTZ_DEVICE_PAGE_ENABLED:
      return {
        ...state,
        devicesPageEnabled: action.enabled,
      }
    default:
      return state
  }
}
