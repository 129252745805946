import React from 'react'
import { Picker, Text, TextInput, View } from 'react-native'
import { Button } from 'components/Form'
import RemoveX from './RemoveX'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const BackgroundItem = ({ ability, description, update, onRemove }) => {
  const [input, setInput] = React.useState(description)

  const onChangeText = input => setInput(input)
  const onBlur = () => update({ description: input })

  const { isMobile } = useDetectDeviceType();
  const flexStyles = isMobile ? [s.flexColumn, s.gap15] : [s.flexRow];

  return (
    <View
      style={[s.p20, s.mb20, s.justifyBetween, s.bgWhite, s.bShadow, ...flexStyles]}
    >
      <View style={[...flexStyles]}>
        <View style={[s.px16, s.alignCenter]}>
          <Text style={[s.mb4, s.textBlack, s.f14]}>
            Students should have...
          </Text>
          <Picker
            style={[s.h40, s.px16, s.b1, s.bBlackLighter, s.br5]}
            selectedValue={ability}
            onValueChange={ability => update({ ability })}
          >
            {['Completed Lesson', 'Ability to', 'Knowledge of'].map(item => (
              <Picker.Item key={item} label={item} value={item} />
            ))}
          </Picker>
        </View>
        <View style={[s.px16, s.alignCenter]}>
          <Text style={[s.mb4, s.textBlackLighter, s.f14]}>ITEM NAME</Text>
          <TextInput
            placeholderTextColor="#252932"
            style={[
              s.w300,
              s.h40,
              s.bBottom1,
              s.bBlackLighter,
              s.f16,
              s.textCenter,
              s.textBold,
              s.outlineStyleNone,
            ]}
            onChangeText={onChangeText}
            value={input}
            onBlur={onBlur}
            placeholder="Enter text or a lesson link"
            // maxLength={80} // appropriate char limit for URLs TBD
          />
        </View>
      </View>
      <RemoveX onPress={onRemove} />
    </View>
  )
}

const SubmissionBackgroundGroup = ({
  field,
  form,
  formAddItem,
  formUpdateItem,
  formRemoveItem,
}) => {
  return (
    <View>
      {form[field].map((item, i) => (
        <BackgroundItem
          key={`${field}:${i}:${item.ability}:${item.description}`}
          {...item}
          update={obj => formUpdateItem(i, obj)}
          onRemove={() => formRemoveItem(i)}
        />
      ))}
      <View style={s.alignEnd}>
        <Button rounded={true} icon="plus" text="Add" onPress={formAddItem} />
      </View>
    </View>
  )
}

export default withSubmissionForm(SubmissionBackgroundGroup)
