import * as React from 'react'
import { View } from 'react-native'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

/**
 * Ozobot Classroom logo used in logged out pages
 * @returns Ozobot Classroom logo
 */
export default function OzobotClassroomLogo () {
  const { isDesktop } =  useDetectDeviceType();

  return (
    <View style={[s.py32]}>
      <Image
        source={require('images/ozobot-classroom-logo-login.png')}
        style={{ height: isDesktop ? 150 : 100, width: '100%' }}
        resizeMode="contain" />
    </View>
  )
}
