import React from 'react'
import { Text, View, Picker } from 'react-native'
import s from 'styles'

export default function Sort({ label, items, selectedValue, sort }) {
  return (
    <View style={[s.flexRow, s.alignCenter]}>
      {label && <Text style={[s.mr20, s.f14, s.textBold]}>{label}</Text>}
      <Picker
        selectedValue={selectedValue}
        style={[s.br5, s.bGrayLighter, s.h40, s.px16, { width: 140 }]}
        onValueChange={sort}
      >
        {items.map(item => (
          <Picker.Item key={item} label={item} value={item} />
        ))}
      </Picker>
    </View>
  )
}
