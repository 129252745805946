import React, { Component } from 'react'

export default class WebView extends Component {
  render() {
    const { nativeID, source, ...props } = this.props

    return React.createElement(
      'iframe',
      Object.assign({}, props, nativeID && { id: nativeID }, {
        'data-hj-allow-iframe': true,
        ref: 'iframe',
        src: source.uri,
      })
    )
  }
}
