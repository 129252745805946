import React from 'react'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useDispatch } from 'react-redux'
import { modalShow } from 'store/modal'
import { CheckLink } from 'components/CheckLink'
import { openURL } from 'lib/utils.web'
import { ViewProps } from 'react-native'
import { useHistory } from 'react-router'

export const openLink = (
  link: string,
  dispatch: React.Dispatch<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  noSiteCheck?: Boolean,
  onCheckLinkFinished?: () => void,
) => {
  if(link[0] === '/') {
    openURL(link, false, {forceCurrentWindow: true})
  } else if (noSiteCheck) {
    openURL(link, true)
  } else {
    dispatch(modalShow(<CheckLink link={link} onCheckLinkFinished={onCheckLinkFinished} />))
  }
}

export interface TouchableLinkProps extends ViewProps {
  to: string,
}

export const TouchableLink = ({to, ...props} : TouchableLinkProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  return <TouchableOpacity
    onPress={() => {
      if(to[0] === '/') {
        history.push(to)
      } else {
        dispatch(modalShow(<CheckLink link={to}/>))
      }
    }}
    style={props.style}
  >
    {props.children}
  </TouchableOpacity>
}
