export const EvoColorCodes = {
  SNAIL_DOSE: 'rgb',
  SLOW: 'rkr',
  CRUISE: 'gkg',
  FAST: 'bkb',
  TURBO: 'bgb',
  NITRO_BOOST: 'bgr',
  GO_LEFT: 'gkr',
  GO_STRAIT: 'bkr',
  GO_RIGHT: 'brg',
  LINE_JUMP_LEFT: 'grg',
  LINE_JUMP_STRAIT: 'gbg',
  LINE_JUMP_RIGHT: 'rgr',
  U_TURN: 'brb',
  U_TURN_LINE_END: 'br',
  TIMER_ON: 'rkbg',
  TIMER_OFF: 'gbkr',
  PAUSE: 'rbr',
  WHIRLWIND: 'rgrg',
  ZIGZAG: 'bkgr',
  SPIN: 'grgr',
  BACK_WALK: 'rgkb',
  PLAY_AGAIN: 'gr',
  GAME_OVER: 'gb',
  XING_COUNTER: 'grbg',
  TURN_COUNTER: 'rbgb',
  PATH_COLOR_COUNTER: 'rgbr',
  POINT_COUNTER: 'rbrg',
}

export const EvoColorCodeNames = {
  rgb: 'Snail Dose',
  rkr: 'Slow',
  gkg: 'Cruise',
  bkb: 'Fast',
  bgb: 'Turbo',
  bgr: 'Nitro Boost',
  gkr: 'Go Left',
  bkr: 'Go Straight',
  brg: 'Go Right',
  grg: 'Line Jump Left',
  gbg: 'Line Jump Straight',
  rgr: 'Line Jump Right',
  brb: 'U Turn',
  br: 'U Turn (Line End)',
  rkbg: 'Timer On',
  gbkr: 'Timer Off',
  rbr: 'Pause',
  rgrg: 'Whirlwind',
  bkgr: 'Zigzag',
  grgr: 'Spin',
  rgkb: 'Back-walk',
  gr: 'Play Again',
  gb: 'Game Over',
  grbg: 'X-ing Counter',
  rbgb: 'Turn Counter',
  rgbr: 'Path Color Counter',
  rbrg: 'Point Counter',
}

// Indexed by error code
export const EvoFALTASErrorDescriptions = [
  'No Error',
  'Generic Error (not in list)',
  'CRC check failed',
  'Unknown failure creating file',
  'FS in unknown state error',
  'Hardware too busy to handle Command',
  'File already exists and was not “in process”',
  'Not enough room on FS for file',
  'Invalid information in packet',
  'Block number is out of sequence',
  'Failed to get all packets (timed out or missing packets)',
  'File/Directory does not exist.',
  'End of any list (not an error)',
  'File descriptor does not exist',
  'Partial file',
  'Already uploaded',
  'File not found',
  'Permission denied',
]
