import React from 'react'
import { View, Image as ReactNativeImage, Animated } from 'react-native'

export function Image({ source, style }) {
  if (!source) {
    return null
  }
  const ReactComponent = source.default
  if (!ReactComponent) {
    return (
      <ReactNativeImage
        source={
          typeof source === 'string' && source.length > 0
            ? { uri: source }
            : source
        }
        resizeMode="contain"
        style={style}
      />
    )
  }

  const { width, height } = style

  return (
    <View style={style}>
      <ReactComponent width={width} height={height} />
    </View>
  )
}

export function AnimatedImage({ source, style }) {
  const ReactComponent = source.default
  if (!ReactComponent) return null

  const { width, height } = style

  return (
    <Animated.View style={style}>
      <ReactComponent width={width} height={height} />
    </Animated.View>
  )
}
