import * as React from 'react'
import { Text, ScrollView, View, TouchableOpacity, KeyboardTypeOptions, ReturnKeyTypeOptions } from 'react-native'
import { Image } from '../ReactNative'
import s from 'styles';

export interface LabelProps {
  label?: string,
  isError?: boolean,
  isFocused?: boolean,
}

export interface Props extends LabelProps {
  title: string,
  height?: number,
  options: string[],
  value: string,
  setValue: (option: string) => void,
  isError?: boolean,
  placeholder?: string,
  ref?: React.RefObject<HTMLInputElement>,
  name?: string,
  keyboardType?: KeyboardTypeOptions,
  returnKeyType?: ReturnKeyTypeOptions,
  enablesReturnKeyAutomatically?: boolean,
  disabled?: boolean,
  onPressEnter?: () => void,
}

export const Label: React.FunctionComponent<LabelProps> = ({ label, isFocused, isError }: LabelProps): JSX.Element => {
  return <View style={[s.bgWhite, s.px4, s.ml8, { position: 'absolute', top: -8, width: 'fit-content', zIndex: 1}]}>
    <Text style={[s.f12, s.textBold, isError ? s.textRed : isFocused ? s.textTeal : s.textGray]}>
      {label}
    </Text>
  </View>
}

export const Dropdown: React.FC<Props> = (props: Props): JSX.Element => {
  const { label, isError } = props
  const [isSelected, setIsSelected] = React.useState(false)
  const onToggle = () => {
    setIsSelected(!isSelected)
  }
  return (
    <View style={[s.mb16, s.zIndex4, s.w100p, s.bgWhite, s.positionAbsolute]}>
      {!!props.value.length && <Label label={label} isFocused={isSelected} isError={isError} />}
      {props.disabled ? <Text style={[s.f14, s.textGray, s.textItalic, s.p8, s.b1, s.bGrayLighter, s.br5]}>
        {props.value}
      </Text> :
        <View style={[s.flexRow, s.alignCenter, s.b1, s.br5, s.p8, isError ? s.bRed : isSelected ? s.bTeal : s.bGray]}>
          <View style={[s.positionRelative, s.zIndex3, s.w100p]}>
            <View style={[s.zIndex4]}>
              <TouchableOpacity
                onPress={onToggle}
                style={[
                  s.flexRow,
                  s.justifyBetween,
                  s.alignCenter,
                  s.bgWhite,
                  s.w100p,
                  {
                    height: props.height,
                  },
                ]}
              >
                <Text
                  style={[
                    s.f14,
                    s.mr8,
                    s.textGrayDarkest,
                    isSelected && s.textBold,
                    !props.value && [s.textItalic, s.textGray],
                    s.flex1,
                  ]}
                >
                  {props.value.substring(0,36) || props.label}
                </Text>
                <Image
                  source={require('images/icon-black-arrow-down.svg')}
                  style={{
                    width: 17,
                    height: 9,
                    resizeMode: 'contain',
                  }}
                />
              </TouchableOpacity>
              {isSelected && (
                <ScrollView
                  style={[
                    s.left0,
                    s.right0,
                    s.bgWhite,
                    s.zIndex4,
                    s.py4,
                    {
                      maxHeight: 140,
                      maxWidth: '100%',
                      top: 5,
                    },
                  ]}
                >
                  {props.options.map((option, i) => (
                    <TouchableOpacity
                      key={i}
                      onPress={() => {
                        props.setValue(option)
                        setIsSelected(false)
                      }}
                    >
                      <View style={[s.py4, s.w100p, option === props.value && s.bgWhiteDarkest]}>
                        <Text numberOfLines={2} style={[s.f14, s.textGrayDarkest, option === props.value && s.textBold]}>
                          {option}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              )}
            </View>
          </View>
        </View>
      }
    </View>
  )
}

export default Dropdown
