import React, { Component } from 'react'
import { View, Text } from 'react-native'
import { ListItem } from 'components/Layout'
import gs from 'styles'

export default class UnorderedList extends Component {
  render() {
    return (
      <View style={this.props.style}>
        {React.Children.map(this.props.children, child =>
          child.type === ListItem ? this.renderListItem(child) : child
        )}
      </View>
    )
  }

  renderListItem(listItem) {
    return (
      <View style={gs.flexRow}>
        <Text>• </Text>
        <View style={gs.flexShrink1}>{listItem}</View>
      </View>
    )
  }
}
