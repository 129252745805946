import * as React from 'react'
import { Text, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { LocationState } from 'history'
import { pick, isEmpty } from 'lodash'
import validate from 'validate.js'
import validations from 'lib/validations'
import {
  userAuthEmailSignInRequest,
  userAuthGoogleSignInRequest,
} from 'store/user'
import Button from 'components/Form/Button2'
import ErrorMessages from 'components/Form/ErrorMessages'
import Input from 'components/Form/Input2'
import OrSeparator from 'components/Form/OrSeparator'
import ServiceButton from 'components/Provider/ServiceButton'
import { Link } from 'components/Router'
import Footer from './components/Footer'
import OzobotBackground from './components/OzobotBackground'
import s from 'styles'
import { isAppLoadedInIframe } from 'lib/utils.js'

interface RedirectState {
  state?: { redirect?: string }
}
interface LocationProps {
  location: LocationState & RedirectState
}
const Login = ({ location }: LocationProps) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState([])

  const dispatch = useDispatch()

  const redirectPath = location.state?.redirect

  const googleSignIn = () => {
    const postLoginRedirect = isAppLoadedInIframe() ? '/lmsnotice' : redirectPath || '/dashboard'
    dispatch(userAuthGoogleSignInRequest({ postLoginRedirect }))
  }

  const submit = () => {
    const postLoginRedirect = redirectPath

    // clear errors before revalidating
    setErrors([])
    const errors = validate(
      { email, password },
      pick(validations, ['email', 'password']),
      { fullMessages: false },
    )

    if (!isEmpty(errors)) {
      setErrors(errors)
    } else {
      dispatch(userAuthEmailSignInRequest({
        postLoginRedirect,
        email,
        password,
      }))
    }
  }

  return (
    <OzobotBackground
      // footer props here are for what is below the white container component
      hasStudentLink
      footerText={'Compatible with most computers (including Chromebooks) with the Chrome or Edge browser.'}
      footerLinkText={'Learn more'}
      footerLink='https://static.ozobot.com/assets/7743bc40-2023-ozobot-evo-compatibility-chart.pdf'
    >
      {errors && <ErrorMessages errorMessages={Object.values(errors)} />}
      <Text style={[s.textCenter, s.f24, s.textBlack, s.textBold, s.mb16]}>
        Educator and Parent Login
      </Text>
      <ServiceButton
        icon="google"
        onPress={googleSignIn}
      />
      <OrSeparator />
      <View style={[s.mb8, { width: 300 }]}>
        <Input
          label="Email"
          name="email"
          isError={Object.keys(errors).includes('email')}
          value={email}
          setValue={setEmail}
          keyboardType="email-address"
          autoCompleteType="email"
          autoCapitalize="none"
          returnKeyType="next"
          enablesReturnKeyAutomatically
        />
        <Input
          value={password}
          setValue={setPassword}
          isError={Object.keys(errors).includes('password')}
          label="Password"
          secureTextEntry={true}
          returnKeyType="go"
          enablesReturnKeyAutomatically
          onPressEnter={submit}
        />
        <View style={[s.bBottom1, s.bGreen, s.mb8, { width: 'fit-content', alignSelf: 'center' }]}>
          <Link to='/account/forgot' >
            <Text style={[s.f14, s.textBlue, s.textBold]}>Forgot password?</Text>
          </Link>
        </View>
      </View>
      <Button text='Sign In' onPress={submit} />
      <View style={s.mb24} />
      <View style={[s.w100p, s.b1, s.bWhiteDarkest, s.mb24]} />
      <Footer text='New to Ozobot Classroom?' linkText='Sign up for free.' link='/account/join'/>
    </OzobotBackground>
  )
}

export default Login
