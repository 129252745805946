import React from 'react';
import Modal from 'react-modal';
import { Link } from 'components/Router'
import { modalHide } from 'store/modal'
import { useDispatch, useSelector } from 'react-redux';
import { userAuthSignoutRequest, userSelector } from 'store/user';
import { openURL } from 'lib/utils';

const popup = {
  container: {
    position: "relative",
    maxWidth: "40%",
    width: "100%",
    margin: "0 auto",
    marginTop: "40px",
    background: "#fff",
    borderRadius: "30px",
    zIndex: "10",
    borderTop: "1px solid #ccc",
  },
  content: {
    position: "relative",
    padding: "20px",
    fontSize: "20px",
    textAlign: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ccc", 
    paddingBottom: "10px", 
  },
  close: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  title: {
    fontSize: '20px',
    fontWeight: '800',
    margin: "0 auto",
  },
  closeOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "0"
  }
};

export const TimeoutWarningModal = ({ isOpen, link, onRequestClose }) => {

  const dispatch = useDispatch();
  const onLogout = (url, link) => {
    dispatch(userAuthSignoutRequest());
  };
  const closeModal = () => {
    dispatch(modalHide());
    onRequestClose();
  };

  return (
    <div>
      <div style={popup.container}>
        <div style={popup.content}>
          <div style={popup.header}>
            <div style={popup.close} onClick={closeModal}>
              X
            </div>
            <h2 style={popup.title}>
              Leave this site
            </h2>
          </div>
          <br />
          <h2>
            You are about to leave this site
          </h2>
          <br />
          <div>
            <p style={{ fontSize: '16px' }}>Would you like to leave this site? Please choose an option to continue.</p>
          </div>
          <br />
          <a href={link.link} target="_blank">
            <button style={{ background: '#efefef', fontWeight: '800', cursor: 'pointer', width: '160px', height: '36px', borderRadius: '20px', border: 'none', marginRight: '10px' }} onClick={onLogout}>Continue + Logout</button>
          </a>
          <a href={link.link} target="_blank">
            <button style={{ background: 'rgb(22, 216, 178)', color: 'black', fontWeight: '800', cursor: 'pointer', width: '100px', height: '36px', borderRadius: '20px', border: 'none' }} onClick={closeModal}>Continue</button>
          </a>
        </div>
      </div>
      <div style={popup.closeOverlay} onClick={closeModal}></div>
    </div>
  );
}
