import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  throttle,
} from 'redux-saga/effects'
import api from 'lib/api'
import { accountIdSelector } from '../user'
import { lessonsActions, lessonsSearchKeySelector } from '.'
import { lessonRequestBody } from './util'


function* onLessonsSearchRequest({ payload }) {
  try {
    const key = yield select(lessonsSearchKeySelector)
    const { meta, data } = yield call(api.searchLessons, { key })
    yield put(
      lessonsActions.searchSuccess({
        meta,
        data,
      })
    )
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsListRequest({ payload }) {
  try {
    const key = yield select(lessonsSearchKeySelector)
    const { meta, data } = yield call(api.getLessons, { key })
    yield put(
      lessonsActions.listSuccess({
        meta,
        data,
      })
    )
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsViewRequest({ payload }) {
  try {
    const viewResponse = yield call(api.getLesson, { id: payload.id })
    yield put(lessonsActions.viewSuccess(viewResponse))
  } catch (err) {
    if(err.message?.toLowerCase().includes('blocked')) {
      yield put(lessonsActions.viewBlocked({ id: payload.id }))
    }
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsCreateRequest({ payload }) {
  const lesson = lessonRequestBody(payload)
  try {
    const authorId = yield select(accountIdSelector)
    const createResponse = yield call(api.createLesson, {
      payload: {
        ...lesson,
        authorId,
      },
    })
    yield put(lessonsActions.createSuccess(createResponse))
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsUpdateRequest({ payload }) {
  const lesson = lessonRequestBody(payload)
  try {
    const authorId = yield select(accountIdSelector)
    const updateResponse = yield call(api.updateLesson, {
      payload: {
        ...lesson,
        authorId,
      },
    })
    yield put(lessonsActions.updateSuccess(updateResponse))
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsDeleteRequest({ payload }) {
  try {
    yield call(api.deleteLesson, { id: payload.id })
    yield put(lessonsActions.deleteSuccess(payload))
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsCollectionListRequest() {
  try {
    const listCollectionResponse = yield call(api.listLessonSeries)

    yield put(lessonsActions.listCollectionSuccess(listCollectionResponse))
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsOwnedListRequest() {
  try {
    const accountId = yield select(s => s.user.accountId)
    const { data } = yield call(api.getOwnedLessons, { accountId })

    yield put(lessonsActions.listOwnedSuccess(data))
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsSavedListRequest() {
  try {
    const accountId = yield select(s => s.user.accountId)
    const listSavedResponse = yield call(api.getSavedLessons, { accountId })
    yield put(lessonsActions.listSavedSuccess(listSavedResponse))
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onLessonsSavedUpdateRequest({ payload }) {
  try {
    // grab latest in queue, and reduce down to the save patch. We need to do all this in the
    // saga, to avoid race conditions in the store.
    const queue = yield select(state => state.lessons.saveQueue)
    const mods = queue.reduce((mods, e) => {
      mods[e.id] = e.add
      return mods
    }, {})
    const savePatch = Object.keys(mods).reduce(
      (savePatch, id) => {
        savePatch[mods[id] ? 'add' : 'remove'].push(id)
        return savePatch
      },
      { add: [], remove: [] }
    )
    const accountId = yield select(state => state.user.accountId)
    const updateSavedResponse = yield call(api.updateSavedLessons, {
      accountId,
      savePatch,
    })
    // include queueSize, so that store can mark the queue for the next save update
    yield put(
      lessonsActions.updateSavedSuccess({
        ...updateSavedResponse,
        queueSize: queue.length,
      })
    )
  } catch (err) {
    yield put(lessonsActions.error(err))
  }
}

function* onRateLessonRequest({ payload }) {
  try {
    const res = yield call(api.rateLesson, payload)
    yield put(lessonsActions.rateLessonSuccess(res))
  } catch (e) {
    console.warn('Could not post rating', e)
  }
}

export default function*() {
  yield all([
    fork(function*() {
      yield takeEvery(lessonsActions.searchRequest, onLessonsSearchRequest)
    }),
    fork(function*() {
      yield takeEvery(lessonsActions.listRequest, onLessonsListRequest)
    }),
    fork(function*() {
      yield takeEvery(lessonsActions.viewRequest, onLessonsViewRequest)
    }),
    fork(function*() {
      yield takeEvery(lessonsActions.createRequest, onLessonsCreateRequest)
    }),
    fork(function*() {
      yield takeEvery(lessonsActions.updateRequest, onLessonsUpdateRequest)
    }),
    fork(function*() {
      yield takeEvery(lessonsActions.deleteRequest, onLessonsDeleteRequest)
    }),
    fork(function*() {
      yield takeEvery(
        lessonsActions.listCollectionRequest,
        onLessonsCollectionListRequest
      )
    }),
    fork(function*() {
      yield takeEvery(
        lessonsActions.listOwnedRequest,
        onLessonsOwnedListRequest
      )
    }),
    fork(function*() {
      yield takeEvery(
        lessonsActions.listSavedRequest,
        onLessonsSavedListRequest
      )
    }),
    fork(function*() {
      yield throttle(
        1000,
        lessonsActions.updateSavedRequest,
        onLessonsSavedUpdateRequest
      )
    }),
    fork(function*() {
      yield throttle(
        1000,
        lessonsActions.rateLessonRequest,
        onRateLessonRequest
      )
    }),
  ])
}
