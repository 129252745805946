import React from 'react'
import { useDispatch } from 'react-redux'
import { View, Text } from 'react-native'
import { Link } from 'components/Router'
import Button from 'components/Form/Button2'
import { ModalFrame } from 'components/modal'
import { modalHide } from 'store/modal'
import s from 'styles'

const SignInErrorModal = () => {
  const dispatch = useDispatch()
  return (
    <ModalFrame title="Error" size="medium">
      <View style={[s.mb20]}>
        <Text style={[s.mb8, s.f28, s.textBold, s.textRed, s.textCenter]}>
          Not Found
        </Text>
        <Text style={[s.f16, s.textCenter]}>
          {'Oops! We can\'t find a user with that email and password.'}
        </Text>
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        <View style={s.m8}>
          <Button text={'Try Again'} onPress={() => dispatch(modalHide())} />
        </View>
        <Link to={'/account/forgot'}>
          <View style={[s.m8, s.py8, s.px20, s.alignCenter, s.bgTeal, s.br5]}>
            <Text style={[s.f16, s.textWhite, s.textBold]}>
              Forgot Password
            </Text>
          </View>
        </Link>
      </View>
      <View style={[s.mt20]}>
        <Text style={[s.f16, s.textCenter]}>
          {'New to Ozobot Classroom? '}
          <Link to={'/account/join'}>
            <Text style={[s.textUnderline, s.textTeal]}>
              Create an account.
            </Text>
          </Link>
        </Text>
      </View>
    </ModalFrame>
  )
}

export default SignInErrorModal

export const makeSignInErrorModal = () => {
  return (<SignInErrorModal/>)
}
