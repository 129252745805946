import * as React from 'react'
import { ScrollView, View } from 'react-native'
import OzobotClassroomLogo from './OzobotClassroomLogo'
import StudentLink from './StudentLink'
import Footer from './Footer'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

export interface AuthBackgroundComponentProps {
  footerText?: string,
  footerLink?: string,
  footerLinkText?: string,
  learnMoreLink?: string,
  children: JSX.Element | JSX.Element[],
  hasStudentLink?: boolean,
}

export const OzobotBackground: React.FC<AuthBackgroundComponentProps> = ({
  children,
  footerLink,
  footerLinkText,
  footerText,
  learnMoreLink,
  hasStudentLink,
}: AuthBackgroundComponentProps) => {
  // get the toggle nav option from the url
  // navigate fn for the ToggleNav

  const { isDesktop } = useDetectDeviceType();

  return (
    <ScrollView style={[s.w100p, s.h100p]} contentContainerStyle={[s.mAuto]}>
      <OzobotClassroomLogo />
      {hasStudentLink && <StudentLink />}
      <View style={[s.bgWhite, s.br5, s.p46, s.mb16, s.alignCenter, s.bgGrayMoreLighter, s.bShadow, s.p20, s.mt20, s.mAuto, isDesktop ? s.w400 : s.w90p]}>
        {children}
      </View>
      <Footer text={footerText} link={footerLink} linkText={footerLinkText} learnMoreLink={learnMoreLink} white={true} />
    </ScrollView>
  )
}

export default OzobotBackground
