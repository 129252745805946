import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  notificationsActions,
  notificationsSelector,
} from 'store/notifications'
import { Image } from 'components/ReactNative'
import s from 'styles'

const DISPLAY_TIME = 5000

export function Notification({
  id,
  message,
  removeById,
  displayTime = DISPLAY_TIME,
}) {
  const [isHover, setIsHover] = React.useState(false)
  const onMouseEnter = () => setIsHover(true)
  const onMouseLeave = () => setIsHover(false)
  // Memoizes a function
  const hide = React.useCallback(() => removeById(id), [removeById, id])

  // This will hide the notification after x seconds if not hovered on
  React.useEffect(() => {
    const timer = !isHover && setTimeout(hide, displayTime)
    return () => clearTimeout(timer)
  }, [isHover, hide, displayTime])

  return (
    <View style={[s.alignEnd]}>
      <View
        style={[
          s.flexRow,
          s.alignCenter,
          s.mb8,
          s.px8,
          s.py8,
          s.bShadow,
          s.br5,
          s.bgWhite,
        ]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Image
          source={require('images/icon-alert.svg')}
          style={[s.h20, s.w20, s.mr8]}
        />
        <Text style={[s.f16, s.textBold]}>{message}</Text>
        <View style={[s.ml4, s.px4, s.bLeft1, s.bGray, s.just]}>
          <TouchableOpacity onPress={hide}>
            <Image
              source={require('images/icon-x.svg')}
              style={[{ height: 17, width: 17 }]}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

export default function Toasts() {
  const notifications = useSelector(notificationsSelector)
  const dispatch = useDispatch()

  function removeById(id) {
    dispatch(notificationsActions.remove(id))
  }

  return (
    <View style={[s.positionAbsolute, { right: 15, top: 65 }]}>
      {notifications
        ?.filter(n => n.showUI)
        .map(notification => (
          <Notification
            key={notification.id}
            removeById={removeById}
            {...notification}
          />
        ))}
    </View>
  )
}
