import * as React from 'react'
import { CheckBox } from 'components/ReactNative'
import { Table, TableRow, Tbody, Tdata, Thead } from 'components/Table'
import { TouchableOpacity, Text, ViewStyle, View } from 'react-native'
import s from 'styles'

import { ContextMenu, ContextMenuContent, Action } from 'components/ContextMenu'
import { TripleDotTeal, TripleDot, SortArrowTealDown, SortArrowTealUp, SortArrowGreyUp, IconRightArrowSmall } from './images'
import { createArray1toN, initArrayWith } from './utils'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'


interface SelectCellProps {
  checked: boolean
  onChange: (value: boolean) => void
  style?: ViewStyle
  disabled?: boolean
}

/**
 * Cell with checkbox.
 */
function SelectCell({checked, style, onChange, disabled}: SelectCellProps) {
  return (
    <Tdata style={style}>
      <View style={[s.flexRow]}>
        {!disabled && <CheckBox value={checked} onValueChange={onChange} style={[]}/>}
      </View>
    </Tdata>
  )
}

interface ActionCellProps {
  actions: RowAction[]
  rowId: unknown
  style?: ViewStyle
  disabled?: boolean
}

/**
 * Table cell with row actions.
 */
function ActionCell({actions, style, rowId, disabled}: ActionCellProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Tdata style={style}>
      <View>
        {!disabled && <ContextMenu
          visible={isOpen}
          onClickOutside={() => setIsOpen(false)}
          render={() => (
            <View style={[s.br5, s.bWhiteDarkest, s.b1, s.bgWhite]}>
              <ContextMenuContent
                actions={actions}
                payload={rowId}
                textStyle={[s.textBlackLighter]}
              />
            </View>
          )}
        >
          <TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
            {isOpen ? <TripleDotTeal/> : <TripleDot/>}
          </TouchableOpacity>
        </ContextMenu>}
      </View>
    </Tdata>
  )
}

type SortDirection = -1 | 0 | 1

interface SortButtonProps {
  sortDirection: SortDirection
  onSort: () => void
  style?: ViewStyle | ViewStyle[]
}

/**
 * Sort column button.
 */
function SortButton({sortDirection, onSort, style}: SortButtonProps) {
  return (
    <TouchableOpacity onPress={onSort} style={style}>
      {
        sortDirection === -1
          ? <SortArrowTealDown />
          : sortDirection === 1
            ? <SortArrowTealUp />
            : <SortArrowGreyUp />
      }
    </TouchableOpacity>
  )
}

interface SelectAllProps {
  checked: boolean
  onChange: (value: boolean) => unknown
  width: number | string
  bulkActions: BulkAction[]
  selected: unknown[]
}

function SelectAllCell({checked, onChange, width, bulkActions, selected}: SelectAllProps) {
  const [open, setOpen] = React.useState<boolean>(false)
  const { isMobile } = useDetectDeviceType();

  return (
    <View>
      <View style={[isMobile ? s.flexColumn : s.flexRow]}>
        <SelectCell
          key={-1}
          checked={checked}
          onChange={onChange}
          style={{width, zIndex: 10000}}
        />

        <ContextMenu
          visible={open}
          onClickOutside={() => setOpen(false)}
          render={() => <View
            style={[
              s.positionAbsolute,
              {
                top: -40,
                left: isMobile ? -10 : -40,
              },
            ]}>

            <ContextMenuContent
              actions={bulkActions}
              payload={selected}
              wrapStyle={[s.px8, s.pb4, s.pt32]}
              textStyle={[s.textBlackLighter]}
            />
          </View>}
        >
          <TouchableOpacity onPress={() => setOpen(!open)} style={[
            {
              zIndex: 10000,
            },
            isMobile ? [s.mt8]: [s.ml20, s.mt4],
          ]}>
            <IconRightArrowSmall />
          </TouchableOpacity>
        </ContextMenu>
      </View>
    </View>
  )
}

function invertSortDirection(sortDirection: SortDirection): SortDirection {
  if (sortDirection === 0) {
    return 1
  } else {
    return sortDirection * -1 as SortDirection
  }
}

interface RowAction extends Action {
  callback: (id: unknown) => void
}

interface BulkAction extends Action {
  callback: (ids: unknown[]) => void
}

type CmpFn = (a: unknown, b: unknown) => number

function defualtCmpFn(a: unknown, b: unknown) {
  return String(a).localeCompare(String(b))
}

function sortData<T>(data: T[], columnIndex: number, sortDirection: SortDirection, key: IdFn<T>, cmp: CmpFn) {
  const sortedIndicies = createArray1toN(data.length)
  const values = data.map((d, i) => key(d[columnIndex], i))

  sortedIndicies.sort((a, b) => {
    if (sortDirection === -1) {
      [a, b] = [b, a]
    }

    if (!cmp) {
      cmp = defualtCmpFn
    }

    const dataA = values[a]
    const dataB = values[b]

    return cmp(dataA, dataB)
  })

  return sortedIndicies
}

type IdFn<T> = (dataRow: T, index: number) => unknown

function defaultIdFn<T>(_: T, index: number) {
  return index
}

type SortKeyFn = (cell: unknown, index: number) => unknown

function defaultSortKeyFn(cell: unknown) {
  return cell
}

type IsDisabledFn = (id: unknown) => boolean

/**
 * Map checked rows to selected data ids.
 *
 * @param checkedRows checked rows in current sort selection
 * @param data data
 * @param idFn id fn
 * @param isDisabledFn disabled data rows function
 * @returns list of selected ids
 */
function pickSelectedRows<T>(checkedRows: boolean[], data: T[], idFn: IdFn<T>, isDisabledFn: IsDisabledFn) {
  const selected = []

  checkedRows.forEach((checked, i) => {
    const id = idFn(data[i], i)
    if (checked && !isDisabledFn(id)) {
      selected.push(id)
    }
  })

  return selected
}

interface ColumnLongDef {
  title: string | React.ReactNode
  sortKeyFn?: SortKeyFn
  cmpFn?: CmpFn
}

function renderColumn<T>(column: ColumnLongDef | React.ReactNode, isSorted = false) {
  if (typeof column === 'string' || typeof column === 'number' || typeof column === 'boolean') {
    return (
      <Text
        style={[ isSorted ? s.textBlack : s.textBlackLighter, s.textBold, s.f14]}
      >
        {column}
      </Text>
    )
  } else if (typeof column === 'object' && 'title' in column) {
    return renderColumn<T>(column.title, isSorted)
  }

  return column
}

function getSortKeyFn<T>(column: ColumnLongDef | React.ReactNode): IdFn<T> {
  if (typeof column === 'object' && 'sortKeyFn' in column) {
    return column.sortKeyFn
  } else {
    return defaultSortKeyFn
  }
}

function getCmpFn(column: ColumnLongDef | React.ReactNode): CmpFn {
  if (typeof column === 'object' && 'cmpFn' in column) {
    return column.cmpFn
  } else {
    return defualtCmpFn
  }
}

interface DataTableProps<T extends Array<unknown>> {
  columns: (ColumnLongDef | React.ReactNode)[]
  data: T[]
  width: (number|string)[]
  rowActions?: RowAction[]
  bulkActions?: BulkAction[]
  actionTitle?: React.ReactNode
  isDisabledFn?: (id: unknown) => boolean
  idFn?: IdFn<T>
}

/**
 * A Simple Data Table.
 *
 * For `data` in array of M rows with N columns it will generate table
 * with optional select and action columns.
 *
 * +---------------+-------------------+..+-------------+-----------+
 * | SelectAllCell | Column1 Title [.] |..| ColumnN [.] | [Actions] |
 * +===============+===================+..+=============+===========+
 * | [ ]           | data[0][0]        +..+ data[0][N]  | [...]     |
 * +---------------+-------------------+..+-------------+-----------+
 * | [ ]           | data[1][0]        +..+ data[1][N]  | [...]     |
 * +---------------+-------------------+..+-------------+-----------+
 * ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
 * +---------------+-------------------+..+-------------+-----------+
 * | [ ]           | data[M][0]        +..+ data[M][N]  | [...]     |
 * +---------------+-------------------+..+-------------+-----------+
 *
 * Currently data has to be a primitive type, but should be possible
 * to support also rendering components from `data`, because only
 * sorting needs direct access to values.
 *
 * `columns` sets the title of columns, can be string or component,
 * which will be rendered in the cell. It can also have `cmpFn` and
 * `sortKeyFn` for sorting. `cmpFn` is compare function given to
 * Array.sort function, so it will receive 2 values and should return
 * -1, 0 or 1 as comparison result. More useful is `sortKeyFn` which
 * has two parameters, the value and value's row index. It maps column
 * value to another, so for displayed date `04/01/21` it can transform
 * to `2021-04-14`, which can be sorted by normal sort cmp function.
 *
 * ```
 * columns={[
 *   'Lesson\'s title',
 *   {
 *     title: 'Date Saved',
 *     sortKeyFn: (_, i) => lessons[i].createdAt,
 *   },
 * ]}
 * ```
 * To add SelectAll action set `bulkActions` parameter, to add row
 * actions set `bulkActions` parameter. Both callbacks will receive
 * id/ids of affected rows generated by `sortKeyFn`.
 *
 * ```
 * bulkActions=[
 *  {
 *    title: 'Remove',
 *    callback: (ids: number[]) => {
 *      console.log(ids)
 *    },
 *   },
 * ]
 *
 * rowActions=[
 *  {
 *    title: 'Edit',
 *    callback: (id: number) => {
 *      console.log('Edit', id)
 *    },
 *  },
 * ]
 * ```
 *
 * `width` parameter is required, it will set the width of every
 * column, so it will look like a table (limitation of React
 * Native). Can be relative ('20%').
 *
 * `actionTitle` is optional title for action column.
 *
 * `isDisabledFn` callback to indicate rows which should not have
 * a bulk and row actions, i.e. no checkbox and menu button will be
 * shown.
 *
 * `idFn` id function for given row. It will receive the row and row
 * index. It is used for rowAction/bulAction callbacks. By default row
 * index is uded.
 *
 */
export default function DataTable<T extends Array<unknown>>({
  columns,
  data,
  width,
  rowActions,
  bulkActions,
  actionTitle = '',
  isDisabledFn = () => false,
  idFn = defaultIdFn,
}: DataTableProps<T>) {
  /** selcted rows */
  const [selected, setSelected] = React.useState<T[]>([])

  /** selectAll checkbox status */
  const [bulkSelect, setBulkSelect] = React.useState(false)

  /** checked table rows */
  const [checkedRows, setCheckedRows] = React.useState<boolean[]>(initArrayWith<boolean>(data.length, bulkSelect))

  /** sorted directions for every column (only one is used) */
  const [sortedColumns, setSortedColumns] = React.useState<SortDirection[]>(initArrayWith<SortDirection>(data.length, 0))

  /**
   * Sorting is done with an indirection with help of `sortedIndecies`
   * array, which holds the indecies from `data`.
   *
   * [1, 2, 3, 4] -> sort -> [3, 2, 1, 4]
   *
   * The indicies are sorted according the `sortFn` and the resulting
   * array are sorted indicies to `data` array.
   */
  const [sortedIndecies, setSortedIndecies] = React.useState<number[]>(createArray1toN(data.length))

  const hasBulkActions = typeof bulkActions !== 'undefined'
  const hasActionColumn = typeof rowActions !== 'undefined'

  React.useEffect(() => {
    const newChecked = initArrayWith(data.length, bulkSelect)
    setSortedIndecies(createArray1toN(data.length))
    setCheckedRows(newChecked)
    setSelected(pickSelectedRows(newChecked, data, idFn, isDisabledFn))
  }, [data.length])

  /**
   * De/Select all rows.
   */
  function selectAll(value: boolean) {
    const newChecked = initArrayWith(data.length, value)
    setBulkSelect(value)
    setCheckedRows(newChecked)
    setSelected(pickSelectedRows(newChecked, data, idFn, isDisabledFn))
  }

  /**
   * De/Select a row.
   */
  function selectRow(cellIndex: number) {
    return (value: boolean) => {
      const newChecked = Array.from(checkedRows)
      newChecked[cellIndex] = value
      setCheckedRows(newChecked)
      setSelected(pickSelectedRows(newChecked, data, idFn, isDisabledFn))

      const allSelected = newChecked.reduce((mem, checked) => mem && checked, true)
      setBulkSelect(allSelected)
    }
  }

  /**
   * Sort `sortedIndecies` according the `sortFn` by given column.
   */
  const sortColumn = (columnIndex: number) => {
    return () => {
      const sortDirection = invertSortDirection(sortedColumns[columnIndex])
      const s = initArrayWith<SortDirection>(data.length, 0)
      const keyFn = getSortKeyFn(columns[columnIndex])
      const cmpFn = getCmpFn(columns[columnIndex])
      s[columnIndex] = sortDirection
      setSortedColumns(s)
      setSortedIndecies(
        sortData(data, columnIndex, sortDirection, keyFn, cmpFn),
      )
    }
  }

  const theadColumns: React.ReactNode[] = columns.map((column, i) => (
    <View key={i} style={[s.flexRow, s.alignBaseline]}>
      {renderColumn<T>(column, (sortedColumns[i] !== 0))}
      <SortButton
        sortDirection={sortedColumns[i]}
        onSort={sortColumn(i)}
        style={[s.ml16]}
      />
    </View>
  ))

  let selectColumnOffset = 0

  if (hasBulkActions) {
    theadColumns.unshift((
      <SelectAllCell
        key={-1}
        checked={bulkSelect}
        onChange={selectAll}
        bulkActions={bulkActions}
        width={width[0]}
        selected={selected}
      />
    ))

    selectColumnOffset = 1
  }

  if (hasActionColumn) {
    theadColumns.push(actionTitle)
  }

  return (
    <Table>
      <Thead
        columns={theadColumns}
        width={width}
        styles={{zIndex: data.length}}
      />
      <Tbody>
        {sortedIndecies.length === data.length && sortedIndecies.map((dataIndex, i) => {
          const dataRow = data[dataIndex]
          const checked = checkedRows[dataIndex]
          const disabled = isDisabledFn(idFn(dataRow, dataIndex))

          return (
            <TableRow key={i} styles={{zIndex: data.length - i}}>
              {hasBulkActions && <SelectCell
                style={{width: width[0]}}
                checked={checked}
                onChange={selectRow(dataIndex)}
                disabled={disabled}
              />}
              {dataRow.map((d, j) => {
                const cellTextStyle = [s.textBlackLighter, s.f16]

                if (checked && !disabled && j == 0) {
                  cellTextStyle.push(s.textBold)
                }

                return (<Tdata
                  key={j}
                  style={[{width: width[j+selectColumnOffset]}]}
                  textStyle={cellTextStyle}
                >
                  {d}
                </Tdata>)
              })}
              {hasActionColumn && <ActionCell
                actions={rowActions}
                style={{width: width[data[dataIndex].length+selectColumnOffset]}}
                rowId={idFn(dataRow, dataIndex)}
                disabled={disabled}
              />}
            </TableRow>
          )
        })}
      </Tbody>
    </Table>
  )
}
