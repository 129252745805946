import { BOTZ_BOT_STATE_CHANGED } from './types'
import {
  call,
  take,
  takeEvery,
  select,
} from 'redux-saga/effects'
import { DeviceMgr } from './deviceMgr'

import { RobotLegacy } from '@ozobot/ozobot-ble-protocol-private'

function* maskMatchesCurrentState(uuid, stateMask) {
  const tmp = yield select(s => s.botz.byIds[uuid] || {})
  return Object.keys(stateMask).reduce(
    (ok, key) =>
      ok && (stateMask[key] === undefined || stateMask[key] === tmp[key]),
    true
  )
}

// waits for the bot state indicated in the states mask
function* waitForBotStateBase(uuid, stateMask, check) {
  if ((yield maskMatchesCurrentState(uuid, stateMask)) === check) {
    return true
  }
  while (true) {
    yield take(a => a.type === BOTZ_BOT_STATE_CHANGED && a.uuid === uuid)
    if ((yield maskMatchesCurrentState(uuid, stateMask)) === check) {
      return true
    }
  }
}

async function to(milliseconds = undefined) {
  return milliseconds
    ? new Promise(resolve => setTimeout(resolve, milliseconds))
    : new Promise(() => {})
}

// this is the max name size, minus the "Ozo" prepend
export const MAX_EVO_NAME_CHARACTERS = 13
export const EDU_BASE_BEHAVIOR_FILEPATH = '/system/ozoblock/01010002.bop'

export const EvoBatteryLevels = {
  HIGH: 80,
  MEDIUM: 20,
  LOW: 1,
  // if the battery level is 0, assume unknown, else, it'll probably disconnect / go offline anyway
  UNKNOWN: 0,
}

// NOTE: this function will yield indefinitely if timeout <= 0
export function* timeout(milliseconds = undefined) {
  yield call(to, milliseconds)
  return true
}

// waits for the bot state indicated in the states mask
export function* waitForBotState(uuid, stateMask) {
  yield waitForBotStateBase(uuid, stateMask, true)
}

export function* waitForNotBotState(uuid, stateMask) {
  yield waitForBotStateBase(uuid, stateMask, false)
}

// takes every state change, and if any state change property is caught by the mask
// calls the saga with the action.
// NOTE: if the stateMask has any property set as undefined, it will be taken to mean a wild card
export function* takeEveryBotStateChange(stateMask, saga, ...args) {
  yield takeEvery(
    action =>
      action.type === BOTZ_BOT_STATE_CHANGED &&
      Object.keys(stateMask).some(
        prop =>
          (stateMask[prop] === undefined &&
            action.stateDelta[prop] !== undefined) ||
          (stateMask[prop] !== undefined &&
            stateMask[prop] === action.stateDelta[prop])
      ),
    saga,
    ...args
  )
}

const predefinedBotNames = ['Ozobot Evo', 'unknown legacy robot']

export const evoBLENameFromDisplayName = name =>
  predefinedBotNames.includes(name) ? name : `Ozo${name}`
export const evoDisplayNameFromBLEName = name =>
  predefinedBotNames.includes(name) ? name : name.slice(3)

// returns undefined if not determined yet
export const levelFromBattery = battery => {
  if (battery >= EvoBatteryLevels.HIGH) {
    return 'high'
  } else if (battery >= EvoBatteryLevels.MEDIUM) {
    return 'medium'
  } else if (battery >= EvoBatteryLevels.LOW) {
    return 'low'
  }
}

export function* callOnBot(uuid, f) {
  const evo = DeviceMgr.botForUUID(uuid)
  if(evo) {
    return yield call(f, evo)
  } else {
    throw new Error('No bot for ', uuid)
  }
}

export function* playEDUBaseBehaviour(uuid) {
  yield callOnBot(uuid, b => {
    if (b instanceof RobotLegacy) {
      console.warn("Cannot execute Edu base behavior on a legacy firmware. Firmware update must happen first.")
      return undefined
    }
    // don't await, as it will hang until the file actually stops playing!
    b.ExecuteFile(EDU_BASE_BEHAVIOR_FILEPATH)
    return undefined
  })
}

// takes an object with r, g, b, mask, or an array of such
// defaults are r = 0, g = 0, b = 0, mask = 0xff
export function* setLEDs(uuid, leds) {
  if (Array.isArray(leds)) {
    for (const ledSet of leds) {
      yield setLEDs(uuid, ledSet)
    }
  } else {
    const { r = 0, g = 0, b = 0,
    mask = { top: true, button: true, back: true, front_left: true, front_left_center: true, front_center: true, front_right_center: true, front_right: true },
    alpha = 255 } = leds
    try {
      yield callOnBot(uuid, bot => {
        return bot.SetLED(mask, r, g, b, alpha)
      })
    } catch {
      console.warn('Could not set LEDs for', uuid)
    }
  }
}
