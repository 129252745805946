import React from 'react'
import { View, Text } from 'react-native'
import s from 'styles'

export default function LessonStep({ text, secondaryText, components, isAdminUser = false }) {
  return (
    <View>
      {text && (
        <Text style={[s.mb8, s.f20, s.textBold, s.textBlack, s.bgLighterGray]}>
          {text}
        </Text>
      )}
      {secondaryText && (
        <Text style={[s.mb8, s.f14, s.textItalic, s.bgLighterGray]}>
          {secondaryText}
        </Text>
      )}
      {components &&
        components.map(({ component, ...props }, i) =>
          React.createElement(component, {
            key: `${i}`,
            ...props,
            isAdminUser,
          })
        )}
    </View>
  )
}
