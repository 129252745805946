import React from 'react'
import { Text, View } from 'react-native'
import { some } from 'lodash'
import RTEMarkdown from './RTEMarkdown'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'

const SubmissionRTE = ({
  field,
  maxLength,
  hasSeparator,
  form,
  formUpdate,
  ...labelProps
}) => {
  const getRemaining = () => {
    if (maxLength && form[field].length > 0) {
      return `${form[field].length}/${maxLength} characters.`
    }

    return `Max ${maxLength} characters`
  }

  return (
    <View
      style={[s.mb20, hasSeparator && [s.pb20, s.bBottom1, s.bGrayLighter]]}
    >
      <SubmissionLabel
        {...labelProps}
        hasError={some(form.errors, { field })}
      />
      {/* Input */}
      <RTEMarkdown
        maxLength={maxLength}
        name={field}
        value={form[field]}
        onFormInputChangeText={formUpdate}
      />
      {maxLength && (
        <Text style={[s.f14, s.textGray, s.textRight]}>{getRemaining()}</Text>
      )}
    </View>
  )
}

export default withSubmissionForm(SubmissionRTE)
