import * as React from 'react'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import OzobotBackground from '../components/OzobotBackground'
import s from 'styles'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { userAuthCheckIfSignedOut } from 'store/user'
interface AdditionalProps {
  isSignedIn?: boolean,
  text: string,
}
type SuccessScreenProps = RouteComponentProps & AdditionalProps

const SuccessScreen = (props: SuccessScreenProps) => {
  const dispatch = useDispatch()
  return (
    <OzobotBackground>
      <View style={[s.flex1, { width: 260 }]}>
        <>
          <Text style={[s.textCenter, s.f20, s.textTeal, s.textBold, s.mb20]}>
            {props.text}
          </Text>
          <View style={[s.alignSelfCenter, { width: 'fit-content'}]}>
            <Button
              text={props.isSignedIn ? 'Back to Dashboard' : 'Continue'}
              onPress={() => {
                props.history.push(props.isSignedIn ? '/dashboard' : '/account/login')
                if(!props.isSignedIn) {
                  dispatch(userAuthCheckIfSignedOut({}))
                }
              }}
            />
          </View>
        </>
      </View>
    </OzobotBackground>
  )
}

export default SuccessScreen

