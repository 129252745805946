import React from 'react'
import { View, ViewStyle } from 'react-native'
import s from 'styles'

export interface TableRowStyle extends ViewStyle {
  color?: string
  zIndex?: number
}

interface TableRowProps {
  styles?: TableRowStyle,
  children?: React.ReactNode,
}

export default function TableRow({children, styles}: TableRowProps) {
  return (
    <View
      style={[
        s.bWhiteDarkest,
        s.bBottom2,
        s.p8,
        s.alignCenter,
        s.justifyBetween,
        s.flexRow,
        {
          zIndex: styles?.zIndex,
          minHeight: (styles && styles.height) || 70,
          margin: 1,
          paddingVertical: 8,
        },
        styles && styles.color === 'gray'
          ? {
            backgroundColor: '#f7f7f7',
          }
          : {
            backgroundColor: 'white',
          },
      ]}
    >
      {children}
    </View>
  )
}
