import {
  BOTZ_BOT_RENAME_REQUEST,
  BOTZ_DEVICE_PAGE_ENABLED,
  BOTZ_REQUEST_ID_BOT_WITH_BLINKING_LEDS,
  BOTZ_OCC_ALLOW_BOT_CONNECTION,
  BOTZ_OCC_SET_BOT_CONNECTION_FILTER,
  BOTZ_PERIPHERAL_CONNECTION_REQUEST,
  BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST,
  BOTZ_UPDATE_BOT_BRIEFING_REQUEST,
  BOTZ_UPDATE_BOTS_REQUEST,
  BOTZ_UPDATE_OCC_FIRMWARE_REQUEST,
} from './types'

import { evoBLENameFromDisplayName } from './util'

import { isEmpty } from 'lodash'

export const botzConnectToOCCWithBLE = () => ({
  type: BOTZ_PERIPHERAL_CONNECTION_REQUEST,
  peripheral: 'OCC_BLE',
})

export const botzConnectToOCCWithUSB = () => ({
  type: BOTZ_PERIPHERAL_CONNECTION_REQUEST,
  peripheral: 'OCC_USB',
})

export const botzConnectToBotWithBLE = () => ({
  type: BOTZ_PERIPHERAL_CONNECTION_REQUEST,
  peripheral: 'EVO_BLE',
})

export const botzDisconnectAllPeripherals = () => ({
  type: BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST,
})

export const botzSetOCCConnectionFilter = ({ bots }) => ({
  type: BOTZ_OCC_SET_BOT_CONNECTION_FILTER,
  bots,
})

export const botzAllowOCCBotConnection = ({ allow }) => ({
  type: BOTZ_OCC_ALLOW_BOT_CONNECTION,
  allow,
})

// === DEMO ===

export const botzFetcDemoCacheBOPs = () => ({
  type: 'BOTZ_FETC_DEMO_CACHE_BOPS',
})

// ============

// === BOT COMMANDS ===

export const botzSetName = ({ uuid, name }) => ({
  type: BOTZ_BOT_RENAME_REQUEST,
  uuid,
  name: evoBLENameFromDisplayName(name),
})

// === UTILS ===

export const botzUpdateOCCFirmware = () => ({
  type: BOTZ_UPDATE_OCC_FIRMWARE_REQUEST,
})

export const botzUpdateBots = ({ uuids, onProgress }) => ({
  type: BOTZ_UPDATE_BOTS_REQUEST,
  uuids,
  onProgress,
})

export const botzGetBriefing = ({ uuids }) => ({
  type: BOTZ_UPDATE_BOT_BRIEFING_REQUEST,
  uuids,
})

export const botzIDBotWithBlinkingLEDs = ({ uuid }) => ({
  type: BOTZ_REQUEST_ID_BOT_WITH_BLINKING_LEDS,
  uuid,
})

export const botzDevicePageEnabled = ({ enabled }) => ({
  type: BOTZ_DEVICE_PAGE_ENABLED,
  enabled,
})

// === selectors ===

export const botzOCCIsUpdatingSelector = ({ botz }) =>
  botz.updateProgressInfo?.updateType === 'occ'

export const botzIsScanningForBotsSelector = ({ botz }) =>
  botz.occ.isScanningForBots

export const botzBotsAreQueuedForUpdating = ({ botz }) =>
  !isEmpty(botz.botsToUpdate)

export const botzOCCIsScanningForBots = ({ botz }) => botz.occ.isScanningForBots

export const botzBotsAreUpdating = ({ botz }) => !!botz.updateProgressInfo

export const botzBotsAreConnectedThatNeedUpdates = ({ botz }) =>
  !!Object.values(botz.byIds).find(bot => bot.updateIsNeeded)
