import React from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { Content, Layout, PageHeader } from 'components/Layout'
import { Image } from 'components/ReactNative'
import { Link } from 'components/Router'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const content = {
  headline: `How do you want to \ncreate your lesson?`,
  options: [
    {
      icon: require('images/create-lesson/lessons-upload-file-teal.png'),
      text: 'Ozobot Template',
      secondaryText: 'Create a Classroom-compatible lesson with this all-in-one tool!',
      to: '/lessons/creator/1',
    },
    {
      icon: require('images/create-lesson/lessons-upload-cloud-teal.png'),
      text: 'Upload As-Is',
      secondaryText: 'Add your existing lesson PDF or video',
      to: '/lessons/uploader/1',
    },
  ],
}

const LessonBackLink = () => {
  return (
    <View style={[s.flexRow]}>
      <TouchableOpacity style={[s.flexColumn, s.alignCenter]}>
        <Link to={'/lessons/mine'}>
          <Text style={[s.f16, s.textGreen, s.textBold, s.textUnderline]}>My Lessons</Text>
        </Link>
      </TouchableOpacity>
      <Image
        source={require('images/create-lesson/Icon-ionic-ios-arrow-forward.png')}
        style={{ height: 12, width: 7, tintColor: '#00b1b9', marginHorizontal: 16, marginVertical: 5 }}
      />
      <Text>Create Lesson</Text>
    </View>
  )
}

/**
 * URL: /lessons/new
 */
export default function LessonsCreatorLanding() {

const { isMobile } = useDetectDeviceType();

  return (
    <Layout>
      <PageHeader>
        <LessonBackLink />
      </PageHeader>
      <Content>
        <Text style={[s.mb32, s.f20, s.textBold, s.textCenter, isMobile ? s.mt20 : null]}>
          {content.headline}
        </Text>
        <View style={[ isMobile ? s.flexColumn : s.flexRow, s.justifyCenter, isMobile ? { gap: '20px' } : null]}>
          {content.options.map(({ icon, to, text, secondaryText }, i) => (
            <View key={i} style={[ isMobile ? s.mx32 : s.mx16, s.bShadow, s.bgGrayMoreLighter, s.br10]}>
              <Link to={to}>
                <View style={[ isMobile ? s.w100p : s.w240, s.p32, s.alignCenter, s.justifyCenter]}>
                  <Image
                    source={icon}
                    style={[s.h60, s.w60, s.mb8]}
                    resizeMode="contain"
                  />
                  <View style={[s.textCenter, s.mt8]}>
                    <Text style={[s.textBold, s.f18]}>{`${text}\n`}</Text>
                    <View style={[s.my16]}>
                      <Text style={[s.f14]}>{secondaryText}</Text>
                    </View>
                  </View>
                </View>
              </Link>
            </View>
          ))}
        </View>
      </Content>
    </Layout>
  )
}
