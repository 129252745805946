import React from 'react'
import { useDispatch } from 'react-redux'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import { ModalFrame } from 'components/modal'
import { modalHide } from 'store/modal'
import s from 'styles'

export interface AlertModalProps {
  title: string,
  text: string,
  headline?: string,
  buttonText?: string,
}

const AlertModal = (props: AlertModalProps) => {
  const dispatch = useDispatch()
  return (
    <ModalFrame title={props.title} size="medium">
      <View style={[s.mb20]}>
        <Text style={[s.mb8, s.f28, s.textBold, s.textRed, s.textCenter]}>
          {props.headline}
        </Text>
        <Text style={[s.f16, s.textCenter]}>{props.text}</Text>
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        <View style={s.mr16}>
          <Button text={props.buttonText || 'Ok'} onPress={() => dispatch(modalHide())} />
        </View>
      </View>
    </ModalFrame>
  )
}

export default AlertModal

export const makeAlertModal = (props: AlertModalProps) => {
  return (<AlertModal {...props} />)
}
