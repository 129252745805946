import {
  EvoFALTASErrorDescriptions,
} from './protocol'

import { 
  robotUpdater,
  UpdaterStatus,
} from "@ozobot/robot-updater";

let logger = {
  log: () => {},
  info: () => {},
  debug: () => {},
  table: () => {},
  warn: console.warn,
  error: console.error,
}

const LOG_PREAMBLE = '[evo-update]'

const durationStr = duration => {
  const minutes = Math.floor(duration / 60000)
  const seconds = (duration - minutes * 60000) / 1000
  return minutes > 0
    ? `${minutes} minutes and ${seconds} seconds`
    : `${seconds} seconds`
}

// set logging
export const setEvoUpdateLogger = l => (logger = l)

export const updateEvo = async ({
  manifest,
  evo,
  onReconnectNeeded, // TODO: remove the 'reason' argument?
  onDisconnectBeginning = undefined,
  onProgress,
  forceUpdate = false,
  logID = 'Evo',
}) => {

  try {

    const updateStartTime = Date.now()
    logger.debug(LOG_PREAMBLE, 'Starting update for Evo', logID)
    if (forceUpdate) {
      logger.debug(LOG_PREAMBLE, 'Forcing update')
    }

    let status
    // eslint-disable-next-line no-constant-condition
    while (true) { 
      status = (await robotUpdater(evo, manifest, onProgress)).state

      // Dummy test reconnect
      // status = 'RECONNECTION_NEEDED'
      // onProgress({ state: status })

      console.log(status)
      if (status !== UpdaterStatus.RECONNECTION_NEEDED) break
    
        evo = await onReconnectNeeded(evo, 'firmware') // as of now, the library cannot tell easily if reconnect is needed due to BLE update or firmware update. Please consider simplifying the updater UI not to distinguish between the two.
    }

    logger.debug(
      LOG_PREAMBLE,
      'Finished update for Evo',
      logID,
      'in',
      durationStr(Date.now() - updateStartTime)
    )

    // return updatesNeeded // TODO: what return value is expected ??
    return
  } catch (e) {
    if (typeof e === 'number') { // TODO: review, this error handling most likely doesn't work, as the exception is not a number type
      throw new Error(`FALTAS: ${EvoFALTASErrorDescriptions[e]}`)
    }
    throw e
  }
}
