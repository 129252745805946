import * as React from 'react'
import { Text, View, ScrollView } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { openURL } from 'lib/utils'
import { lessonByIdSelector, lessonPublicRatingSelector, lessonsActions, lessonsIsFetchingSelector } from 'store/lessons'
import Button from 'components/Form/Button2'
import { Content } from 'components/Layout'
import { LessonType } from 'components/LessonTypes'
import { TabbedLessonInfo } from 'components/TabbedLessonInfo'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import LessonViewFrame from './components/LessonViewFrame'
import LoadingLessons from './components/LoadingLessons'
import { shouldShareLesson } from 'store/lessons/util'
import MissingPage from 'pages/404'

interface MatchParams {
  id: string
}

const LessonPublic: React.FC<RouteComponentProps<MatchParams>> = (props: RouteComponentProps<MatchParams>) => {
  const id = props.match.params.id
  const lesson: LessonType = useSelector(lessonByIdSelector(id))
  const isFetching: boolean = useSelector(lessonsIsFetchingSelector)
  const lessonRating: number | undefined = useSelector(lessonPublicRatingSelector(id))

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(lessonsActions.viewRequest({ id }))
  }, [id])

  const { isDesktop, isMobile } = useDetectDeviceType()

  const textFont = isDesktop
    ? s.f18
    : isMobile
      ? s.f14
      : s.f16

  if(!lesson) {

    if(isFetching) {
      return <LoadingLessons/>
    }

    return <MissingPage {...props} />
  }

  return (
    <Content>
      <ScrollView>
        <View style={[s.flexColumn, s.justifyStart, s.alignStretch, s.mb120]}>
          <View style={[s.flexRow, s.flex1, s.justifyCenter]}>
            <View style={[s.flexColumn, s.flex1, s.alignStretch, { maxWidth: 1600 }]}>
              {isDesktop &&
                <View style={[s.flexRow, s.justifyBetween, s.alignCenter, s.h100, s.px40]}>
                  <View style={[s.w150, s.mb8]} >
                    {!!lesson.studentPdfUrl && (
                      <Button text='Activity Sheets' role='greenBgWhiteText' onPress={() => openURL(lesson.studentPdfUrl, true)} />
                    )}
                  </View>
                </View>
              }
              {shouldShareLesson(lesson) && <LessonViewFrame
                lesson={lesson}
                mainRollContent={(
                  <View>
                    <View style={[s.mt32, !isDesktop ? s.px16 : null]}>
                      <Text style={[s.mb8, textFont, s.textLight]}>{lesson.summary}</Text>
                      <Text style={[s.mb8, textFont, s.textItalic]}>{'Before you start, make sure your Ozobot is charged.'}</Text>
                    </View>
                    { !isDesktop && <View style={[s.px16, s.mt24]}>
                      <View style={[s.w150]} >
                        {!!lesson.studentPdfUrl && (
                          <Button text='Activity Sheets' role='greenBgWhiteText' onPress={() => openURL(lesson.studentPdfUrl, true)} />
                        )}
                      </View>
                    </View>
                    }
                    <TabbedLessonInfo lesson={lesson} lessonRating={lessonRating} isPublicView={true} />
                  </View>
                )}
                secondaryRollContent={(<View/>)}
              />}
            </View>
          </View>
        </View>
      </ScrollView>
    </Content>
  )
}

export default LessonPublic
