import { OzobotEvoBle } from './ozobotEvoBLE'
import {
  BotMgr as OzoBridgeBotMgr,
  MAX_CONNECTABLE_BOTS as OZOBRIDGE_MAX_CONNECTED_BRIDGE_BOTS,
} from '@ozobot/bot-manager'

import { 
  getManifestOverHttp,
  assessUpdateNeed,
} from "@ozobot/robot-updater"

import { Robot, RobotLegacy } from "@ozobot/ozobot-ble-protocol-private"
import {
  setEvoUpdateLogger,
  updateEvo,
} from './updateEvo'
import { Version } from '@ozobot/common';
import { isEqual } from 'lodash'

// FIXME: later, move this sort of thing to a higher level (params should be passed in as option to this module)
import { modes } from 'lib/modes'

const LOG_PREAMBLE = '[device-mgr]'

const params = modes.occParams
  ? modes.occParams.split(',').map(v => parseInt(v))
  : [
      // Multi bot

      // connection bridge interval
      40,
      // evo connection interval scalar
      6,
      // evo boosted connection interval scalar
      2,

      // Single bot

      // min connection bridge interval
      20,
      // evo connection interval scalar
      1,
      // evo boosted connection interval scalar
      1,
    ]
if (params.length !== 6) {
  throw new Error(`Invalid OCC params ${params}`)
}

console.debug(LOG_PREAMBLE, 'Using OCC params', params)

const botsByID = {}

const botStateSubscriptions = []
const botEventSubscriptions = []
const botFALTASSubscriptions = []
const botUpdateProgressSubscriptions = []

const OzobridgeProfiles = {
  MANY_BOTS: 18,
  SINGLE_BOT: 1,
}

const colorNames = {
  0: 'k',
  1: 'r',
  2: 'g',
  4: 'b',
  6: 'c',
  5: 'm',
  3: 'y',
  7: 'w',
}

let logger = {
  log: () => {},
  info: () => {},
  debug: () => {},
  table: () => {},
  warn: console.warn,
  error: console.error,
}

const subscribeTo = (list, handler) => {
  list.push(handler)
  return () => {
    const index = list.indexOf(handler)
    if (index >= 0) {
      list.splice(index, 1)
    }
  }
}

const callSubscribers = (subscriptions, msg) => {
  for (let subscription of subscriptions) {
    try {
      subscription(msg)
    } catch (err) {
      logger.error(err)
    }
  }
}

const trimNulls = str => str.replace(/\0/g, '')

const keyForValue = (obj, val) => Object.keys(obj).find(key => obj[key] === val)

const delay = async milliseconds =>
  new Promise(resolve => {
    if (milliseconds > 0) {
      setTimeout(resolve, milliseconds)
    } else {
      resolve()
    }
  })

class TaskChannel {
  constructor(waitBetweenItems = 0) {
    this._queue = []
    this._waitBetweenItems = waitBetweenItems
    this._runningTasks = false
  }

  run = async task => {
    // return task()
    return new Promise((resolve, reject) => {
      this._queue.push({
        task,
        resolve,
        reject,
      })
      if (!this._runningTasks) {
        this._runningTasks = true
        new Promise(async resolve => {
          while (this._queue.length > 0) {
            const taskItem = this._queue.splice(0, 1)[0]
            try {
              taskItem.resolve(await taskItem.task())
            } catch (e) {
              taskItem.reject(e)
            }
            await delay(this._waitBetweenItems)
          }
          this._runningTasks = false
          resolve()
        })
      }
    })
  }
}

// rejects with null if cancelled
const retryUntilCancelled = ({
  onRetry,
  maxRetries = undefined,
  interval = undefined,
  logMsg = 'task',
}) => {
  let cancelled = false
  let counter = 0
  let cancel = undefined
  // NOTE: according to spec, the executor is run before the promise is assigned, so
  // cancel will be valid -- though we will make sure...
  const promise = new Promise(async (resolve, reject) => {
    cancel = () => {
      logger.debug(LOG_PREAMBLE, 'cancelling retries for', logMsg)
      cancelled = true
      reject(null)
    }
    while ((!maxRetries || ++counter < maxRetries) && !cancelled) {
      try {
        logger.debug(LOG_PREAMBLE, 'retrying', logMsg, counter)
        const result = await onRetry()
        if (!cancelled) {
          resolve(result)
        }
        break
      } catch (err) {
        if (!cancelled && counter >= maxRetries) {
          logger.warn(LOG_PREAMBLE, 'last failed retry for', logMsg, err)
          reject(err)
          break
        } else {
          logger.debug(LOG_PREAMBLE, 'retry failed for', logMsg, counter)
        }
      }
      await delay(interval)
    }
  })
  if (!cancel) {
    throw new Error('Cancel was not set!')
  }
  return {
    promise,
    cancel,
  }
}

class DirectConnectMgr {
  _botStateSubscriptions = []
  _connectedEvos = {}
  _noReconnectBots = new Set()

  subscribeToBotStates = onState =>
    subscribeTo(this._botStateSubscriptions, onState)

  request = async () => {
    let evo
    try {
      logger.debug(LOG_PREAMBLE, 'Requesting single bot')
      evo = await OzobotEvoBle.requestDevice([{ namePrefix: 'Ozo' }])
      logger.info(LOG_PREAMBLE, 'Requested single bot')
      if (!evo) {
        throw new Error('cancelled')
      }
    } catch (err) {
      logger.warn(LOG_PREAMBLE, 'Single bot request failed', err)
      throw err
    } finally {
      if (OzobotEvoBle.cancelDeviceRequest) {
        OzobotEvoBle.cancelDeviceRequest()
      }
    }
    return this._setupEvo(evo)
  }

  disconnect = async uuid => {
    logger.debug(LOG_PREAMBLE, 'Disconnecting single bot called', uuid)
    const info = this._connectedEvos[uuid]
    if (info) {
      logger.info(LOG_PREAMBLE, 'Disconnecting single bot', uuid)
      if (info.reconnectionCancel) {
        info.reconnectionCancel()
      }
      if (info.evo) {
        logger.debug(LOG_PREAMBLE, 'Calling device disconnect on', uuid)
        this._noReconnectBots.add(uuid)
        await info.evo.disconnect()
      }
      callSubscribers(this._botStateSubscriptions, {
        uuid,
        state: {
          connected: false,
          available: false,
          reconnecting: false,
        },
      })
    }
  }

  disconnectAll = async () => {
    logger.debug(LOG_PREAMBLE, 'Disconnecting all single bots called')
    Promise.all(
      Object.keys(this._connectedEvos).map(uuid => this.disconnect(uuid))
    )
  }

  disableReconnect = (uuid, disable) => {
    if (disable) {
      this._noReconnectBots.add(uuid)
    } else {
      this._noReconnectBots.delete(uuid)
    }
  }

  _cleanupEvoEntry = uuid => {
    const info = this._connectedEvos[uuid]
    if (info) {
      logger.debug(LOG_PREAMBLE, 'Cleaning up single Evo', uuid)
      if (info.evo && info.disconnectHandler) {
        logger.debug(
          LOG_PREAMBLE,
          'Removing single bot disconnection handler',
          uuid
        )
        info.evo.device.removeDisconnectListener(info.disconnectHandler)
      } else {
        logger.debug(LOG_PREAMBLE, 'No single bot disconnection handler', uuid)
      }
      if (info.reconnectionCancel) {
        info.reconnectionCancel()
      }
      delete this._connectedEvos[uuid]
    }
  }

  _setupEvo = async evo => {
    const firmware = await evo.get_fwVersion_converted()

    let badUUID = false
    let uuid
    try {
      uuid = await evo.get_serialNumber_converted()
    } catch (e) {
      logger.warn(LOG_PREAMBLE, 'Could not get single bot UUID value', uuid)
      uuid = evo.getBluetoothDeviceId()
      badUUID = true
    }

    logger.info(LOG_PREAMBLE, 'Single bot connect with UUID', uuid)

    // FIXME: need to check to see if existing Evo was reconnecting, and remove...

    // HACK: due to caching issues on Chrome BLE, if the bot is renamed the
    // new name is not reflected by the Evo until the device is power cycled
    // (presumably because a power cycle refreshes the device ID). This HACK
    // is not a complete fix, but will at least provide some name consistency
    // until the browser is refreshed, at which point if they connect again,
    // the old name might by reported by the device. The correct solution,
    // at least as far as name display once connected, is to query using
    // some separate service, the name of the bot, such that the name
    // is always up to date, and not cached by the OS. One possibility is to
    // use the device name characteristic (00002a26-0000-1000-8000-00805f9b34fb)...
    let prevName = undefined
    if (botsByID[uuid]) {
      logger.debug(
        LOG_PREAMBLE,
        'Found previous name for single bot',
        botsByID[uuid].state && botsByID[uuid].state.name
      )
      prevName = botsByID[uuid].state.name
      this._cleanupEvoEntry(uuid)
    }

    const disconnectFunc = () => this._onDisconnect(uuid, evo)
    evo.addDisconnectListener(disconnectFunc)

    this._connectedEvos[uuid] = {
      evo,
      disconnectFunc,
      badUUID,
    }

    if (!badUUID) {
      this._noReconnectBots.delete(uuid)
    }

    callSubscribers(this._botStateSubscriptions, {
      uuid,
      evo,
      state: {
        uuid,
        name: prevName || ( evo instanceof Robot ? await evo.get_deviceName() : "unknown legacy robot" ),
        firmware,
        connected: true,
        available: false,
        reconnecting: false,
      },
    })

    return uuid
  }

  _onDisconnect = (uuid, evo) => {
    logger.debug(LOG_PREAMBLE, 'Single bot onDisconnect called', uuid)

    // remove entry -- we're disconnected, so assume the worst
    const badUUID =
      this._connectedEvos[uuid] && this._connectedEvos[uuid].badUUID
    this._cleanupEvoEntry(uuid)

    // if we got a "bad UUID", don't try to reconnect (as the UUID might change) -- just finish things
    if (badUUID || this._noReconnectBots.has(uuid)) {
      logger.debug(
        LOG_PREAMBLE,
        'Single bot onDisconnect with bad UUID or no-reconnect',
        uuid
      )
      callSubscribers(this._botStateSubscriptions, {
        uuid,
        state: {
          connected: false,
          available: false,
          reconnecting: false,
        },
      })
    } else {
      callSubscribers(this._botStateSubscriptions, {
        uuid,
        state: {
          connected: false,
          available: false,
          reconnecting: true,
        },
      })

      // setup retry connection
      const { cancel, promise } = retryUntilCancelled({
        onRetry: async () => evo.device.connect(),
        maxRetries: 6,
        interval: 3000,
        logMsg: `reconnection of ${uuid}`,
      })

      this._connectedEvos[uuid] = { reconnectionCancel: cancel }

      // handle result of retry
      promise
        .then(newEvo => this._setupEvo(newEvo))
        .catch(err => {
          this._connectedEvos[uuid] = undefined
          // if not cancelled, alert that we've disconnected
          if (err) {
            callSubscribers(this._botStateSubscriptions, {
              uuid,
              state: {
                connected: false,
                available: false,
                reconnecting: false,
              },
            })
          }
        })
    }
  }
}

export const MAX_CONNECTED_BRIDGE_BOTS = OZOBRIDGE_MAX_CONNECTED_BRIDGE_BOTS

class BridgeConnectMgr {
  _botMgr = new OzoBridgeBotMgr()
  _bridgeStateSubscriptions = []
  _evoStateSubscriptions = []
  _updateProgressSubscriptions = []
  _readyToProcessBotEvents = false
  _manuallyDisconnecting = false

  _bridgeChannel = new TaskChannel(30)

  constructor() {
    this._botMgr.subscribeToOzobridgeStatusChange(status => {
      logger.debug(LOG_PREAMBLE, 'Bridge status change', status)
      if (status === 'not_connected') {
        logger.info(LOG_PREAMBLE, 'Bridge disconnected')

        // The disconnection bot statuses on bridge disconnection are a bit sloppy --
        // do a hard, fake disconnect message here, to make sure we're noted as disconnected on bots
        // before the bridge notification is sent
        for (const status of this._botMgr.botStatusesByUuid.values()) {
          this._updateStatus({
            ...status,
            connection: 'out_of_range',
          })
        }
        this._readyToProcessBotEvents = false

        callSubscribers(this._bridgeStateSubscriptions, {
          state: {
            connected: false,
            reconnecting: false,
            disconnected: !this._manuallyDisconnecting,
          },
        })
        this._manuallyDisconnecting = false
      }
    })
    this._botMgr.subscribeToBotStatusChange(this._updateStatus)
  }

  subscribeToBridgeState = onState =>
    subscribeTo(this._bridgeStateSubscriptions, onState)
  subscribeToBotStates = onState =>
    subscribeTo(this._evoStateSubscriptions, onState)
  subscribeToUpdateProgress = onProgress =>
    subscribeTo(this._updateProgressSubscriptions, onProgress)

  usingLegacyBridge = () => !!this._botMgr.currentLegacyOcc

  isConnected = () => !!this._botMgr.currentOzobridge

  request = async () => {
    logger.info(LOG_PREAMBLE, 'Requesting bridge')
    // make sure disconnected -- for now, we can have only one
    await this.disconnect()
    this._manuallyDisconnecting = false
    await this._botMgr.connectOzobridge(OzobridgeProfiles.MANY_BOTS, true)
    const device = this._botMgr.currentOzobridge
    if (device) {
      logger.debug(LOG_PREAMBLE, 'Bridge device connected')
      await delay(100) // wait for any notifications, etc.., to quiet
      const info = device.rawDriver.deviceInfo
      const state = {
        name: trimNulls(info.name),
        serial: trimNulls(info.GetIdentifierAsString()),
        firmware: info.version,
        connected: true,
        reconnecting: false,
        disconnected: false,
      }
      // only continue if still connected after those async functions...
      if (!this._botMgr.currentOzobridge) {
        throw new Error('disconnected')
      }
      logger.info(LOG_PREAMBLE, 'Bridge connected with', state)
      // to keep things clean, we want to block any any in-coming bot events until we're setup up (for example, we make sure)
      // that scanning is off
      callSubscribers(this._bridgeStateSubscriptions, { state })
    } else {
      logger.debug(LOG_PREAMBLE, 'Legacy bridge device connected')
      if (!this._botMgr.currentLegacyOcc) {
        throw new Error('Unsupported legacy version')
      }
      const state = {
        name: `Legacy OCC`,
        serial: '',
        firmware: new Version(1,1,10), // this is just a guess, since we can't get the actual firmware version...
        connected: true,
        reconnecting: false,
        disconnected: false,
      }
      callSubscribers(this._bridgeStateSubscriptions, { state })
    }
  }

  disconnect = async () => {
    if (this._botMgr.currentOzobridge) {
      logger.info(LOG_PREAMBLE, 'Bridge disconnecting')
      this._manuallyDisconnecting = true
      return this._botMgr.disconnectOzobridge()
    } else {
      logger.info(LOG_PREAMBLE, 'Bridge already disconnected')
    }
  }

  updateFirmware = async (buffer, addReconnectDelay=false) => {
    logger.info(LOG_PREAMBLE, 'Updating bridge firmware')
    const uploadEndPercent = addReconnectDelay ? 90 : 100
    const res = await this._bridgeChannel.run(async () =>
      this._botMgr.uploadOzobridgeFirmware(new Uint8Array(buffer), progress => {
        callSubscribers(
          this._updateProgressSubscriptions,
          {
            ...progress,
            progress: Math.min((progress.uploaded / progress.totalSize) * uploadEndPercent, uploadEndPercent),
            text: 'Uploading firmware'
          },
        )
      })
    )
    if(addReconnectDelay) {
      console.debug(LOG_PREAMBLE, 'Firmware update delaying while OCC restarts')
      // According to spec, it will take about 20 seconds for the OCC to restart itself...
      const totalDelay = 20 * 1000
      const delayBeg = Date.now()
      const delayEnd = delayBeg + totalDelay
      let currTime = delayBeg
      while(currTime < delayEnd) {
        await delay(1000)
        currTime = Date.now()
        const percentDone = ((currTime - delayBeg) / totalDelay)
        callSubscribers(
          this._updateProgressSubscriptions,
          {
            progress: Math.min(uploadEndPercent + percentDone * (100 - uploadEndPercent), 100),
            text: 'Waiting for restart'
          },
        )
      }
    }
    return res
  }

  confirmFirmware = async () => {
    logger.debug(LOG_PREAMBLE, 'Confirming bridge firmware')
    return this._bridgeChannel.run(async () =>
      this._botMgr.confirmRunningOzobridgeFirmware()
    )
  }

  enableScanning = async enabled => {
    logger.debug(LOG_PREAMBLE, 'Bridge scannning enabled', enabled)
    if (enabled) {
      this._readyToProcessBotEvents = true
    }
    return this._bridgeChannel.run(async () =>
      this._botMgr.currentOzobridge.setScanning(enabled)
    )
  }

  refreshBotStates = async () => {
    logger.debug(LOG_PREAMBLE, 'Refreshing bridge bots')
    return (
      this._botMgr.currentOzobridge &&
      this._bridgeChannel.run(async () => {
        await this._botMgr.currentOzobridge.getDeviceInfo('all')
        for (const status of this._botMgr.botStatusesByUuid.values()) {
          this._updateStatus(status)
        }
      })
    )
  }

  evosToKeepConnected = () => this._botMgr.botsToKeepConnected

  keepConnectedToEvo = async uuid => {
    logger.debug(LOG_PREAMBLE, 'Bridge keeping connected to bot', uuid)
    return this._botMgr.keepBotConnected(uuid, true)
  }

  setBotsToKeepConnected = async uuids => {
    logger.debug(LOG_PREAMBLE, 'Bridge setting all bot connections', uuids)
    return this._botMgr.setBotsToKeepConnected(uuids)
  }

  disconnectFromEvo = async uuid => {
    logger.info(LOG_PREAMBLE, 'Bridge disconnecting from bot', uuid)
    return this._botMgr.disconnectBot(uuid)
  }

  disconnectFromAllEvos = async () => {
    logger.info(LOG_PREAMBLE, 'Bridge disconnecting from all bots')
    return this._botMgr.disconnectAllBots()
  }

  enableSingleBotPerformanceMode = async enable => {
    logger.info(LOG_PREAMBLE, 'setting bridge single bot profile', enable)
    return this._botMgr.setOzobridgeConnectionProfile(
      enable ? OzobridgeProfiles.SINGLE_BOT : OzobridgeProfiles.MANY_BOTS
    )
  }

  _updateStatus = (status, prevStatus) => {
    if (this._readyToProcessBotEvents) {
      const uuid = status.id
      const update = {
        uuid,
        state: {
          uuid,
          name: trimNulls(status.info.name),
          firmware: trimNulls(status.info.version.toString()),
          connected: status.connection === 'connected',
          available: status.connection === 'available',
          reconnecting: status.connection === 'connecting',
        },
      }
      if (status.connection === 'connected') {
        update.evo = this._botMgr.botByUUID(uuid)
      }

      callSubscribers(this._evoStateSubscriptions, update)
    }
  }
}

const parseColorCode = val => {
  const colorCode = []
  for (let i = 0; i < 10; ++i) {
    // break if all the next characters are black
    const shifted = val >> (i * 8)
    if (shifted === 0) {
      break
    }
    colorCode.push(colorNames[shifted & 0x7])
  }
  return colorCode.join('')
}

const onCharacteristic = (uuid, data) => {
  callSubscribers(botEventSubscriptions, {
    uuid,
    ...data,
  })
}

const infoForUUID = uuid => {
  const info = botsByID[uuid]
  if (!info) {
    throw new Error(`Device with uuid ${uuid} not found!`)
  }
  return info
}

const updateBotState = state => {
  const uuid = state.uuid
  const prevState = botsByID[uuid] || { state: {} }
  const newState = {
    ...prevState,
    ...state,
    state: {
      ...prevState.state,
      ...state.state,
    },
  }
  if (!isEqual(newState, prevState)) {
    if (newState.state.available && !prevState.state.available) {
      logger.info(LOG_PREAMBLE, 'Bot available', newState.state)
    } else if (newState.state.connected && !prevState.state.connected) {
      logger.info(LOG_PREAMBLE, 'Bot connected', newState.state)
      // subscribe to event notifications
      /* TODO: subscribing to event notification not yet implemetned in the ble-protocol library
         however, it might not be needed in Classroom, as RTI are not used anyway
      newState.unsubscribes = Object.keys(notificationFuncs).map(
        notification =>
          newState.evo.subscribeCommand(notification, data => {
            const info = notificationFuncs[notification](data)
            onCharacteristic(uuid, {
              eventType: info.eventType,
              data: {
                ...info,
                eventType: undefined,
              },
            })
          }).unsubscribe
      )
      */
    } else if (!newState.state.connected && prevState.state.connected) {
      logger.info(LOG_PREAMBLE, 'Bot disconnected', newState.state)
      // unsubscribe from event notifications
      if (newState.unsubscribes) {
        for (const unsubscribe of newState.unsubscribes) {
          if (unsubscribe) {
            unsubscribe()
          }
        }
      }
      delete newState.unsubscribes
      delete newState.evo
    } else {
      logger.debug(
        LOG_PREAMBLE,
        `bot state update for ${uuid}`,
        newState,
        prevState
      )
    }

    // assume not owned by bridge if bridge is not handling
    if (
      !newState.state.connected &&
      !newState.state.reconnecting &&
      !newState.state.available
    ) {
      delete newState.isBridgeBot
    }
    newState.state.isOCC = !!newState.isBridgeBot

    botsByID[uuid] = newState

    callSubscribers(botStateSubscriptions, {
      uuid,
      state: { ...newState.state },
      prevState: { ...prevState.state },
    })
  }
}

const directConnect = new DirectConnectMgr()
directConnect.subscribeToBotStates(updateBotState)
const bridgeConnect = new BridgeConnectMgr()
bridgeConnect.subscribeToBotStates(state =>
  updateBotState({ ...state, isBridgeBot: true })
)

let currUpdatingBot = undefined

export const DeviceMgr = {
  setLogger: l => {
    logger = l
    setEvoUpdateLogger(l)
  },

  // === OzoBridge ===

  subscribeToBridgeStateChanges: callback =>
    bridgeConnect.subscribeToBridgeState(callback),
  subscribeToBridgeUpdateProgress: callback =>
    bridgeConnect.subscribeToUpdateProgress(callback),

  usingLegacyBridge: () => bridgeConnect.usingLegacyBridge(),

  bridgeIsConnected: () => bridgeConnect.isConnected(),
  requestBridge: async () => bridgeConnect.request(),
  disconnectFromBridge: async () => bridgeConnect.disconnect(),
  updateBridgeFirmware: async (buffer, addReconnectDelay=false) => bridgeConnect.updateFirmware(buffer, addReconnectDelay),
  confirmBridgeFirmware: async () => bridgeConnect.confirmFirmware(),
  enableScanningOnBridge: async enable => bridgeConnect.enableScanning(enable),
  keepConnectedToBotOnBridge: async uuid =>
    bridgeConnect.keepConnectedToEvo(uuid),
  setBotConnectionOnBridge: async (uuid, shouldBeConnected) =>
    shouldBeConnected
      ? bridgeConnect.keepConnectedToEvo(uuid)
      : bridgeConnect.disconnectFromEvo(uuid),
  setBotsToKeepConnectedOnBridge: async uuids =>
    bridgeConnect.setBotsToKeepConnected(uuids),
  disconnectedFromAllBotsOnBridge: async () =>
    bridgeConnect.disconnectFromAllEvos(),

  // === Direct connect ===

  requestBotDirectly: async () => directConnect.request(),
  disconnectFromAllDirectlyConnectedBots: async () =>
    directConnect.disconnectAll(),

  // === General ===

  subscribeToBotStateUpdates: callback =>
    subscribeTo(botStateSubscriptions, callback),
  subscribeToBotEvents: callback =>
    subscribeTo(botEventSubscriptions, callback),
  subscribeToBotFALTAS: callback =>
    subscribeTo(botFALTASSubscriptions, callback),
  subscribeToBotUpdateProgress: callback =>
    subscribeTo(botUpdateProgressSubscriptions, callback),

  disconnectFromAnEvo: async uuid => {
    const info = botsByID[uuid]
    return info.isBridgeBot
      ? bridgeConnect.disconnectFromEvo(uuid)
      : directConnect.disconnect(uuid)
  },

  disconnectFromAllBots: async () => {
    await directConnect.disconnectAll()
    await bridgeConnect.disconnectFromAllEvos()
  },

  refreshBotInfo: async () => bridgeConnect.refreshBotStates(),

  onRenamedHack: (uuid, name) => {
    updateBotState({
      uuid,
      state: { name },
    })
  },

  enableBridgeSingleBotPerformanceMode: async enable =>
    bridgeConnect.enableSingleBotPerformanceMode(enable),

  updateBot: async ({
    manifest,
    uuid,
    onReconnectBLEReconnectionNeeded,
    onReconnectionChosen = undefined,
    forceUpdate = false,
    onProgress,
  }) => {
    if (currUpdatingBot) {
      throw new Error('Busy')
    }
    currUpdatingBot = uuid

    const info = infoForUUID(uuid)
    const needToAdjustUUID =
      !info.isBridgeBot && !info.state.firmware.IsAtLeast(new Version(1,15,0))
    const onReconnectNeeded = async (_, phase) =>
      new Promise(async (resolve, reject) => {
        const unsubscribe = subscribeTo(botStateSubscriptions, update => {
          const state = update.state
          // HACK: 1.12 FW bots don't have UUID, so just grab the UUID of the connected bot
          if (needToAdjustUUID) {
            uuid = state.uuid
          }
          if (state.connected) {
            if (info.isBridgeBot) {
              if (state.uuid === uuid) {
                unsubscribe()
                resolve(infoForUUID(state.uuid).evo)
              }
            } else {
              directConnect.disableReconnect(uuid, false)
              unsubscribe()
              if (state.uuid === uuid) {
                resolve(infoForUUID(state.uuid).evo)
              } else {
                reject(new Error(`Reconnected to wrong Evo`))
              }
            }
          }
        })

        if (!info.isBridgeBot) {
          try {
            let isReconnected = false;

            while(!isReconnected) {
              await onReconnectBLEReconnectionNeeded(phase)

              try {
                await directConnect.request()
                
                isReconnected = true;
              } catch(e) {
                console.log("Reconnection error: "+e);
              }
            }

            onReconnectionChosen && onReconnectionChosen(true)
          } catch (e) {
            unsubscribe()
            onReconnectionChosen && onReconnectionChosen(false)
            reject(e)
          }
        }
      })

    const onDisconnectBeginning = async () => {
      if (!info.isBridgeBot) {
        // disable reconnect with can conflict with the bot restarts in the update flow
        directConnect.disableReconnect(uuid, true)
      }
    }
    try {
      await updateEvo({
        manifest,
        evo: info.evo,
        onReconnectNeeded,
        onDisconnectBeginning,
        onProgress,
        logID: uuid,
        forceUpdate,
      })
    } finally {
      currUpdatingBot = undefined
    }
  },

  // WARNING -- SHOULD ONLY be used if update keeps failing, and we want to "reset"
  // things for another update try. WILL need to reconnect bot afterwards.
  // !!This WILL invalidate any seal file, and delete the firmware file on the flash drive!!
  forceEvoFirmwareRestartDestructive: async uuid => {
    // Since we can't power cycle when bot is connected to a charger, we'll hijack the
    // firmware update functionality, which will restart the firmware at least. We'll
    // first delete the firmware file, so that this restart doesn't try to actually
    // install anything.
    const info = infoForUUID(uuid)
    await info.evo.faltas.deleteFile('/system/firmware/firmware.ozo')
    await info.evo.UpdateFirmware()
  },

  currUpdatingBot: () => currUpdatingBot,

  botUUIDs: () => Object.keys(botsByID),

  bridgeBotsToKeepConnected: () => bridgeConnect.evosToKeepConnected(),

  botStateForUUID: uuid => botsByID[uuid] && { ...botsByID[uuid].state },

  // HACK: used for legacy BotCamp stuff, remove once integrated with sagas
  botForUUID: uuid => {
    try {
      return botsByID[uuid].evo
    } catch {
      logger.warn('No evo for ' + uuid)
    }
  },

  getManifest: async () => {
    const FW = {
      tag: 'latest-v3',
      // tag: 'ASSET-32', // dummy downgrade to 3.0.3 (prod,dev) - compatible with both Evo and Jot15b
      // tag: 'ASSET-31', // dummy downgrade to 2.0.11 (prod,dev) - Evo only!
      url: 'https://static.ozobot.com/bot-assets/' // process.env.FW_UPDATER_BASE_URL
    }

    return await getManifestOverHttp(FW.tag, FW.url)
  },

  getBriefing: async (uuid, manifest) => {
    const info = infoForUUID(uuid);
    return await assessUpdateNeed(info.evo, manifest)
  },

  getDeviceName: async (uuid) => {
    const info = infoForUUID(uuid);
    return await info.evo.get_deviceName()
  },
}
