import React, { useState } from 'react'
import { Text, View, TouchableOpacity, ScrollView } from 'react-native'
import { Image } from 'components/ReactNative'
import VideoPlayer, { VideoPreview } from 'components/NewVideoPlayer'
import s from 'styles'
import LessonViewLayout from 'components/Layout/LessonViewLayout'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const VideoEntry = ({
  index,
  currentId,
  video,
  onPlay,
  onTitle,
  playing,
  dark,
  isDesktop,
  isTablet,
  isMobile
}) => {
  const isCurrent = video.videoID === currentId

  const templates = {
    desktop: {
      view: [s.h80, s.py8],
      video: [
        { width: 116, height: 64 }
      ],
      text: [s.f16],
      icon: { width: 10, height: 10 },
      iconText: [s.f16],
    },
    tablet: {
      view: [s.h120, s.py16],
      video: [
        { width: 145, height: 86 }
      ],
      text: [s.f16, s.pt4],
      icon: { width: 13, height: 15 },
      iconText: [s.f16],
    },
    mobile: {
      view: [s.h80, s.py8],
      video: [
        { width: 100, height: 64 }
      ],
      text: [s.f14, s.pt4],
      icon: { width: 10, height: 10 },
      iconText: [s.f14],
    },
  }

  const template = isDesktop
    ? templates.desktop
    : isTablet
      ? templates.tablet
      : templates.mobile

  return (
    <View
      style={[
        s.px8,
        s.flexRow,
        s.alignStretch,
        isCurrent
          ? s.bgGrayEvenLessLighter
          : s.bgGrayMoreLighter,
        ...template.view
      ]}
    >
      <TouchableOpacity activeOpacity={1} onPress={() => onPlay(video)} style={[s.alignStretch, s.w32, s.justifyCenter, s.pl16]}>
        {isCurrent
          ? <Image
              source={
                playing
                  ? require('images/OC-Student_pause-chapter.svg')
                  : require('images/lesson-detail-play.svg')
              }
              style={{
                resizeMode: 'contain',
                ...template.icon
              }}
            />
          : <View style={[template.icon, isTablet ? s.pb24 : s.pb20]}>
              <Text style={[...template.iconText]}>{index + 1}</Text>
            </View>
        }
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onPlay(video)}
        style={[
          s.br8,
          s.mx8,
          s.alignCenter,
          s.justifyCenter,
          ...template.video,
        ]}
      >
        <VideoPreview video={video}/>
      </TouchableOpacity>
      <TouchableOpacity
        style={[s.justifyStart, s.flex1, s.alignStretch]}
        onPress={() => {
          if(!isCurrent) {
            onTitle(video)
          }
        }}
      >
        <Text
          style={[s.ml4, dark ? s.textBlackLighter : s.textBold, ...template.text]}
        >
          {video.title}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const VideoDropDownList = ({
  videos,
  title,
  playing,
  onPlay,
  onTitle,
  currentId,
  dark
}) => {
  const {isDesktop, isTablet, isMobile} = useDetectDeviceType()
  const [ collapsed, setCollapsed ] =  useState(false)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const currVideoNumber = videos.findIndex(v => v.videoID === currentId) + 1

  return (
    <View style={[
      s.flexColumn,
      s.alignStretch,
      s.pt24,
      isDesktop && s.br10,
      s.bgGrayMoreLighter,
      isDesktop && { maxWidth: 500 }
    ]}>
      <TouchableOpacity
        style={[s.alignStart, s.justifyStart, s.pb8, s.px24, !collapsed && s.bBottom1, s.bGrayLighter]}
        onPress={toggleCollapsed}
      >
        <View style={[s.flexRow, s.flex1, s.justifyBetween, s.alignStart, s.w100p, s.pb4]}>
          <View style={[s.alignStretch, s.flex1]}>
            <Text style={[s.textBold, s.f20, s.mb4]} numberOfLines={1} ellipsizeMode='tail'>Explore by Chapters</Text>
            <Text style={[s.f16]} numberOfLines={1} ellipsizeMode='tail'>{title}</Text>
            <Text style={[s.f16, s.textLight]} numberOfLines={1} ellipsizeMode='tail'>{'' + currVideoNumber + ' / ' + videos.length}</Text>
          </View>
          <Image
            source={
              collapsed
                ? require('images/dropdown-icon-collapsed.svg')
                : require('images/dropdown-icon-expanded.svg')
            }
            style={[{width: 16, height: 10, margin: 4}]}
          />
        </View>
      </TouchableOpacity>
      {!collapsed &&
        <ScrollView contentContainerStyle={[isDesktop && { maxHeight: 700 }, s.mt8]}>
          {videos.map((video, index) => (
            <VideoEntry
              key={video.videoID}
              video={video}
              index={index}
              playing={playing}
              onPlay={onPlay}
              onTitle={onTitle}
              currentId={currentId}
              dark={dark}
              isDesktop={isDesktop}
              isTablet={isTablet}
              isMobile={isMobile}
            />
          ))}
        </ScrollView>
      }
    </View>
  )
}

export default function VideoWithPlaylist(props) {
  const [id, setId] = useState(props.id)
  const [playing, setPlaying] = useState(false)
  const [requestPlaying, setRequestPlaying] = useState(false)
  const {isDesktop} = useDetectDeviceType()

  let videoIndex = props.list.findIndex(video => video.videoID === id)
  if (videoIndex < 0) {
    videoIndex = 0
  }
  const video = props.list[videoIndex]

  function onChapter(index) {
    if (index >= 0 && index < props.list.length) {
      const video = props.list[index]
      setId(video.videoID)
    }
  }

  function onPlay(video) {
    if (video.videoID === id) {
      setRequestPlaying(!requestPlaying)
    } else {
      setRequestPlaying(true)
      setId(video.videoID)
    }
  }

  function onTitle(video) {
    if (video.videoID !== id) {
      setPlaying(false)
      setId(video.videoID)
    }
  }

  function handlePlay(isPlaying) {
    setPlaying(isPlaying)
    setRequestPlaying(isPlaying)
  }

  function handleEnded() {
    let found = false
    props.list.forEach((video, index) => {
      if (!found && video.videoID === id) {
        found = 1
        if (props.list.length > index + 1) {
          setId(props.list[index + 1].videoID)
        }
      }
    })
  }

  return <LessonViewLayout
    useDesktopLayout={isDesktop}
    imageContent={(
      <View style={[s.bShadow]}>
        <VideoPlayer
          video={video}
          autoHeight={!props.height}
          onEnded={handleEnded}
          onProgress={props.onUpdateVideoProgress}
          onChapter={onChapter}
          onPlay={handlePlay}
          chapterNumber={videoIndex}
          requestPlaying={requestPlaying}
        />
      </View>
    )}
    mainRollContent={isDesktop
      ? props.children
      : <View>
        <VideoDropDownList
          title={props.title}
          videos={props.list}
          playing={playing}
          onPlay={onPlay}
          onTitle={onTitle}
          currentId={video.videoID}
          dark={props.dark}
        />
        {props.children}
      </View>
    }
    secondaryRollContent={(isDesktop
      ? <View>
        <View style={[s.mb32]}>
        <VideoDropDownList
          title={props.title}
          videos={props.list}
          playing={playing}
          onPlay={onPlay}
          onTitle={onTitle}
          currentId={video.videoID}
          dark={props.dark}
        />
        </View>
        {props.secondaryRollContent}
      </View>
      : <View>{props.secondaryRollContent}</View>
    )}
  />
}
