import * as React from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import Button from 'components/Form/Button2'
import { modalShow } from 'store/modal'
import s from 'styles'
import { CheckLink } from 'components/CheckLink'

export default function FreeDemoButton(props: {onPress?: () => void}) {
  const dispatch = useDispatch()
  const demoLink = 'https://share.hsforms.com/1gtK8xxXnQouwodgNQI1UWgnpl1g'
  return (
    <View style={[s.flexRow, s.alignCenter]}>
      <Button
        role='callout'
        text='Free Virtual Demo'
        small={true}
        onPress={() => {
          dispatch(modalShow(<CheckLink link={demoLink}/>))
          if(props.onPress) {
            props.onPress()
          }
        }}
      />
      <View style={s.w40} />
    </View>
  )
}
