import React, { useState } from 'react'
import { View, Text } from 'react-native'
import validate from 'validate.js'
import { isEmpty, pick } from 'lodash'
import validations from 'lib/validations'
import Button from 'components/Form/Button2'
import Input from 'components/Form/Input2'
import Dropdown from 'components/DropDown/DropDown'
import s from 'styles'
import { useSelector, useDispatch } from 'react-redux'
import ErrorMessages from 'components/Form/ErrorMessages'
import EducatorImage from './components/EducatorImage'
import { EditPicture } from 'components/EditPicture'
import { Footer } from './account'
import { userPDCreditsSelector } from 'store/user'
import { PDCredit } from '../help/educator-training'
import { openURL } from 'lib/utils'
import { Column, Row } from 'react-native-web-ui-components'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

interface User {
  firstName: string,
  lastName: string,
  jobTitle: string,
  school: string,
  district: string,
  email: string,
  phone: string,
  accountId: string,
  profileImageUrl: string,
  userUpdateRequest: (myobj: { [name: string]: string }) => unknown,
}

interface Errors {
  firstName?: string,
  lastName?: string,
  jobTitle?: string,
  district?: string,
  school?: string,
  email?: string,
  phone?: string,
  profileImageUrl?: string,
}

const rolesList = [
  'Assistant Superintendent',
  'Camp/Center Organizer',
  'Chief Academic Officer',
  'Chief Innovation Officer/Director of Innovation',
  'Curriculum Coordinator',
  'Director of Information Technology / Technology Director',
  'Director Professional Staff Development / Supervisor of Professional Development',
  'District Administrator',
  'Educational Technology Specialist',
  'Instructional Coach',
  'Librarian',
  'Media Specialist',
  'PTA',
  'School Administrator',
  'STEM Coach',
  'Teacher',
  'Technology Coach',
  'Other',
]

interface DisplayLinkType {
  url: string,
  text: string,
}

function DisplayLink({ url, text }: DisplayLinkType) {
  if (!url) return null
  return (
    <View style={[s.flexRow, s.alignCenter, s.mb8]}>
      <View
        style={[
          s.justifyCenter,
          s.alignCenter,
          s.h10,
          s.w10,
          s.br10,
          s.bgGreen,
          s.mr16,
        ]}
      />
      <Text onPress={() => openURL(url, true)} numberOfLines={1} style={[s.f16, s.textBold, s.textGreen, s.textUnderline]}>{text}</Text>
    </View>
  )
}


interface PDCreditDisplayType {
  credit: Array<PDCredit>
}

function PDCreditDisplay({ credit }: PDCreditDisplayType) {
  return (
    <View style={[s.my32]}>
      <Text style={[s.f16, s.textBlack, s.textBold, s.mt32, s.mb16]}>PD Certificates</Text>
      {credit.map((entry, index) =>
        (<DisplayLink key={index} url={entry.certificateUrl} text={entry.title} />))}
    </View>
  )
}

function Profile({
  firstName,
  lastName,
  jobTitle,
  school,
  district,
  email,
  phone,
  accountId,
  profileImageUrl,
  userUpdateRequest,
}: User) {
  const dispatch = useDispatch()
  const [user, updateUser] = useState({
    firstName,
    lastName,
    jobTitle,
    school,
    district,
    email,
    phone,
    accountId,
  })
  const [errors, updateErrors] = useState<Errors>({} as Errors)
  const { isDesktop } = useDetectDeviceType();

  const onFormInputChange = obj => {
    updateUser({
      ...user,
      ...obj,
    })
  }

  const submit = () => {
    const errors = validate(
      user,
      pick(validations, ['firstName', 'lastName']),
      {
        fullMessages: false,
        format: 'grouped',
      },
    )

    if (!isEmpty(errors)) {
      updateErrors(
        Object.keys(errors).reduce((prev, curr) => {
          prev[curr] = errors[curr][0]
          return prev
        }, {}),
      )
    } else {
      updateErrors({})
      dispatch(userUpdateRequest({
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle?.length ? user.jobTitle : null,
        district: user.district,
        school: user.school,
        email: user.email,
        phone: user.phone,
        id: user.accountId,
        successMessage: 'Your profile changes have been saved.',
      }))
    }
  }

  const credit = useSelector(userPDCreditsSelector) as Array<PDCredit>

  return (
    <View style={[s.h100p, s.justifyBetween]}>
      <View style={s.flexRow}>
        <Row style={[s.justifyBetween]}>
          <Column xs={12} md={6}>
            <View style={s.flex1}>
              <View style={[ isDesktop ? s.w75p : s.w100p, s.mb8, s.zIndex2]}>
                <ErrorMessages errorMessages={Object.values(errors)} />
                <Text style={[s.pb4]}>First Name</Text>
                <Input
                  isError={!!errors.firstName}
                  name="firstName"
                  setValue={str => onFormInputChange({ firstName: str })}
                  value={user.firstName}
                />
                <Text style={[s.pb4]}>Last Name</Text>
                <Input
                  isError={!!errors.lastName}
                  name="lastName"
                  setValue={str => onFormInputChange({ lastName: str })}
                  value={user.lastName || ''}
                />
                <Text style={[s.pb4]}>School</Text>
                <Input
                  name="school"
                  setValue={str => onFormInputChange({ school: str })}
                  value={user.school || ''}
                />
                <Text style={[s.pb4]}>District</Text>
                <Input
                  name="district"
                  setValue={str => onFormInputChange({ district: str })}
                  value={user.district || ''}
                />
                <Text style={[s.pb4]}>Role</Text>
                <View style={[s.positionRelative, s.zIndex3, s.h55]}>
                  <Dropdown
                    name="jobTitle"
                    setValue={str => onFormInputChange({ jobTitle: str })}
                    value={user.jobTitle ? rolesList.includes(user.jobTitle) ? user.jobTitle : 'Other' : ''}
                    title={''}
                    options={rolesList}
                  />
                </View>
                <Text style={[s.pb4]}>Phone</Text>
                <Input
                  isError={!!errors.phone}
                  name="phone"
                  setValue={str => onFormInputChange({ phone: str })}
                  value={user.phone || ''}
                />
              </View>
            </View>
          </Column>
          <Column xs={12} md={4} style={[isDesktop ? s.h100p : null]}>
            <View style={[s.flex1, s.bgGrayMoreLighter, s.bShadow, s.p32, !isDesktop ? s.mt45 : null]}>
              <Text style={[s.f16, s.textBlack, s.textBold, s.mt16, s.mb20]}>
                Profile Photo <Text style={[s.f14, s.textBlack, s.textNormal, s.textItalic]}> (1MB max).</Text>
              </Text>
              <View style={[s.flexRow, s.alignCenter, s.mAuto]} >
                <EducatorImage
                  name={user.firstName.charAt(0) + user.lastName.charAt(0)}
                  image={profileImageUrl}
                  font={s.f16}
                  size={150}
                />
              </View>
              <View style={[s.ml16]}>
                <View >
                  <EditPicture imageUrl={profileImageUrl} accountId={user.accountId} userUpdateRequest={userUpdateRequest} />
                </View>
              </View>
              {credit?.length > 0 && < PDCreditDisplay credit={credit} />}
            </View>
          </Column>
        </Row>
      </View>
      <View style={[s.alignCenter, s.flexRow, s.justifyEnd, s.my16]}>
        <Button text="Save" onPress={submit} />
      </View>
      <Footer />
    </View>
  )
}

export default Profile
