import * as React from 'react'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxCircle: {
    width: 18,
    height: 18,
    borderRadius: 12, // Make it fully rounded
    borderWidth: 2, // Add a border for the circle
    borderColor: 'grey',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmarkIcon: {
    color: 'white',
    fontSize: 10,
  },
  checkedCircle: {
    backgroundColor: 'grey', // Change the background color when checked
    borderColor: 'grey', // Change the border color when checked
  },
  checkedIcon: {
    color: 'grey', // Change the checkmark color when checked
  },
  label: {
    marginLeft: 8,
    fontSize: 16,
  },
});


interface CheckBoxItemProps {
  isChecked: boolean,
  label?: string
  name: string,
  onChange: Function,
  value: string,
}

const CheckBoxItemRounded: React.FC<CheckBoxItemProps> = ({
  label,
  name,
  value,
  isChecked,
  onChange,
}: CheckBoxItemProps) => {
  const onPress = () => onChange({ [name]: [value] })

  return (
    <TouchableOpacity activeOpacity={1} onPress={onPress}>
      <View style={styles.checkboxContainer}>
        <View style={[styles.checkboxCircle, isChecked && styles.checkedCircle]}>
          {isChecked && <Icon name="check" style={[styles.checkmarkIcon, isChecked && styles.checkedIcon]} />}
        </View>
        {!!label && <Text style={styles.label}>{label}</Text>}
      </View>
    </TouchableOpacity>
  );
}

export default CheckBoxItemRounded
