import * as React from 'react'
import { Text, Image, View } from 'react-native'
import { NumberChooser,NumberChooserChangeCallback } from 'components/NumberChooser2'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
const botImage = {
  evo: require('images/DevicesSingleBotEvo.png'),
  bit: require('images/DevicesSingleBotBit.png'),
  occ: require('images/settings-devices-communicator.png'),
}

export type BotChooserChangeCallback = NumberChooserChangeCallback
interface BotCounterType {
  label: string,
  id: string,
  count: number,
  onChange: BotChooserChangeCallback,
}

export function BotCounter({ label, id, count, onChange }: BotCounterType) {
  const { isDesktop } = useDetectDeviceType();
  return (
    <View style={isDesktop ? s.mr20 : s.mt20}>
      <View
        style={[
          s.bGrayLighter,
          s.b1,
          s.br5,
          s.mb8,
          s.justifyCenter,
          s.alignCenter,
          {
            height: 125,
            width: 125,
          },
        ]}
      >
        <Image
          source={botImage[id]}
          style={[
            {
              height: 112,
              width: 113,
            },
          ]}
        />
      </View>
      <Text style={[s.mb16, s.f16, s.textBold, s.textCenter]}>{label}</Text>
      <NumberChooser
        name={id + 'Count'}
        count={count}
        onChange={onChange}
      />
    </View>
  )
}
