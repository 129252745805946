
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View, Text, Image } from 'react-native'
import Button from 'components/Form/Button2'
import s from 'styles'
import { LessonType } from 'components/LessonTypes'
import { OZOBOT_AUTHOR_ID } from 'lib/utils.js'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { lessonsActions, lessonsResultsSelector, lessonsIsFetchingSelector } from 'store/lessons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { openRoute } from './../utils'
import { openLink } from 'pages/util'

const AuthorSubjectsAndSaves = ({ lesson, isDesktop, isMobile } : { lesson: LessonType, isDesktop: boolean, isMobile: boolean }) => {
  const DotText = (props: { text: string, maxWidth?: number, isMobile: boolean }) => (
    <View style={[s.flexRow, s.flexShrink1, s.mr8, props.maxWidth && { maxWidth: props.maxWidth }]}>
      <Text style={[ isDesktop ? s.f12 : isMobile ? s.f14 : s.f16, s.textLight, s.textGrayDarker ]} numberOfLines={1} ellipsizeMode='tail'>{props.text}</Text>
    </View>
  )
  return (
    <View style={[ s.flexRow, s.flex1, isMobile ? s.mt4 : s.mt8 ]}>
      <DotText text={lesson.authorId === OZOBOT_AUTHOR_ID ? 'Ozobot' : 'Community Lesson'} isMobile={isMobile}/>
      <DotText text={'\u2022'} isMobile={isMobile}/>
      <DotText text={'' + lesson.userSaveCount + ' saved'} isMobile={isMobile}/>
    </View>
  )
}

const LessonEntry = ({ lesson, key, isDesktop, isMobile }: { lesson: LessonType, key: number, isDesktop: boolean, isMobile: boolean }) => {
  const openLesson = () => openRoute('/lessons/' + lesson.id)
  const imageSrc = { uri: lesson.coverImageUrl }
  const shadowProps = {
    shadowColor: 'rgba(0,0,0,0.20)',
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
    shadowOpacity: 1.0,
  }
  const imageSize = isMobile ? 200 : 144
  return isMobile
    ? (
      <TouchableOpacity
        style={[s.flexColumn, s.alignStretch, s.flex1, s.mt24]}
        key={key}
        onPress={openLesson}
      >
        <Image
          source={imageSrc}
          style={{
            resizeMode: 'cover',
            width: imageSize,
            height: imageSize,
            aspectRatio: 1,
            borderRadius: isMobile ? 8 : 5,
            ...shadowProps,
          }}
        />
        <Text style={[s.f14, s.flexShrink1, s.mt8]} numberOfLines={1} ellipsizeMode='tail'>{lesson.title}</Text>
        <AuthorSubjectsAndSaves
          lesson={lesson}
          isDesktop={isDesktop}
          isMobile={isMobile}
        />
      </TouchableOpacity>
    )
    : (
      <TouchableOpacity
        style={[s.flexRow, s.flex1, s.my8]}
        key={key}
        onPress={openLesson}
      >
        <Image
          source={imageSrc}
          style={{
            width: 150,
            height: 150,
            resizeMode: 'cover',
            borderRadius: 8,
            ...shadowProps,
          }}
        />
        <View style={[s.flexColumn, s.flex1, s.alignStretch, s.ml16]}>
          <Text style={[s.f16, s.flexShrink1]}>{lesson.title}</Text>
          <AuthorSubjectsAndSaves
            lesson={lesson}
            isDesktop={isDesktop}
            isMobile={isMobile}
          />
        </View>
      </TouchableOpacity>
    )
}

const ExploreMoreLessons = () => {

  const lessons = useSelector(lessonsResultsSelector)
  const isFetching = useSelector(lessonsIsFetchingSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if(lessons.length === 0 && !isFetching) {
      dispatch(lessonsActions.searchRequest({
        query:'',
        sort:'Newest',
        page:'1',
        limit:20,
        grade:[],
        subject:[],
        bots:[],
        coding:[],
        author:[],
        duration:[],
        categories:['Featured Lessons'],
        key: 'query=&sort=Newest&page=1&limit=20&categories=Featured%20Lessons',
      }))
    }
  }, [lessons, isFetching])

  const { isDesktop, isMobile } = useDetectDeviceType()

  return (
    <View style={[s.flexColumn, s.flex1, s.alignStretch]}>
      <Text style={[s.f20, s.textBold]}>Explore more lessons</Text>
      {lessons.slice(0, isMobile ? 5 : 9).map((l, k) => (
        <LessonEntry lesson={l} key={k} isDesktop={isDesktop} isMobile={isMobile}/>
      ))}
      <View style={[s.alignSelfCenter, s.mt20]}>
        <Button
          text={'Show more...'}
          role={'secondary'}
          onPress={() => openLink('/lessons', dispatch)}
        />
      </View>
    </View>
  )
}

export default ExploreMoreLessons
