import React, { memo } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import s from 'styles'

function displayTime(totalseconds) {
  var sec_num = parseInt(totalseconds, 10) // don't forget the second param
  var minutes = Math.floor(sec_num / 60)
  var seconds = sec_num - minutes * 60

  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return minutes + ':' + seconds
}

const Label = ({ text, ...restProps }) => {
  return (
    <View style={styles.root} {...restProps}>
      <Text style={[s.textWhite, s.textCenter, s.f16]}>
        {displayTime(text)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#252932',
    borderRadius: 2,
    width: 54,
    height: 24,
  },
})

export default memo(Label)
