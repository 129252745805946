import { push, replace } from 'connected-react-router'
import { eventChannel, buffers } from 'redux-saga'
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLeading,
  throttle,
  debounce,
} from 'redux-saga/effects'
import { persistor } from '../../globalstore'
import api from 'lib/api'
import { closeTopDialog, showProgressDialog } from '../dialogBox'
import { modalShow, modalHide } from 'store/modal'
import { modalShow as modalShow2, modalHide as modalHide2 } from 'store/modal'
import {
  showTermsPrivacyModal,
  showCancelSignUp,
} from 'pages/account/loginModals'
import { emailNotificationSentModal } from 'pages/settings/accountModals'
import { makeNotificationModal as notificationModal } from 'components/modals/NotificationModal'
import { makeAlertModal as alertModal } from 'components/modals/AlertModal'
import { makeProgressModal as progressModal } from 'components/modals/ProgressModal'
import { makeSignInErrorModal as signInErrorModal } from 'components/modals/SignInErrorModal'
import {
  accountIdSelector,
  signedInSelector,
  userAuthCheckIfSignedIn,
  userAuthCheckIfSignedOut,
  userAuthCheckIfVerified,
  userAuthCodeRequest,
  userAuthCodeSuccess,
  userAuthEmailSettingsFailure,
  userAuthEmailSettingsRequest,
  userAuthEmailSettingsSuccess,
  userAuthEmailSignInRequest,
  userAuthEmailSignInSuccess,
  userAuthGoogleLinkFailure,
  userAuthGoogleLinkRequest,
  userAuthGoogleLinkSuccess,
  userAuthGoogleRedirectedToURL,
  userAuthGoogleSignInFailure,
  userAuthGoogleSignInRequest,
  userAuthGoogleSignInSuccess,
  userAuthSignoutRequest,
  userAuthUnlinkRequest,
  userAuthUnlinkSuccess,
  userAwardPDCreditRequest,
  userAwardPDCreditSuccess,
  userCreateFailure,
  userCreateRequest,
  userCreateSuccess,
  userDeleteRequest,
  userDeleteSuccess,
  userError,
  userEventRequest,
  userPasswordChangeRequest,
  userPasswordChangeSuccess,
  userPasswordForgotRequest,
  userPasswordForgotSuccess,
  userPasswordResetRequest,
  userPasswordResetSuccess,
  userSetMiscValuesFailure,
  userSetMiscValuesRequest,
  userSetMiscValuesSuccess,
  userUpdateRequest,
  userUpdateSuccess,
  userVerificationStatusUpdated,
  userVerifyEmailRequest,
  userVerifyEmailSuccess,
  userViewRequest,
  userViewSuccess,
  useSendVerificationEmailRequest,
  useSendVerificationEmailSuccess,
  userProductUnlockRequest,
  userProductUnlockSuccess,
  userProductUnlockFailure,
} from 'store/user'
import { notificationsActions } from '../notifications'

// function* onUpdateProgress({ namespace, progress }) {
//   try {
//     const accountId = yield select(accountIdSelector)
//     yield call(api.updateUserProgression, {
//       accountId,
//       namespace,
//       progress,
//     })
//     // This seems to be causing race conditions -- we should have a separate event for update success, if we want to sync...
//     // yield put({ type: USER_REFRESH_PROGRESS_SUCCESS, namespace, progress })
//   } catch (err) {
//     yield put({ type: 'USER_UPDATE_PROGRESS_ERROR', message: err.apiMessage })
//   }
// }

function* resetState() {
  yield call([persistor, persistor.purge])
  yield call([persistor, persistor.persist])
  yield put({ type: 'RESET' }) // from redux-reset
}

function* handleAPISubscriptions() {
  const _USER_SIGNED_OUT = '_USER_SIGNED_OUT'
  const _USER_VERIFICATION_STATUS_UPDATED = '_USER_VERIFICATION_STATUS_UPDATED'
  const channel = eventChannel(emitter => {
    api.subscribeUserSignedOut(causedExternally => {
      emitter({
        type: _USER_SIGNED_OUT,
        causedExternally,
      })
    })

    api.subscribeVerificationStatus((providerIsVerified, roles) => {
      emitter({
        type: _USER_VERIFICATION_STATUS_UPDATED,
        providerIsVerified,
        roles,
      })
    })

    // FIXME: this should return cleanup code
    return () => {}
  }, buffers.fixed(32))

  while (true) {
    const action = yield take(channel)
    if (action) {
      if (action.type === _USER_SIGNED_OUT) {
        const weThinkWeAreSignedIn = yield select(s => !!s.user.accountId)
        if (weThinkWeAreSignedIn) {
          yield resetState()
          yield put(
            replace('/login/educator', {
              search: '',
            })
          )
          if (action.causedExternally) {
            yield put(
              modalShow(
                notificationModal({
                  title: 'Log Back into Your Account',
                  text:
                    'For security reasons, you were logged out of your Ozobot Classroom account. Please close any other open Ozobot Classroom windows and log back in.',
                  buttonText: 'Got It',
                })
              )
            )
          }
        }
      } else if(action.type == _USER_VERIFICATION_STATUS_UPDATED) {
        yield put(userVerificationStatusUpdated({ ...action }))
      }
    }
  }
}

function* checkUserCreationAgreement() {
  // keep trying until user definitely cancels
  let result = undefined
  while (!result) {
    yield put(
      modalShow2(
        showTermsPrivacyModal(r => {
          result = r
        })
      )
    )
    yield take(modalHide2)
    if (!result) {
      let cancel = true
      yield put(
        modalShow2(
          showCancelSignUp(() => {
            cancel = false
          })
        )
      )
      yield take(modalHide2)
      if (cancel) {
        break
      }
    }
  }
  return result
}

function* onUserCreateRequest({ payload }) {
  try {
    // make sure that email does not exist
    if (payload.provider === 'email') {
      const check = yield call(api.userAuthEmailProviderExists, {
        email: payload.credential.email,
      })
      if (check.exists) {
        yield put(
          userCreateFailure({ error: 'Email exists', emailExists: true })
        )
        // email exists, put error
        return
      }
    }

    // ask for terms and conditions
    const accept = yield checkUserCreationAgreement()
    if (!accept) {
      yield put(userCreateFailure({ error: 'Cancelled', cancelled: true }))
      // email exists, put error
      return
    }

    yield put(showProgressDialog())
    const userResult = yield call(api.userCreate, {
      ...payload,
      receiveEmails: accept.subscribe,
    })
    const signInResult = yield call(api.userAuthSignIn, payload)
    yield put(userAuthEmailSignInSuccess(signInResult))
    yield put(
      userCreateSuccess({
        roles: signInResult.roles,
        ...userResult,
      })
    )
    yield put(closeTopDialog())
    yield put(push(payload.postAuthRedirect || '/dashboard'))
  } catch (err) {
    yield put(closeTopDialog())
    if (err.status === 409) {
      yield put(
        modalShow(
          alertModal({
            headline: 'Sign up error',
            text: 'Sorry, it looks like a user with this email already exists!',
          })
        )
      )
    } else {
      console.error(err)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
    yield put(userCreateFailure({ error: err.toString() }))
  }
}

function* onUserViewRequest({ payload }) {
  try {
    const accountId = yield select(s => s.user.accountId)
    const result = yield call(api.userView, { id: accountId })
    yield put(userViewSuccess(result))
  } catch (err) {
    console.error(err)
    yield put(userError(err))
  }
}

function* onUserUpdateRequest({ payload }) {
  try {
    const id = yield select(({ user }) => user.accountId)
    const result = yield call(api.userUpdate, { id, ...payload })
    yield put(userUpdateSuccess(result))
    if (payload.successMessage) {
      yield put(
        modalShow(
          alertModal({
            text: payload.successMessage,
            buttonText: 'Ok',
          })
        )
      )
    }
  } catch (err) {
    console.error(err)
    yield put(
      modalShow(
        alertModal({
          text: payload.errorMessage || err.message,
          buttonText: 'Try Again',
        })
      )
    )
    // HACK: the following is to handle the "Try Again" aspect, where we need to re-show the dialog that called this function if there
    // is an error...
    yield take(modalHide)
    if (payload.onError) {
      yield call(payload.onError)
    }
  }
}

function* onUserSetMiscValuesRequest({ payload }) {
  try {
    const [id, misc] = yield select(({ user }) => [user.accountId, user.misc])
    const result = yield call(api.userUpdate, { id, misc })
    yield put(userSetMiscValuesSuccess(result.misc))
  } catch (err) {
    console.error(err)
    yield put(
      userSetMiscValuesFailure({
        misc: payload,
        error: err,
      })
    )
  }
}

function* onUserDeleteRequest({ payload }) {
  try {
    yield call(api.userDelete, payload)
    yield put(userDeleteSuccess())
    yield put(push('/account/login'))
  } catch (err) {
    yield put(userError(err))
  }
}

function* onUserEventRequest({ payload }) {
  try {
    const accountId = yield select(accountIdSelector)
    yield call(api.sendAnalyticEvent, {
      type: payload.eventType,
      data: payload.eventData,
      common: { accountId },
      timestamp: payload.timestamp,
    })
  } catch (err) {
    console.error(err)
  }
}

function* onUserAuthEmailSignInRequest({ payload }) {
  try {
    yield put(showProgressDialog())
    const signInResult = yield call(api.userAuthSignIn, {
      provider: 'email',
      credential: payload,
    })
    const userResult = yield call(api.userView, { id: signInResult.accountId })
    yield put(
      userViewSuccess({
        roles: signInResult.roles,
        ...userResult,
      })
    )
    yield put(userAuthEmailSignInSuccess(signInResult))
    yield put(push(payload.postLoginRedirect || '/dashboard'))
    yield put(closeTopDialog())
  } catch (err) {
    yield put(closeTopDialog())
    if (err.status === 403) {
      yield put(modalShow(signInErrorModal()))
    } else {
      console.error(err)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}

function* onUserAuthGoogleSignInRequest({ payload }) {
  try {
    yield call(api.userAuthGoogleSignIn, {
      state: {
        postAuthRedirect: payload.postLoginRedirect,
        postAuthAction: payload.postLoginAction,
      },
    })
  } catch (err) {
    // User closed modal
    if (err.error !== 'popup_closed_by_user') {
      console.error(err.error)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}

function* onUserAuthGoogleLinkRequest({ payload }) {
  try {
    yield call(api.userAuthGoogleSignIn, {
      state: {
        postAuthRedirect: payload.postLoginRedirect,
        postAuthAction: payload.postLoginAction,
      },
      linkToCurrentAccount: true,
    })
  } catch (err) {
    // User closed modal
    if (err.error !== 'popup_closed_by_user') {
      console.error(err.error)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}

function* onUserAuthEmailSettingsRequest({ payload }) {
  const emailExistsMessage =
    'That email already exists, please use a different email.'
  const type = 'auth/settings'
  let message = undefined
  const emailNotificationSentDialog = (title, text) =>
    modalShow2(
      emailNotificationSentModal({
        title,
        headline: 'Check Your Inbox',
        text,
      })
    )
  const passwordNotification = email =>
    emailNotificationSentDialog(
      'Password Reset',
      `We sent a message to ${email} to reset your password. If you don’t receive it within a few minutes, check your spam folder.`
    )
  const verifyNotification = email =>
    emailNotificationSentDialog(
      'Email Verification',
      `We sent a message to ${email} to verify your email. If you don’t receive it within a few minutes, check your spam and junk folders.`
    )
  try {
    const { email, verifyEmails } = payload
    if (verifyEmails) {
      try {
        for (const verifyEmail of verifyEmails) {
          yield call(api.userSendVerifyEmail, { email: verifyEmail })
        }
        yield put(verifyNotification(email))
      } catch (e) {
        message = 'You cannot verify your email at this time.'
      }
    } else {
      const [accountId, prevEmail] = yield select(s => [
        s.user.accountId,
        s.user.providers.find(p => p.providerType === 'email')?.email,
      ])
      if (prevEmail) {
        if (prevEmail !== email) {
          try {
            const result = yield call(api.userUpdate, {
              id: accountId,
              email,
            })
            yield put(userUpdateSuccess(result))
            yield put(userAuthEmailSettingsSuccess(result.providers))
            yield put(verifyNotification(email))
          } catch (e) {
            console.error(e)
            message =
              e?.status === 409
                ? emailExistsMessage
                : 'You cannot change your email at this time.'
          }
        } else {
          try {
            yield call(api.userSendResetPassword, { email })
            yield put(passwordNotification(email))
          } catch (e) {
            console.error(e)
            message = 'You cannot change your password at this time.'
          }
        }
      } else {
        try {
          const providers = yield call(api.userLinkProvider, {
            provider: 'email',
            credential: { email },
          })
          yield put(userAuthEmailSettingsSuccess(providers))
          yield put(passwordNotification(email))
        } catch (e) {
          console.error(e)
          message =
            e?.status === 409
              ? emailExistsMessage
              : 'You cannot set your email at this time.'
          yield put(userAuthEmailSettingsFailure(e))
        }
      }
    }
  } catch (err) {
    yield put(userAuthEmailSettingsFailure(err))
  } finally {
    if (message) {
      yield put(
        notificationsActions.add({
          type,
          message,
        })
      )
    }
  }
}

function* onUserAuthUnlinkRequest({ payload }) {
  try {
    const providers = yield call(api.userUnlinkProvider, {
      provider: payload.providerType,
      providerId: payload.providerId,
    })
    // if there is no email provider, set user contact email to most recent provider
    const [accountId, contactEmail] = yield select(s => [
      s.user.accountId,
      s.user.email,
    ])
    if (
      !providers.find(p => p.email.toLowerCase() === contactEmail.toLowerCase())
    ) {
      // choose the most recent email address
      const email = [...providers].sort((a, b) =>
        a.creationDate > b.creationDate ? 0 : 1
      )[0].email
      const user = yield call(api.userUpdate, {
        id: accountId,
        email,
      })
      yield put(userUpdateSuccess(user))
    }
    yield put(userAuthUnlinkSuccess(providers))
  } catch (err) {
    console.error(err.error)
    yield put(
      modalShow(
        alertModal({
          title: 'Error',
          headline: err.status,
          text: err.message,
        })
      )
    )
  }
}

function* onUserAuthGoogleRedirectedToURL({ payload }) {
  let redirect = '/dashboard'
  let isLinking = false
  try {
    // clear out query string
    yield put(replace({ pathname: '/account/redirect', search: '' }))

    yield put(modalShow(progressModal()))
    const googleAuthResult = yield call(api.userAuthFinishWebGoogleSignIn, {
      url: payload.url,
      onStateParsed: (state, linking) => {
        redirect = state.postAuthRedirect || redirect
        isLinking = linking
      },
    })
    const state = googleAuthResult.state
    let user = googleAuthResult.user
    const providers = googleAuthResult.providers
    yield put(modalHide())

    if (user) {
      // if we're currently signed in, and the user doesn't match, sign out first
      // TODO: for non-web, how is this done?
      const currAccountId = yield select(accountIdSelector)
      if (currAccountId && currAccountId !== user.accountId) {
        // Question - does this reveal to other logging in users the prev user's account id, and is that an issue? -T
        console.warn(
          'Force switching from ',
          currAccountId,
          'to',
          user.accountId
        )
        yield resetState()
        yield delay(1000)
        yield put(
          modalShow(
            notificationModal({
              title: 'Account switched',
              text: `You have chosen to use a different account. If you did not intend to do this, please sign out and then sign into your previous account.`,
            })
          )
        )
        yield take(modalHide)
      }

      // handle case where we need to create a user
      if (!user.accountId) {
        // make sure that a suggested profile is present
        if (!user.profile) {
          throw new Error('Missing profile and account ID')
        }
        const accept = yield checkUserCreationAgreement()
        if (!accept) {
          // just abort -- use doesn't want to complete auth
          return
        }

        try {
          yield put(showProgressDialog())
          yield call(api.userCreate, {
            ...user.profile,
            receiveEmails: accept.subscribe,
          })
          user = yield call(api.userAuthSignIn, {
            ...user.profile,
            provider: 'authKey',
          })
        } finally {
          yield put(closeTopDialog())
        }
      }

      const userResult = yield call(api.userView, { id: user.accountId })
      yield put(
        userViewSuccess({
          roles: user.roles,
          ...userResult,
        })
      )
      yield put(userAuthGoogleSignInSuccess(user))
      if (state.postAuthAction) {
        yield put(state.postAuthAction)
      }
    } else if (providers) {
      yield put(userAuthGoogleLinkSuccess(providers))
    }
  } catch (err) {
    console.error(err)
    if (isLinking) {
      yield put(userAuthGoogleLinkFailure(err))
    } else {
      yield put(userAuthGoogleSignInFailure(err))
      // FIXME: this should be moved UI side....
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  } finally {
    // if previously signed in, we'll return to the page from which we made the call, else this will
    // redirect to the sign-in page
    yield put(push(redirect))
  }
}

function* onUserAuthCodeRequest({ payload }) {
  try {
    const result = yield call(api.userAuthCode, payload)
    yield put(userAuthCodeSuccess(result))
  } catch (err) {
    console.error(err)
    yield put(userError(err))
  }
}

function* onUserAuthSignoutRequest({ payload }) {
  try {
    // rest of sign-out handled by api subscription
    yield call(api.userAuthSignOut)
  } catch (err) {
    console.error(err)
    yield put(userError(err))
  }
}

function* onUserAuthCheckIfSignedOut({ localCheckOnly }) {
  try {
    const weThinkWeAreSignedIn = yield select(s => !!s.user.accountId)
    if (weThinkWeAreSignedIn) {
      // NOTE: this will throw an exception if user was signed out somewhere else, so that can be normal
      yield call(api.verifyUserWasNotSignedOut, localCheckOnly)
    }
  } catch (err) {
    console.warn('Signed in check', err)
  }
}

function* onUserAuthCheckIfSignedIn() {
  try {
    const weThinkWeAreSignedIn = yield select(s => s.user.accountId)
    if (!weThinkWeAreSignedIn) {
      // NOTE: this will throw an exception if user was signed out somewhere else, so that can be normal
      yield call(api.verifyUserWasNotSignedIn)
    }
  } catch (err) {
    // This is abrupt, but just reload the page -- we assume this is in response to a check by the
    // sign in screen.
    window.location.reload()
  }
}

function* onUserAuthCheckIfVerified() {
  try {
    // This call will also update the verification information
    // NOTE: this will throw an exception if user was signed out somewhere else, so that can be normal
    yield call(api.verifyUserWasNotSignedOut)
  } catch (err) {
    console.warn('Verify check', err)
  }
}

function* onUserPasswordForgotRequest({ payload }) {
  try {
    yield call(api.userSendResetPassword, payload)
    yield put(userPasswordForgotSuccess(payload))
  } catch (err) {
    console.error(err)
    yield put(userError(err))
  }
}
function* onUserPasswordResetRequest({ payload }) {
  try {
    const result = yield call(api.finishPasswordReset, payload)
    yield put(userPasswordResetSuccess(result))
    // it might be better to use a modal instead
    // of a static page for success
    yield put(push('/account/reset/success'))
  } catch (err) {
    console.error(err)
    yield put(userError(err))
    if (err.status === 403) {
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            text: err.message,
            buttonText: 'Try Again',
          })
        )
      )
    } else {
      console.error(err)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}
function* onUserPasswordChangeRequest({ payload }) {
  try {
    const result = yield call(api.userPasswordChange, payload)
    yield put(userPasswordChangeSuccess(result))
    yield put(
      modalShow(
        alertModal({
          text: payload.successMessage,
          buttonText: 'Got It',
        })
      )
    )
  } catch (err) {
    if (err.status === 403) {
      yield put(
        modalShow(
          alertModal({
            text: payload.errorMessage,
            buttonText: 'Try Again',
          })
        )
      )
    } else {
      console.error(err)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}

function* onUserVerifyEmailRequest({ payload }) {
  try {
    const result = yield call(api.finishEmailVerification, payload)
    yield put(userVerifyEmailSuccess(result))
    // it might be better to use a modal instead
    // of a static page for success
    yield put(push('/account/verify/success'))
  } catch (err) {
    console.error(err)
    yield put(userError(err))
    if (err.status === 403) {
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            text: err.message,
            buttonText: 'Try Again',
          })
        )
      )
    } else {
      console.error(err)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}

function* onUseSendVerificationEmailRequest({ payload }) {
  try {
    for (const email of payload.emails) {
      yield call(api.userSendVerifyEmail, { email })
    }
    yield put(useSendVerificationEmailSuccess())
  } catch (err) {
    console.error(err)
    yield put(userError(err))
    if (err.status === 403) {
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            text: err.message,
            buttonText: 'Try Again',
          })
        )
      )
    } else {
      console.error(err)
      yield put(
        modalShow(
          alertModal({
            title: 'Error',
            headline: err.status,
            text: err.message,
          })
        )
      )
    }
  }
}

function* onUserProductUnlockRequest({ payload }) {
  try {
    const closeModalOnSuccess = !!payload.closeModalOnSuccess
    const routeURL = payload.routeURL
    const accountId = yield select(accountIdSelector)
    const request = {
      accountId,
      ...payload
    }
    delete request.closeModalOnSuccess
    delete request.routeURL

    yield call(api.userUnlockProduct, { accountId, ...payload })
    if(closeModalOnSuccess) {
      yield put(modalHide())
    }
    if(routeURL) {
      yield put(push(routeURL))
    }
    yield put(userProductUnlockSuccess({}))
  } catch(err) {
    yield put(userProductUnlockFailure({ error: err }))
  }
}

function* onUserAwardPDCreditRequest({ payload }) {
  try {
    const accountId = yield select(accountIdSelector)
    const credit = yield call(api.createPDCredit, {
      accountId,
      ...payload,
    })
    yield put(userAwardPDCreditSuccess(credit))
  } catch (e) {
    yield put(
      userAwardPDCreditSuccess({
        ...payload,
        error: e,
      })
    )
  }
}

function* watchUserAwardPDCreditRequest() {
  yield takeEvery(userAwardPDCreditRequest, onUserAwardPDCreditRequest)
}

function* startupRefresh() {
  yield delay(2000)
  yield put(userAuthCheckIfSignedOut({ localCheckOnly: true }))
  // wait for any dust to settle on the immediate sign-out check, before refreshing user view
  yield delay(1000)
  if (yield select(signedInSelector)) {
    yield put(userViewRequest())
  }
}

function* watchUserViewRequest() {
  yield takeLeading(userViewRequest, onUserViewRequest)
}

function* watchUserCreateRequest() {
  yield takeLeading(userCreateRequest, onUserCreateRequest)
}

function* watchUserUpdateRequest() {
  yield takeLeading(userUpdateRequest, onUserUpdateRequest)
}

function* watchUserSetMiscValuesRequest() {
  yield debounce(1000, userSetMiscValuesRequest, onUserSetMiscValuesRequest)
}

function* watchUserDeleteRequest() {
  yield takeLeading(userDeleteRequest, onUserDeleteRequest)
}

function* watchUserEventRequest() {
  yield takeEvery(userEventRequest, onUserEventRequest)
}

function* watchUserAuthEmailSignInRequest() {
  yield takeLeading(userAuthEmailSignInRequest, onUserAuthEmailSignInRequest)
}

function* watchUserAuthGoogleSignInRequest() {
  yield takeLeading(userAuthGoogleSignInRequest, onUserAuthGoogleSignInRequest)
}

function* watchUserAuthGoogleLinkRequest() {
  yield takeLeading(userAuthGoogleLinkRequest, onUserAuthGoogleLinkRequest)
}

function* watchUserAuthEmailSettingsRequest() {
  yield takeLeading(
    userAuthEmailSettingsRequest,
    onUserAuthEmailSettingsRequest
  )
}

function* watchUserAuthUnlinkRequest() {
  yield takeLeading(userAuthUnlinkRequest, onUserAuthUnlinkRequest)
}

function* watchUserAuthGoogleRedirectedToURL() {
  yield takeLeading(
    userAuthGoogleRedirectedToURL,
    onUserAuthGoogleRedirectedToURL
  )
}

function* watchUserAuthCodeRequest() {
  yield takeLeading(userAuthCodeRequest, onUserAuthCodeRequest)
}

function* watchUserAuthSignoutRequest() {
  yield takeLeading(userAuthSignoutRequest, onUserAuthSignoutRequest)
}

function* watchUserAuthCheckIfSignedOut() {
  yield throttle(
    30000,
    userAuthCheckIfSignedOut,
    onUserAuthCheckIfSignedOut,
    false
  )
}

function* watchUserAuthCheckIfSignedIn() {
  yield throttle(2000, userAuthCheckIfSignedIn, onUserAuthCheckIfSignedIn)
}

function* watchUserAuthCheckIfVerified() {
  yield throttle(2000, userAuthCheckIfVerified, onUserAuthCheckIfVerified)
}

function* watchUserAuthCheckIfSignedOutLocal() {
  yield takeLeading(userAuthCheckIfSignedOut, onUserAuthCheckIfSignedOut, true)
}

function* watchUserPasswordForgotRequest() {
  yield takeLeading(userPasswordForgotRequest, onUserPasswordForgotRequest)
}
function* watchUserPasswordResetRequest() {
  yield takeLeading(userPasswordResetRequest, onUserPasswordResetRequest)
}
function* watchUserPasswordChangeRequest() {
  yield takeLeading(userPasswordChangeRequest, onUserPasswordChangeRequest)
}
function* watchUserEmailVerifyRequest() {
  yield takeLeading(userVerifyEmailRequest, onUserVerifyEmailRequest)
}
function* watchUseSendVerificationEmailRequest() {
  yield takeLeading(
    useSendVerificationEmailRequest,
    onUseSendVerificationEmailRequest
  )
}
function* watchUserProductUnlockRequest() {
  yield takeLeading(userProductUnlockRequest, onUserProductUnlockRequest)
}

export default function*() {
  yield all([
    fork(handleAPISubscriptions),
    fork(startupRefresh),
    fork(watchUserAuthCheckIfSignedOut),
    fork(watchUserAuthCheckIfSignedIn),
    fork(watchUserAuthCheckIfVerified),
    fork(watchUserAuthCheckIfSignedOutLocal),
    fork(watchUserAuthCodeRequest),
    fork(watchUserAuthEmailSignInRequest),
    fork(watchUserAuthGoogleSignInRequest),
    fork(watchUserAuthGoogleLinkRequest),
    fork(watchUserAuthEmailSettingsRequest),
    fork(watchUserAuthUnlinkRequest),
    fork(watchUserAuthGoogleRedirectedToURL),
    fork(watchUserAuthSignoutRequest),
    fork(watchUserCreateRequest),
    fork(watchUserDeleteRequest),
    fork(watchUserEventRequest),
    fork(watchUserPasswordChangeRequest),
    fork(watchUserPasswordForgotRequest),
    fork(watchUserPasswordResetRequest),
    fork(watchUserEmailVerifyRequest),
    fork(watchUseSendVerificationEmailRequest),
    fork(watchUserProductUnlockRequest),
    fork(watchUserUpdateRequest),
    fork(watchUserSetMiscValuesRequest),
    fork(watchUserViewRequest),
    fork(watchUserAwardPDCreditRequest),
    // fork(onUpdateProgress),
  ])
}
