import React from 'react'
import RichTextEditor from '@trojanowski/react-rte'

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
}

export default function RTEMarkdown({
  name,
  value = '',
  onFormInputChangeText,
}) {
  const [editorState, setEditorState] = React.useState(
    RichTextEditor.createValueFromString(value, 'markdown')
  )
  const onChange = content => setEditorState(content)
  const onBlur = () =>
    onFormInputChangeText({ [name]: editorState.toString('markdown') })

  return (
    <RichTextEditor
      rootStyle={{ marginBottom: 20, fontFamily: 'Lato' }}
      editorStyle={{ height: '120px' }}
      toolbarConfig={toolbarConfig}
      value={editorState}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}
