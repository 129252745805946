import React from 'react'
import { View } from 'react-native'
import LottieView from 'react-native-web-lottie'
import s from 'styles'

// NOTE: the progress modal does not have a frame
const ProgressModal = () => {
  return (
    <View style={[s.alignCenter, s.justifyCenter, s.mAuto, s.w100p]}>
      <LottieView
        style={{ width: 120, height: 120 }}
        source={require('images/lottie-spinner.json')}
        autoPlay
        loop
      />
    </View>
  )
}

export default ProgressModal

export const makeProgressModal = () => {
  return (<ProgressModal />)
}
