import React, { useState } from "react";
import {TimeoutWarningModal} from "./TimeoutWarningModal"
    
export const CheckLink = ({link, onCheckLinkFinished = undefined}) => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(true)
  return (
    <div>
      {isWarningModalOpen && link && (
        <TimeoutWarningModal 
          isOpen={isWarningModalOpen}
          link={{link}}
          onRequestClose={() => {
            setWarningModalOpen(false)
            if(onCheckLinkFinished) {
              onCheckLinkFinished()
            }
          }}
        />
        )
      }
    </div>
  ) 
}