import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { some } from 'lodash'
import { Button, Input } from 'components/Form'
import { Image } from 'components/ReactNative'
// import { Link } from 'components/Router'
import SubmissionLabel from './SubmissionLabel'
import RemoveX from './RemoveX'
import s from 'styles'
import withSubmissionForm from './withSubmissionForm'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

// const CustomSectionTitle = () => {
//   return (
//     <View style={[s.flexRow, s.alignEnd, s.mb8]}>
//       <Text style={[s.mr20, s.f16]}>Lesson Objectives</Text>
//       <Link to="https://ozobot.com/blog/how-to-write-clear-lesson-objectives">
//         <Text style={[s.f14, s.textTeal, s.textUnderline]}>
//           Learn more about writing lesson objectives here
//         </Text>
//       </Link>
//     </View>
//   )
// }

const ObjectivesInput = ({ name = 'objectives', add, objectivesCount }) => {
  const [value, setValue] = React.useState('')
  const { isDesktop, isMobile } = useDetectDeviceType()

  const onUpdate = obj => {
    setValue(obj[name])
  }
  const onAdd = () => {
    if (!value) return
    add(value)
    clearInput()
  }
  const clearInput = () => setValue('')

  return (
    <View style={[ !isDesktop ? s.flexColumn : s.flexRow, s.alignStart]}>
      <View style={[s.flexRow, s.alignStart, s.mr20]}>
        <Input
          errorMessage=""
          name={name}
          onFormInputChangeText={onUpdate}
          value={value}
          width={isMobile ? "w100p" : "w600"}
          multiline={false}
          maxLength={180}
          onPressEnter={onAdd}
        />
        {value.length > 0 && (
          <View style={[s.positionAbsolute, { top: 12, right: 10 }]}>
            <TouchableOpacity onPress={clearInput}>
              <Image
                source={require('images/icon-x.svg')}
                style={{ height: 16, width: 16 }}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <Button
        text="Add"
        icon="plus"
        onPress={onAdd}
        disabled={objectivesCount >= 5}
      />
    </View>
  )
}

const ObjectiveItem = ({ text, onRemove, isMobile }) => {
  return (
    <View style={[s.mb4, s.flexRow, s.alignCenter, s.justifyBetween, isMobile ? s.w300 : s.w600]}>
      <Text>• {text}</Text>
      <RemoveX onPress={onRemove} />
    </View>
  )
}

const SubmissionObjectivesGroup = ({
  field,
  text,
  secondaryText,
  secondaryLink,
  beforeInput = '',
  hasSeparator,
  value,
  form,
  formAddItem,
  formRemoveItem,
}) => {
  const { isMobile } = useDetectDeviceType();
  return (
    <View
      style={[s.mb20, hasSeparator && [s.pb20, s.bBottom1, s.bGrayLighter]]}
    >
      {/* <CustomSectionTitle /> */}
      <SubmissionLabel
        text={text}
        secondaryText={secondaryText}
        secondaryLink={secondaryLink}
        hasError={some(form.errors, { field })}
      />
      <View style={[s.flexRow, s.mb16]}>
        {!!beforeInput && (
          <Text style={[s.f16, s.mt8, s.mr4]}>{beforeInput}</Text>
        )}
        <ObjectivesInput
          add={formAddItem}
          beforeInput={beforeInput}
          objectivesCount={form[field].length}
        />
      </View>
      {form[field].map((item, i) => (
        <ObjectiveItem
          key={i}
          text={`${beforeInput}${item}`}
          onRemove={() => formRemoveItem(i)}
          isMobile={isMobile}
        />
      ))}
    </View>
  )
}

export default withSubmissionForm(SubmissionObjectivesGroup)
