import * as React from 'react'
import { TouchableOpacity, Text, View, GestureResponderEvent } from 'react-native'
import s from 'styles'

export interface ServiceButtonProps {
  icon: string;
  onPress?: (event: GestureResponderEvent) => void;
}

export const ServiceButton: React.FunctionComponent<ServiceButtonProps> = ({ onPress }: ServiceButtonProps) => {
  return (
    <TouchableOpacity onPress={onPress} style={{width: '80%'}}>
      <View
        style={[
          s.flexRow,
          s.alignCenter,
          s.justifyCenter,
          s.br25,
          s.br0,
          s.py8, 
          s.px20,
          s.br10,
          { backgroundColor: '#5283EB'},
        ]}
      >
        <Text
          style={[
            s.f16,
            s.textBold,
            s.textWhite,
          ]}
        >
          {'Log in with Google'}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export default ServiceButton