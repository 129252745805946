import React from 'react'
import { Text, View } from 'react-native'
import Markdown from '@ozobot/react-native-simple-markdown'
import { Link } from 'components/Router'
import s from 'styles'

const TextLink = ({ url, ...props }) => {
  return (
    <View style={{ marginTop: 0 }}> {/** HACKME: Aligns links to other text */}
      <Link to={url}>
        <Text {...props} />
      </Link>
    </View>
  )
}

const styles = {
  text: {
    fontFamily: 'Lato',
  },
}

const markdownRules = {
  link: {
    react: (node, output, state) => {
      return React.createElement(
        TextLink,
        {
          style: [s.textUnderline, s.textTeal, s.f14],
          key: state.key,
          url: node.target,
        },
        output(node.content, state)
      )
    },
  },
  // Rule to match ++TEXT++ instead of __TEXT__
  u: {
    match: source => {
      return /^\+\+((?:\\[\s\S]|[^\\])+?)\+\+(?!\+)/.exec(source)
    },
    react: (node, output, state) => {
      state.withinText = true
      return React.createElement(
        Text,
        {
          style: s.textUnderline,
          key: state.key,
        },
        output(node.content, state)
      )
    },
  },
}

export default function TextMarkdown({ text }) {
  return (
    <Markdown styles={styles} rules={markdownRules}>
      {text}
    </Markdown>
  )
}
