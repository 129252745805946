import { eventChannel, buffers } from 'redux-saga'
import {
  actionChannel,
  all,
  call,
  fork,
  put,
  take,
  takeLeading,
  takeEvery,
} from 'redux-saga/effects'
import { openURL } from 'lib/utils'
import io from 'socket.io-client'
import fileDownload from 'js-file-download'
import printJS from 'print-js'
import axios from 'axios'

import {
  printPDFFromURL,
  saveFileFromURL,
  socketConnection,
  socketJoinRoomRequest,
  socketLeaveRoomRequest,
  socketMessage,
  socketSendMessageFailure,
  socketSendMessageRequest,
  socketSendMessageSuccess,
} from '.'

// ==== SOCKETS =====

async function connectToSocket() {
  const socket = io(
    process.env.REACT_APP_WS_URL ||
      'https://wss-dot-ozobot-api-production.appspot.com',
    {
      path: '/ws',
      // this option seems required to avoid 400 errors on socket handshake, and to avoid defaulting to long polling
      transports: ['websocket'],
    }
  )
  return new Promise(resolve => {
    socket.on('connect', () => {
      resolve(socket)
      console.log('Socket connected')
    })
  })
}

function* handelSocketInMessages(socket) {
  let connected = true
  socket.on('disconnect', () => {
    connected = false
  })
  const channel = eventChannel(emitter => {
    socket.on('broker.in', message => emitter(message))
    return () => {}
  }, buffers.fixed(1024))
  while (connected) {
    const msg = yield take(channel)
    yield put(
      socketMessage({
        from: msg.from,
        room: msg.to !== socket.id ? msg.to : undefined,
        event: msg.data.event,
        ...msg.data,
      })
    )
  }
}

function* watchSocketRequests() {
  const channel = yield actionChannel([
    socketSendMessageRequest,
    socketJoinRoomRequest,
    socketLeaveRoomRequest,
  ])
  let socket = undefined
  let rooms = new Set()
  while (true) {
    const { type, payload } = yield take(channel)
    switch (type) {
      case socketJoinRoomRequest.toString():
        if (!socket) {
          try {
            socket = yield call(connectToSocket)
            // eslint-disable-next-line no-loop-func
            socket.on('disconnect', () => {
              socket = undefined
              rooms = new Set()
            })
            yield fork(handelSocketInMessages, socket)
            yield put(
              socketConnection({
                connected: true,
                socketId: socket.id,
              })
            )
          } catch (e) {
            console.error('Could not connect to socket', e)
          }
        }
        if (payload.room && !rooms.has(payload.room)) {
          try {
            socket.emit('broker.join', { room: payload.room })
            rooms.add(payload.room)
          } catch (e) {
            console.error('Could not join room', e)
          }
        }
        break
      case socketLeaveRoomRequest.toString():
        if (payload.room && rooms.has(payload.room)) {
          try {
            socket.emit('broker.leave', { room: payload.room })
          } catch (e) {
            console.error('Could not join room', e)
          }
        }
        // delete room regardless
        rooms.delete(payload.room)
        // disconnect socket if no more rooms
        if (socket && rooms.size === 0) {
          try {
            socket.close(true)
            yield put(
              socketConnection({
                connected: false,
                socketId: undefined,
              })
            )
          } catch (e) {
            console.error('Could not disconnect from socket', e)
          }
        }
        break
      case socketSendMessageRequest.toString():
        const { toSocket, toRoom, event, data, waitPutMarker } = payload
        try {
          if (!socket) {
            throw new Error('No socket connection')
          }
          yield socket.emit('broker.out', {
            socketId: socket.id,
            to: toSocket || toRoom,
            message: {
              event,
              timestamp: Date.now(),
              ...data,
            },
          })
          yield put(
            socketSendMessageSuccess({
              toSocket,
              toRoom,
              event,
              data,
              timestamp: Date.now(),
              waitPutMarker,
            })
          )
        } catch (error) {
          yield put(
            socketSendMessageFailure({
              toSocket,
              toRoom,
              event,
              data,
              error,
              waitPutMarker,
            })
          )
        }
        break
      default:
        console.error('Unhandled socket action type', type)
        break
    }
  }
}

function* onSaveFileFromURL({ payload }) {
  try {
    const res = yield call(axios.get, payload.url, { responseType: 'blob' })
    yield call(fileDownload, res.data, payload.fileName)
  } catch (err) {
    console.error(err)
    openURL(payload.url, true)
  }
}

const printJSAsync = async url =>
  new Promise((resolve, reject) => {
    printJS({
      printable: url,
      type: 'pdf',
      onError: reject,
      onPrintDialogClose: resolve,
    })
  })

function* onPrintPDFFromURL({ payload }) {
  try {
    yield call(printJSAsync, payload.url)
  } catch (err) {
    console.error(err)
    openURL(payload.url, true)
  }
}

// ==================

export default function*() {
  yield all([
    // fork(watchOutboundSocketMessages),
    fork(watchSocketRequests),
    fork(function*() {
      yield takeLeading(saveFileFromURL, onSaveFileFromURL)
    }),
    fork(function*() {
      yield takeEvery(printPDFFromURL, onPrintPDFFromURL)
    }),
  ])
}
