import React, { Component } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import qs from 'qs'
import {
  botzConnectToBotWithBLE,
  botzDevicePageEnabled,
  botzDisconnectAllPeripherals,
  botzIDBotWithBlinkingLEDs,
  botzSetName,
} from 'store/botz'
import { modalShow } from 'store/modal'
import { Button } from 'components/Form'
import { Layout, PageHeader, PageH1, Content } from 'components/Layout'
import { Image } from 'components/ReactNative'
import DeviceRenameModal from './components/DeviceRenameModal'
import { openURL } from 'lib/utils'
import s from 'styles'

export class Devices extends Component {
  constructor(props) {
    super(props)

    // Moved bot filter from dashboard to devices
    const { bots } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    })

    if (bots) {
      this.props.botzSetOCCConnectionFilter({ bots })
    }
  }

  componentDidMount() {
    this.props.botzDevicePageEnabled({ enabled: true })
  }

  componentWillUnmount() {
    this.props.botzDevicePageEnabled({ enabled: false })
  }

  updateStatus = () => {

  }

  renderStatusBox() {
    if (!this.props.peripheralInfo) {
      return this.renderStatusBoxDefault()
    } else {
      return this.renderStatsBoxConnected()
    }
  }

  renderStatsBoxConnected() {
    const bot = this.props.peripheralInfo
    return (
      <View
        style={[
          s.minWidth600,
          s.alignSelfStart,
          s.b1,
          s.bGrayLighter,
          s.br5,
          s.p16,
          s.mb20,
        ]}
      >
        <View
          style={[
            s.minHeight45,
            s.mb16,
            s.flexRow,
            s.justifyBetween,
            s.alignCenter,
          ]}
        >
          <View style={[s.flexRow, s.pr16]}>
            <Text style={[s.f16, s.textBold]}>{`Connected Evo: `}</Text>
            <Text style={[s.f16, s.textBold, s.textTeal]}>{bot.name}</Text>
          </View>
          <View style={[s.flexRow, s.alignCenter, s.pr16]}>
            <Image
              source={require('images/component-bot-status-teal.svg')}
              style={{
                width: 30,
                height: 30,
              }}
            />
          </View>
        </View>
        {
          bot.hasLegacyFirmware
            ? (
              <View>
                <Text style={[s.f16, s.textRed, s.mb32]}>
                  {`This Evo needs to be updated before it can be renamed. \nGo to `}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.botzDisconnectAllPeripherals()
                      openURL('https://ozoblockly.com/editor?robot=evo')
                    }}
                  >
                    <Text style={[s.textTeal, s.textUnderline]}>{`Ozoblockly.com`}</Text>
                  </TouchableOpacity>
                  {` and pair Evo to update.`}
                </Text>
                <View style={[s.flexRow]}>
                  <Button
                    style={[s.px16]}
                    text="Disconnect Evo"
                    onPress={() => this.props.botzDisconnectAllPeripherals()}
                  />
                </View>
              </View>
            )
            : (
              <View style={[s.flexRow]}>
                <Button
                  style={[s.px16]}
                  role="secondary"
                  text="Disconnect Evo"
                  onPress={() => this.props.botzDisconnectAllPeripherals()}
                />
                <Button
                  text="Rename Evo"
                  onPress={
                    () => this.props.modalShow(
                      <DeviceRenameModal
                        title="Rename Bot"
                        uuid={bot.uuid}
                        name={bot.name}
                      />
                    )
                  }
                />
              </View>
            )
        }

      </View>
    )
  }

  renderStatusBoxDefault() {
    return (
      <View
        style={[s.alignSelfStart, s.b1, s.bGrayLighter, s.br5, s.p16, s.mb20]}
      >
        {/* Top */}
        <View
          style={[
            s.minHeight45,
            s.mb16,
            s.flexRow,
            s.justifyBetween,
            s.alignCenter,
          ]}
        >
          <View>
            <Text style={[s.f16, s.textBold]}>{'Pair with your Evo'}</Text>
            {/* Error Message */}
          </View>

          <View style={[s.flexRow, s.alignCenter, s.pl16]}>
            {/* Button */}
            {/* Count */}
            <Image
              source={require('images/component-bot-status-grey.svg')}
              style={{
                width: 30,
                height: 30,
              }}
            />
          </View>
        </View>

        {/* Bottom */}
        <View style={[s.flexRow]}>
          <Button
              text="Pair Now"
              onPress={() => this.props.botzConnectToBotWithBLE()}
          />
        </View>
      </View>
    )
  }

  render() {
    return (
      <Layout>
        <PageHeader>
          <PageH1 text="Rename Evo" />
        </PageHeader>
        <Content>
          {this.renderStatusBox()}
        </Content>
      </Layout>
    )
  }
}

const mapStateToProps = s => {

  const { botz, user } = s

  // A peripheral is "active" if it is connecting or connected, or has an error
  // NOTE, for the OCC2, we need to check multiple things, to handle the case where
  // no serial is present (such as when upgrading from 1.x OCC FW)
  const peripheralInfo = Object.values(botz.byIds).find(
    bot => bot.connected
  )

  return {
    peripheralInfo,
    license: user.license,
    showProductSurvey: user.showProductSurvey,
    hideOCCPairOnboarding: !!user.hideOCCPairOnboarding,
    hideEvoPairOnboarding: !!user.hideEvoPairOnboarding,
  }
}

export default connect(mapStateToProps, {
  botzConnectToBotWithBLE,
  botzDevicePageEnabled,
  botzDisconnectAllPeripherals,
  botzIDBotWithBlinkingLEDs,
  botzSetName,
  modalShow,
})(Devices)
