import React from 'react'
import { View } from 'react-native'

export function ModalTopRight({ children }) {
  return (
    <View
      style={{
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      {children}
    </View>
  )
}
