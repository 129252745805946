
import React, { useState } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { modalHide } from 'store/modal'
import { Button2 as Button, Input } from 'components/Form'
import { ModalFrame } from 'components/modal'
import { botzSetName, botzIDBotWithBlinkingLEDs } from 'store/botz'
import { MAX_EVO_NAME_CHARACTERS } from 'store/botz/util'
import s from 'styles'

const DeviceRenameModal = (props: { title: string, uuid: string, name?: string }) => {
  const [name, setName] = useState(props.name || '')
  const dispatch = useDispatch()

  const closeModal = () => dispatch(modalHide())

  const saveClose = () => {
    const trimmed = name.trim()
    if (trimmed) {
      const uuid = props.uuid
      dispatch(botzSetName({ uuid, name: trimmed }))
      dispatch(botzIDBotWithBlinkingLEDs({ uuid }))
    }
    closeModal()
  }

  const onFormInputChangeText = obj => {
    setName(obj.name)
  }

  return (
    <ModalFrame title={props.title} size="medium">
      <View style={[s.mb20]}>
        <Input
          label="New Name (Max 13 characters)"
          labelTextStyle={s.textBold}
          name="name"
          onFormInputChangeText={onFormInputChangeText}
          value={name}
          maxLength={MAX_EVO_NAME_CHARACTERS}
          minLength={1}
        />
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        <View style={s.mr16}>
          <Button
            text="Cancel"
            role="secondary"
            onPress={closeModal}
          />
        </View>
        <Button text="Save" onPress={saveClose} />
      </View>
    </ModalFrame>
  )
}

export default DeviceRenameModal
