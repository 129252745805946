import * as React from 'react'
import { Text, View } from 'react-native'
import { Link } from 'components/Router'
import s from 'styles'

const StudentLink: React.FC = () => {
  return (
    <Link forceCurrentWindow to="https://ozobot.com/welcome">
      <View style={[s.w75p, s.mAuto, s.bgWhite, s.py8, s.mb16, s.br20, s.bBlueDark, s.b2, { maxWidth: 420 }]}>
        <Text style={[s.f18, s.textBold, s.textBlueDark, s.textCenter]}>Are you a student? Click here</Text>
      </View>
    </Link>
  )
}

export default StudentLink
