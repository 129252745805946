import React from 'react'
import * as Sentry from '@sentry/browser'
import { UIProvider } from 'react-native-web-ui-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import DialogBox from 'components/DialogBox'
import { Modal } from 'components/modal'
import { Notifications } from 'components/Notification'
import ChatbaseButton from 'components/ChatbaseButton'
import Routes from './Routes'
import store, { history, persistor } from './globalstore' // TODO: Remove globalstore when devices refactored

import { ModalProvider } from './context/ModalContext'

const env_name = process.env.REACT_APP_ENV_NAME || 'dev'
if (env_name !== 'dev') {
  Sentry.init({
    dsn: 'https://3eb63b99d4604473abea8f2b5837f696@sentry.io/1485788',
    environment: env_name,
  })
}

const chatbotID = process.env.REACT_APP_CHATBASE_ID

export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <UIProvider history={history}>
          <ModalProvider>
            <Routes />
            <DialogBox />
            <Modal />
            <Notifications />
            <ChatbaseButton chatbotId={chatbotID}/>
          </ModalProvider>
        </UIProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)
