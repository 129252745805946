import React from 'react'
import { CheckBoxItem } from 'components/Form'
import withSubmissionForm from './withSubmissionForm'

const SubmissionCheckboxSingle = ({ text, field, form }) => {
  const onChange = () => {
    form.updateForm({ [field]: !form[field] })
  }

  return (
    <CheckBoxItem
      isChecked={form[field]}
      label={text}
      name={field}
      onChange={onChange}
      value={form[field]}
    />
  )
}

export default withSubmissionForm(SubmissionCheckboxSingle)
