import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  district: {
    results: [],
    query: '',
  },
  school: {
    results: [],
    query: '',
  },
  academicStandards: {
    results: [],
    query: '',
  },
  error: null,
}

const autocomplete = createSlice({
  name: 'autocomplete',
  initialState,
  reducers: {
    autocompleteRequest(state, { payload }) {
      state.isFetching = true
      state[payload.dataType].query = payload.query
    },
    autocompleteSuccess(state, { payload }) {
      state.isFetching = false
      state[payload.dataType].results = payload.results.slice(0, 16)
      state[payload.dataType].query = payload.query
    },
    autocompleteError(state, { payload }) {
      state.isFetching = false
      state.error = payload
    },
    autocompleteClear(state, { payload }) {
      state[payload.dataType].results = []
    },
  },
})

export const {
  autocompleteRequest,
  autocompleteSuccess,
  autocompleteError,
  autocompleteClear,
} = autocomplete.actions

export default autocomplete.reducer
