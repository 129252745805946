import React from 'react'
import qs from 'qs'
import { clamp } from 'lodash'
import WithOptionsModal from 'components/modals/WithOptionsModal'
import AlertModal from 'components/modals/AlertModal'

export default function withSteps(WrappedComponent, options) {
  return class extends React.Component {
    constructor(props) {
      super(props)

      // Parses URL query params when page is refreshed
      const { id } = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      })
      this.state = {
        id,
        step: clamp(props.match.params.page, 1, WrappedComponent.steps.length),
      }
      this.scrollView = React.createRef()
    }

    componentDidMount() {
      if (this.state.id && !this.props.lesson.id) {
        this.props.lessonsViewRequest({ id: this.state.id })
      }

      // Catch page refresh
      window.addEventListener('beforeunload', this.onUnload)
    }

    componentDidUpdate(prevProps) {
      // Update step state when user uses back button
      if (
        prevProps.match.params.page !== this.props.match.params.page &&
        this.props.match.params.page !== this.state.step
      ) {
        this.scrollView.current.scrollTo({ x: 0, y: 0, animated: false })
        this.setState({ step: Number(this.props.match.params.page) })
      }

      // Add id to URL when saved
      if (
        this.props.lastUpdatedId &&
        prevProps.lastUpdatedId !== this.props.lastUpdatedId
      ) {
        this.props.history.replace({
          search: '?id=' + this.props.lastUpdatedId,
        })
        this.setState({ id: this.props.lastUpdatedId })
      }

      // Display redirect modal after submitted lesson is successful
      if (
        prevProps.isFetching !== this.props.isFetching &&
        this.props.isFetching === false &&
        this.state.status === 'pending'
      ) {
        this.props.modalShow(
          <WithOptionsModal
            title="Lesson Created"
            text="Thank you for sharing your Ozobot lesson with other educators! We’ll be in touch to let you know if it is approved or if we recommend any changes."
            buttons={[
              {
                component: 'ButtonLink',
                text: 'Ok',
                url: '/lessons/mine',
              },
            ]}
          />
        )
      }
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', this.onUnload)
    }

    // Messages user with confirmation message on refresh
    onUnload = e => {
      const confirmationMessage = 'Changes you made may not be saved.'
      e.returnValue = confirmationMessage
      return confirmationMessage
    }

    goTo = step => {
      this.props.history.push({
        pathname: `/lessons/${options.uri}/${step}`,
        search: this.props.location.search,
      })
    }

    save = form => {
      const data = {
        ...form,
        status: 'draft',
        type: options.type,
      }
      if (this.state.id) {
        this.props.lessonsUpdateRequest({
          ...data,
          id: this.state.id,
        })
      } else {
        this.props.lessonsCreateRequest({
          ...data,
          schemaVersion: 3,
        })
      }
      this.props.modalShow(
        <AlertModal
          title="Draft Saved"
          text="Your lesson has been saved to your drafts. Come back soon!"
        />
      )
    }

    submit = form => {
      const data = {
        ...form,
        status: 'pending',
        type: options.type,
      }

      this.setState({ status: 'pending' })
      if (this.state.id) {
        this.props.lessonsUpdateRequest({
          ...data,
          id: this.state.id,
        })
      } else {
        this.props.lessonsCreateRequest(data)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          id={this.state.id}
          step={this.state.step}
          goTo={this.goTo}
          save={this.save}
          submit={this.submit}
          scrollView={this.scrollView}
        />
      )
    }
  }
}
