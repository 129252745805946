import {
  all,
  call,
  cancelled,
  debounce,
  delay,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { eventChannel, buffers } from 'redux-saga'
import { DeviceMgr, MAX_CONNECTED_BRIDGE_BOTS } from './deviceMgr'
import { AssetsMgr } from './assetsMgr'
import { ButtonOverrideEnum, BehaviourTypeEnum } from '@ozobot/protocol-generated'

import {
  evoDisplayNameFromBLEName,
  levelFromBattery,
  playEDUBaseBehaviour,
  callOnBot,
  setLEDs,
  takeEveryBotStateChange,
  timeout,
  waitForBotState,
} from './util'

import {
  botzAllowOCCBotConnection,
  botzBotsAreConnectedThatNeedUpdates,
  botzBotsAreQueuedForUpdating,
  botzConnectToOCCWithBLE,
  botzDisconnectAllPeripherals,
  botzIsScanningForBotsSelector,
  botzGetBriefing,
  botzUpdateBots,
  botzUpdateOCCFirmware,
} from './index'

import {
  _BOTZ_CLEAR_ALL_LAST_ERRORS,
  _BOTZ_SET_INLINE_UPDATING,
  BOTZ_BOT_RENAME_REQUEST,
  BOTZ_BOT_EVENT_RECEIVED,
  BOTZ_BOT_STATE_CHANGED,
  BOTZ_DEVICE_PAGE_ENABLED,
  BOTZ_OCC_ALLOW_BOT_CONNECTION,
  BOTZ_OCC_BOT_SCAN_BEGIN,
  BOTZ_OCC_BOT_SCAN_ENDED,
  BOTZ_OCC_SET_BOT_CONNECTION_FILTER,
  BOTZ_OCC_STATE_CHANGED,
  BOTZ_OCC_UPLOAD_PROGRESS,
  BOTZ_PERIPHERAL_CONNECTION_FAILURE,
  BOTZ_PERIPHERAL_CONNECTION_REQUEST,
  BOTZ_PERIPHERAL_CONNECTION_SUCCESS,
  BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST,
  BOTZ_PERIPHERAL_DISCONNECT_ALL_SUCCESS,
  BOTZ_REQUEST_ID_BOT_WITH_BLINKING_LEDS,
  BOTZ_MANIFEST_RETRIEVED,
  BOTZ_UPDATE_BOT_BRIEFING_REQUEST,
  BOTZ_UPDATE_BOT_CANCEL,
  BOTZ_UPDATE_BOT_FAILURE,
  BOTZ_UPDATE_BOT_INLINE_REQUEST,
  BOTZ_UPDATE_BOT_PROGRESS,
  BOTZ_UPDATE_BOT_SUCCESS,
  BOTZ_UPDATE_BOTS_REQUEST,
  BOTZ_UPDATE_OCC_FIRMWARE_FAILURE,
  BOTZ_UPDATE_OCC_FIRMWARE_REQUEST,
  BOTZ_UPDATE_OCC_FIRMWARE_SUCCESS,
} from './types'

import { userSetMiscValuesRequest } from 'store/user'

import { empty } from 'lib/utils'

import { modes } from 'lib/modes'
import flaggedLog from 'lib/flaggedLog'
import pubsub from 'lib/pubsub.ts'

import { makeBotUpdateProgressModal as updateProgressModal } from 'components/modals/BotUpdateProgressModal'
import { makeProgressModal as progressModal } from 'components/modals/ProgressModal'
import { makeAlertModal as alertModal } from 'components/modals/AlertModal'
import { makeLegacyDialogBoxModal as legacyDialogBoxModal } from 'components/modals/LegacyDialogBoxModal'

// FIXME: find a better way of doing this!
import store from '../../globalstore'
import { modalShow, modalHide } from 'store/modal'
import api from 'lib/api'

import { EvoStatisticsFile, Robot, RobotLegacy } from '@ozobot/ozobot-ble-protocol-private'
import { Version, VersionFromString } from '@ozobot/common'
import { async } from 'validate.js'

// OCC.subscribeToLogs(flaggedLog(['occ', 'occ2']).log)
// OCC.subscribeToProfileLogs(flaggedLog('occ2').log)
DeviceMgr.setLogger(console)

const { log, warn } = flaggedLog('bots')

const DEVICE_QUEUE_SIZE = 1024

const _BOTZ_UPDATE_STATE_FOR_COMMAND_REQUEST =
  '_BOTZ_UPDATE_STATE_FOR_COMMAND_REQUEST'

const _BOTZ_POST_STATS_REQUEST = '_BOTZ_POST_STATS_REQUEST'

const yellowish = { r: 64, g: 32 }
const BotStatusColors = {
  READY: [{}, { g: 64, mask: { top: true } }],
  READY_WITH_ERROR: [{}, { r: 128, mask: { top: true } }],
  READY_WITH_UPDATE_NEEDED: [{}, { ...yellowish, mask: { top: true } }],
  QUEUED_FOR_UPDATE: [{}, { b: 64, mask: { top: true } }],
  UPDATING: { b: 64 },
  UPDATE_SUCCEEDED: [{ g: 64 }, { b: 64, mask: { top: true } }],
  UPDATE_WARNING: { ...yellowish },
  UPDATE_FAIL: { r: 255 },
  FLASH_COLOR: [{}, { b: 255, g: 255, mask: { front_left: true, front_left_center: true, front_center: true, front_right_center: true, front_right: true } }],
  BLACK: {},
}

const errorMessageFrom = e => e?.message || e?.toString() || 'Unknown error'

const durationStr = duration => {
  const minutes = Math.floor(duration / 60000)
  const seconds = (duration - minutes * 60000) / 1000
  return minutes > 0
    ? `${minutes} minutes and ${seconds} seconds`
    : `${seconds} seconds`
}

function stateDeltaFrom(state, prevState) {
  state = state || {}
  prevState = prevState || {}
  return Object.keys(state).reduce((delta, prop) => {
    if (state[prop] !== undefined && state[prop] !== prevState[prop]) {
      delta[prop] = state[prop]
    }
    return delta
  }, {})
}

function* getValueFromBotAndSetState(uuid, valueType, getter) {
  const res = yield callOnBot(uuid, getter)

  // Automatically update relevant state with new value -- this is to support legacy saga behaviour.
  const state = {}
  if (valueType === 'battery') {
    log('battery', uuid, res)
    state.battery = res
  } else if (valueType === 'charging') {
    log('charging', 'value', uuid, res)
    state.charging = res
  }
  if (!empty(state)) {
    const stateDelta = stateDeltaFrom(
      state,
      yield select(s => s.botz.byIds[uuid])
    )
    if (!empty(stateDelta)) {
      log('event-delta', stateDelta)
      yield put({
        type: BOTZ_BOT_STATE_CHANGED,
        uuid,
        stateDelta,
      })
    }
  }

  return res;
}

function* commandFence(uuid) {
  try {
    // For now, just make a BLE call that waits for a result
    // this will ensure that previous calls have been processed
    yield getValueFromBotAndSetState(uuid, 'charging', b => b.get_chargerState().then( s => s.state == "Connected") )
  } catch (e) {
    console.warn('Command fence failed for', uuid, e)
  }
}

function* blinkBotLEDs({
  uuid,
  color,
  endColor = undefined,
  interval = 1000,
  times = 4,
}) {
  for (let i = 0; i < times; ++i) {
    yield setLEDs(uuid, BotStatusColors.BLACK)
    yield delay(interval)
    yield setLEDs(uuid, color)
    yield delay(interval)
  }
  if (endColor) {
    yield setLEDs(uuid, endColor)
  }
}

function* getAssetWithBinary(selector) {
  try {
    if (!AssetsMgr.hasAssets) {
      yield put(modalShow(progressModal()))
      yield call(AssetsMgr.refresh)
    }
    const asset = AssetsMgr.assets.find(selector)
    if (!asset) {
      throw new Error('Could not find asset')
    }
    if (!AssetsMgr.hasBinaryForAsset(asset.handle)) {
      yield put(modalShow(progressModal()))
    }
    const binary = yield call(AssetsMgr.binaryForAsset, asset.handle)
    return {
      ...asset,
      binary,
    }
  } finally {
    yield put(modalHide())
  }
}

// TODO: refactor this for the new Watcher API in protocol 3.0
function* onBotEventReceived(event) {
  if (event.eventType === 'CHARGER_CONNECTION') {
    log('charging', 'event', event.uuid, event.data.value)
    yield put({
      type: BOTZ_BOT_STATE_CHANGED,
      uuid: event.uuid,
      stateDelta: {
        charging: event.data.charging,
      },
    })
  }

  // put the public event
  yield put({
    ...event,
    type: BOTZ_BOT_EVENT_RECEIVED,
  })
}

// needs to be in here so that we can reference some local variables (for now)
function occBotsAllowedToBeConnectedSelector(s) {
  const botFilter = s.botz.occBotFilter
  const result = Object.keys(s.botz.byIds).filter(
    uuid =>
      s.botz.occ.ready &&
      s.botz.occ.allowBotConnection &&
      s.botz.byIds[uuid].isOCC
  )
  log('occ-bots-allowed-to-connect', result, botFilter)
  return result
}

// TODO: this can be factored into the code from the BLEBotStateUpdated function
function* onBotStateUpdated({ state, wasUpdating = false }) {
  try {
    const uuid = state.uuid

    const update = {
      uuid: state.uuid,
      connected: !!state.connected,
      connecting: !!state.reconnecting,
      available: !!state.available,
      disconnected: !!state.disconnected,
      firmware: state.firmware,
      name: state.name ? evoDisplayNameFromBLEName(state.name) : state.name,
      isOCC: !!state.isOCC,
    }

    if (!state.connected) {
      update.ready = false
    }
    const [
      botState,
      updatesAreQueued,
      isScanningForBots,
      isInlineUpdating,
    ] = yield select(s => [
      s.botz.byIds[uuid],
      botzBotsAreQueuedForUpdating(s),
      botzIsScanningForBotsSelector(s),
      s.botz.isInlineUpdating,
    ])
    const stateDelta = stateDeltaFrom(update, botState)

    // force a soft internal "connection" event
    if (wasUpdating && state.connected) {
      stateDelta.connected = true
      stateDelta.connecting = false
      stateDelta.available = false
      stateDelta.disconnected = false
    }

    const doSideEffects =
      wasUpdating ||
      !(updatesAreQueued || isScanningForBots || isInlineUpdating)

    if (!empty(stateDelta)) {
      log('evo-state-delta', state.uuid, stateDelta, botState)
      if (stateDelta.connected !== undefined) {
        log('evo-connection-changed', state.uuid, stateDelta.connected)
      }
      yield put({
        type: BOTZ_BOT_STATE_CHANGED,
        uuid,
        stateDelta,
      })
      // Don't perform post connection operations if we're processing updates to bots
      if (stateDelta.connected && doSideEffects) {
        yield spawn(onBotConnected, { uuid, isOCC: state.isOCC, wasUpdating })
      }
    }
  } catch (err) {
    console.error(err)
  }
}

function* blockingBLEBotUpdate({ uuid }) {
  const showInlinedEvoUpdateReconnectDialog = async () =>
    new Promise(resolve => {
      store.dispatch(
        modalShow(
          updateProgressModal({
            isRePairing: true,
            onRePair: resolve,
          })
        )
      )
    })
  const hideInlineEvoUpdateReconnectDialog = () =>
    store.dispatch(modalShow(updateProgressModal({ isRePairing: false })))
  function* ensureConnected(uuid) {
    const state = DeviceMgr.botStateForUUID(uuid)
    if (!state.connected) {
      yield put(modalShow(progressModal()))
      // give things a chance to settle
      yield delay(2000)
      const newUUID = yield call(DeviceMgr.requestBotDirectly)
      if (newUUID !== uuid) {
        yield call(DeviceMgr.disconnectFromAnEvo(newUUID))
        throw new Error('Connected to wrong Evo')
      }
      // give things a chance to settle
      yield delay(2000)
    }
  }
  function* ensureBattery(uuid) {
    yield put(modalShow(progressModal()))
    const battery = yield getValueFromBotAndSetState( uuid, 'battery', b => b.get_batteryState().then( s => s.remainingPower ) )
    if (levelFromBattery(battery.value) === 'low') {
      yield put(
        modalShow(
          alertModal({
            title: 'Ozobot needs some energy!',
            text: 'Please charge your Ozobot Evo first and connect again!',
          })
        )
      )
      yield take(modalHide)
      throw new Error('Cancelled due to power level')
    }
  }
  while (true) {
    try {
      yield ensureConnected(uuid)
      yield ensureBattery(uuid)
      yield put({
        type: BOTZ_BOT_STATE_CHANGED,
        uuid,
        stateDelta: { lastUpdateError: '' },
      })
      yield put({
        type: BOTZ_UPDATE_BOT_INLINE_REQUEST,
        uuid,
        timestamp: Date.now(),
      })
      yield put(modalShow(updateProgressModal({})))
      yield updateBot({
        uuid,
        onRePair: showInlinedEvoUpdateReconnectDialog,
        onRePairChosen: hideInlineEvoUpdateReconnectDialog,
        refreshState: true,
      })
      yield put({
        type: BOTZ_UPDATE_BOT_SUCCESS,
        uuid,
        timestamp: Date.now(),
      })
      yield onBotStateUpdated({
        state: DeviceMgr.botStateForUUID(uuid),
        wasUpdating: true,
      })
      yield put(modalShow(updateProgressModal({ isFinished: true })))
      yield take(modalHide)
      break
    } catch (err) {
      console.warn('Update failed', err)
      yield put(modalHide())
      let retry = false
      const cancelled = err
        ?.toString()
        .toLowerCase()
        .includes('cancel')
      if (!cancelled) {
        yield put({
          type: BOTZ_UPDATE_BOT_FAILURE,
          uuid,
          timestamp: Date.now(),
          error: errorMessageFrom(err),
        })
        yield put(modalShow(updateProgressModal({ hasError: true })))
        const modalResult = yield take(modalHide)
        retry = !!modalResult?.payload?.retry
      }
      if (!retry) {
        throw err
      }
    }
  }
}

function* ensureBotIsInChargerForUpdate({ bot }) {
  if (!bot.charging) {
    // HACK: there is a bug in the 2.x firmware that causes the bot to crash if plugged into a charger.
    // For now, just skip allowing the user to plug the bot in, and just let them continue
    if (bot.firmware.IsAtLeast(new Version(2, 0, 0))) {
      console.warn(
        'Detected bot with 2.x firmware that is not charging, but needs update. Skipping charger check'
      )
      return
    }

    yield put(
      modalShow(
        alertModal({
          title: '',
          headline: 'Your Evo needs to be plugged in to update',
          text:
            'Please plug your Evo into its charging cable, or cancel to disconnect...',
          buttonText: 'Cancel',
        })
      )
    )
    yield race([waitForBotState(bot.uuid, { charging: true }), take(modalHide)])
    const newBot = yield select(s => s.botz.byIds[bot.uuid])
    if (newBot.charging) {
      yield put(modalHide())
    } else {
      throw new Error('Bot was not charging')
    }
  }
}

function* handleBLEEvoUpdate({ uuid }) {
  const [onDevicesPage, bot] = yield select(s => [
    s.botz.devicesPageEnabled,
    s.botz.byIds[uuid],
  ])
  try {
    if (onDevicesPage) {
      yield ensureBotIsInChargerForUpdate({ bot })
      yield put(botzUpdateBots({ uuids: [uuid] }))
    } else {
      try {
        yield put({ type: _BOTZ_SET_INLINE_UPDATING, updating: true })
        yield blockingBLEBotUpdate({ uuid })
      } finally {
        yield put({ type: _BOTZ_SET_INLINE_UPDATING, updating: false })
      }
    }
  } catch (err) {
    throw err
  }
}

function* prepareForFirmwareUpdate() {
  // shut down scanning, and bot connections, until we've finished our check
  if (!DeviceMgr.usingLegacyBridge()) {
    yield call(DeviceMgr.disconnectFromAllBots)
    yield call(DeviceMgr.enableScanningOnBridge, false)
    yield waitForBotsInactivity(5000)
  }
}

function* checkOCCFirmware() {
  const [onDevicesPage, installedVersion] = yield select(s => [
    s.botz.devicesPageEnabled,
    s.botz.occ.firmware,
  ])
  try {
    const firmware = yield getAssetWithBinary(
      a => a.platform === 'occ' && a.filePath.includes('firmware')
    )
    if (!firmware) {
      throw new Error('No firmware available')
    }

    const latestVersion = VersionFromString(firmware.metaData.version)
    log(
      `OCC version check -- latest: ${latestVersion}, installed: ${installedVersion}`
    )

    const updateIsNeeded = latestVersion.IsNewer(installedVersion)
    yield put({
      type: BOTZ_OCC_STATE_CHANGED,
      stateDelta: { updateIsNeeded },
    })

    if (updateIsNeeded) {
      if (onDevicesPage) {
        yield put(botzUpdateOCCFirmware())
      } else {
        throw new Error('Ozobot Communicator must be updated')
      }
      return false
    }

    log('checking OCC firmware version')
    yield call(DeviceMgr.confirmBridgeFirmware)

    yield put({
      type: BOTZ_OCC_STATE_CHANGED,
      stateDelta: { ready: true },
    })

    return true
  } catch (err) {
    if (!onDevicesPage) {
      yield put(
        modalShow(alertModal({
          title: `Firmware check error`,
          text: errorMessageFrom(err),
        }))
      )
    }

    throw err
  }
}

function* onOCCDeviceConnected() {
  try {
    if (!modes.noOCCUpdate && !(yield checkOCCFirmware())) {
      return
    }
    yield spawn(occScanForBotsUnlessDisconnected)
    yield call(DeviceMgr.enableScanningOnBridge, true)
    yield call(DeviceMgr.refreshBotInfo)
  } catch (e) {
    console.error('OCC disconnecting due to check failure...', e)
    yield put({
      type: BOTZ_OCC_STATE_CHANGED,
      stateDelta: { lastUpdateError: errorMessageFrom(e) },
    })
    try {
      yield call(DeviceMgr.disconnectFromBridge)
    } catch {
      console.error('Could not disconnect from OCC after error')
    }
  }
}

function* onOCCDeviceStateUpdated({ stateDelta }) {
  if (stateDelta.connected === true) {
    stateDelta.connectedAt = Date.now()
  } else if (stateDelta.connected === false) {
    stateDelta.ready = false
    stateDelta.connectedAt = null
    if (stateDelta.disconnected) {
      stateDelta.lastUpdateError = 'Disconnected'
    }
  }
  yield put({
    type: BOTZ_OCC_STATE_CHANGED,
    stateDelta,
  })
  if (stateDelta.connected) {
    yield spawn(onOCCDeviceConnected)
  }
}

function* handleDeviceSubscriptions() {
  const DEVICE_SUB_BRIDGE_STATE_UPDATED = 'DEVICE_SUB_BRIDGE_STATE_UPDATED'
  const DEVICE_SUB_BRIDGE_UPDATE_PROGRESS_RECEIVED =
    'DEVICE_SUB_BRIDGE_UPDATE_PROGRESS_RECEIVED'
  const DEVICE_SUB_BOT_STATE_UPDATED = 'DEVICE_SUB_BOT_STATE_UPDATED'
  const DEVICE_SUB_BOT_EVENT_RECEIVED = 'DEVICE_SUB_BOT_EVENT_RECEIVED'
  const DEVICE_SUB_BOT_FALTAS_PROGRESS_RECEIVED =
    'DEVICE_SUB_BOT_FALTAS_PROGRESS_RECEIVED'
  const DEVICE_SUB_BOT_UPDATE_PROGRESS_RECEIVED =
    'DEVICE_SUB_BOT_UPDATE_PROGRESS_RECEIVED'

  let lastProgressAction = undefined
  let lastProgressTime = 0

  function* throttleProgress(action) {
    const UPDATE_INTERVAL = 5000
    if (action.progress && lastProgressAction) {
      const now = Date.now()
      if (
        now - lastProgressTime > UPDATE_INTERVAL ||
        lastProgressAction.text !== action.text ||
        lastProgressAction.uuid !== action.uuid ||
        action.progress < lastProgressAction.progress ||
        action.progress >= 100
      ) {
        lastProgressTime = now
        yield put(action)
      }
    } else {
      yield put(action)
    }
    lastProgressAction = action
  }

  let dataIndex = 0

  const channel = eventChannel(emitter => {
    DeviceMgr.subscribeToBridgeStateChanges(stateDelta =>
      emitter({
        type: DEVICE_SUB_BRIDGE_STATE_UPDATED,
        timestamp: Date.now(),
        dataIndex: ++dataIndex,
        stateDelta: stateDelta.state,
      })
    )

    DeviceMgr.subscribeToBridgeUpdateProgress(progress =>
      emitter({
        type: DEVICE_SUB_BRIDGE_UPDATE_PROGRESS_RECEIVED,
        timestamp: Date.now(),
        dataIndex: ++dataIndex,
        progress,
      })
    )

    DeviceMgr.subscribeToBotStateUpdates(stateUpdate =>
      emitter({
        // blah: console.log('emitting', stateUpdate),
        type: DEVICE_SUB_BOT_STATE_UPDATED,
        timestamp: Date.now(),
        dataIndex: ++dataIndex,
        state: { ...stateUpdate.state, uuid: stateUpdate.uuid },
      })
    )

    DeviceMgr.subscribeToBotEvents(eventData =>
      emitter({
        type: DEVICE_SUB_BOT_EVENT_RECEIVED,
        timestamp: Date.now(),
        dataIndex: ++dataIndex,
        ...eventData,
      })
    )

    DeviceMgr.subscribeToBotFALTAS(progress =>
      emitter({
        type: DEVICE_SUB_BOT_FALTAS_PROGRESS_RECEIVED,
        timestamp: Date.now(),
        dataIndex: ++dataIndex,
        ...progress,
      })
    )

    DeviceMgr.subscribeToBotUpdateProgress(progress =>
      emitter({
        type: DEVICE_SUB_BOT_UPDATE_PROGRESS_RECEIVED,
        timestamp: Date.now(),
        dataIndex: ++dataIndex,
        ...progress,
      })
    )

    // FIXME: this should return cleanup code
    return () => {}
  }, buffers.fixed(DEVICE_QUEUE_SIZE))

  while (true) {
    const action = yield take(channel)
    if (action) {
      log(
        'device-action',
        'type',
        action.type,
        'queue-time',
        Date.now() - action.timestamp,
        'packet-index',
        action.dataIndex,
        action
      )

      yield delay(1)

      try {
        switch (action.type) {
          case DEVICE_SUB_BRIDGE_STATE_UPDATED:
            yield onOCCDeviceStateUpdated(action)
            break
          case DEVICE_SUB_BRIDGE_UPDATE_PROGRESS_RECEIVED:
            yield throttleProgress({
              ...action,
              type: BOTZ_OCC_UPLOAD_PROGRESS,
            })
            break
          case DEVICE_SUB_BOT_STATE_UPDATED:
            yield onBotStateUpdated({ ...action })
            break
          case DEVICE_SUB_BOT_EVENT_RECEIVED:
            yield onBotEventReceived(action)
            break
          case DEVICE_SUB_BOT_UPDATE_PROGRESS_RECEIVED:
            yield throttleProgress({
              data: { ...action },
              progress: action.totalProgress,
              text: action.phase,
              uuid: action.uuid,
              timestamp: action.timestamp,
              type: BOTZ_UPDATE_BOT_PROGRESS,
            })
            break
          default:
            console.error('Invalid device action received', action)
            break
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}

function* onRenameRequest({
  uuid,
  name,
}) {
  try {
    yield callOnBot(uuid, b => b.set_deviceName(name))
    DeviceMgr.onRenamedHack(uuid, name)
  } catch (err) {
    console.error('Could not set name for bot', uuid)
  }
}

function* onUpdateStateForCommand() {
  // wait for any commands to settle
  yield timeout(1000)
  try {
    yield call(DeviceMgr.refreshBotInfo)
  } catch {
    console.warn('Could not bot info')
  }
}

function* onPeripheralConnectionRequest({ peripheral, waitPutMarker }) {
  try {
    log(`Peripheral connection request: ${peripheral}`)

    // some legacy (bot-camp dialogs) don't close quickly enough -- this allows them to close, preventing
    // a race condition
    yield delay(1)
    yield put(modalShow(progressModal()))
    const [byIds, occ] = yield select(s => [s.botz.byIds, s.botz.occ])
    // for single peripheral request, it's useful to have the UUID present in the response
    let uuid

    switch (peripheral) {
      case 'OCC_BLE':
      case 'OCC_USB':
        // FIXME: restore USB support when available
        yield call(
          peripheral === 'OCC_USB' || modes.forceUSB
            ? DeviceMgr.requestBridge
            : DeviceMgr.requestBridge
        )
        for (let bot of Object.values(byIds)) {
          if (!bot.isOCC && (bot.connecting || bot.connected)) {
            yield call(DeviceMgr.disconnectFromAnEvo, bot.uuid)
          }
        }
        break
      case 'EVO_BLE':
        uuid = yield call(DeviceMgr.requestBotDirectly)
        if (occ.connected || occ.connecting) {
          yield call(DeviceMgr.disconnectFromBridge)
        }
        for (let bot of Object.values(byIds)) {
          if (
            !bot.isOCC &&
            bot.uuid !== uuid &&
            (bot.connecting || bot.connected)
          ) {
            yield call(DeviceMgr.disconnectFromAnEvo, bot.uuid)
          }
        }
        break
      default:
        throw new Error(`Unsupported bots manager: ${peripheral}`)
    }

    // HACK: clear out last update errors for UI use
    yield put({ type: _BOTZ_CLEAR_ALL_LAST_ERRORS })

    yield put(modalHide())
    yield put({
      type: BOTZ_PERIPHERAL_CONNECTION_SUCCESS,
      waitPutMarker,
      peripheral,
      uuid,
    })
  } catch (err) {
    console.error(err)
    // HACK: notify components that connection failed
    pubsub.pub('_BOTZ_BLE_CONNECTION_ERROR')
    if (
      err
        ?.toString()
        .toLowerCase()
        .includes('cancelled')
    ) {
      yield put(modalHide())
    } else {
      yield put(
        modalShow(
          alertModal({
            title: 'Connection Error',
            text: errorMessageFrom(err),
          })
        )
      )
    }
    yield put({
      type: BOTZ_PERIPHERAL_CONNECTION_FAILURE,
      waitPutMarker,
      peripheral,
      error: err,
    })
  }
}

function* onPeripheralDisconnectAllRequest({ waitPutMarker }) {
  yield put(modalShow(progressModal()))
  const [byIds, occ] = yield select(s => [s.botz.byIds, s.botz.occ])
  const botsToWaitOn = new Set()
  const cleanupBot = async (bot, b) => {
    if (b instanceof RobotLegacy) {
      console.warn("Cannot execute charging animation on legacy firmware. Firmware update must happen first.")
      return
    }
    await b.SetLED({ top: true, button: true, back: true, front_left: true, front_left_center: true, front_center: true, front_right_center: true, front_right: true }, 0, 0, 0, 0)
    // don't await on this, as it will actually block as long as the charing animation continues
    console.warn("Firmware 3.0 API cannot launch pre-coded animation, this feature is deprecated. Execute a blockly program instead.")
    b.ExecuteFile(bot.charging ? '/system/ozoblock/01010005.bop' : '/system/ozoblock/01010002.bop')
    // TODO: this file doesn't exist on robots yet. Resolve JOT-1680 or create a mock blockly file that starts the animation
    return undefined
  }
  for (let bot of Object.values(byIds)) {
    if (bot.connected) {
      try {
        yield callOnBot(bot.uuid, b => b.setButtonHandler(false))
        botsToWaitOn.add(bot.uuid)
      } catch {
        console.warn('Could not reset button state for ', bot.uuid)
      }
    }
    if (bot.isOCC) {
      if (bot.connected) {
        try {
          if (!bot.lastUpdateError) {
            // restart charging animation
            yield callOnBot(bot.uuid, b => cleanupBot(bot, b))
            botsToWaitOn.add(bot.uuid)
          }
        } catch (e) {
          console.warn('Could not reset button state for ', bot.uuid, e)
        }
      }
    } else {
      if (bot.connecting || bot.connected) {
        try {
          yield callOnBot(bot.uuid, b => cleanupBot(bot, b))
          yield commandFence(bot.uuid)
        } catch(e4) {
          console.warn('Could not cleanup BLE Evo', e4)
        }
        try {
          yield call(DeviceMgr.disconnectFromAnEvo, bot.uuid)
        } catch {
          console.warn('Could not disconnect from BLE Evo')
        }
      }
    }
  }
  const botList = [...botsToWaitOn]
  yield all(botList.map(uuid => commandFence(uuid)))
  if (occ.connected || occ.connecting) {
    try {
      // make sure bots are disconnected, so that they turn off in the charger
      yield call(DeviceMgr.disconnectedFromAllBotsOnBridge)
      yield all(
        botList.map(uuid =>
          waitForBotStateWithTimeout(uuid, true, { available: true }, 30000)
        )
      )
      yield call(DeviceMgr.disconnectFromBridge)
    } catch {
      console.warn('Could not disconnect all bots from OCC.')
    }
  }
  yield put({
    type: BOTZ_PERIPHERAL_DISCONNECT_ALL_SUCCESS,
    waitPutMarker,
  })
  yield put(modalHide())
}

function* allowingOCCBotConnection({ allow }) {
  const [connected, bots, botsAllowedToConnect] = yield select(s => [
    s.botz.occ.connected,
    Object.values(s.botz.byIds),
    occBotsAllowedToBeConnectedSelector(s),
  ])
  if (connected) {
    if (allow) {
      // go through each bot, and allow connection to bots that pass
      for (let bot of bots) {
        if (bot.isOCC) {
          yield call(
            DeviceMgr.setBotConnectionOnBridge,
            bot.uuid,
            botsAllowedToConnect.includes(bot.uuid)
          )
        }
      }
    } else {
      yield call(DeviceMgr.disconnectedFromAllBotsOnBridge)
    }
  }
}

function* batteryPoll() {
  while (true) {
    const botIds = yield select(s => Object.keys(s.botz.byIds))
    for (const uuid of botIds) {
      const ready = yield select(s => s.botz.byIds[uuid].ready)
      if (
        ready &&
        !DeviceMgr.currUpdatingBot()
      ) {
        try {
          yield getValueFromBotAndSetState(uuid, 'battery',  b => b.get_batteryState().then( s => s.remainingPower ))
        } catch (err) {
          warn('Battery poll fail', err)
        }

        yield timeout(10000)
      }
    }
    yield timeout(10000)
  }
}

async function analyzeBotUpdates(uuid) {
  return DeviceMgr.analyzeBotForUpdates({
    uuid,
    ...(await marshallEvoUpdateAssets()),
  })
}

function* waitForBotsInactivity(milliseconds = 5000) {
  log(`Waiting for bots inactivity for`, milliseconds)
  while (true) {
    const [timeout] = yield race([
      delay(milliseconds),
      take(BOTZ_BOT_STATE_CHANGED),
    ])
    if (timeout) {
      break
    }
  }
  log(`Finished waiting for bots inactivity for`)
}

function* botIsOnCharger(uuid) {
  try {
    const charging = yield getValueFromBotAndSetState(uuid, 'charging', b => b.get_chargerState().then( s => s.state == "Connected") )
    return charging
  } catch (e) {
    console.warn('Could not determine charging status of bot', uuid, e)
    return false
  }
}

function* occScanForBots() {
  yield put({ type: BOTZ_OCC_BOT_SCAN_BEGIN })
  const selectedBots = []
  const discardedBots = []
  try {
    // wait for scanning to produce results
    yield waitForBotsInactivity()

    const bots = DeviceMgr.botUUIDs().reduce((bots, uuid) => {
      bots[uuid] = DeviceMgr.botStateForUUID(uuid)
      return bots
    }, {})
    // prefer any bots already connected to bridge -- they would just be reconnected to anyway, so
    // this speeds things up in some cases (OCC was disconnected involuntarily)
    // TODO: later, we can use RSSI or something to adjust our preference on first bot picks
    const botsToKeepConnected = new Set(
      DeviceMgr.bridgeBotsToKeepConnected().filter(uuid => {
        const bot = bots[uuid]
        return bot?.connected || bot?.connecting || bot?.available
      })
    )
    // choose to connect to any bots that are available, up to the max number of connected bots
    // any "extra" bots will be put into reserve, in case our initial choices don't work out
    const botsInReserve = []
    for (const bot of Object.values(bots).filter(bot => bot.available)) {
      if (botsToKeepConnected.size < MAX_CONNECTED_BRIDGE_BOTS) {
        botsToKeepConnected.add(bot.uuid)
      } else {
        botsInReserve.push(bot.uuid)
      }
    }

    yield call(DeviceMgr.setBotsToKeepConnectedOnBridge, [
      ...botsToKeepConnected,
    ])

    // go through, and check for connectivity and charger state, and add / remove bots based on that
    const botsChecked = new Set()
    while (true) {
      yield waitForBotsInactivity()
      const uncheckedBots = DeviceMgr.bridgeBotsToKeepConnected()
        .filter(uuid => !botsChecked.has(uuid))
        .map(uuid => DeviceMgr.botStateForUUID(uuid))
      const botsToRemove = []

      // any bots that are not connected yet should be removed
      botsToRemove.push(
        ...uncheckedBots.filter(bot => !bot.connected).map(bot => bot.uuid)
      )

      // make sure any connected bots are charging
      const uncheckedConnectedBots = uncheckedBots
        .filter(bot => bot.connected)
        .map(bot => bot.uuid)
      const chargeResults = yield all(
        uncheckedConnectedBots.map(botIsOnCharger)
      )
      for (let i = 0; i < uncheckedConnectedBots.length; ++i) {
        const uuid = uncheckedConnectedBots[i]
        if (chargeResults[i]) {
          botsChecked.add(uuid)
        } else {
          botsToRemove.push(uuid)
          console.warn('Bot', uuid, 'was not on charger -- disconnecting...')
        }
      }

      // handle any bots that didn't make the cut, and replace with any of our reserves
      if (botsToRemove.length > 0) {
        for (const uuid of botsToRemove) {
          discardedBots.push(uuid)
          yield call(DeviceMgr.disconnectFromAnEvo, uuid)
          if (botsInReserve.length > 0) {
            DeviceMgr.keepConnectedToBotOnBridge(botsInReserve.pop())
          }
        }
      } else {
        break
      }
    }

    if (discardedBots.length > 0) {
      console.warn('Discarded bots from scan', JSON.stringify(discardedBots))
    }
    if (botsInReserve.length > 0) {
      console.warn('Found extraneous bots', JSON.stringify(botsInReserve))
    }
    selectedBots.push(...DeviceMgr.bridgeBotsToKeepConnected())

    // our left over reserve bots to our discarded list, in addition to the bots that didn't make the cut
    discardedBots.push(...botsInReserve)

    yield all(selectedBots.map(uuid => onBotConnected({ uuid, isOCC: true })))
  } finally {
    // if we throw or get cancelled, make sure that we end the scanning
    yield put({ type: BOTZ_OCC_BOT_SCAN_ENDED, selectedBots, discardedBots })
  }
}

function* occScanForBotsUnlessDisconnected() {
  yield race([
    occScanForBots(),
    take(a => a.BOTZ_OCC_STATE_CHANGED && a.stateDelta.connected === false),
  ])
}

function* getEvoStats(uuid) {
  if( DeviceMgr.botForUUID(uuid) instanceof RobotLegacy ) {
    console.warn("Cannot retrieve stats from a legacy robot, firmware update must happen first.")
    return
  }

  const label = `stats-download-time-${uuid}`
  console.time(label)
  try {
    let statBinary = undefined
    let triesLeft = 4
    while (triesLeft-- > 0) {
      try {
        statBinary = yield callOnBot(uuid,
          b => b.faltas.downloadFile(
            '/user/stats.bin',
            undefined, // no callback needed
            true // allowMissingDescriptorFile - there is no descriptor for files that were created by the firmware itself
            )
          )
        break
      } catch (e) {
        if (triesLeft > 0) {
          console.warn('Retrying stats download for', uuid)
        } else {
          throw e
        }
      }
      yield delay(1000)
    }

    const stats = new EvoStatisticsFile(statBinary)
    return {
      distanceLineFollowing: stats.distance.lineFollowing,
      distanceFreeMovement: stats.distance.freeMovement,

      intersectionLineEnd: stats.intersections.lineEnd,
      intersectionTLeft: stats.intersections.tLeft,
      intersectionTRight: stats.intersections.tRight,
      intersectionTEnd: stats.intersections.tEnd,
      intersectionPlus: stats.intersections.plus,

      eventPaperCalibrationSucceeded: stats.counters.paperCalibrationSucceeded,
      eventTotalCalibrationFailed: stats.counters.totalCalibrationFailed,
      eventDigitalCalibrationSucceeded:
        stats.counters.digitalCalibrationSucceeded,
      eventLineColorChange: stats.counters.lineColorChange,
      eventSurfaceColorChange: stats.counters.surfaceColorChange,
      eventColorCode: stats.counters.colorCode,
      eventObstacle: stats.counters.obstacle,
      eventMessageFromOtherRobotReceived:
        stats.counters.messageFromOtherRobotReceived,
      eventAudioTriggered: stats.counters.audioTriggered,
      eventRobotPickedUp: stats.counters.robotPickedUp,
      eventUserProgramFlashLoadSucceeded:
        stats.counters.userProgramFlashLoadSucceed,
      eventUserProgramFlashLoadAttempts:
        stats.counters.userProgramFlashLoadAttempts,
      eventUserProgramStarted: stats.counters.userProgramStarted,
      eventSmartSkinConnected: stats.counters.smartSkinConnected,
      eventBoot: stats.counters.boot,
      eventShutdown: stats.counters.shutdown,

      timeCharging: stats.time.Operation_Charging,
      timeIdle: stats.time.Operation_Idle,
      timeLineFollowing: stats.time.Operation_LineFollowing,
      timeFreeMovement: stats.time.Operation_FreeMovement,
      timeBleConnected: stats.time.BLE_Connected,
      timeBleDisconnected: stats.time.BLE_Standalone,
      timeSurfaceDigital: stats.time.Surface_Digital,
      timeSurfacePaper: stats.time.Surface_Paper,
      timeSmartskinConnected: stats.time.SmartSkin_Connected,
      timeSmartskinDisconnected: stats.time.SmartSkin_Disconnected,

      failureColorSensor: stats.failureCounters.colorSensor,
      failureMultiplexer: stats.failureCounters.multiplexer,
      failureLedstrip: stats.failureCounters.ledStrip,
      failureBle: stats.failureCounters.ble,
    }
  } catch (e) {
    console.error('Stats download failure', e)
  } finally {
    console.timeEnd(label)
  }
}

function* postEvoStats() {
  try {
    const [accountId, bots, occId] = yield select(s => [
      s.user.accountId,
      Object.values(s.botz.byIds),
      s.botz.occ.ready ? s.botz.occ.serial : undefined,
    ])
    const logs = bots
      .filter(bot => bot.ready && !bot.updateIsNeeded && bot.stats)
      .map(bot => ({
        botUuid: bot.uuid,
        accountId,
        occId,
        firmware: bot.firmware.toString(),
        ...bot.stats,
      }))
    yield call(api.sendBotConnectionLogs, { logs })
  } catch (e) {
    console.warn('Could not post bot stats', e)
  }
}

function* updateUserStateForBotUpdate({ assetCRC }) {
  const botsNeedUpdate = yield select(botzBotsAreConnectedThatNeedUpdates)
  if (botsNeedUpdate) {
    yield put(userSetMiscValuesRequest({ lastBotUpdateAssetCRC: null }))
  } else {
    if (assetCRC) {
      yield put(userSetMiscValuesRequest({ lastBotUpdateAssetCRC: assetCRC }))
    } else {
      console.warn(
        'No Asset CRC -- cannot set connection lastBotUpdateAssetCRC'
      )
    }
  }
}

function* onBotConnected({ uuid, isOCC, wasUpdating = false }) {
  try {
    const startTime = Date.now()
    log(`Starting bot setup`, uuid)

    // FIXME: investigate if these can be called with one callOnBot... without breaking things (sensitive timing)
    yield callOnBot(uuid, b => b instanceof Robot && b.set_behaviour(BehaviourTypeEnum.School))
    // yield callOnBot(uuid, b => b instanceof Robot && b.set_buttonOverride(ButtonOverrideEnum.Overriden))
    yield callOnBot(uuid, b => b.StopExecution())
    yield getValueFromBotAndSetState(uuid, 'charging', b => b.get_chargerState().then( s => s.state == "Connected") )
    yield getValueFromBotAndSetState(uuid, 'battery', b => b.get_batteryState().then( s => s.remainingPower ) )

    log(`Finished bot setup`, uuid, Date.now() - startTime)

    const stateDelta = {
      ready: true,
      stats: yield getEvoStats(uuid),
      hasLegacyFirmware: !!(DeviceMgr.botForUUID(uuid) instanceof RobotLegacy)
    }

    stateDelta.lastUpdateError = ''

    if (stateDelta.stats) {
      yield put({ type: _BOTZ_POST_STATS_REQUEST })
    }

    yield put({
      type: BOTZ_BOT_STATE_CHANGED,
      uuid,
      stateDelta,
    })

    /* This is handled by UI now
    // handle update response
    if (!isOCC && !wasUpdating) {
      try {
        yield handleBLEEvoUpdate({ uuid })
      } catch (err) {
        yield put({
          type: BOTZ_BOT_STATE_CHANGED,
          uuid,
          stateDelta: { lastUpdateError: errorMessageFrom(err) },
        })
        yield call(DeviceMgr.disconnectFromAnEvo, uuid)
      }
    }
    */

    yield put(userSetMiscValuesRequest({ hasConnectedToABot: true }))
  } catch (err) {
    console.error(err)
  }
}

function* onSetBotConnectionFilter() {
  const [bots, allowBotConnection] = yield select(s => [
    Object.values(s.botz.byIds),
    s.botz.occ.allowBotConnection && s.botz.occ.ready,
  ])
  if (allowBotConnection) {
    for (let bot of bots.filter(bot => bot.isOCC)) {
      const shouldBeConnected = true
      if (bot.connected && !shouldBeConnected) {
        yield setLEDs(bot.uuid, BotStatusColors.BLACK)
      }
      try {
        yield call(
          DeviceMgr.setBotConnectionOnBridge,
          bot.uuid,
          shouldBeConnected
        )
      } catch {
        console.warn('Could not set bot connected.')
      }
    }
  }
}

async function showQueuedEvoUpdateReconnectDialog({ phase }) {
  return new Promise(resolve => {
    store.dispatch(
      modalShow(
        legacyDialogBoxModal({
          teal: 'Update complete!',
          gray:
            phase === 'firmware'
              ? 'Please reconnect to the robot to continue.'
              : 'Your Evo’s bluetooth software has also been updated, reconnect again to your bot.',
          buttonText: 'Connect Evo',
          onClose: resolve,
        })
      )
    )
  })
}

function* updateBot({
  uuid,
  onRePair,
  onRePairChosen = undefined,
  forceUpdate = false,
  refreshState = false,
  onProgress,
}) {
  const wasOCC = DeviceMgr.botStateForUUID(uuid).isOCC
  try {
    const assetInfo = yield call(marshallEvoUpdateAssets)
    const manifest = yield select(s => s.botz.manifest)

    // NOTE: update progress is hooked into the BOTZ_UPDATE_BOT_PROGRESS action
    yield call(DeviceMgr.updateBot, {
      manifest,
      uuid,
      onReconnectBLEReconnectionNeeded: async phase => onRePair({ phase }),
      onReconnectionChosen: hasChosen =>
        onRePairChosen && onRePairChosen(hasChosen),
      onProgress,
      forceUpdate,
    })

    // Update the user state, so that we know the last update the user did
    yield put(
      userSetMiscValuesRequest({
        lastBotUpdateSucceeded: true,
        lastBotUpdateAssetCRC: assetInfo.assets.find(
          a => a.fileName === 'assets'
        )?.fileCRC,
      })
    )
  } catch (e) {
    console.warn('updateBot failed', e)

    yield put(
      userSetMiscValuesRequest({
        lastBotUpdateSucceeded: false,
        lastBotUpdateAssetCRC: null,
      })
    )

    // modify error to reflect circumstances better
    const botState = DeviceMgr.botStateForUUID(uuid)
    if (wasOCC) {
      if (!DeviceMgr.bridgeIsConnected()) {
        throw new Error('Communicator disconnected')
      } else if (!botState.connected) {
        throw new Error('Disconnected')
      }
    } else {
      if (!botState.connected) {
        throw new Error('Disconnected')
      }
    }
    throw e
  } finally {
    if (refreshState) {
      yield onBotStateUpdated({
        state: DeviceMgr.botStateForUUID(uuid),
        wasUpdating: true,
      })
    }
  }
}

async function marshallEvoUpdateAssets() {
  if (!AssetsMgr.hasAssets) {
    await AssetsMgr.refresh()
  }
  const assets = AssetsMgr.assets
  return {
    assets: assets
      .filter(asset => asset.platform === 'evo')
      .map(asset => ({
        url: asset.url,
        ...asset.metaData,
        handle: asset.handle,
      })),
  }
}

function* waitForOCCDisconnect() {
  if (!(yield select(s => s.botz.occ.disconnected))) {
    yield take(
      a => a.type === BOTZ_OCC_STATE_CHANGED && a.stateDelta.disconnected
    )
  }
  return true
}

function* waitForBotStateWithTimeout(uuid, isOCC, state, timeout = 10000) {
  console.debug('Saga waiting for bot state for', uuid, state, isOCC)
  const [timedOut, occDisconnected] = yield race([
    delay(timeout, true),
    isOCC ? waitForOCCDisconnect() : take(() => false),
    waitForBotState(uuid, state),
  ])
  if (timedOut || occDisconnected) {
    console.debug(
      'Saga waiting for bot state FAILED for',
      uuid,
      occDisconnected ? 'OCC disconnected' : 'timed out'
    )
    if (occDisconnected) {
      throw new Error('Communicator disconnected')
    }
  }
  return !timedOut
}

function* onBotGetBriefing({ uuids }) {

  for (const uuid of uuids) {
    let manifest = yield select(s => s.botz.manifest)

    if(!manifest) {
      manifest = yield call(DeviceMgr.getManifest);

      yield put({
        type: BOTZ_MANIFEST_RETRIEVED,
        manifest,
      })
    }

    const briefing = yield call(DeviceMgr.getBriefing, uuid, manifest)

    yield put({
      type: BOTZ_BOT_STATE_CHANGED,
      uuid,
      stateDelta: {
        briefing,
      },
    })
  }
}

// use takeLeading
function* onBotUpdateRequest({ uuids, onProgress }) {
  const keepConnectedBots = DeviceMgr.bridgeBotsToKeepConnected()
  const isOCC = DeviceMgr.bridgeIsConnected()

  function* miniReset(uuid) {
    yield callOnBot(uuid, b => b.StopExecution() )
    yield delay(2000)
  }

  const startTime = Date.now()

  // Set bot queued LED
  for (const uuid of uuids) {
    // yield callOnBot(uuid, b => b.StopExecution() ) this used to stop only the animation, is it still needed?
    yield setLEDs(uuid, BotStatusColors.QUEUED_FOR_UPDATE)

    yield put({
      type: BOTZ_BOT_STATE_CHANGED,
      uuid,
      stateDelta: {
        updateIsInProgress: true,
      },
    })
  }

  if (isOCC) {

    // disconnect all OCC bots - this will improve performance of each bot updating
    // NOTE: the DeviceMgr update function disconnects any superfluous bots as well, but
    // doing it once here will prevent disconnecting and re-connecting all 18 bots after
    // each update

    try {
      yield call(DeviceMgr.disconnectFromAllBots)
      yield waitForBotsInactivity()
      yield call(DeviceMgr.enableBridgeSingleBotPerformanceMode, true)
    } catch (e) {
      console.error('Could not disconnect from all bots', e)
    }
  }

  let communicatorError = ''
  for (const uuid of uuids) {
    // make sure bot is ready (connected, and not setting up)
    if (isOCC) {
      yield call(DeviceMgr.keepConnectedToBotOnBridge, uuid)
    }

    // console.log('--------------------- stopped updateBot --------------------------');
    // return;

    const MAX_TRIES = 4

    let botError = undefined
    for (let i = 0; i < MAX_TRIES; ++i) {
      const isLastTry = i === MAX_TRIES - 1
      const nextToLastTry = i === MAX_TRIES - 2
      try {
        console.debug(
          'Staring update pass on',
          i + 1,
          'of',
          MAX_TRIES,
          'on',
          uuid
        )

        if (
          !(yield waitForBotStateWithTimeout(uuid, isOCC, { connected: true }))
        ) {
          throw new Error('Disconnected')
        }

        yield setLEDs(uuid, BotStatusColors.UPDATING)

        yield updateBot({
          uuid,
          onRePair: showQueuedEvoUpdateReconnectDialog,
          forceUpdate: isLastTry,
          onProgress,
          refreshState: true,
        })

        yield setLEDs(uuid, BotStatusColors.UPDATE_SUCCEEDED)

        console.debug('Finished update for', uuid)

        if (isOCC) {
          // Disconnect the bot again, so that it doesn't disrupt any other bots that may be updating next.
          yield call(DeviceMgr.disconnectFromAnEvo, uuid)
          if (
            !(yield waitForBotStateWithTimeout(uuid, isOCC, {
              connected: false,
            }))
          ) {
            throw new Error('Could not disconnect')
          }
        }

        const updatedDeviceName = yield call(DeviceMgr.getDeviceName, uuid)

        // we'll do a full check later, but for now, indicate that the update has succeeded for UI purposes.
        yield put({
          type: BOTZ_BOT_STATE_CHANGED,
          uuid,
          stateDelta: {
            updateIsNeeded: false,
            updateIsInProgress: false,
            updateFinished: true,
            lastUpdateError: '',
            name: updatedDeviceName
          },
        })

        console.debug('Finished update tear-down for', uuid)

        // we've succeeded, so make sure that any error messages for this bot are removed
        botError = undefined

        break
      } catch (e) {
        console.warn('Update error', e)
        const message = errorMessageFrom(e)
        if (message.toLowerCase().includes('communicator')) {
          communicatorError = message
        } else {
          botError = message

          // rest a few seconds, so that things can settle a bit
          yield waitForBotsInactivity()

          try {
            if (isLastTry) {
              yield put({
                type: BOTZ_BOT_STATE_CHANGED,
                uuid,
                stateDelta: { lastUpdateError: message },
              })
            }
            if (isOCC) {
              yield call(DeviceMgr.keepConnectedToBotOnBridge, uuid)
            }
            if (
              yield waitForBotStateWithTimeout(uuid, isOCC, { connected: true })
            ) {
              yield blinkBotLEDs({
                uuid,
                color: isLastTry
                  ? BotStatusColors.UPDATE_FAIL
                  : BotStatusColors.UPDATE_WARNING,
                times: 3,
              })
              // In call cases, disconnect -- for single bot, we want to disconnect so the user cannot use that bot,
              // for OCC, we want to disconnect so that other updates will not be interfered with...
              if (isLastTry) {
                yield setLEDs(uuid, BotStatusColors.UPDATE_FAIL)
                yield commandFence(uuid)
                yield call(DeviceMgr.disconnectFromAnEvo, uuid)
                if (
                  !(yield waitForBotStateWithTimeout(uuid, isOCC, {
                    connected: false,
                  }))
                ) {
                  throw new Error('Could not disconnect')
                }
              } else if (nextToLastTry && isOCC) {
                // Force device reset, so that hopefully things will be fresh again on reconnect
                yield call(DeviceMgr.forceEvoFirmwareRestartDestructive, uuid)
                yield waitForBotStateWithTimeout(uuid, isOCC, {
                  connected: false,
                })
              }
            }
          } catch (e2) {
            console.warn('Could not display error animation for Evo', uuid, e2)
          }
        }
      }

      if (communicatorError) {
        break
      }
    }

    if (communicatorError) {
      break
    }

    yield put({
      type: !!botError ? BOTZ_UPDATE_BOT_FAILURE : BOTZ_UPDATE_BOT_SUCCESS,
      uuid,
      timestamp: Date.now(),
      error: botError,
    })
  }

  console.debug('Bot updates ended in', durationStr(Date.now() - startTime))

  // if we've gotten a communicator error, do some cleanup
  if (communicatorError) {
    yield put({ type: BOTZ_UPDATE_BOT_CANCEL })
  } else {
    // if OCC is connected, reconnect to all of the bots
    if (DeviceMgr.bridgeIsConnected()) {
      try {
        yield call(DeviceMgr.enableBridgeSingleBotPerformanceMode, false)
      } catch (e) {
        console.error('Could not reset bot performance mode after update')
      }
      for (const uuid of keepConnectedBots) {
        yield call(DeviceMgr.keepConnectedToBotOnBridge, uuid)
      }
    }

    // hard refresh states, so that things are setup again (LEDs, etc) properly
    for (const uuid of DeviceMgr.botUUIDs()) {
      console.debug('Saga forcing bot state refresh for', uuid)
      yield onBotStateUpdated({
        state: DeviceMgr.botStateForUUID(uuid),
        wasUpdating: true,
      })
    }
  }
}

function* onUpdateOCCFirmware() {
  try {
    const firmware = yield getAssetWithBinary(
      a => a.platform === 'occ' && a.filePath.includes('firmware')
    )
    if (!firmware) {
      throw new Error('No firmware available')
    }

    yield prepareForFirmwareUpdate()
    yield call(DeviceMgr.updateBridgeFirmware, firmware.binary, true)
    yield put({ type: BOTZ_UPDATE_OCC_FIRMWARE_SUCCESS })
    yield put(modalShow(legacyDialogBoxModal({
      teal: `Communicator updated!`,
      gray: `Please pair to the Communicator again to finish the update.`,
      buttonText: 'Pair Now',
    })))
    yield take(modalHide)
    yield put(botzConnectToOCCWithBLE({}))
  } catch (err) {
    yield put({
      type: BOTZ_UPDATE_OCC_FIRMWARE_FAILURE,
      error: errorMessageFrom(err),
    })
  }
}

// ==== DEVICES PAGE HANDLERS =====

function colorForBotState(bot) {
  return bot.lastUpdateError
    ? BotStatusColors.READY_WITH_ERROR
    : bot.updateIsNeeded
    ? BotStatusColors.READY_WITH_UPDATE_NEEDED
    : BotStatusColors.READY
}

function* setLEDForBotState(bot) {
  yield setLEDs(bot.uuid, colorForBotState(bot))
}

function* onDevicesPageEnabled({ enabled }) {
  try {
    yield put(botzAllowOCCBotConnection({ allow: enabled }))
    if (enabled) {
      if (!AssetsMgr.hasAssets) {
        // Just call, don't wait on promise
        AssetsMgr.refresh()
      }
      const bots = yield select(s =>
        Object.values(s.botz.byIds).filter(bot => bot.ready)
      )
      for (let bot of bots) {
        yield put(
          botzStopExecution({ uuid: bot.uuid }) // TODO: call on bot directly?
        )
        yield setLEDForBotState(bot)
      }
    } else {
      const needsToDisconnectPeripheral = yield select(
        (s => s.botz.occ.connected && !s.botz.occ.ready) ||
          (s =>
            Object.values(s.botz.byIds).some(
              bot => !bot.isOCC && bot.connected && bot.updateIsNeeded
            ))
      )
      if (needsToDisconnectPeripheral) {
        yield put(botzDisconnectAllPeripherals())
      }

      // HACK: clear out last update errors for UI use
      yield put({ type: _BOTZ_CLEAR_ALL_LAST_ERRORS })
    }
  } catch (e) {
    console.error(e)
  }
}

function* onIDBotWithBlinkingLEDs({ uuid }) {
  const [bot, scanning, updating] = yield select(s => [
    s.botz.byIds[uuid],
    botzIsScanningForBotsSelector(s),
    botzBotsAreQueuedForUpdating(s),
  ])
  if (!scanning && !updating && bot.ready) {
    try {
      // flash bot, but interrupt if use does something
      yield race([
        blinkBotLEDs({
          uuid,
          color: BotStatusColors.FLASH_COLOR,
          endColor: colorForBotState(bot),
          times: 4,
        }),
        take([
          BOTZ_OCC_BOT_SCAN_BEGIN,
          BOTZ_UPDATE_BOTS_REQUEST,
          BOTZ_DEVICE_PAGE_ENABLED,
          BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST,
        ]),
      ])
    } finally {
      // if we were cancelled by another blinking request, make sure original color is set
      if (yield cancelled()) {
        yield setLEDForBotState(bot)
      }
    }
  }
}

function* onDevicesPageBotChargingOrReady({ uuid }) {
  const [
    devicesPageEnabled,
    bot,
    botsAreQueuedForUpdating,
  ] = yield select(s => [
    s.botz.devicesPageEnabled,
    s.botz.byIds[uuid],
    botzBotsAreQueuedForUpdating(s),
  ])
  // NOTE: we skip setup if the bot needs and update and is NOT an OCC bot, as we'll launch immediately into an update,
  // which will take care of additional setup
  const skipDevicePageSetup =
    (!bot.isOCC && bot.updateIsNeeded) ||
    botsAreQueuedForUpdating ||
    DeviceMgr.currUpdatingBot() === uuid
  if (devicesPageEnabled && !skipDevicePageSetup && bot.ready) {
    // HACK: if the bot does not support GetValue (that is, a 1.12 bot), just assume we're
    // charging. The fancy pull-out-of-charger behaviour is only supported on 1.15+ bots

    // Disabling all this for now https://ozobot.atlassian.net/browse/CLASS-3252
    /*
    if (bot.charging) {
      yield callOnBot(uuid, b => b.StopExecution()) // this used to stop only animation, is it an issue?
      yield timeout(2000)
      yield setLEDForBotState(bot)
    } else {
      // This implements https://ozobot.atlassian.net/browse/CLASS-2823
      // where if the user powers a bot on again out of the cradle, we should disconnect from it
      yield playEDUBaseBehaviour(bot.uuid)
      yield commandFence(bot.uuid)
      yield call(DeviceMgr.disconnectFromAnEvo, bot.uuid)
      yield put({
        type: BOTZ_BOT_STATE_CHANGED,
        uuid: bot.uuid,
        stateDelta: {
          isSelectedByScan: false,
          isDiscardedByScan: false,
        },
      })
    }
    */
  }
}

// ==================================

export default function*() {
  yield all([
    // take every, so that waiting on commands will work -- let the OS work out any conflicts
    fork(function*() {
      yield takeEvery(
        BOTZ_PERIPHERAL_CONNECTION_REQUEST,
        onPeripheralConnectionRequest
      )
    }),
    fork(function*() {
      yield takeLatest(BOTZ_OCC_ALLOW_BOT_CONNECTION, allowingOCCBotConnection)
    }),
    fork(function*() {
      yield takeEvery(
        BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST,
        onPeripheralDisconnectAllRequest
      )
    }),
    fork(function*() {
      yield takeEvery(BOTZ_BOT_RENAME_REQUEST, onRenameRequest)
    }),
    fork(function*() {
      yield takeLatest(
        _BOTZ_UPDATE_STATE_FOR_COMMAND_REQUEST,
        onUpdateStateForCommand
      )
    }),
    fork(function*() {
      yield takeLatest(
        BOTZ_OCC_SET_BOT_CONNECTION_FILTER,
        onSetBotConnectionFilter
      )
    }),

    fork(handleDeviceSubscriptions),
    fork(batteryPoll),

    fork(function*() {
      yield takeLeading(BOTZ_UPDATE_OCC_FIRMWARE_REQUEST, onUpdateOCCFirmware)
    }),

    fork(function*() {
      yield takeLeading(
        // spawn loop if not already going
        BOTZ_UPDATE_BOTS_REQUEST,
        onBotUpdateRequest
      )
    }),

    fork(function*() {
      yield takeLatest(
        BOTZ_UPDATE_BOT_BRIEFING_REQUEST,
        onBotGetBriefing
      )
    }),

    // === devices page stuff ===

    fork(function*() {
      yield takeLatest(BOTZ_DEVICE_PAGE_ENABLED, onDevicesPageEnabled)
    }),
    fork(function*() {
      yield takeLatest(
        BOTZ_REQUEST_ID_BOT_WITH_BLINKING_LEDS,
        onIDBotWithBlinkingLEDs
      )
    }),
    fork(function*() {
      yield takeEveryBotStateChange(
        { ready: true, charging: true },
        onDevicesPageBotChargingOrReady
      )
    }),
    fork(function*() {
      yield debounce(5000, _BOTZ_POST_STATS_REQUEST, postEvoStats)
    }),
  ])
}
