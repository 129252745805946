import React from 'react'
import { useDispatch } from 'react-redux'
import { View, Text, TextStyle } from 'react-native'
import { Link } from 'components/Router'
import Button, { ButtonRoles } from 'components/Form/Button2'
import { ModalFrame } from 'components/modal'
import { modalHide } from 'store/modal'
import s from 'styles'


export interface WithOptionsModalButton {
  component: string,
  text: string,
  url?: string,
  role?: ButtonRoles,
  action?: () => void,
}

const WithOptionsModal = (props: {
  title: string,
  headline?: string,
  headlineColor?: TextStyle,
  text?: string,
  buttons: Array<WithOptionsModalButton>,
}) => {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(modalHide())

  return (
    <ModalFrame title={props.title} size="medium">
      <View style={[s.mb20]}>
        <Text style={[s.mb8, s.f28, s.textBold, props.headlineColor || s.textRed, s.textCenter]}>
          {props.headline}
        </Text>
        <Text style={[s.f16, s.textCenter]}>{props.text}</Text>
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        {props.buttons.map((item, i) => {
          switch (item.component) {
            case 'ButtonLink':
              return (
                <Link key={i} to={item.url}>
                  <View
                    style={[
                      s.m8,
                      s.py8,
                      s.px20,
                      s.alignCenter,
                      s.bgTeal,
                      s.br5,
                    ]}
                  >
                    <Text style={[s.f16, s.textWhite, s.textBold]}>
                      {item.text}
                    </Text>
                  </View>
                </Link>
              )
            case 'ButtonClose':
              return (
                <View style={s.m8}>
                  <Button
                    key={i}
                    role={item.role}
                    text={item.text}
                    onPress={closeModal}
                  />
                </View>
              )
            case 'ButtonAction':
              return (
                <View style={s.m8}>
                  <Button
                    key={i}
                    role={item.role}
                    text={item.text}
                    onPress={() => {
                      item.action()
                      closeModal()
                    }}
                  />
                </View>
              )
            default:
              return undefined
          }
        })}
      </View>
    </ModalFrame>
  )
}

export default WithOptionsModal
