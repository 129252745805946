import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { Button, Input } from 'components/Form'
import { Image } from 'components/ReactNative'
import RTEMarkdown from './RTEMarkdown'
import SubmissionAttachments from './SubmissionAttachments'
import SubmissionLabel from './SubmissionLabel'
// import LessonStepsGroupItem from '../../../components/LessonSubmission/LessonStepsGroupItem'
import RemoveX from './RemoveX'
import withSubmissionForm from './withSubmissionForm'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import s from 'styles'
import { some } from 'lodash'

const StepItem = ({
  isOpen = true,
  instructions,
  hasAttachments,
  attachmentsText,
  attachments,
  attachmentsFolder,
  goal,
  hasGoal,
  number,
  onRemove,
  update,
  hasError,
  isDesktop,
}) => {
  const [open, setOpen] = React.useState(isOpen)

  const toggleOpen = () => setOpen(!open)
  const updateStep = obj => {
    update(obj)
  }

  return (
    <View style={[s.mb20, s.ml30, isDesktop ? s.p32 : s.p8, s.bgWhiteDarker]}>
      <View
        style={[
          s.positionAbsolute,
          s.w50,
          s.h50,
          s.br25,
          s.bgTeal,
          s.alignCenter,
          s.justifyCenter,
          { left: isDesktop ? -30 : -10, top: 10 },
        ]}
      >
        <Text style={[s.f25, s.textWhite]}>{number}</Text>
      </View>
      <View style={[s.alignEnd]}>
        <TouchableOpacity onPress={toggleOpen}>
          <Image
            source={require('images/caret-teal.svg')}
            style={[
              {
                height: 14,
                width: 24,
                transform: [{ rotate: isOpen ? '0' : '180deg' }],
              },
            ]}
          />
        </TouchableOpacity>
      </View>
      {open ? (
        <View>
          <View style={[!isDesktop && s.ml45]}>
            <SubmissionLabel text="Instruction" hasError={hasError} />
          </View>
          <View style={isDesktop ? [] : [s.mt20]}>
            <RTEMarkdown
              name="instructions"
              value={instructions}
              onFormInputChangeText={updateStep}
            />
          </View>
          {hasAttachments && (
            <SubmissionAttachments
              attachments={attachments}
              attachmentsFolder={attachmentsFolder}
              onChange={attachments => updateStep({ attachments })}
              text={attachmentsText}
            />
          )}
          {hasGoal && (
            <Input
              name="goal"
              label="Goal"
              value={goal}
              onFormInputChangeText={updateStep}
            />
          )}
          <View style={[s.alignEnd]}>
            <RemoveX onPress={onRemove} />
          </View>
        </View>
      ) : (
        <View style={[!isDesktop && s.pl45]}>
          <Text style={[s.mb4, s.f16, s.textBold]}>Instruction</Text>
          <Text>{instructions || '(Empty)'}</Text>
        </View>
      )}
    </View>
  )
}

const SubmissionStepsGroup = ({
  field,
  text,
  form,
  formAddItem,
  formUpdateItem,
  formRemoveItem,
  ...props
}) => {
  const { isDesktop } = useDetectDeviceType()
  return (
    <View>
      {form[field].map((step, i) => (
        <StepItem
          key={`${field}:${i}:${step.instructions.substring(0, 5)}`}
          number={i + 1}
          attachmentsFolder={form.attachmentsFolder}
          {...step}
          {...props}
          update={obj => formUpdateItem(i, obj)}
          onRemove={() => formRemoveItem(i)}
          hasError={i === 0 && some(form.errors, { field })}
          isDesktop={isDesktop}
        />
      ))}
      <View style={[s.alignStart]}>
        <Button
          icon="plus"
          text="Add Step"
          rounded={true}
          onPress={formAddItem}
        />
      </View>
    </View>
  )
}

export default withSubmissionForm(SubmissionStepsGroup)

// class LessonStepsGroup extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//     this.makeEmptyStep = this.makeEmptyStep.bind(this)
//   }

//   makeEmptyStep() {
//     return {
//       collapsed: false,
//       duration: this.props.hasTime ? '01' : undefined,
//       instructions: '',
//       goal: this.props.hasGoal ? '' : undefined,
//       attachments: [],
//     }
//   }

//   render() {
//     return (
//       <View style={[s.bgWhite]}>
//         <View>
//           <Text style={[s.textTeal, s.f18]}>{this.props.heading}</Text>
//           <View
//             style={{
//               marginTop: 20,
//               marginBottom: 10,
//             }}
//           >
//             {this.props.steps.map((step, i) => (
//               <View
//                 style={{
//                   marginBottom: 10,
//                 }}
//               >
//                 <LessonStepsGroupItem
//                   arrayKey={i}
//                   field={this.props.field}
//                   hasTime={this.props.hasTime}
//                   hasGoal={this.props.hasGoal}
//                   goalSecondaryText={this.props.goalSecondaryText}
//                   hasAttachments={this.props.hasAttachments}
//                   hasRTI={this.props.hasRTI}
//                   instructionHeading={this.props.text || ''}
//                   goalHeading={this.props.goalHeading}
//                   makeEmptyStep={
//                     this.props.required ? this.makeEmptyStep : undefined
//                   }
//                 />
//               </View>
//             ))}
//           </View>
//           <View
//             style={[
//               {
//                 flexDirection: 'row-reverse',
//               },
//             ]}
//           >
//             <Button
//               text={'Add Step'}
//               isAdd={true}
//               onPress={() =>
//                 this.props.addField(this.props.field, this.makeEmptyStep())
//               }
//             />
//           </View>
//         </View>
//       </View>
//     )
//   }
// }
