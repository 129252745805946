import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { formattedLesson } from './util'

/**
 * Get lessons endpoints
 * searchLessons > get(`lessons${q}`)
 * getLessons > get(`lessons`)
 * getLesson > get(`lessons/${id}.json`)
 * getMyLessons > authGet(`lessons?author=${author}`)
 * submitLesson > authPost(`lessons`, lesson)
 * resubmitLesson > authPut(`lessons/${id}.json`, lesson)
 * deleteDraft > authDelete(`lessons/${id}.json`)
 * publishLesson > authPatch(`lessons/${id}.json`, { hidden: false })
 * listLessonSeries > authGet(`lessonGroup/${uri}`)
 * getLessonSeries > authGet(`lessonGroup/${uri}`)
 * createLessonSeriesBookmark > authPost(`lessonGroup/${uri}/save`)
 * removeLessonSeriesBookmark > authPost(`lessonGroup/${uri}/unsave`)
 * getSavedLessons > authGet(`users/${accountId}/lessons`)
 * updateSavedLessons > authPatch(`users/${accountId}/lessons`, lessons)
 *
 */

const initialState = {
  isFetching: false,
  search: {
    query: '',
    filter: {},
    sort: '',
    limit: '',
    page: '',
    total: '',
  },
  cache: {
    'query=text': [], // user search url
    '': [],
  },
  results: [],
  owned: [],
  saved: [],
  saveQueue: [],
  byId: {},
  collectionByUri: {},
  usersRatingById: {},
  publicRatingById: {},
  blockedStatusById: {},
  categorizedResults: [],
}

const extractLessonsFromClasses = (state, classes) => {
  const byIDs = state.byId
  for (const c of classes) {
    if (c.lessons) {
      for (const l of c.lessons) {
        byIDs[l.id] = {
          ...(byIDs[l.id] || {}),
          ...l,
        }
      }
    }
  }
}

const lessons = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    searchRequest(state, { payload }) {
      state.isFetching = true
      state.search = payload
    },
    searchSuccess(state, { payload }) {
      const lessons = payload.data.map(formattedLesson)
      const lessonsById = keyBy(lessons, 'id')
      state.isFetching = false
      state.byId = {
        ...state.byId,
        ...lessonsById,
      }
      state.search.total = payload.meta.totalResults
      state.results = Object.keys(lessonsById)
      state.categorizedResults = payload.meta.categorized || []
      for(const lesson of lessons) {
        delete state.blockedStatusById[lesson.id]
      }
    },
    listRequest(state, { payload }) {
      state.isFetching = true
      state.search = payload
    },
    listSuccess(state, { payload }) {
      const lessons = payload.data.map(formattedLesson)
      const lessonsById = keyBy(lessons, 'id')
      state.isFetching = false
      state.byId = {
        ...state.byId,
        ...lessonsById,
      }
      for(const lesson of lessons) {
        delete state.blockedStatusById[lesson.id]
      }
    },
    viewRequest(state, { payload }) {
      // Bob - I added ,{payload} to get rid of typescript errors
      state.isFetching = true
    },
    viewSuccess(state, { payload }) {
      state.isFetching = false
      state.byId = {
        ...state.byId,
        [payload.id]: formattedLesson(payload),
      }
      delete state.blockedStatusById[payload.id]
      state.lastUpdatedId = undefined
    },
    viewBlocked(state, { payload }) {
      state.blockedStatusById[payload.id] = true
    },
    createRequest(state) {
      state.isFetching = true
    },
    createSuccess(state, { payload }) {
      state.isFetching = false
      state.byId = {
        ...state.byId,
        [payload.id]: formattedLesson(payload),
      }
      state.lastUpdatedId = payload.id
    },
    updateRequest(state) {
      state.isFetching = true
    },
    updateSuccess(state, { payload }) {
      state.isFetching = false
      state.byId = {
        ...state.byId,
        [payload.id]: formattedLesson(payload),
      }
      state.lastUpdatedId = payload.id
    },
    deleteRequest(state) {
      state.isFetching = true
    },
    deleteSuccess(state, action) {
      state.isFetching = false
      state.owned = state.owned.filter(id => id !== action.payload.id)
      delete state.byId[action.payload.id]
    },
    listCollectionRequest(state) {
      state.isFetching = true
    },
    listCollectionSuccess(state, { payload }) {
      const uris = keyBy(payload, 'uri')
      state.isFetching = false
      state.collectionByUri = uris

      // Add collections to lessons
      for (let collection of payload) {
        state.byId[collection.id] = {
          ...state.byId[collection.id],
          ...collection,
        }
      }
    },
    listOwnedRequest(state) {
      state.isFetching = true
    },
    listOwnedSuccess(state, { payload }) {
      const lessons = payload.map(formattedLesson)
      const owned = keyBy(lessons, 'id')
      state.isFetching = false
      state.owned = Object.keys(owned)
      state.byId = {
        ...state.byId,
        ...owned,
      }
    },
    listSavedRequest(state) {
      state.isFetching = true
    },
    listSavedSuccess(state, { payload }) {
      const lessons = payload.map(formattedLesson)
      const saved = keyBy(lessons, 'id')
      state.isFetching = false
      state.saved = Object.keys(saved)
      for (let lesson of lessons) {
        state.byId[lesson.id] = {
          ...state.byId[lesson.id],
          ...lesson,
        }
      }
    },
    updateSavedRequest(state, { payload }) {
      const ids = payload.addIDs || [payload.id]
      const addOnly = !!payload.addIDs
      for (const id of ids) {
        const lesson = state.byId[id]
        state.isFetching = true
        if (state.saved.includes(id)) {
          if (addOnly) {
            continue
          }
          state.saved = state.saved.filter(e => e !== id)
          state.saveQueue.push({ id, add: false })
          if (lesson) {
            lesson.userSaveCount = Math.max(0, lesson.userSaveCount - 1)
          }
        } else {
          state.saved.push(id)
          state.saveQueue.push({ id, add: true })
          if (lesson) {
            lesson.userSaveCount = lesson.userSaveCount + 1
          }
        }
      }
    },
    updateSavedSuccess(state, { payload }) {
      state.isFetching = false
      state.saveQueue = state.saveQueue.slice(payload.queueSize)
    },

    rateLessonRequest: (state, { payload }) => {
      const list = payload.applyForUser
        ? state.usersRatingById
        : state.publicRatingById
      list[payload.lessonId] = {
        rating: payload.rating,
      }
    },
    rateLessonSuccess: (state, { payload }) => {},

    error(state, action) {
      state.isFetching = false
    },
  },
  extraReducers: {
    // When the user's view is updated, pull out any ratings changes
    'user/userViewSuccess': (state, action) => {
      const ratings = action?.payload?.lessonRatings
      if (ratings) {
        state.usersRatingById = ratings.reduce(
          (usersRatingById, lessonRating) => {
            usersRatingById[lessonRating.lessonId] = {
              rating: lessonRating.rating,
            }
            return usersRatingById
          },
          {}
        )
      }
    },
    'classes/createSuccess': (state, action) => {
      extractLessonsFromClasses(state, [action.payload])
    },
    'classes/viewSuccess': (state, action) => {
      extractLessonsFromClasses(state, [action.payload])
    },
    'classes/updateSuccess': (state, action) => {
      extractLessonsFromClasses(state, [action.payload])
    },
    'classes/listSuccess': (state, action) => {
      extractLessonsFromClasses(state, action.payload)
    },
  },
})

export const lessonsHasCreatedSelector = ({ lessons }) =>
  lessons.owned.length > 0
export const lessonsIsFetchingSelector = ({ lessons }) => lessons.isFetching
export const lessonsSearchKeySelector = ({ lessons }) => lessons.search.key
export const lessonsResultsSelector = ({ lessons }) =>
  lessons.results.map(id => lessons.byId[id])
export const lessonsCategorizedResultsSelector = ({ lessons }) =>
  lessons.categorizedResults.map(cr => ({
    name: cr.category,
    totalResults: cr.totalResults,
    entries: cr.entries.map(id => lessons.byId[id])
  }))
export const lessonsSearchTotalSelector = ({ lessons }) => lessons.search.total
export const lessonsSavedSelector = ({ lessons }) =>
  lessons.saved.map(id => lessons.byId[id])
export const lessonsOwnedSelector = ({ lessons }) =>
  lessons.owned.map(id => lessons.byId[id])
export const lessonSelector = ({ lessons }, id) => lessons.byId[id] || {}
export const lessonCollectionSelector = ({ lessons }, uri) =>
  lessons.collectionByUri[uri]
export const lessonByIdSelector = id => state => state.lessons.byId[id]
export const lessonUserRatingSelector = id => ({ lessons }) =>
  lessons.usersRatingById[id]?.rating
export const lessonPublicRatingSelector = id => ({ lessons }) =>
  lessons.publicRatingById[id]?.rating
export const lessonBlockedStatusSelector = id => state => {
  if(state.lessons.byId[id]) {
    return undefined
  }
  return state.lessons.blockedStatusById[id]
}

export const lessonsActions = lessons.actions
export default lessons
