import { v4 as uuid4 } from 'uuid'
import { pick } from 'lodash'
import { OZOBOT_AUTHOR_ID } from 'lib/utils.js'

const uploadRequiredFields = [
  'title',
  'grades',
  'subjects',
  'academicStandards',
  'attachments',
  'coverImage',
]

const stepRequiredFields = [
  'title',
  'summary',
  'objectives',
  'grades',
  'subjects',
  'prereader',
  'codingStyles',
  'bots',
  // 'academicStandards',
  'coverImage',
  'materials',
  'studentSteps',
]

export function cleanedSubmissionData(submissionData) {
  // removes extra array items that have no required data in them
  const cleanedLesson = { ...submissionData }
  if (cleanedLesson.type !== 'upload') {
    const fieldsForCleaning = [
      'materials',
      'teacherSteps',
      'extensions',
      'backgroundKnowledge',
    ]
    // clean fields that are arrays of objects
    fieldsForCleaning.forEach(field => {
      cleanedLesson[field] = submissionData[field].filter(val =>
        Object.values(val).every(item => item !== '')
      )
    })
    // ensure materials and background dropdown values exist, or have default
    cleanedLesson['materials'] = submissionData['materials'].map(material => ({
      ...material,
      grouping: material.grouping || 'Per Group',
    }))
    cleanedLesson['backgroundKnowledge'] = submissionData[
      'backgroundKnowledge'
    ].map(bg => ({
      ...bg,
      ability: bg.ability || 'Ability to',
    }))
    // since goals can be empty, make an empty goal not remove a step
    cleanedLesson.studentSteps = submissionData.studentSteps.filter(
      val => !!val.instructions
    )
    if (cleanedLesson.teacherTips === `\u2022 \n\u2022 \n\u2022 \n`) {
      cleanedLesson.teacherTips = ''
    }
  }
  return cleanedLesson
}

export function getSubmissionErrors(
  submissionData,
  requiredFields = undefined
) {
  submissionData = cleanedSubmissionData(submissionData)

  if (requiredFields === undefined) {
    requiredFields =
      submissionData.type === 'upload'
        ? uploadRequiredFields
        : stepRequiredFields
  }

  // add any required fields to submissionErrors to prevent user deleting fields before submitting
  return requiredFields.reduce((errs, field) => {
    const data = submissionData[field]
    let pdf = 0
    submissionData.attachments.forEach(att => {
      if (att.name.substring(att.name.length - 4, att.name.length) === '.pdf') {
        pdf++
      }
    })

    if (data === '') {
      errs.push(field)
    } else if (typeof data === 'object') {
      if (
        data.length === 0 ||
        data[0].instructions === '' ||
        data[0].item === '' ||
        (!pdf && field === 'attachments')
      ) {
        errs.push(field)
      }
    }
    return errs
  }, [])
}

export function withFolderAttachment(obj) {
  return {
    ...obj,
    attachmentsFolder: uuid4(),
  }
}

// takes API lesson and formats for reducer
export const formattedLesson = (lesson, videoProgress = undefined) => ({
  ...lesson,
  videoPlaylist: lesson.studentSteps?.reduce((playlist, step) => {
    for (const attachment of step.attachments) {
      if (attachment.type === 'video' && attachment.videoID) {
        playlist.push({
          title: attachment.name,
          captions: attachment.captions,
          provider: attachment.provider,
          videoID: attachment.videoID,
          text: step.instructions,
          ...((videoProgress && videoProgress[attachment.videoID]) || {}),
        })
      }
    }
    return playlist
  }, []),
  academicStandards: lesson.academicStandards
    ? lesson.academicStandards.map(s => (s.label ? s.label : s))
    : [],
  fullVideo: lesson.attachments?.find(attachment => {
    return attachment.type === 'video' && attachment.name.includes('[Full]')
  }),
})

// Grabs only lesson table fields for Create or Update lesson request bodies
export const lessonRequestBody = lesson => {
  return pick(lesson, [
    'academicStandards',
    'attachments',
    'attachmentsFolder',
    'authorId',
    'bots',
    'codingStyles',
    'coverImageUrl',
    'coverImage800Url',
    'duration',
    'extensions',
    'grades',
    'id',
    'lessonClosure',
    'materials',
    'objectives',
    'otherAcademicStandards',
    'prereader',
    'prerequisites',
    'hasVideo',
    'schemaVersion',
    'status',
    'studentSteps',
    'subjectSuggestions',
    'subjects',
    'summary',
    'teacherSteps',
    'teacherTips',
    'title',
    'type',
    'categories',
  ])
}

export const lessonHasPlaylist = lesson => lesson.videoPlaylist?.length > 0
export const shouldShareLesson = lesson => lessonHasPlaylist(lesson) && (lesson.authorId === OZOBOT_AUTHOR_ID)
