import React from 'react'
import { Text, View } from 'react-native'
import Button from 'components/Form/Button2'
import s from 'styles'
import noop from 'lodash/noop'
import { useDispatch } from 'react-redux'
import { modalHide } from 'store/modal'
import { ModalFrame } from 'components/modal'

interface ConfirmModalProps {
  title: string,
  heading: string
  description: string
  cancelText?: string
  confirmText?: string
  onCancel?: () => void
  onConfirm?: () => void
}

function execAndClose(fn: () => void) {
  const dispatch = useDispatch()

  return () => {
    dispatch(modalHide())
    fn()
  }
}

/**
 * Simple confirm modal with [Cancel] [Ok] buttons, which can trigger
 * corresponding functions. Cancel and Ok function will close the modal.
 *
 * +------------------------------------+
 * | Title ........................ [x] |
 * +====================================+
 * |                                    |
 * |             {HEADING}              |
 * |           {description}            |
 * |                                    |
 * |   [{cancelText}] [{confirmText}]   |
 * |                                    |
 * +------------------------------------+
 *
 * ```
 *   // to open
 *   dispatch(modalShow(
 *     <ConfirmModal
 *       title="Confirm modal"
 *       heading="Are you sure?"
 *       description="Are you really sure?"
 *       confirmText="Remove"
 *       onConfirm={() => {
 *         console.log('bye bye lessons')
 *       }}
 *   ))
 * ```
 *
 */
export default function ConfirmModal({
  title,
  heading,
  description,
  cancelText='Cancel',
  confirmText='Ok',
  onCancel=noop,
  onConfirm=noop,
}: ConfirmModalProps) {
  return (
    <ModalFrame
      title={title}
      size={'medium'}
    >
      <View style={[s.mb8]}>
        <Text style={[s.f24, s.textBold, s.textBlackLighter, s.alignSelfCenter]}>
          {heading}
        </Text>
      </View>

      <View style={[s.mb24]}>
        <Text style={[s.f16, s.textBlackLighter, s.alignSelfCenter]}>
          {description}
        </Text>
      </View>

      <View style={[s.flexRow, s.justifyCenter]}>
        <View style={[s.mr20]}>
          <Button
            text={cancelText}
            onPress={execAndClose(onCancel)}
            role="secondary"
          />
        </View>
        <View>
          <Button
            text={confirmText}
            onPress={execAndClose(onConfirm)}
            role="primary"
          />
        </View>
      </View>
    </ModalFrame>
  )
}
