import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { View, Text } from 'react-native'
import GooglePicker from 'react-google-picker'
import FilePicker from 'components/FilePicker'
import { modalShow, modalHide } from 'store/modal'
import ProgressModal from 'components/modals/ProgressModal'
import { ModalFrame } from 'components/modal'
import { Button } from 'components/Form'
import AttachmentsList from './AttachmentsList'
import s from 'styles'
import api from 'lib/api'
import mimejs from 'mime'
import axios from 'axios'
import MD5 from 'md5.js'

const imageMIMETypes = ['image/jpeg', 'image/png', 'image/gif']

const videoMIMETypes = [
  'video/mp4',
  'video/quicktime',
  'video/x-msvideo',
  'video/avi',
  'video/mpeg',
  'video/webm',
]

const docMIMETypes = [
  'application/pdf',
  'application/rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.oasis.opendocument.text', // odt
]

const mimeTypes = [...imageMIMETypes, ...videoMIMETypes, ...docMIMETypes]

const MB = 1048576
const maxFileSizeForMIME = mime => {
  return (mime && mime.startsWith('video/') ? 1024 : 10) * MB
}

const blobToBuffer = async blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(Buffer.from(reader.result))
  reader.onerror = reject
  reader.readAsArrayBuffer(blob)
})

const hashFromBytes = async blob => {
  const md5 = new MD5()
  const SLICE_SIZE = 1048576 / 16 // This size seems to perform well on a 2014 MacBook -- as the md5 update blocks
  let bytesLeft = blob.size
  while(bytesLeft > 0) {
    const beg = blob.size - bytesLeft
    const data = await blob.slice(beg, Math.min(beg + SLICE_SIZE, blob.size))
    md5.update(await blobToBuffer(data))
    bytesLeft -= SLICE_SIZE
  }
  return md5.digest('hex')
}

export const capital1st = str =>
  str ? str[0].toUpperCase() + str.slice(1) : str
const nameFromFilePath = path => capital1st(path.split('.')[0])

const downloadGoogleDriveFile = async (fileID, token) => {
  const res = await axios.get(
    `https://www.googleapis.com/drive/v3/files/${encodeURIComponent(fileID)}?alt=media`,
    {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    }
  )
  return res.data
}

const handleAttachmentPicks = async ({
  picks,
  folder,
  onGetDataFromPick,
  onAddAttachment,
  onError,
  dispatch,
}) => {
  const fixedFileInfo = (fileName, intendedMIME) => {
    if (intendedMIME) {
      // calculate mime from fileName, and make sure it matches
      const derivedMime = mimejs.getType(fileName)
      if (derivedMime !== intendedMIME) {
        if (derivedMime) {
          console.warn(
            `File ${fileName} has inconsistent MIME types ${intendedMIME} and ${derivedMime}`
          )
          return { fileName, mime: derivedMime }
        } else {
          console.error(
            `File ${fileName} cannot derive to intended MIME ${intendedMIME}, the filename will be adjusted...`
          )
          return {
            fileName: `${fileName}.${mimejs.getExtension(intendedMIME)}`,
            mime: intendedMIME,
          }
        }
      }
    }
    return { fileName, mime: intendedMIME }
  }

  dispatch(modalShow(<ProgressModal/>))
  const errors = []
  for (const pick of picks) {
    try {
      const { fileName, mime, blob } = await onGetDataFromPick(pick)

      const name = nameFromFilePath(fileName)

      // see if we can get attachment data for this hash only
      let attachment = undefined
      const fileHash = await hashFromBytes(blob)

      try {
        attachment = await api.makeLessonAttachment({ fileHash })
      } catch (e) {
        console.warn(`Couldn't make attachment from hash`, e)
      }

      if (!attachment) {
        const fixedInfo = fixedFileInfo(fileName, mime)
        const url = await api.uploadBlobToStorage({
          namespace: 'lessons',
          folder: folder || 'undefined',
          blob,
          ...fixedInfo,
        })
        attachment = await api.makeLessonAttachment({ url, name, fileHash })
      }

      onAddAttachment(attachment)
    } catch (e) {
      console.warn(e)
      errors.push(e)
    }
  }

  dispatch(modalHide())
  for(const error of errors) {
    onError(error)
  }
}

const AttachmentFile = ({ File, add, attachmentsFolder, onError }) => {
  const dispatch = useDispatch()
  // Just to make sure we go to the trouble to specify...
  // CLASS-2356 Commented out since it breaks legacy lessons saved in undefined folder
  // if (!attachmentsFolder) {
  //   throw new Error('Missing attachments folder name')
  // }

  const onFilesPicked = async files =>
    handleAttachmentPicks({
      picks: files,
      folder: attachmentsFolder,
      onGetDataFromPick: async file => ({
        fileName: file.name,
        mime: file.type,
        blob: file,
      }),
      onAddAttachment: add,
      onError,
      dispatch,
    })

  return (
    <FilePicker
      types={mimeTypes}
      maxFileSizeForMIME={maxFileSizeForMIME}
      onFiles={onFilesPicked}
      onError={onError}
    >
      {File ? (
        <File />
      ) : (
        <Button
          presentationOnly={true}
          rounded={true}
          icon="plus"
          text="Files"
          style={[s.mr20]}
        />
      )}
    </FilePicker>
  )
}

const AttachmentGoogle = ({ Google, add, attachmentsFolder, onError }) => {
  const [token, setToken] = useState('')
  const dispatch = useDispatch()

  // NOTE: when this callback is called, is not awaited on, but will continue asynchronously
  // apart from the GooglePicker infrastructure
  const onChange = async data => {
    if (data.action === 'picked') {
      // This just trims out selected files that are too large, and prints a warning if they are
      const docs = data.docs.reduce((docs, doc) => {
        const maxSize = maxFileSizeForMIME(doc.mimeType)
        if (doc.sizeBytes <= maxSize) {
          docs.push(doc)
        } else {
          const message = `The file size of "${doc.name}" exceeds ${Math.floor(maxSize / MB)} MB.`
          console.warn(message)
          onError && onError(new Error(message))
        }
        return docs
      }, [])
      await handleAttachmentPicks({
        picks: docs,
        folder: attachmentsFolder,
        onGetDataFromPick: async doc => {
          return {
            fileName: doc.name,
            mime: doc.mimeType,
            blob: await downloadGoogleDriveFile(doc.id, token),
          }
        },
        onAddAttachment: add,
        onError,
        dispatch,
      })
    }
  }

  const clientID = process.env.REACT_APP_API_GOOGLE_CLIENT_ID
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY

  return (
    <GooglePicker
      clientId={clientID}
      developerKey={apiKey}
      scope={['https://www.googleapis.com/auth/drive.file']}
      onChange={onChange}
      onAuthenticate={token => setToken(token)}
      onAuthFailed={data => console.warn('on auth failed:', data)}
      multiselect={false}
      navHidden={true}
      authImmediate={false}
      mimeTypes={mimeTypes}
      viewId={'DOCS'}
      createPicker={(google, oauthToken) => {
        // HACK: we need to manually create the picker only because the default implementation does not set the app ID, which is
        // necessary in order to user the Google Drive API to download the file
        const googleProjectID = clientID.split('-')[0]
        const googleViewId = google.picker.ViewId.DOCS
        const docsView = new google.picker.DocsView(googleViewId)
          .setMimeTypes(mimeTypes.join(','))
          .setSelectFolderEnabled(true)
        const picker = new window.google.picker.PickerBuilder()
          .addView(docsView)
          .setOAuthToken(oauthToken)
          .setDeveloperKey(apiKey)
          .setAppId(googleProjectID)
          .setCallback(onChange)
        picker.build().setVisible(true)
      }}
    >
      {Google ? (
        <Google />
      ) : (
        <Button
          presentationOnly={true}
          rounded={true}
          icon="plus"
          text="Google Drive"
        />
      )}
    </GooglePicker>
  )
}

const AttachmentErrorModal = ({ text }) => {
  const dispatch = useDispatch()
  return (
    <ModalFrame title="Uh oh!" size="medium">
      <View style={[s.mb20]}>
        <Text style={[s.mb8, s.f28, s.textBold, s.textBlackLighter, s.textCenter]}>
          Unable to Add File
        </Text>
        <Text style={[s.f16, s.textBlackLighter, s.textCenter]}>
          {`${text}\n\nMax. file size: 1 GB videos, 10 MB all other files`}
        </Text>
      </View>
      <View style={[s.flexRow, s.justifyCenter]}>
        <View style={s.mr16}>
          <Button text="Ok" onPress={() => dispatch(modalHide())} />
        </View>
      </View>
    </ModalFrame>
  )
}

export default function SubmissionAttachments({
  File,
  Google,
  attachments,
  attachmentsFolder,
  onChange,
  text,
}) {
  const add = item => onChange([...attachments, item])
  const removeByIndex = index =>
    onChange([...attachments.slice(0, index), ...attachments.slice(index + 1)])

  const dispatch = useDispatch()
  const onError = e => {
    dispatch(modalShow(<AttachmentErrorModal text={e.message || e.toString()} />))
  }

  return (
    <>
      <View style={[s.mb20, s.flexRow]}>
        <AttachmentFile
          File={File}
          add={add}
          attachmentsFolder={attachmentsFolder}
          onError={onError}
        />
        <AttachmentGoogle
          Google={Google}
          add={add}
          attachmentsFolder={attachmentsFolder}
          onError={onError}
        />
      </View>
      {!!text && (
        <View style={[s.mb20]}>
          <Text style={[s.f14, s.textItalic]}>{text}</Text>
        </View>
      )}
      <AttachmentsList
        attachments={attachments}
        removeByIndex={removeByIndex}
      />
    </>
  )
}
