import * as React from 'react'
import { Text, View, ImageBackground } from 'react-native'
import { useDispatch } from 'react-redux'
import { Column, Row } from 'react-native-web-ui-components'
import { Content, Layout } from 'components/Layout'
import { ButtonLinkWhite } from 'components/ButtonLink'
import TextLink from 'components/TextLink'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { openLink } from 'pages/util'

interface PacingGuideProps {
  text: string,
  pdfURL: string,
  docsURL: string,
  image: string,
}

const CONTENT = {
  guides: [
    { 
      text: 'Kindergarten',
      pdfURL: 'https://static.ozobot.com/assets/2272f33b-24-ozobot-kindergarten-pacing-guide-pdf.pdf',
      docsURL: 'https://docs.google.com/spreadsheets/d/1_aCeGJ98PK2ZGPGGnDF3DhB7vC7TbgYEhjpLarhxzX4/edit?usp=sharing',
      image: 'pacing-guides-grade-k.jpg',
    },
    { 
      text: 'Grade 1', 
      pdfURL: 'https://static.ozobot.com/assets/5a643523-24-ozobot-1st-grade-pacing-guide-pdf.pdf',
      docsURL: 'https://docs.google.com/spreadsheets/d/1-JakhZg26a_t3lP_Vc-msA1HtOYBXCJ1aDUNv6lt23k/edit?usp=sharing',
      image: 'pacing-guides-grade-1.jpg',
    },
    { 
      text: 'Grade 2', 
      pdfURL: 'https://static.ozobot.com/assets/68904d83-24-ozobot-2nd-grade-pacing-guide-pdf.pdf',
      docsURL: 'https://docs.google.com/spreadsheets/d/1j-xJchM5JCz--sW-p7oYQxKTKllntyTGUbPwm_CtWV0/edit?usp=sharing',
      image: 'pacing-guides-grade-2.jpg',
    },
    { 
      text: 'Grade 3', 
      pdfURL: 'https://static.ozobot.com/assets/96b32ff2-24-ozobot-3rd-grade-pacing-guide-pdf.pdf',
      docsURL: 'https://docs.google.com/spreadsheets/d/1XN685Xp4HkJ5a44uaYsbgFMEUe8fbEaLSHLQFxfnNZM/edit?usp=sharing',
      image: 'pacing-guides-grade-3.jpg',
    },
    { 
      text: 'Grade 4', 
      pdfURL: 'https://static.ozobot.com/assets/bf3edaed-24-ozobot-4th-grade-pacing-guide-pdf.pdf',
      docsURL: 'https://docs.google.com/spreadsheets/d/1163XFiizeZnXNJEVcOVk6Hg1Io-lQ34-vWbK9RoEbcQ/edit?usp=sharing',
      image: 'pacing-guides-grade-4.jpg',
    },
    { 
      text: 'Grade 5', 
      pdfURL: 'https://static.ozobot.com/assets/d35361f9-24-ozobot-5th-grade-pacing-guide-pdf.pdf',
      docsURL: 'https://docs.google.com/spreadsheets/d/1QrDlP1-fENRfqKlFOn294RXN9Hu0U1I2Ap90jf3IhDw/edit?usp=sharing',
      image: 'pacing-guides-grade-5.jpg',
    },
    /*
    { 
      text: 'Middle School', 
      pdfURL: '',
      docsURL: '',
      image: 'pacing-guides-grade-middle-school.jpg',
    },
    */
  ],
}

const PacingGuide: React.FC<PacingGuideProps> = ({ text, pdfURL, docsURL, image }: PacingGuideProps) => {
  const { isDesktop } = useDetectDeviceType()
  const dispatch = useDispatch()
  const onDocs = React.useCallback(() => {
    openLink(docsURL, dispatch)
  }, [docsURL])  
  return (
    <Column xs={12} sm={6} md={3} style={[ isDesktop ? s.px16 : null, s.mb32]}>
      <View style={[
        s.br10,
        s.bTeal,
        s.bgGrayMoreLighter,
        s.bShadow,
        s.w75p,
        s.mAuto,
      ]}>
        {image && <Image source={require('images/pacing-guides/'+image)} style={{ 
          height: 180, 
          width: '100%', 
          borderTopLeftRadius: 10, 
          borderTopRightRadius: 10,
        }} resizeMode="cover" />}

        <View style={s.p20}>
          <View style={[]}>
            <Text style={[s.f16]}>
              <Text style={[s.textBold]}>{'Pacing Guide | '}</Text>
              {text}
            </Text>
          </View>
          <View style={[s.flexRow, s.alignCenter, s.mt32, s.justifyBetween]}>
            <TextLink style={[s.f16, s.mr32]} onPress={onDocs}>Google Sheets</TextLink>
            <ButtonLinkWhite buttonText="Download PDF" buttonLink={pdfURL} noSiteCheck={true}/>
          </View>
        </View>
      </View>
    </Column>
  )
}

export default function HelpPacingGuides() {
  const { isMobile, isTablet, isDesktop } = useDetectDeviceType();
  return (
    <Layout>
      <Content>
        <View style={[isDesktop ? s.w33p : ( isTablet ? { width: '60%' } : s.w90p )]}>
          <View>
            <ImageBackground source={require('images/pacing-guides/pacing-guides-top-header.png')} resizeMode="cover" imageStyle={isMobile ? { height: 50} : { height: 90 }}>
              { isMobile ?
                <Text style={[ s.f24, s.textBold, s.textItalic, s.textWhite, s.pl16, { paddingVertical: 10 } ]}>Ozobot Pacing Guides</Text>
                : 
                <Text style={[ s.f35, s.textBold, s.textItalic, s.textWhite, s.p24, s.textCenter, s.w500]}>Ozobot Pacing Guides</Text>
              }
            </ImageBackground>
          </View>
          <View>
            <Text style={[s.mb16, isDesktop ? s.f20 : s.f16, s.p16]}>Ozobot Pacing Guides help you plan CS and content-integrated lessons by grade level.</Text>
          </View>
        </View>
        <View style={[s.w100p]}>
          <Row>
            {CONTENT.guides.map(((guide, i) => <PacingGuide key={i} {...guide} />))}
          </Row>
        </View>
      </Content>
    </Layout>
  )
}
