import React, { useCallback, useEffect, useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useWindowDimensions } from 'hooks/useWindowDimensions'

export const ModalContext = React.createContext()

export function Modal({ modal, unSetModal }) {
  useEffect(() => {}, [modal, unSetModal])

  const { width, height } = useWindowDimensions()

  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: width,
        height: height,
        backgroundColor: 'rgba(37, 41, 50, 0.8)',
      }}
    >
      <TouchableOpacity
        onPress={() => unSetModal()}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: width,
          height: height,
        }}
      />
      {modal}
    </View>
  )
}

export function ModalProvider(props) {
  const [modal, setModal] = useState()

  const unSetModal = useCallback(() => {
    setModal()
  }, [setModal])

  return (
    <ModalContext.Provider value={{ unSetModal, setModal }} {...props}>
      {props.children}
      {modal && <Modal modal={modal} unSetModal={unSetModal} />}
    </ModalContext.Provider>
  )
}
