import React from 'react'
import { useSelector } from 'react-redux'
import {
  Switch,
  PrivateRoute,
  PublicRoute,
  UnverifiedRoute,
  Route,
  Redirect,
} from 'components/Router'
import { signedInSelector } from 'store/user'

export default function() {
  return (
    <Switch>
      <Redirect exact from="/account/logout" to="/account/login" />
      <Redirect exact from="/login/educator" to="/account/login" />
      <Redirect exact from="/login/student" to="/account/login" />
      <Redirect exact from="/lti" to="/" />
      <Redirect
        exact
        from="/"
        to={useSelector(signedInSelector) ? '/dashboard' : '/account/login'}
      />
      <Redirect exact from="/lessons/search" to="/lessons" />
      <Redirect exact from="/lessons/create-a-lesson" to="/lessons/new" />
      <Redirect exact from="/lessons/creator" to="/lessons/creator/1" />
      <Redirect exact from="/lessons/uploader" to="/lessons/uploader/1" />
      <Redirect exact from="/settings" to="/settings/profile" />
      <Redirect exact from="/settings/products" to="/devices" />

      {/* NOTE: Add new routes alphabetically */}
      <Route
        exact
        path="/account/forgot"
        component={require('pages/account/forgot').default}
      />
      <Route
        exact
        path="/account/join"
        component={require('pages/account/join').default}
      />
      <PublicRoute
        exact
        path="/account/login"
        component={require('pages/account/login').default}
      />
      <UnverifiedRoute
        exact
        path="/account/unverified"
        component={require('pages/account/unverified').default}
      />
      <Route
        exact
        path="/account/verify"
        component={require('pages/account/verify').default}
      />
      <Route
        exact
        path="/account/redirect"
        component={require('pages/account/oauth-redirect').default}
      />
      <Route
        exact
        path="/account/reset"
        component={require('pages/account/reset').default}
      />
      <Route
        exact
        path="/account/reset/success"
        component={require('pages/account/password/success').default}
      />

      <Route
        exact
        path="/account/verify/success"
        component={require('pages/account/verify/success').default}
      />

      <PrivateRoute
        exact
        path="/dashboard"
        component={require('pages/dashboard').default}
      />

      <PrivateRoute
        exact
        path="/devices"
        component={require('pages/devices/devices').default}
      />
      <PrivateRoute
        exact
        path="/devices/rename-evo"
        component={require('pages/devices/rename-evo').default}
      />
      <PrivateRoute
        exact
        path="/help"
        allowUnverified={true}
        component={require('pages/help/help').default}
      />
      <PrivateRoute
        exact
        path="/help/educator-training"
        component={require('pages/help/educator-training').default}
      />
      <PrivateRoute
        exact
        path="/lessons"
        component={require('pages/lessons/search').default}
      />
      <PrivateRoute
        exact
        path="/lessons/new"
        component={require('pages/lessons/new').default}
      />
      <PrivateRoute
        exact
        path="/lessons/creator/:page(\d+)"
        component={require('pages/lessons/creator').default}
      />
      <PrivateRoute
        exact
        path="/lessons/uploader/:page(\d+)"
        component={require('pages/lessons/uploader').default}
      />
      <PrivateRoute
        exact
        path="/lessons/mine"
        component={require('pages/lessons/mine.tsx').default}
      />
      <PrivateRoute
        exact
        path="/lessons/saved"
        component={require('pages/lessons/saved').default}
      />
      <PrivateRoute
        exact
        path="/lessons/:id"
        component={require('pages/lessons/detail').default}
      />
      <PrivateRoute
        exact
        path="/lessons/:id/edit"
        component={require('pages/lessons/uploader').default}
      />
      <Route
        exact
        path="/lessons/:id/public"
        component={require('pages/lessons/public').default}
      />
      <PrivateRoute
        exact
        path="/lessons/detail/:id"
        component={require('pages/lessons/detail').default}
      />

      <PrivateRoute
        exact
        path="/settings/:page"
        allowUnverified={true}
        component={require('pages/settings/index').default}
      />

      <PrivateRoute
        exact
        path="/help/pacing-guides"
        component={require('pages/help/pacing-guides').default}
      />

      <Route
        exact
        path="/lmsnotice"
        component={require('pages/lmsnotice').default}
      />

      <Route path="*" component={require('pages/404').default} />
    </Switch>
  )
}
