import * as React from 'react'
import { Text, TextInput, View, TouchableOpacity, KeyboardTypeOptions, ReturnKeyTypeOptions } from 'react-native'
import { Image } from '../ReactNative'
import s from 'styles';

// Team to use interfaces than types
export interface InputLabelProps {
  label?: string,
  isError?: boolean,
  isFocused?: boolean,
}

export interface InputProps extends InputLabelProps {
  value: string,
  setValue: (text: string) => void,
  isError?: boolean,
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters',
  placeholder?: string,
  secureTextEntry?: boolean,
  ref?: React.RefObject<HTMLInputElement>,
  name?: string,
  keyboardType?: KeyboardTypeOptions,
  autoCompleteType?: 'email' | 'cc-csc' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-number' | 'name' | 'password' | 'postal-code' | 'street-address' | 'tel' | 'username' | 'off',
  returnKeyType?: ReturnKeyTypeOptions,
  enablesReturnKeyAutomatically?: boolean,
  disabled?: boolean,
  onPressEnter?: () => void,
}

export const InputLabel: React.FunctionComponent<InputLabelProps> = ({ label, isFocused, isError }: InputLabelProps): JSX.Element => {
  return <View style={[s.bgWhite, s.px4, s.ml8, { position: 'absolute', top: -8, width: 'fit-content', zIndex: 1}]}>
    <Text style={[s.f12, s.textBold, isError ? s.textRed : isFocused ? s.textTeal : s.textBlackLighter]}>
      {label}
    </Text>
  </View>
}

export const Input: React.FC<InputProps> = (props: InputProps): JSX.Element => {
  const { label, placeholder, isError, secureTextEntry } = props
  // const [value, setValue] = React.useState('')
  // const [value, setValue] = React.useState(props.value || '')
  const [isFocused, setIsFocused] = React.useState(false)
  const [isSecure, setIsSecure] = React.useState(secureTextEntry)
  const updateValues = (): void => {
    setIsFocused(false)
  }

  return (
    <View style={[s.mb16]}>
      {!!props.value.length && <InputLabel label={label} isFocused={isFocused} isError={isError} />}
      {props.disabled ? <Text style={[s.f14, s.textGray, s.textItalic, s.p8, s.b1, s.bGrayLighter, s.br5]}>
        {props.value}
      </Text> :
        <View style={[s.flexRow, s.alignCenter, s.b1, s.br5, s.p8, isError ? s.bRed : isFocused ? s.bTeal : s.bGray]}>
          <TextInput
            style={[s.f14, s.w100p, !props.value.length && s.textItalic, s.outlineStyleNone]}
            onFocus={(): void => setIsFocused(true)}
            onBlur={(): void => updateValues()}
            placeholder={placeholder || label}
            placeholderTextColor={'#95abb9'}
            onChangeText={props.setValue}
            value={props.value}
            // TODO: figure out right types for refs
            // to allow for Enter going to next field
            // ref={props.ref}
            secureTextEntry={isSecure}
            keyboardType={props.keyboardType || 'default'}
            autoCompleteType={props.autoCompleteType || 'off'}
            autoCapitalize={props.autoCapitalize || 'none'}
            returnKeyType={props.returnKeyType || 'next'}
            enablesReturnKeyAutomatically={props.enablesReturnKeyAutomatically || true}
            onSubmitEditing={props.onPressEnter}
          />
          {secureTextEntry && <TouchableOpacity onPress={() => setIsSecure(!isSecure)}>
            <Image
              source={require('images/account-secure-eye-gray.svg')}
              style={{
                width: 17,
                height: 9,
                resizeMode: 'contain',
                tintColor: isSecure ? '#95abb9' : '#00B1B9',
              }}
            />
          </TouchableOpacity>}
        </View>
      }
    </View>
  )
}

export default Input
