import React from 'react'
import { Text, View, Image } from 'react-native'
import s from 'styles'

export default function EducatorImage({
  name = '',
  image = null,
  size = 40,
  font = s.f20,
}) {
  return (
    <View
      style={[
        s.mr8,
        { width: size },
        { height: size },
        s.br100,
        s.bgGray,
        s.alignCenter,
        s.justifyCenter,
      ]}
    >
      {image !== null ? (
        <Image
          source={{ uri: image }}
          style={{
            height: size,
            width: size,
            resizeMode: 'contain',
            borderRadius: size / 2,
          }}
        />
      ) : (
        <Text style={[font, s.textWhite, s.textBold]}>
          {name
            .replace(/[^a-z]+/gi, '')
            .substring(0, 2)
            .toUpperCase()}
        </Text>
      )}
    </View>
  )
}
