import React from 'react'
import { FormContext } from 'components/Form'

const withSubmissionForm = WrappedComponent => ({ field, ...props }) => {
  const form = React.useContext(FormContext)

  const addItem = item => {
    form.updateForm({ [field]: [...form[field], props.initialItem || item] })
  }

  const updateItem = (index, obj) => {
    const updatedItems = form[field].map((item, i) => {
      return i !== index ? item : Object.assign({}, item, obj)
    })
    form.updateForm({ [field]: updatedItems })
  }

  const removeItem = index => {
    form.updateForm({ [field]: form[field].filter((v, i) => i !== index) })
  }
  const update = obj => form.updateForm(obj)

  return (
    <WrappedComponent
      {...props}
      field={field}
      form={form}
      formAddItem={addItem}
      formUpdateItem={updateItem}
      formRemoveItem={removeItem}
      formUpdate={update}
    />
  )
}

export default withSubmissionForm
