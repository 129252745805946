import React, { useState } from 'react'
import { Text, TouchableOpacity, View, Clipboard } from 'react-native'
import { Button } from 'components/Form/Button2'
import { Image } from 'components/ReactNative'
import s from 'styles'
import { LessonType } from 'components/LessonTypes'
import { ModalFrame } from 'components/modal'
import { useDispatch } from 'react-redux'
import { modalShow, modalHide } from 'store/modal'
import { lessonsActions } from 'store/lessons'
import { lessonHasPlaylist } from 'store/lessons/util'
import { openURL } from 'lib/utils'

export const getSavedImage = (isSaved: boolean, useDarkGreen?: boolean) =>
  isSaved
    ? require(
      useDarkGreen
        ? 'images/saved-icon-dark-green.svg'
        : 'images/saved-icon.svg',
    )
    : require(
      useDarkGreen
        ? 'images/save-icon-dark-green.svg'
        : 'images/save-icon.svg',
    )

interface CircleCheckProps {
  text: string,
}

const CircleCheck: React.FC<CircleCheckProps> = ({ text }: CircleCheckProps) => (
  <View style={[s.flexRow, s.justifyCenter, s.alignCenter, s.mb16]}>
    <Image
      source={
        require('images/icon-tealCheckCircle.svg')}
      style={{
        width: 22, height: 22, marginRight: 8, resizeMode: 'contain',
      }}
    />
    <Text style={[s.f16, s.textItalic, s.textTeal]}>{text}</Text>
  </View>
)

interface ModalShareLessonProps {
  lesson: LessonType,
}

export const ModalShareLesson: React.FC<ModalShareLessonProps> = ({ lesson }: ModalShareLessonProps) => {
  const [copied, setCopied] = useState(false)
  const uri = `${window.origin}/lessons/${lesson.id}/public`
  const dispatch = useDispatch()

  return (
    <ModalFrame title="Share Lesson with Students" size="large">
      <Text style={[s.textCenter, s.f16, s.mb16, s.mx32]}>Copy the link below and share with students via your LMS or preferred method. Tip: make sure students’ bots are charged and updated!</Text>
      <View style={[s.flexRow, s.flexGrow1, s.mb16]} >
        <View style={[s.flexRow, s.flexGrow1, s.alignCenter, s.b1, s.br5, s.p8, s.bGray, s.mr16]}>
          <Text>{uri}</Text>
          <View style={[s.bgWhite, s.px4, s.ml8, { position: 'absolute', top: -8, width: 'fit-content', zIndex: 1 }]}>
            <Text style={[s.f12, s.textBold, s.textGray]}>
              {'Lesson Link'}
            </Text>
          </View>
        </View>
        <View>
          <Button text="Copy Link" onPress={() => { Clipboard.setString(uri); setCopied(true) }} />
        </View>
      </View>
      {copied && <CircleCheck text='Link Copied' />}
      <View style={[s.flexRow, s.justifyCenter]}>
        <Button role="secondary" text="Cancel" onPress={() => dispatch(modalHide())} />
        <View style={[s.w32]} />
        <Button text="Open Lesson as a Student" onPress={() => openURL(uri, true)} />
      </View>
    </ModalFrame>
  )
}

interface SaveLessonProps {
  isSaved: boolean,
  lesson: LessonType,
  isDesktop: boolean,
  hasShare?: boolean,
  hasPreview?: boolean,
  useDarkGreen?: boolean,
}

/**
 * Save lesson component for search/details page
 *
 * <SaveLesson
 *  isSaved
 *  hasPreview
 *  lesson={lesson}
 * />
 */
const SaveLesson: React.FC<SaveLessonProps> = ({
  isSaved,
  lesson,
  hasShare,
  isDesktop,
  useDarkGreen,
}: SaveLessonProps) => {
  const dispatch = useDispatch()

  const onShare = React.useCallback(() => {
    dispatch(modalShow(<ModalShareLesson lesson={lesson} />))
  }, [lesson.id])
  const onSaveOrUnsave = React.useCallback(() => {
    dispatch(lessonsActions.updateSavedRequest({ id: lesson.id }))
  }, [lesson.id])

  return (
    <View>
      {hasShare && lessonHasPlaylist(lesson) && (
        <Button role="greenBgWhiteText" text='Share with Students' onPress={onShare} />
      )}
      <TouchableOpacity style={[s.flexGrow1]} onPress={onSaveOrUnsave}>
        <View style={[s.flexGrow1, s.flexRow, s.alignStretch, s.m8, s.justifyBetween, s.alignCenter]}>
          <Image
            source={getSavedImage(isSaved, useDarkGreen)}
            style={isDesktop ? { height: 30, width: 30 } : { height: 16, width: 16 }}
          />
          <Text style={[isDesktop ? s.f14 : s.f12, s.textBold, s.textCenter, s.ml8]}>
            {lesson.userSaveCount || 0}
          </Text>
        </View>
      </TouchableOpacity>
      {/* {hasPreview && lesson.pdfUrl && <Button text='Open' onPress={onPreview} />} */}
    </View>
  )
}

export default SaveLesson
