import * as React from 'react'
import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'components/Router'
import { userAuthCheckIfVerified, userSelector } from 'store/user'
import { RouteComponentProps } from 'react-router'

export interface UnverifiedRouteProps {
  component: React.FunctionComponent<RouteComponentProps> | React.ComponentClass<RouteComponentProps>,
}

const UnverifiedRoute = ({
  component: Component,
  ...props
}: UnverifiedRouteProps) => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  // fire off a check to ensure we've been signed out
  const onFocus = React.useCallback(() => dispatch(userAuthCheckIfVerified()), [
    userAuthCheckIfVerified,
  ])
  React.useEffect(() => {
    // NOTE: we do the Platform check here to avoid React Hook lint errors
    if (Platform.OS === 'web') {
      window.addEventListener('focus', onFocus)
      return () => {
        window.removeEventListener('focus', onFocus)
      }
    }
  }, [])

  return (
    <Route
      {...props}
      render={routeProps => {
        if (!user.isSignedIn) {
          return <Redirect
            to={{
              pathname: '/account/login',
              state: {
                redirect: `${routeProps.location.pathname}${routeProps.location.search}`,
              },
            }}
          />
        }

        if (user.isVerified) {
          return  (<Redirect to={{ pathname: '/dashboard' }} />) 
        }

        return <Component {...routeProps} />
      }
      }
    />
  )
}

export default UnverifiedRoute
