import React from 'react'
import { Text } from 'react-native'
import s from 'styles'

export interface PageH1Props {
  text: string,
  hasMarginBottom?: boolean, // Only pages with Tab navigation sets this
}

const PageH1: React.FC<PageH1Props> = (props: PageH1Props) => {
  return (
    <Text style={[s.f22, s.textBold, props.hasMarginBottom && s.mb8]}>{props.text}</Text>
  )
}

export default PageH1
