import React, { Component } from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import { clamp } from 'lodash'
import s from 'styles'

const customStyles = {
  countInput: [
    s.f18,
    s.bTeal,
    s.bBottom1,
    s.bTop1,
    {
      width: 50,
      marginLeft: -5,
      marginRight: -5,
      fontWeight: '400',
      color: '#333',
      backgroundColor: '#fff',
      textAlign: 'center',
      zIndex: 1,
    },
  ],
}

const CounterButton = ({ direction, onUpdate }) => {
  return (
    <TouchableOpacity onPress={() => onUpdate(direction)}>
      <Text
        style={[
          s.f20,
          s.textWhite,
          s.textBold,
          s.bgGreen,
          s.br5,
          s.textCenter,
          {
            fontSize: 26,
            paddingVertical: 3,
            width: 45,
            height: 40,
          },
        ]}
      >
        {direction}
      </Text>
    </TouchableOpacity>
  )
}

export default class NumberChooser extends Component {
  static defaultProps = {
    max: '99',
  }

  onUpdate = direction => {
    const count = Number(this.props.count)
    this.props.onChange({
      [this.props.name]: clamp(
        direction === '-' ? count - 1 : count + 1,
        0,
        this.props.max
      ),
    })
  }

  updateFromTextInput = val => {
    this.props.onChange({
      [this.props.name]: clamp(val, 0, this.props.max),
    })
  }

  render() {
    return (
      <View style={[s.flexRow]}>
        <CounterButton direction="-" onUpdate={this.onUpdate} />
        <TextInput
          keyboardType="numeric"
          max={this.props.max}
          maxLength={3}
          min="0"
          multiline={false}
          onChangeText={this.updateFromTextInput}
          style={customStyles.countInput}
          type="number"
          value={String(this.props.count)}
        />
        <CounterButton direction="+" onUpdate={this.onUpdate} />
      </View>
    )
  }
}
