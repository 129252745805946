import * as React from 'react'
import { Text, View } from 'react-native'
import { Link } from 'components/Router'
import s from 'styles'

interface FooterComponentProps {
  text?: string;
  linkText?: string;
  link?: string;
  white?: boolean;
  learnMoreLink?: string;
  style?: Record<string, unknown>;
}

const Footer: React.FC<FooterComponentProps> = ({ text, link, linkText, learnMoreLink, style, white = false }: FooterComponentProps) => (<View style={[s.alignCenter, s.textCenter].concat(style)}>
  {!!text && 
    <Text style={[s.f16, s.mb4, white ? [s.f14, s.textBlack] : s.textGrayDarker]}>
      {text} 
      {learnMoreLink && 
        <Link to={learnMoreLink} style={[s.bTeal, s.mb8, { width: 'fit-content' }]}>
          <Text style={[s.textBlue, s.textBold]}>Learn more</Text>
        </Link>
      }
    </Text>}
  {!!linkText && <Link to={link}>
    <View style={[s.bBlackLighter, s.mb8]}>
      <Text style={[s.textBlue, s.textBold]}>{linkText}</Text>
    </View>
  </Link>}
</View>)

export default Footer
