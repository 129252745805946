import React from 'react'
import { View } from 'react-native'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

export interface PageHeaderProps {
  children: React.ReactNode
}

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const { isDesktop } = useDetectDeviceType();
  return (
    <View style={[ isDesktop ? s.px60 : s.px20, s.pt16, s.alignCenter, s.mt16]}>
      <View style={[s.w100p]}>{props.children}</View>
    </View>
  )
}

export default PageHeader
