import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'components/Router'
import { Content, Layout, PageH1, PageHeader } from 'components/Layout'
import { lessonsActions, lessonsOwnedSelector } from 'store/lessons'
import DataTable from 'components/DataTable'
import { formatStatus, shortUSDate, reallyShortUSDate } from './utils'
import { modalShow } from 'store/modal'
import ConfirmModal from 'components/ConfirmModal'
import SubHeaderWithButton from 'components/Layout/SubHeaderWithButton'
import LessonTitleLink from './components/LessonTitleLink'
import NoLessons from './components/NoLessons'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import s from 'styles'

function editLessonUrl(lesson: PartialLesson): string {
  return `/lessons/${lesson.type === 'step' ? 'creator/6' : 'uploader/2'}?id=${lesson.id}`
}

interface PartialState {
  lessons: {
    isFetching: boolean
  }
}

interface PartialLesson {
  id: string
  title: string
  uri: string
  coverImageUrl: string
  createdAt: string
  updatedAt: string
  type: string
  [key: string]: unknown
}

export default function Mine(): JSX.Element {
  const isFetching = useSelector<PartialState, boolean>(state => state.lessons.isFetching)
  const lessons = useSelector<PartialState, PartialLesson[]>(state => lessonsOwnedSelector(state))
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(lessonsActions.listOwnedRequest())
  }, [])

  const idToLesson = lessons.reduce((id2l, l) => {
    id2l[l.id] = l
    return id2l
  }, {})

  const { isDesktop, isMobile } = useDetectDeviceType()

  return (
    <Layout isFetching={isFetching}>
      <PageHeader>
        <PageH1 text="My Lessons" />
        <SubHeaderWithButton
          text="Create and share your own Ozobot lessons to inspire students around the world!"
          buttonText="Create Lesson"
          onPress={() => history.push('/lessons/new')}
        />
      </PageHeader>
      <Content>
        {lessons.length === 0 && <View style={[s.mt45]}><NoLessons
          image={require('images/my_lessons_no_lesson.svg')}
          text={'You haven’t created any lessons...yet!'}
          link={'/lessons/new'}
          linkText='Create Lesson'
        /></View>}
        <View style={[isDesktop ? s.mx50 : s.mx8]}>
          {lessons.length > 0 && <DataTable
            columns={[
              {
                title: isMobile ? 'Title' : 'Lesson Title',
                sortKeyFn: (_, i) => lessons[i].title,
              },
              'Status',
              {
                title: isMobile ? 'Modified' : 'Last Modified',
                sortKeyFn: (_, i) => lessons[i].createdAt,
              },
            ]}
            data={lessons.map(d => [
              <LessonTitleLink id={d.id} title={d.title} key={d.id}/>,
              formatStatus(d.status as string),
              isMobile
                ? reallyShortUSDate(d.updatedAt || d.createdAt)
                : shortUSDate(d.updatedAt || d.createdAt),
            ])}
            width={[
              '7%',
              '42%',
              '23%',
              '20%',
              '8%',
            ]}
            bulkActions={[
              {
                title: 'Delete',
                callback: (ids: string[]) => {
                  dispatch(confirmRemove(() => {
                    ids.forEach(id => {
                      // @ts-expect-error Expected 0 arguments, but got 1.
                      dispatch(lessonsActions.deleteRequest({id}))
                    })
                  }))
                },
              },
            ]}
            rowActions={[
              {
                title: 'Edit',
                callback: (id: string) => {
                  history.push(editLessonUrl(idToLesson[id]))
                },
              },
              {
                title: 'Delete',
                callback: (id: string) => {
                  dispatch(confirmRemove(() => {
                    // @ts-expect-error Expected 0 arguments, but got 1.
                    dispatch(lessonsActions.deleteRequest({id}))
                  }))
                },
              },
            ]}
            idFn={(_, index) => lessons[index].id}
            isDisabledFn={(id: string) => idToLesson[id].status === 'published' }
          />}
        </View>
      </Content>
    </Layout>
  )
}

function confirmRemove(callback: () => void) {
  return modalShow(<ConfirmModal
    title="Delete Your Lesson"
    heading="Are you sure?"
    description="Once you delete a draft or pending lesson, you can’t undo it."
    confirmText="Delete Forever"
    onConfirm={callback}
  />)
}
