import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useDispatch } from 'react-redux'
import s from 'styles'
import { modalHide } from 'store/modal'
import { ModalFrame } from 'components/modal'
import { Image } from 'components/ReactNative'

export const dialogCancelledText = 'Cancelled'

type CallbackFunc = () => void

interface LegacyDialogBoxModalProps {
  checkBox?: boolean,

  red?: string,
  teal?: string,
  gray?: string,
  gray2?: string,
  smallTeal?: string,
  smallGray?: string,

  buttonText?: string,
  altButtonText?: string,

  linkText?: string,
  linkTextTeal?: string,

  onClose?: CallbackFunc,
  onAltClose?: CallbackFunc,
  onLinkClose?: CallbackFunc,
}

const LegacyDialogBoxModal = (props: LegacyDialogBoxModalProps) => {
  const dispatch = useDispatch()
  return (
    <ModalFrame
      title=""
      size="medium"
    >
      {props.checkBox && (
        <Image
          style={{
            alignSelf: 'center',
            resizeMode: 'contain',
            height: 100,
            width: 100,
          }}
          source={require('images/CheckedBox.svg')}
        />
      )}
      {!!props.red && (
        <Text style={[s.textCenter, s.textRed, s.f35]}>
          {props.red}
        </Text>
      )}
      {!!props.teal && (
        <Text style={[s.textCenter, s.textTeal, s.f35]}>
          {props.teal}
        </Text>
      )}
      {!!props.gray && (
        <Text style={[s.textCenter, s.textGray, s.f35]}>
          {props.gray}
        </Text>
      )}
      {!!props.gray2 && (
        <Text style={[s.textCenter, s.textGray, s.f35]}>
          {props.gray2}
        </Text>
      )}
      {!!props.smallTeal && (
        <View style={{ paddingTop: 10 }}>
          <Text style={[s.textCenter, s.textBold, s.textTeal, s.f18]}>
            {props.smallTeal}
          </Text>
        </View>
      )}
      {!!props.smallGray && (
        <View style={{ paddingTop: 10 }}>
          <Text style={[s.textCenter, s.textGray, s.f18]}>
            {props.smallGray}
          </Text>
        </View>
      )}
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        {!!props.altButtonText && (
          <TouchableOpacity
            onPress={() => {
              props.onAltClose && props.onAltClose()
              dispatch(modalHide())
            }}
          >
            <View
              style={{
                backgroundColor: 'white',
                margin: 20,
                padding: 20,
                borderColor: '#00b1b9',
                borderWidth: 1,
                borderRadius: 10,
                minHeight: 40,
                minWidth: 140,
              }}
            >
              <Text style={[s.textCenter, s.textTeal, s.textBold]}>
                {props.altButtonText}
              </Text>
            </View>
          </TouchableOpacity>
        )}
        {!!props.buttonText && (
          <TouchableOpacity
            onPress={() => {
              props.onClose && props.onClose()
              dispatch(modalHide())
            }}
          >
            <View
              style={{
                backgroundColor: '#00B1B9',
                margin: 20,
                padding: 20,
                borderRadius: 10,
                minHeight: 40,
                minWidth: 140,
              }}
            >
              <Text style={[s.textCenter, s.textWhite, s.textBold]}>
                {props.buttonText}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>

      {!!props.linkText && (
        <TouchableOpacity
          onPress={() => {
            props.onLinkClose && props.onLinkClose()
            dispatch(modalHide())
          }}
        >
          <View
            style={{
              padding: 10,
            }}
          >
            <Text style={[s.textCenter, s.textGray, s.f18]}>
              {props.linkText}
            </Text>
          </View>
        </TouchableOpacity>
      )}

      {!!props.linkTextTeal && (
        <TouchableOpacity
          onPress={() => {
            props.onLinkClose && props.onLinkClose()
            dispatch(modalHide())
          }}
        >
          <View
            style={{
              padding: 10,
            }}
          >
            <Text
              style={[
                s.textCenter,
                s.textTeal,
                s.textBold,
                s.textUnderline,
                s.f18,
              ]}
            >
              {props.linkTextTeal}
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </ModalFrame>
  )
}

export default LegacyDialogBoxModal

export const makeLegacyDialogBoxModal = (props: LegacyDialogBoxModalProps) => {
  return (<LegacyDialogBoxModal {...props} />)
}
