import React from 'react'
import { ModalContext } from '../context/ModalContext'

export default function useModal() {
  const context = React.useContext(ModalContext)

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}
