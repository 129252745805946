import React from 'react'
import Tippy from '@tippyjs/react/headless'

const components = {
  headerPortalMenu: require('./tooltips/HeaderPortalMenu').default,
  headerPortalMenuTarget: require('./tooltips/HeaderPortalMenu').target,
}

export default function ToolTip(props) {
  const component = components[props.componentName]
  if (!component) {
    return null
  }

  const target = components[props.componentName + 'Target']
  const placement = props.placement || 'bottom'

  const ref = React.useRef()
  const [instance, setInstance] = React.useState(null)

  const onShouldClose = () => {
    instance?.hide()
  }

  return (
    <Tippy
      ref={ref}
      interactive={true}
      appendTo={document.body}
      placement={placement}
      theme={'light'}
      arrow={true}
      zIndex={9999}
      trigger="click"
      render={() => React.createElement(component, { ...props, onShouldClose })}
      onShow={i => { setInstance(i) }}
      onHide={i => { setInstance(null) }}
    >
      <span>{React.createElement(target)}</span>
    </Tippy>
  )
}
