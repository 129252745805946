import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  settingsUpdated: false,
  error: '',
  socketIO: {
    socketId: undefined,
  },
}

const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    socketConnection(state, { payload }) {
      state.socketIO.socketId = payload.socketId
    },
    socketJoinRoomRequest(state, { payload }) {},
    socketLeaveRoomRequest(state, { payload }) {},
    socketSendMessageRequest(state, { payload }) {},
    socketSendMessageSuccess(state, { payload }) {},
    socketSendMessageFailure(state, { payload }) {},
    socketMessage(state, { payload }) {},

    saveFileFromURL(state, { payload }) {},
    printPDFFromURL(state, { payload }) {},
  },
})

export const {
  globalSetPlatform,
  globalSetViewport,

  socketConnection,
  socketJoinRoomRequest,
  socketLeaveRoomRequest,
  socketSendMessageRequest,
  socketSendMessageSuccess,
  socketSendMessageFailure,
  socketMessage,

  saveFileFromURL,
  printPDFFromURL,
} = global.actions

export default global.reducer
