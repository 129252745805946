
import React, { useEffect } from 'react'
import {Text, View, TouchableOpacity } from 'react-native'
import { Image } from 'components/ReactNative'
import { Link } from 'components/Router'
import s from 'styles'
import Button from 'components/Form/Button2'
import Input from 'components/Form/Input2'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { ModalFrame } from 'components/modal'
import { useDispatch, useSelector } from 'react-redux'
import { userProductUnlockRequest, userUnlockingStatusSelector, userCanViewORALessonsSelector } from 'store/user'
import { modalShow, modalHide } from 'store/modal'
import { openLink } from 'pages/util'

export const ORARegisterModal = (props: {
  routeURL: string,
  isSingleLesson?: boolean,
  onCancelled?: () => void,
}) => {
  const dispatch = useDispatch()
  const [serialNumber, setSerialNumber] = React.useState('')
  const [registering, setRegistering] = React.useState(false)

  const unlockingStatus = useSelector(userUnlockingStatusSelector)

  const submit = () => {
    dispatch(userProductUnlockRequest({ 
      closeModalOnSuccess: true,
      routeURL: props.routeURL,
      product: 'ora-content', 
      serialNumber, 
    }))
    setRegistering(true)
  }
  
  const shouldTryAgain = registering && unlockingStatus === 'error'

  return (<ModalFrame 
    title="Verification Required" 
    size="small" 
    noCloseButton={registering && unlockingStatus !== 'error'}
    onClose={props.onCancelled ? () => {
      dispatch(modalHide())
      props.onCancelled()
    } : undefined}
  >
    {!shouldTryAgain && (<Text style={[s.f16, s.textBlack, s.mb16]}>
      {`To access ${props.isSingleLesson ? 'this lesson' : 'these lessons'}, please enter the serial number found on the bottom of your ORA or located in your user manual.`}
    </Text>)}
    {registering 
      ? unlockingStatus === 'error'
        ? (<>
          <View style={[s.justifyCenter, s.alignCenter, s.alignSelfCenter, s.mb20]}>
            <Text style={[s.f24, s.textBold, s.textGrayDarkest, s.mb8]}>
              Uh Oh!
            </Text>
            <Text style={[s.f16, s.textRed]}>
              {'It looks like there was a problem with this serial number. Please try again, or contact '}            
              <Link to='mailto:support@ozobot.com'>
                <Text style={[s.textGreenDark, s.textUnderline]}>
                  support@ozobot.com
                </Text>
              </Link>
              {'.'}
            </Text>
          </View>
        </>)
        : (<>
          <View style={[{ width: 250 }, s.justifyCenter, s.alignCenter, s.alignSelfCenter, s.mb20]}>
            <Text style={[s.f20, s.textBold, s.textGrayDarkest]}>
              Please wait...
            </Text>
          </View>
        </>)
      : (<Input
        value={serialNumber}
        setValue={v => setSerialNumber(v.trim())}
        label='Serial Number'
        onPressEnter={submit}
      />)
    }
    <View style={[s.alignSelfCenter, {width: 150}, s.mb16]}>
      <Button
        text={shouldTryAgain ? 'Try Again' : 'Submit'}
        onPress={() => shouldTryAgain ? setRegistering(false) : submit()}
        disabled={!serialNumber || (registering && !shouldTryAgain)}
      />
    </View>
    <View style={[{ width: 250 }, s.justifyCenter, s.alignCenter, s.alignSelfCenter]}>
      <Text style={[s.f16, s.textBlack, s.mb8]}>
        {'Don’t have an ORA? Click '}
        <TouchableOpacity 
          onPress={() => {
            openLink('https://shop.ozobot.com/collections/all/products/ora-ozobot-robotic-arm', dispatch, false, () => {
              dispatch(modalShow(<ORARegisterModal {...props}/>))
            })
          }}
        >
          <Text style={[s.textGreenDark, s.textUnderline, s.textBold]}>here</Text>
        </TouchableOpacity>
        {' to shop now.'}
      </Text>
    </View>
  </ModalFrame>)
}

export const lessonSearchLinkReferencesORALessons = (link: string) => {
  return link.includes('=ORA')
}

export const useLessonURLIsBlocked = (link: string) => {
  if(link.startsWith('/lessons')) {
    if(lessonSearchLinkReferencesORALessons(link)) {
      return !useSelector(userCanViewORALessonsSelector)
    }
  }
  return false
}

export const useOpenPossiblyBlockedLessonRoute = () => {
  const canViewORALessons = useSelector(userCanViewORALessonsSelector)
  return (
    link: string, 
    dispatch: React.Dispatch<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    const isBlocked = link.includes('ORA') && !canViewORALessons
    if(isBlocked) {
      dispatch(modalShow((<ORARegisterModal routeURL={link}/>)))
    } else {
      openLink(link, dispatch)
    }
  }
}

const BlockedLessonView = (props: {
  routeURL: string,
  isSingleLesson?: boolean,
}) => {
  const { isDesktop, browserWidowWidth, browserWidowHeight } = useDetectDeviceType()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(modalShow(<ORARegisterModal 
      routeURL={props.routeURL} 
      isSingleLesson={props.isSingleLesson}
      onCancelled={() => { openLink('/lessons', dispatch) }}
    />))
  }, [])

  return (
    <View style={[s.flexColumn, s.alignStretch]}>
      <Image
        source={isDesktop 
          ? require('images/ora-blocked-background.png') 
          : require('images/ora-blocked-background-mobile.png')
        }
        style={{ width: browserWidowWidth, height: browserWidowHeight }}
      />
    </View>
  )
}

export default BlockedLessonView
