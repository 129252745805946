import React from 'react'
import { View, Text } from 'react-native'
import { Input } from 'components/Form'
import { some } from 'lodash'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'

const SubmissionNumberTextarea = ({ field, text, secondaryText, form }) => {
  return (
    <View>
      <SubmissionLabel
        text={text}
        secondaryText={secondaryText}
        hasError={some(form.errors, { field })}
      />
      <View style={[s.flexRow, s.alignCenter]}>
        <Input
          name={field}
          width="w60"
          maxLength={3}
          value={form[field]}
          onFormInputChangeText={obj =>
            form.updateForm({ [field]: obj[field].replace(/[^0-9]+/g, '') })
          }
        />
        <Text style={[s.ml16, s.mb4, s.f14, s.textBold]}>Minutes</Text>
      </View>
    </View>
  )
}

export default withSubmissionForm(SubmissionNumberTextarea)

// Delete after migration complete
// class LessonNumberTextarea extends React.Component {
//   constructor(props) {
//     super(props)
//     this.onChangeInput = this.onChangeInput.bind(this)
//     this.validate = this.validate.bind(this)
//   }

//   validate() {
//     if (this.props.validation) {
//       // TODO: Use validation lib like validator.js
//       const fns = {
//         required: function(str) {
//           return str.length > 0
//         },
//       }

//       const value = !this.props.validation.every(
//         fn => fns[fn](this.props.lesson[this.props.field]) === true
//       )

//       this.props.lessonSubmissionErrorUpdate({
//         field: this.props.field,
//         value,
//       })
//     }
//   }

//   render() {
//     return (
//       <View style={[s.px16, s.mb20, { paddingHorizontal: 0 }]}>
//         <View style={[s.flexRow, s.alignEnd]}>
//           <Text
//             style={[
//               s.f16,
//               s.textBold,
//               this.props.errors.indexOf(this.props.field) > -1 &&
//               this.props.lesson[this.props.field].length === 0
//                 ? s.textRed
//                 : s.textGrayDarker,
//               s.mb4,
//             ]}
//           >
//             {this.props.text}
//           </Text>
//           <Text
//             style={[
//               s.f14,
//               this.props.errors.indexOf(this.props.field) > -1 &&
//               this.props.lesson[this.props.field].length === 0
//                 ? s.textRed
//                 : s.textGray,
//               s.mb4,
//               s.px8,
//             ]}
//           >
//             {this.props.secondaryText}
//           </Text>
//         </View>
//         <View style={[s.mb16, s.flexRow, s.positionRelative]}>
//           <RoundedText
//             onChangeText={this.onChangeInput}
//             onBlur={this.validate}
//             value={this.props.lesson[this.props.field]}
//             short={true}
//             number={true}
//             errors={this.props.errors}
//             field={this.props.field}
//             width={50}
//           />
//           <Text
//             style={[
//               s.f14,
//               this.props.errors.indexOf(this.props.field) > -1 &&
//               this.props.lesson[this.props.field].length === 0
//                 ? s.textRed
//                 : s.textGrayDarker,
//               s.textBold,
//               s.px8,
//               s.py8,
//               s.textCenter,
//             ]}
//           >
//             minutes
//           </Text>
//         </View>
//       </View>
//     )
//   }
// }
