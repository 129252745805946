
import React from 'react'
import {Text, View, Image, ImageSourcePropType } from 'react-native'
import { Link } from 'components/Router'
import s from 'styles'
import Button from 'components/Form/Button2'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

const NoLessons = (props: {
  image?: ImageSourcePropType,
  imageWidth?: number,
  imageHeight?: number,
  text: string,
  link: string,
  linkText: string
}) => {
  // FIXME: need "official" image
  const { isDesktop } = useDetectDeviceType()
  return (
    <View style={[s.flexGrow1, s.justifyCenter, s.alignSelfCenter, { maxWidth: isDesktop ? 700 : 600 }]}>
      <View style={[s.mlAuto, s.mrAuto]}>
        <Image
          source={props.image || require('images/bot-disconnected.svg')}
          style={[{ width: props.imageWidth || 200, height: props.imageHeight || 200 }]}
        />
      </View>
      <Text style={[s.textGray, s.px32, s.pt32, s.f18, s.textLeft]}>
        {props.text + '\n'}
      </Text>
      <View style={[s.textCenter, s.alignCenter, s.justifyCenter]}>
        {<Link to={props.link} style={[s.mt16]}>
          <Button
            rounded={true}
            text={props.linkText}
          />
        </Link>}
      </View>

    </View>
  )
}

export default NoLessons
