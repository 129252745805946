import React from 'react'
import { View, Text, TouchableOpacity, Dimensions, ImageBackground, ImageSourcePropType } from 'react-native'
import { Content, Layout } from 'components/Layout'
import { ModalFrame } from 'components/modal'
import { useDispatch } from 'react-redux'
import { modalShow } from 'store/modal'
import VideoPlayer from 'components/NewVideoPlayer'
import { Image } from 'components/ReactNative'
import WebView from 'components/WebView'
import s from 'styles'
import { printPDFFromURL, saveFileFromURL } from 'store/global'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { Column, Row } from 'react-native-web-ui-components'
import { openLink } from 'pages/util'
import { makePDFViewURL } from 'lib/utils.js'
import { ButtonLinkBlack, ButtonLinkBlackMobile, ButtonLinkProps } from 'components/ButtonLink'

export interface CardProps extends ButtonLinkProps {
  image?: ImageSourcePropType,
  text: string,
  overlayText?: string,
}

export default function Help() {

  const dispatch = useDispatch();
  const { isDesktop, isMobile, isTablet } = useDetectDeviceType()
  const baseTextStyle = [s.f20]
  const linkStyle = [...baseTextStyle, s.textGreenDark]
  const showModal = (Component) => dispatch(modalShow(Component))
  const PrintLink = ({ text, url }: { text: string; url?: string }) => (
    <View style={[s.flexRow, s.alignCenter, s.ml32]}>
      <Image
        source={require('images/print-icon.svg')}
        style={{
          width: 17, height: 17, marginRight: 8, resizeMode: 'contain',
        }}
      />
      {url ? (<Text onPress={() => dispatch(printPDFFromURL({ url }))} numberOfLines={1} style={[s.f16, s.textBold, s.textGreen, s.textUnderline]}>{text}</Text>) :
        (<Text numberOfLines={1} style={[s.f16]}>{text}</Text>)}
    </View>
  )

  const DownloadLink = ({ text, url, filename }: { text: string; url: string, filename: string }) => (
    <View style={[s.flexRow, s.alignCenter, s.ml32]}>
      <Image
        source={require('images/download-icon.svg')}
        style={{
          width: 17, height: 17, marginRight: 8, resizeMode: 'contain',
        }}
      />
      {url ? (<Text onPress={() => dispatch(saveFileFromURL({ url, fileName: filename }))} numberOfLines={1} style={[s.f16, s.textBold, s.textGreen, s.textUnderline]}>{text}</Text>) :
        (<Text numberOfLines={1} style={[s.f16]}>{text}</Text>)}
    </View>
  )

  interface PdfModalType {
    uri: string
    filename: string
  }
  function PdfModal({ uri, filename }: PdfModalType) {
    const dim = Dimensions.get('window')
    return (
      <View>
        <View style={[s.flexRow, s.justifyEnd, s.mr32]}>
          <PrintLink text={'print'} url={uri} />
          <DownloadLink text={'download'} filename={filename} url={uri} />
        </View>
        <View style={[{ height: dim.height - 200 }, s.w100p, s.m32]} >
          <WebView source={{ uri: makePDFViewURL(uri) }} style={{ height: '100%', width: 900 - 64 }} />
        </View>
      </View>
    )
  }


  const showPdf = ({ uri, title, external, dispatch }) => {
    if(external) {
      openLink(uri, dispatch)
    } else {
      const filename = title.replace(/\s+/g, '-').toLowerCase() + '.pdf';
      showModal(<ModalFrame title={title} size="large" noMargins={true}>
        <PdfModal filename={filename} uri={uri} />
      </ModalFrame>)
    }
  }

  return (
    <Layout>
      <Content>
        <ImageBackground
          source={require('images/Top-Header-Help.png')}
          resizeMode="stretch"
          imageStyle={isMobile ? { width: 200, height: 50 } : { width: 500 }}
        >
          <Text style={[
            s.textBold, s.textItalic, s.textWhite, s.textLeft,
            isMobile
              ? [s.f24, s.p8, s.w200]
              : [s.f45, s.p32, s.w500],
          ]}>
            Help
          </Text>
        </ImageBackground>
        <Row style={[s.flexRow, s.w100p, !isMobile && s.mb32, s.mt45, isDesktop ? s.ml32 : null]}>
          <Column xs={12} sm={6} md={7} style={[]}>
            {!isMobile && <HelpVideos showModal={showModal}/>}
            {isDesktop && <Downloadables showPdf={showPdf}/>}
          </Column>
          <Column xs={12} sm={6} md={5} style={[s.flexWrap, isMobile ? s.px16 : s.pr20, (isTablet) ? s.justifyCenter : null]}>
            <View style={[s.bgGrayMoreLighter, s.br20, s.bShadow, isDesktop ? s.w80p : s.w100p, {maxWidth: '600px'}, isDesktop && s.mxl70, s.alignCenter, isTablet && s.ml20]}>
              <TouchableOpacity
                onPress={() => openLink('https://www.facebook.com/groups/OzobotAcademy', dispatch)}
              >
                <Image
                  source={require('images/Help_Illustration.png')}
                  style={{ width: '100%', height: 200, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
                />
                <Text style={[...baseTextStyle, s.textBold, s.p46 ]}>
                  Gain insight from fellow educators using Ozobot in the classroom by
                  <Text style={linkStyle}> joining our Ozobot Academy group on Facebook</Text>
                </Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity
              style={[s.w100p, s.mt32]}
              onPress={() => openLink('mailto:support@ozobot.com', dispatch)}
            >
              <View
                style={[
                  s.flexRow,
                  s.alignCenter,
                  s.bgGrayMoreLighter,
                  s.br20,
                  s.p46,
                  s.bShadow,
                  s.h100,
                  isDesktop ? s.w80p : s.w100p,
                  { maxWidth: '600px' },
                  isDesktop ? s.mxl70: s.mlAuto,
                  isTablet && s.ml20,
                ]}
              >
                <Text style={[...baseTextStyle, s.textBold]}>
                  More questions? Contact <Text style={linkStyle}>support@ozobot.com</Text>
                </Text>
              </View>
            </TouchableOpacity>
            <View style={[s.mb8, s.alignCenter, s.mt32, isDesktop ? s.w80p : s.w100p]}>
              <View style={s.row}>
                {CONTENT.guides.map((guide, i) => (
                  <Guide key={i} {...guide} />
                ))}
              </View>
            </View>
            {isMobile && <View style={[s.alignCenter, s.mt32, isDesktop ? s.w80p : s.w100p]}>
              <HelpVideos showModal={showModal}/>
            </View>}
          </Column>
        </Row>
        {!isDesktop && <View style={[s.flexRow, s.mb8, s.alignCenter, s.w100p, s.px16]}>
          <Downloadables showPdf={showPdf}/>
        </View>}
      </Content>
    </Layout>
  )
}



const CONTENT = {
  guides: [
    {
      url: 'https://ozobot.com/support/faq#section-classroom',
      text: 'FAQ',
    },
    {
      url: '/help/educator-training',
      text: 'Educator Training',
    },
  ],
  downloadables: {
    Resources: [
      {
        url: 'https://static.ozobot.com/assets/a40b6c92-ozobot-color-codes-guide.pdf',
        title: 'Color Code Guide',
        external: true,
      },
      {
        url: 'https://static.ozobot.com/assets/7743bc40-2023-ozobot-evo-compatibility-chart.pdf',
        title: 'Evo Compatibility Chart',
      },
    ],
    'Printouts': [
      {
        url:
          'https://files.ozobot.com/resources/ozobot-student-steam-certificates.pdf',
        title: 'STEAM Student Certificate',
      },
    ],
  },
  videos: [
    {
      title: 'How To: Get Started with Evo',
      video: {
        videoID: 'https://files.ozobot.com/videos/ozobot-how-to-get-started-with-ozobot-evo-2023.mp4',
        provider: 'url',
      },
    },
    {
      title: 'How To: Color Code',
      video: {
        videoID: 'https://files.ozobot.com/videos/ozobot-how-to-color-codes.mp4',
        provider: 'url',
      },
    },
  ],
}

interface GuideProps {
  url: string
  text: string
}

const Guide = ({ url, text }: GuideProps) => {
  const { isDesktop, isTablet } = useDetectDeviceType()
  return (
    <View style={[s.px16, isDesktop ? s.w40p : s.w40p, s.mb20, s.mt20, s.mxl20, isTablet ? s.mr32 : s.mrAuto ]}>
      <ButtonLinkBlack
        buttonText={text}
        buttonLink={url}
      />
    </View>
  )
}

const Downloadables = ({showPdf}: {showPdf: Function}) => {
  const { isDesktop, isMobile } = useDetectDeviceType()
  const dispatch = useDispatch()
  return (
    <View style={[isMobile ? s.flexColumn : s.flexRow, s.w100p, s.py32, isMobile && s.justifyBetween, isDesktop && s.ml64]}>
      {Object.keys(CONTENT.downloadables).map((title, i) => (
        <View key={i} style={[!isMobile && s.mr45, isMobile && s.mb16]}>
          <Text style={[s.f16, s.textBold, s.textGrayDarkest, s.mb8]}>
            {title}
          </Text>
          {CONTENT.downloadables[title].map(({ url, title, external }, i) => (
            <View key={i}>
              <TouchableOpacity onPress={() => showPdf({ uri: url, title, external, dispatch })} >
                <Text style={[s.mb4, s.f16]}>
                  <Text style={[s.f24, s.textGreenDark]}>• </Text>
                  <Text style={[s.f16, s.textGreenDark, s.textUnderline]}>
                    {title}
                  </Text>
                </Text>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

export const HelpVideos = ({ showModal }: { showModal: (component: React.ReactNode) => void }) => {
  const { isMobile } = useDetectDeviceType()

  const showVideo = ({ video, title }) => {
    showModal(<ModalFrame title={title} size="large" noMargins={true}>
      <VideoPlayer video={video} />
    </ModalFrame>)
  }

  return (
    <Row style={[s.flexWrap, s.alignStretch, !isMobile && s.mb32]}>
      {CONTENT.videos.map(({ title, video }, i) => (
        <Column xs={12} sm={12} md={4} key={i} style={[!isMobile && s.px16, s.mb8, s.flexWrap]}>
          <TouchableOpacity
            onPress={() => showVideo({ video, title })}
          >
            <View
              style={[
                s.px20,
                s.flexRow,
                s.alignCenter,
                s.bgGrayMoreLighter,
                s.br10,
                s.py24,
                s.bShadow,
                s.h100,
                s.mb16,
              ]}
            >
              <Image
                source={require('images/Play-arrow.png')}
                style={{ height: 24, width: 18, marginRight: 20 }}
              />
              <Text style={[s.f16, s.textBlack, s.textBold]}>
                {title}
              </Text>
            </View>
          </TouchableOpacity>
        </Column>
      ))}
    </Row>
  )
}

export function DashboardMainCard({ text, ...buttonProps }: CardProps) {
  const { isDesktop } = useDetectDeviceType();

  return (
    <Column xs={12} style={[s.mb32]}>
      <View style={[
        s.br4,
        s.bWhite,
        s.b1,
        s.flexRow,
      ]}>

        <View style={[s.positionAbsolute, s.top0, s.right0, s.h500, s.bBlackLighter]}>
          <ImageBackground
            source={require('images/Help_Illustration.png')}
            imageStyle={{ borderRadius: 4, height: 300 }}
            resizeMode="cover">
            {isDesktop ? (
              <View style={[s.flex1, s.justifyCenter, s.p46, isDesktop ? s.ml64 : s.ml32]}>
                <Text style={[s.f24, s.textWhite, s.pb8, s.textItalic]}>{text}</Text>
                <ButtonLinkBlack {...buttonProps} />
              </View>
            ) : (
              <View style={[{ width: 260, height: 150, paddingHorizontal: 40, paddingVertical: 12 }]}>
                <Text style={[s.f16, s.textWhite, s.pb8, s.textItalic]}>{text}</Text>
                <ButtonLinkBlackMobile {...buttonProps} />
              </View>
            )}
          </ImageBackground>
        </View>
      </View>
    </Column>
  )
}
