import React, { useState } from 'react'
import { View, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  userAuthGoogleLinkRequest,
  userAuthUnlinkRequest,
  userUpdateRequest,
  userDeleteRequest,
  userAuthEmailSettingsRequest,
  userSelector,
} from 'store/user'
import { notificationsActions } from 'store/notifications'
import { modalShow, modalHide } from 'store/modal'
import { ModalFrame } from 'components/modal'
import { SectionH2 } from 'components/Layout'
import { Button, CheckBoxItem } from 'components/Form'
import Input from 'components/Form/Input2'
import { Image } from 'components/ReactNative'
import s from 'styles'
import Link from 'components/TextLink'
import validations from 'lib/validations'
import validate from 'validate.js'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import { openLink } from 'pages/util'

interface Message {
  id?: number
  isEmpty?: boolean
  text?: string
  timestamp: number
}
interface GlobalStore {
  user: {
    firstName: string,
    lastName: string,
    email: string,
    providers: {
      providerType: string
    }[],
    receiveEmails: boolean,
  },
  notifications: {
    list: Array<{
      id: number,
      message: string,
      timestamp: number,
      type: string,
    }>,
  }
}

interface Provider {
  email: string,
  providerType: 'google' | 'email',
  providerId: string,
  isVerified: boolean,
}

const NEED_VERIFICATION_ERROR = 'Before updating account info, you must verify your email.'

const canConnectProvider = (providers: Array<Provider>) =>
  !providers.find(provider => !provider.isVerified)

const canDisconnectProvider = (providers: Array<Provider>) =>
  providers.length > 1 && !providers.find(provider => !provider.isVerified)

function DisconnectLinkedProviderDialog(props: {
  onModalClose: () => void | undefined,
  onDisconnect: () => void | undefined,
}) {
  const { onModalClose, onDisconnect } = props
  const [confirmText, setConfirmText] = useState<string>('')
  const canDisconnect = confirmText && confirmText.toLowerCase() === 'disconnect'
  const disconnect = () => {
    if (canDisconnect) {
      onDisconnect()
      onModalClose()
    }
  }
  return (
    <ModalFrame title="Disconnect Google Account?" size="medium">
      <View style={[s.alignCenter]}>
        <View style={[s.mb32]}>
          <Text style={[s.f16, s.textCenter,s.textBold]}>
          You won’t be able to log in with Google in the future.
          </Text>
        </View>
        <View style={[s.w300]}>
          <View style={[s.mb32]}>
            <Input
              label={'To confirm, type “disconnect”'}
              name="confirm"
              setValue={setConfirmText}
              onPressEnter={disconnect}
              value={confirmText}
              returnKeyType="done"
            />
          </View>
          <View style={[s.flexRow, s.justifyBetween]}>
            {/* @ts-expect-error  */}
            <Button text="Cancel" onPress={onModalClose} />
            {/* @ts-expect-error  */}
            <Button text="Disconnect" onPress={disconnect} disabled={!canDisconnect} />
          </View>
        </View>
      </View>
    </ModalFrame>
  )
}

function Account() {
  const user = useSelector(userSelector)
  const hasEmailPasswordProvider = useSelector<GlobalStore, boolean>(({ user }) => {
    return user.providers && user.providers.some(provider => provider.providerType === 'email')
  })
  const providers = useSelector<GlobalStore, Array<Provider>>(({ user }) => {
    return user.providers as Array<Provider>
  })

  const { isDesktop } = useDetectDeviceType();

  const emptyMessage = {
    isEmpty: true,
    timestamp: 0,
  }

  const [lastMessage, setLastMessage] = useState<Message>(emptyMessage)

  const unverifiedEmails = providers
    .filter(p => p.providerType === 'email' && !p.isVerified)
    .map(p => p.email)

  useSelector<GlobalStore, Message>(({ notifications }) => {
    const messages: Array<Message> = notifications.list
      .filter(n => n.type.includes('auth/'))
      .map(n => ({ id: n.id, timestamp: n.timestamp, text: n.message }))

    const currentMessages = messages.filter(m => m.timestamp > lastMessage.timestamp);
    if (currentMessages.length > 0) {
      const message = currentMessages[0];

      setTimeout(() => {
        setLastMessage(message)
      })

      return message
    }
  })

  const dispatch = useDispatch()

  const notificationTypes = ['auth/settings']
  const clearNotifications = () => {
    setLastMessage(emptyMessage)
    dispatch(notificationsActions.removeType(notificationTypes))
  }

  React.useEffect(() => {
    // Make sure notifications are cleared for this view
    return () => {
      dispatch(notificationsActions.removeType(notificationTypes))
    }
  }, [])

  return (
    <View style={s.h100p}>
      {!lastMessage.isEmpty && (
        <View style={[s.px16, s.mb32]}>
          {lastMessage.text === NEED_VERIFICATION_ERROR && (
            <EmailVerifyMsg onResend={() => {
              clearNotifications()
              dispatch(userAuthEmailSettingsRequest({ verifyEmails: unverifiedEmails }))
              dispatch(notificationsActions.remove(lastMessage.id))
              setLastMessage({
                isEmpty: true,
                timestamp: 0,
              })
            }} />
          )}
          {lastMessage.text !== NEED_VERIFICATION_ERROR && (
            <Error text={lastMessage.text} />
          )}
        </View>
      )}
      <View style={[s.h100p, s.justifyBetween]}>
        <View style={[ isDesktop ? s.flexRow : s.flexColumn]}>
          <View style={[s.flexShrink0, s.flexGrow1, isDesktop ? s.col6 : null, s.px16]}>
            <EmailSection
              email={user.email}
              canEditEmail={hasEmailPasswordProvider}
              onChangeEmail={email => {
                clearNotifications()
                email = email.trim().toLowerCase()
                if(email) {
                  // validate email
                  const errors = validate({ email }, { email: validations.email } )
                  if(errors && errors.length > 0) {
                    setLastMessage({
                      timestamp: Date.now(),
                      text: 'Please enter a valid email address.',
                    })
                  } else {
                    dispatch(userAuthEmailSettingsRequest({ email }))
                  }
                }
              }}
            />
            <CreatePassword
              onResetPassword={() => {
                clearNotifications()
                dispatch(userAuthEmailSettingsRequest({ email: user.email }))
              }}
            />
          </View>
          <View style={[s.col6, s.px16, s.flexGrow1, s.flexShrink0]}>
            <GoogleAccountSection
              providers={providers}
            />
            <View style={[s.pb20]}>
              <EmailPreferencesSection
                receiveEmails={user.receiveEmails}
                onChange={obj => {
                  clearNotifications()
                  dispatch(userUpdateRequest(obj))
                }}
              />
            </View>
          </View>
        </View>

        <Footer
          onDeleteAccount={() => {
            dispatch(modalShow(<DeleteAccount id={user.accountId} />))
          }}
        />
      </View>
    </View>
  )
}

interface DeleteAccountProps {
  id: string
}

function DeleteAccount({ id }: DeleteAccountProps) {
  const [text, setText] = useState<string>('')
  const dispatch = useDispatch()

  const message = 'To confirm, type “delete”'
  const expectedText = 'delete'

  const isValid = () => expectedText === text.toLowerCase()

  return (
    <ModalFrame title="Delete Account?" size="medium">
      <View style={{width: 394}}>
        <View style={[s.pb16]}>
          <Text style={[s.f24, s.textBlackLighter, s.textBold, s.textCenter]}>This action cannot be undone.</Text>
        </View>
        <View style={[s.alignSelfCenter]}>
          <Input
            name="check"
            placeholder={message}
            setValue={setText}
            value={text}
          />
        </View>

        <View style={[s.flexRow, s.justifyBetween, s.px32, s.py8]}>
          {/* @ts-expect-error  */}
          <Button
            text="Cancel"
            onPress={() => {
              dispatch(modalHide())
            }}
          />

          {/* @ts-expect-error  */}
          <Button
            text="Delete Account"
            disabled={!isValid()}
            onPress={() => {
              dispatch(userDeleteRequest({ id }))
              dispatch(modalHide())
            }}
          />
        </View>
      </View>
    </ModalFrame>
  )
}

interface ErrorProps {
  text: string
}

function Error({ text }: ErrorProps): JSX.Element {
  return <Text style={[s.f16, s.textBold, s.textRed]}>{text}</Text>
}

interface EmailVerifyMsgProps {
  onResend: () => void
}
function EmailVerifyMsg({ onResend }: EmailVerifyMsgProps): JSX.Element {
  return (
    <View style={[s.flexRow]}>
      <Error text={NEED_VERIFICATION_ERROR} />
      <Link
        onPress={() => onResend()}
        style={[s.f16, s.alignBaseline, s.ml4]}
      >
        Resend email
      </Link>
    </View>
  )
}

interface SectionProps {
  title: string
  children: React.ReactNode
}

function Section({ title, children }: SectionProps): JSX.Element {
  return (
    <View style={[s.mb45]}>
      <SectionH2 text={title} />
      {children}
    </View>
  )
}

interface EmailProps {
  email: string,
  canEditEmail: boolean,
  onChangeEmail?: (email: string) => void
  error?: string
}

function EmailSection({ email, canEditEmail, onChangeEmail, error }: EmailProps): JSX.Element {
  const [isEditable, setEditable] = useState(false)
  const [inputEmail, setInputEmail] = useState(email)

  return (
    <Section title="Email">
      {isEditable && (
        <View style={[s.pr32]}>
          <Input
            isError={!!error}
            value={inputEmail}
            name="email"
            keyboardType="email-address"
            autoCompleteType="email"
            autoCapitalize="none"
            setValue={setInputEmail}
          />

          <View style={[s.flexRow]}>
            {/* @ts-expect-error  */}
            <Button
              text="Save"
              onPress={() => {
                onChangeEmail(inputEmail)
                setEditable(false)
              }}
              style={[s.mr20]}
            />

            <Link
              onPress={() => setEditable(false)}
              style={[s.f16]}
              outerStyle={[s.justifyCenter]}
            >
              Cancel
            </Link>
          </View>
        </View>
      )}

      {!isEditable && (
        <View>
          <Text style={[s.f16, s.textBlackLighter, s.mb24]}>{email}</Text>
          {canEditEmail && (
            <View style={[s.flexRow]}>
              {/* @ts-expect-error  */}
              <Button text="Change Email"
                onPress={() => {
                  setInputEmail(email)
                  setEditable(true)
                }}
              />
            </View>)}
        </View>
      )}
    </Section>
  )
}
interface ResetPasswordGoogleProps {
  onResetPassword: () => void
}

function CreatePassword({ onResetPassword: onCreatePassword }: ResetPasswordGoogleProps): JSX.Element {
  return (
    <Section title="Password">
      <View style={[s.flexRow]}>
        {/* @ts-expect-error  */}
        <Button text="Reset Password"
          onPress={() => onCreatePassword()}
        />
      </View>
    </Section>
  )
}

interface GoogleAccountSectionProps {
  providers: Array<Provider>
}

function GoogleAccountSection({ providers }: GoogleAccountSectionProps): JSX.Element {
  const dispatch = useDispatch()
  const canDisconnect = canDisconnectProvider(providers)
  return (
    <Section title="Connected Account(s)">
      <View style={[s.mb20]}>
        {providers.filter(provider => provider.providerType === 'google').map(provider => (<GoogleAccount
          key={provider.providerId}
          name={provider.email}
          canDisconnect={canDisconnect}
          onDisconnect={() => {
            dispatch(modalShow(<DisconnectLinkedProviderDialog
              onDisconnect={() => dispatch(userAuthUnlinkRequest(provider))}
              onModalClose={() => dispatch(modalHide())}
            />))
          }}
        />))}
        <ConnectGoogleAccount
          canConnect={canConnectProvider(providers)}
        />
      </View>
    </Section>
  )
}

function ConnectGoogleAccount(props: { canConnect: boolean }): JSX.Element {
  const { canConnect } = props
  const dispatch = useDispatch()
  return (
    <View style={[s.bgGrayMoreLighter, s.bShadow, s.br12, s.p8, s.flexRow, s.justifyBetween]}>
      <GoogleLogo />
      <Link
        style={[s.alignSelfCenter, s.f16]}
        outerStyle={[s.justifyCenter]}
        onPress={() => {
          if (canConnect) {
            dispatch(
              userAuthGoogleLinkRequest({
                postLoginRedirect: '/settings/account',
              }),
            )
          } else {
            dispatch(notificationsActions.add({
              message: NEED_VERIFICATION_ERROR,
              type: 'auth/link',
              showUI: false,
            }))
          }
        }}
      >
        Connect
      </Link>
    </View>
  )
}

interface GoogleAccountProps {
  name: string
  onDisconnect: () => void
  canDisconnect: boolean
}

function GoogleAccount({ name, onDisconnect, canDisconnect }: GoogleAccountProps): JSX.Element {
  return (
    <View style={[s.bgGrayMoreLighter, s.bShadow, s.br12, s.p8, s.flexRow, s.justifyBetween, s.my4]}>
      <GoogleLogo text={name} />
      {canDisconnect && <Link
        style={[s.alignSelfCenter, s.f16]}
        outerStyle={[s.justifyCenter]}
        onPress={() => onDisconnect()}
      >
        Disconnect
      </Link>}
    </View>
  )
}

interface EmailPreferencesSectionProps {
  receiveEmails: boolean,
  onChange: (obj: object) => void,
}

function EmailPreferencesSection({ receiveEmails, onChange }: EmailPreferencesSectionProps): JSX.Element {
  const onEmailPreferenceChange = obj => {
    onChange({ receiveEmails: !(obj.receiveEmails[0] === 'true') })
  }
  return (
    <Section title="Email Notifications">
      <CheckBoxItem
        label="Get updates, lesson ideas, and more!"
        name="receiveEmails"
        value={'' + !!receiveEmails}
        isChecked={!!receiveEmails}
        onChange={onEmailPreferenceChange}
      />
    </Section>
  )
}

interface GoogleLogoProps {
  text?: string
}

function GoogleLogo({ text = 'Google Account' }: GoogleLogoProps): JSX.Element {
  const {isDesktop} = useDetectDeviceType();
  return (
    <View style={[s.flexRow]}>
      <View style={[s.bgWhite, { width: 58, height: 58 }, s.justifyCenter]}>
        <Image
          source={require('images/logo-google.svg')}
          style={{ width: 27, height: 27, alignSelf: 'center' }}
        />
      </View>

      <Text style={[ isDesktop ? s.ml20 : null, s.alignSelfCenter, s.f16, s.textBlackLighter]}>
        {text}
      </Text>
    </View>
  )
}

interface FooterProps {
  onDeleteAccount?: () => void
}

export function Footer({ onDeleteAccount }: FooterProps): JSX.Element {
  const dispatch = useDispatch()
  return (
    <View style={[s.mb32]}>
      {onDeleteAccount && (
        <View style={[s.mb24]}>
          <Link
            outerStyle={[s.justifyCenter]}
            onPress={() => onDeleteAccount()}
            style={[s.w100p, s.textRight]}
          >
            Delete Account
          </Link>
        </View>
      )}
      <View style={[s.flexRow]}>
        <Text style={[s.textBold, s.f16, s.textBlackLighter]}>
          Questions?
        </Text>
        <Text style={[s.f16, s.ml8, s.textBlackLighter]}>
          Contact <Link
            style={[s.f16]}
            onPress={() => openLink('mailto:support@ozobot.com', dispatch)}
          >
            support@ozobot.com
          </Link>
        </Text>
      </View>
    </View>
  )
}

export default Account
