import { some } from 'lodash'
import React from 'react'
import { Text, View } from 'react-native'
import { Link } from 'components/Router'
import { Image } from 'components/ReactNative'
import SubmissionAttachments from './SubmissionAttachments'
import SubmissionLabel from './SubmissionLabel'
import withSubmissionForm from './withSubmissionForm'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
import s from 'styles'

const ButtonLarge = ({ hasError, text, secondaryText, imageSource, isMobile }) => (
  <View
    style={[
      s.b1,
      hasError ? s.bRed : s.bGrayLighter,
      s.alignCenter,
      s.br10,
      s.mr20,
      isMobile
        ? { width: 150, height: 140, paddingTop: 20 }
        : { width: 190, height: 170, paddingTop: 30 },
    ]}
  >
    <Image
      source={imageSource}
      style={[
        s.w80,
        s.w70p,
        s.mb8,
        { height: 67, tintColor: hasError ? '#fa7272' : null },
      ]}
      resizeMode="contain"
    />
    <Text style={[isMobile ? s.f12 : s.f16, s.textCenter, hasError && s.textRed]}>
      <View>
        <Text style={s.textBold}>{text}</Text>
        <Text style={[s.textGray]}>{secondaryText}</Text>
      </View>
    </Text>
  </View>
)

const SubmissionAttachmentsUploaderGroup = ({
  text,
  field,
  secondaryText,
  form,
}) => {
  const hasError = some(form.errors, { field })
  const { isMobile } = useDetectDeviceType()
  return (
    <View style={[s.mt4]}>
      <SubmissionLabel
        hasError={hasError}
        text={text}
        secondaryText={secondaryText}
      />
      <SubmissionAttachments
        File={() => (
          <ButtonLarge
            hasError={hasError}
            imageSource={require('images/lessons-upload-file-teal.svg')}
            text="Add PDF*"
            isMobile={isMobile}
          />
        )}
        Google={() => (
          <ButtonLarge
            imageSource={require('images/lessons-drive-add-teal.svg')}
            text="Add from Google Drive"
            secondaryText="optional"
            isMobile={isMobile}
          />
        )}
        attachments={form[field]}
        attachmentsFolder={form.attachmentsFolder}
        onChange={attachments => form.updateForm({ [field]: attachments })}
      />
      <Text style={[s.f14]}>
        *PDF is required. If your lesson is a video, please create a PDF
        outlining lesson objectives for other teachers. Get{' '}
        <Link to="https://files.ozobot.com/OzobotEasyLessonTemplate.pdf">
          <Text style={[s.textTeal, s.textUnderline]}>a template here</Text>
        </Link>
        .
      </Text>
    </View>
  )
}

export default withSubmissionForm(SubmissionAttachmentsUploaderGroup)
