import * as React from 'react'
import { Text, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { BotCounter } from 'components/BotCounter2'
import { Link } from 'components/Router'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

interface DevicesType {
  label: string,
  evoCount: number,
  bitCount: number,
  occCount: number,
  userUpdateRequest: (myobj: { [name: string]: number }) => void,
}

function Devices({
  evoCount,
  bitCount,
  occCount,
  userUpdateRequest,
}: DevicesType) {
  
  const dispatch = useDispatch()
  const { isDesktop } = useDetectDeviceType();

  return (
    <View style={[s.flex1,s.justifyBetween]}>
      <View>
        <Text style={[s.mb8, s.f18, s.textBlack, s.textBold, s.textCenter]}>
          My Ozobot Devices
        </Text>
        <View style={[ isDesktop ? s.flexRow : s.flexColumn, s.mAuto ]}>
          <BotCounter
            label="Evo"
            id="evo"
            count={evoCount}
            onChange={obj => dispatch(userUpdateRequest(obj))}
          />
          <BotCounter
            label="Bit"
            id="bit"
            count={bitCount}
            onChange={obj => dispatch(userUpdateRequest(obj))}
          />
          <BotCounter
            label="Communicator"
            id="occ"
            count={occCount}
            onChange={obj => dispatch(userUpdateRequest(obj))}
          />
        </View>
      </View>
      <Text style={[s.f16, s.mt20, s.pb20]}>
        <Text style={[s.textBold]}>Need more bots?{' '}</Text>
        <Link to="mailto:sales@ozobot.com">
          <Text style={[s.textGreen, s.textUnderline]}>Contact an Ozobot Account Executive</Text>
        </Link>
        <Text>{' '}to learn more and get a quote.</Text>
      </Text>
    </View>
  )
}

export default Devices
