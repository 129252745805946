import React from 'react'
import { StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useDispatch } from 'react-redux'
import { modalShow } from 'store/modal'
import { Link } from 'components/Router'
import { openLink } from 'pages/util'
import s from 'styles'

const dispatch = useDispatch()

export interface ButtonLinkProps {
  buttonText: string,
  buttonLink?: string,
  Modal?: React.FunctionComponent,
  noSiteCheck?: Boolean,
}

interface BaseButtonLinkProps extends ButtonLinkProps {
  style: StyleProp<ViewStyle>,
  textStyle: StyleProp<TextStyle>,
}

const BaseButtonLink = ({
  buttonLink,
  buttonText,
  Modal,
  style,
  textStyle,
  noSiteCheck,
}: BaseButtonLinkProps) => {

  if (Modal)  {
    return (
      <TouchableOpacity style={style} onPress={() => {
        dispatch(modalShow(<Modal/>))
      }}>
        <Text style={textStyle}>{buttonText}</Text>
      </TouchableOpacity>
    )
  }

  // FIXME: this is really just opening non-webpage types?
  if(!buttonLink.includes('.com') && buttonLink[0] !== '/') {
    return (
      <Link to={buttonLink} style={[s.alignSelfStart]}>
        <View style={style}>
          <Text style={textStyle}>{buttonText}</Text>
        </View>
      </Link>
    )
  }

  return (
    <TouchableOpacity
      style={style}
      onPress={() => {openLink(buttonLink, dispatch, noSiteCheck)}}
    >
      <Text style={textStyle}>{buttonText}</Text>
    </TouchableOpacity>
  )
}

export function ButtonLink (props: ButtonLinkProps) {
  return <BaseButtonLink
    style={[s.bgGreen, s.py8, s.px16, s.br5, s.alignSelfStart]}
    textStyle={[s.f14, s.textBold, s.textWhite]}
    {...props}
  />
}

export function ButtonLinkBlack (props: ButtonLinkProps) {
  return <BaseButtonLink
    style={[s.bgBlueLighter, s.py8, s.px16, s.br25, s.mt8, s.alignSelfStart]}
    textStyle={[s.f18, s.textBold, s.textBlack, s.px8]}
    {...props}
  />
}

export function ButtonLinkBlackMobile (props: ButtonLinkProps) {
  return <BaseButtonLink
    style={[s.bgBlueLighter, s.py4, s.px8, s.br25, s.alignSelfStart]}
    textStyle={[s.f14, s.textBold, s.textBlack]}
    {...props}
  />
}

export function ButtonLinkWhite (props: ButtonLinkProps) {
  return <BaseButtonLink
    style={[s.bgGrayMoreLighter, s.py8, s.px16, s.br25, s.b1, s.mt8, s.bGreenDark, s.alignSelfStart, s.mAuto]}
    textStyle={[s.f12, s.textBold, s.textGreenDark]}
    {...props}
  />
}
