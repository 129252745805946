import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { Button } from 'components/Form'
import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'

export default function SubmissionFooter({
  currentStep,
  to,
  isLastStep,
  save,
  submit,
  errorMessage,
}) {
  currentStep = Number(currentStep)
  const { isMobile } = useDetectDeviceType();

  function goBack() {
    to(currentStep - 1)
  }

  function goForward() {
    if (isLastStep) {
      return submit()
    }

    to(currentStep + 1)
  }

  return (
    <>
      <View style={[isMobile ? s.mb40 : s.mb8, s.flexRow, s.alignCenter, s.justifyBetween]}>
        {currentStep > 1
          ? <Button style={[s.w80]} text="Back" onPress={goBack} disabled={currentStep === 1} />
          : <View style={[s.w80]}/>
        }
        <TouchableOpacity onPress={save}>
          <Text style={[s.textGreen, s.f16, s.textBold, s.textUnderline]}>
            Save Draft
          </Text>
        </TouchableOpacity>
        <Button text={isLastStep ? 'Submit' : 'Next'} onPress={goForward} />
      </View>
      {errorMessage && (
        <View style={[s.alignEnd]}>
          <Text style={[s.f16, s.textRed]}>
            <Text style={[s.textBold]}>
              {'These required fields are empty:\n'}
            </Text>
            {errorMessage}
          </Text>
        </View>
      )}
    </>
  )
}
