export const BOTZ_PERIPHERAL_CONNECTION_REQUEST =
  'BOTZ_PERIPHERAL_CONNECTION_REQUEST'
export const BOTZ_PERIPHERAL_CONNECTION_SUCCESS =
  'BOTZ_PERIPHERAL_CONNECTION_SUCCESS'
export const BOTZ_PERIPHERAL_CONNECTION_FAILURE =
  'BOTZ_PERIPHERAL_CONNECTION_FAILURE'
export const BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST =
  'BOTZ_PERIPHERAL_DISCONNECT_ALL_REQUEST'
export const BOTZ_PERIPHERAL_DISCONNECT_ALL_SUCCESS =
  'BOTZ_PERIPHERAL_DISCONNECT_ALL_SUCCESS'
export const BOTZ_OCC_STATE_CHANGED = 'BOTZ_OCC_STATE_CHANGED'
export const BOTZ_OCC_SET_BOT_CONNECTION_FILTER =
  'BOTZ_OCC_SET_BOT_CONNECTION_FILTER'
export const BOTZ_OCC_ALLOW_BOT_CONNECTION = 'BOTZ_OCC_ALLOW_BOT_CONNECTION'
export const BOTZ_BOT_RENAME_REQUEST = 'BOTZ_BOT_RENAME_REQUEST'
export const BOTZ_BOT_STATE_CHANGED = 'BOTZ_BOT_STATE_CHANGED'
export const BOTZ_BOT_EVENT_RECEIVED = 'BOTZ_BOT_EVENT_RECEIVED'

export const BOTZ_UPDATE_OCC_FIRMWARE_REQUEST =
  'BOTZ_UPDATE_OCC_FIRMWARE_REQUEST'
export const BOTZ_UPDATE_OCC_FIRMWARE_SUCCESS =
  'BOTZ_UPDATE_OCC_FIRMWARE_SUCCESS'
export const BOTZ_UPDATE_OCC_FIRMWARE_FAILURE =
  'BOTZ_UPDATE_OCC_FIRMWARE_FAILURE'
export const BOTZ_OCC_UPLOAD_PROGRESS = 'BOTZ_OCC_UPLOAD_PROGRESS'

export const BOTZ_OCC_BOT_SCAN_BEGIN = 'BOTZ_OCC_BOT_SCAN_BEGIN'
export const BOTZ_OCC_BOT_SCAN_ENDED = 'BOTZ_OCC_BOT_SCAN_ENDED'

export const BOTZ_UPDATE_BOT_BRIEFING_REQUEST = 'BOTZ_UPDATE_BOT_BRIEFING_REQUEST'
export const BOTZ_UPDATE_BOTS_REQUEST = 'BOTZ_UPDATE_BOTS_REQUEST'
export const BOTZ_UPDATE_BOT_INLINE_REQUEST = 'BOTZ_UPDATE_BOT_INLINE_REQUEST'
export const BOTZ_UPDATE_BOT_PROGRESS = 'BOTZ_UPDATE_BOT_PROGRESS'
export const BOTZ_UPDATE_BOT_SUCCESS = 'BOTZ_UPDATE_BOT_SUCCESS'
export const BOTZ_UPDATE_BOT_FAILURE = 'BOTZ_UPDATE_BOT_FAILURE'
export const BOTZ_UPDATE_BOT_CANCEL = 'BOTZ_UPDATE_BOT_CANCEL'

export const BOTZ_MANIFEST_RETRIEVED = 'BOTZ_MANIFEST_RETRIEVED'
export const BOTZ_ASSETS_RETRIEVED = 'BOTZ_ASSETS_RETRIEVED'

// TODO: this really should go in its own store, but it's such a small thing that
// botz will handle it for now, until there is call to setup a new reducer / saga thing
export const BOTZ_DEVICE_PAGE_ENABLED = 'BOTZ_DEVICE_PAGE_ENABLED'
export const BOTZ_REQUEST_ID_BOT_WITH_BLINKING_LEDS =
  'BOTZ_REQUEST_ID_BOT_WITH_BLINKING_LEDS'

// These are internal types, for private use
export const _BOTZ_CLEAR_ALL_LAST_ERRORS = '_BOTZ_CLEAR_ALL_LAST_ERRORS'
export const _BOTZ_SET_INLINE_UPDATING = '_BOTZ_SET_INLINE_UPDATING'
