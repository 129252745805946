import React from 'react'
import ReactAutocomplete from 'react-autocomplete'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { autocompleteRequest, autocompleteClear } from 'store/autocomplete'
import RemoveX from './RemoveX'
import withSubmissionForm from './withSubmissionForm'
import s from 'styles'

const StandardsAutoComplete = ({
  category,
  form,
  formAddItem,
  autocompleteRequest,
  autocompleteClear,
  results,
}) => {
  const [standard, setStandard] = React.useState('')

  const onSelect = item => {
    formAddItem(item)
    setStandard('')
    autocompleteClear({ dataType: 'academicStandards' })
  }

  return (
    <View style={[s.b1, s.bGrayLighter, s.br5]}>
      <ReactAutocomplete
        getItemValue={item => item.label}
        items={results}
        renderItem={(item, isHighlighted) => (
          <div
            style={{
              background: 'white',
              color: isHighlighted ? '#008891' : '#404040',
              padding: '10px 15px',
            }}
          >
            <div style={{ fontWeight: 700, marginBottom: 5 }}>{item.label}</div>
            <div style={{ paddingLeft: 15 }}>{item.description}</div>
          </div>
        )}
        // (Theresa) cant figure out how to run onChange when someone selects input field
        // to refresh the standards when someone changes the dropdown for standard library
        inputProps={{
          style: {
            height: 40,
            width: '100%',
            paddingLeft: 15,
            paddingRight: 45,
            outlineStyle: 'none',
            border: 'none',
            fontSize: 16,
            fontFamily: 'Lato',
            borderRadius: 5,
          },
        }}
        menuStyle={{
          background: 'white',
          position: 'absolute',
          left: 0,
          top: 43,
          border: '1px solid #cadde2',
          borderRadius: 5,
          overflow: 'scroll',
          maxHeight: 180,
        }}
        value={standard}
        onChange={e => {
          setStandard(e.target.value)
          autocompleteRequest({
            type: category,
            query: e.target.value,
            exclude: form['academicStandards'].join(','),
            dataType: 'academicStandards',
          })
        }}
        onSelect={onSelect}
      />
      {standard !== '' && (
        <View style={[s.positionAbsolute, { top: 12, right: 15 }]}>
          <RemoveX text="" onPress={() => setStandard('')} />
        </View>
      )}
    </View>
  )
}

const mapStateToProps = ({ autocomplete }) => {
  return {
    results: autocomplete.academicStandards.results || [],
    query: autocomplete.academicStandards.query || '',
  }
}

export default connect(mapStateToProps, {
  autocompleteRequest,
  autocompleteClear,
})(withSubmissionForm(StandardsAutoComplete))
