import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { openURL } from 'lib/utils'
import { Image } from 'components/ReactNative'
import RemoveX from './RemoveX'
import s from 'styles'

const icons = {
  image: {
    icon: require('images/image-icon-xs.svg'),
    size: { width: 20, height: 16.7 },
  },
  file: {
    icon: require('images/file-icon-xs.svg'),
    size: { width: 13, height: 20 },
  },
  link: {
    icon: require('images/link-icon-xs.svg'),
    size: { width: 16, height: 16 },
  },
  video: {
    icon: require('images/link-icon-xs.svg'),
    size: { width: 16, height: 16 },
  },
}

export function AttachmentsListItem({ content, hasRemove, onRemove }) {
  return (
    <View style={[s.mb4, s.flexRow, s.justifyBetween, s.alignCenter]}>
      <View style={[s.flexRow]}>
        <View style={[s.mr8]}>
          <Image
            source={icons[content.type].icon}
            style={[icons[content.type].size]}
          />
        </View>
        <TouchableOpacity
          onPress={() => openURL(content.url, true)}
          disabled={!content.url}
        >
          <Text
            style={[s.f14, s.textUnderline]}
            numberOfLines={1}
            ellipsizeMode={'tail'}
          >
            {content.name}
          </Text>
        </TouchableOpacity>
      </View>
      {hasRemove && (
        <View style={[s.flexRow, s.ml20]}>
          <RemoveX onPress={onRemove} />
        </View>
      )}
    </View>
  )
}

export default function AttachmentsList({
  attachments,
  hasRemove = true,
  removeByIndex,
}) {
  if (attachments.length === 0) return null

  return (
    <View style={[s.mb20]}>
      <View style={[s.mb4, s.flexRow]}>
        <Text style={[s.f14, s.textBold]}>Name</Text>
      </View>
      {attachments.map((attachment, i) => (
        <AttachmentsListItem
          key={i}
          content={attachment}
          hasRemove={hasRemove}
          onRemove={() => removeByIndex(i)}
        />
      ))}
    </View>
  )
}
