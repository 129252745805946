import * as React from 'react'
import { KeyboardAvoidingView, ScrollView, View } from 'react-native'
import LottieView from 'react-native-web-lottie'
import { HeaderEducator, HeaderPublic } from 'components/Header'
import s from 'styles'

export interface LayoutProps {
  scrollRef?: React.RefObject<ScrollView>
  bgColor?: string,
  hasLoggedInHeader?: boolean,
  hasSidebar?: boolean,
  isFetching?: boolean,
  children: React.ReactNode,
}

const Layout: React.FC<LayoutProps> = ({ scrollRef, bgColor = 'White', hasLoggedInHeader = true, isFetching, children }: LayoutProps) => {
  
  return (
    <KeyboardAvoidingView behavior={'padding'} style={s.flex1}>
      {hasLoggedInHeader ? <HeaderEducator /> : <HeaderPublic />}
      <View style={[s.flexRow, { height: 'calc(100% - 60px)' }]}>
        <ScrollView ref={scrollRef} contentContainerStyle={s.flexGrow1} style={[s[`bg${bgColor}`]]}>
          {children}
        </ScrollView>
      </View>
      {isFetching && (
        <View style={[s.positionAbsolute, s.left0, s.right0, s.top0, s.bottom0, s.alignCenter, s.justifyCenter, s.zIndex2, { backgroundColor: '#252932cc'}]}>
          <LottieView
            style={{ width: 120, height: 120 }}
            source={require('images/lottie-spinner.json')}
            autoPlay
            loop
          />
        </View>
      )}
    </KeyboardAvoidingView>
  )
}

export default React.forwardRef((props: LayoutProps, ref?: React.RefObject<ScrollView>) => (
  <Layout scrollRef={ref} {...props} />
))
