import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import LottieView from 'react-native-web-lottie'
import { Image } from 'components/ReactNative/Image'
import { Button } from 'components/Form'
import isUndefined from 'lodash/isUndefined'
import { modalShow } from 'store/modal'
import { DynamicModal } from 'components/modals/DynamicModal'

import s from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  botzConnectToBotWithBLE,
  botzDisconnectAllPeripherals,
  botzBotsAreUpdating,
} from 'store/botz'
import { userSetOnboarded, userSetSimplifiedOzobotConnect } from 'store/user'
import { openURL } from 'lib/utils'
import { push } from 'connected-react-router'
import pubsub from 'lib/pubsub.ts'

const bcStyle = StyleSheet.create({
  container: {
    width: 322,
    borderRadius: 4,
    borderColor: '#ECF1F4',
    borderWidth: 1,
  },
  getYourOzobot: {
    width: 270,
    height: 148,
  },
  heading: {
    color: '#313847',
  },
  col90: {
    width: 90,
  },
  col20: {
    width: 20,
    paddingLeft: 5,
  },
})

const OZOBOT_SHOP_URL = 'https://shop.ozobot.com'
const GOOGLE_CLASSROOM = 'https://classroom.google.com'

const lottie = {
  up: require('images/lottie-up.json'),
  on: require('images/lottie-on.json'),
  connected: require('images/lottie-connected.json'),
}

const onTrouble = setStep => {
  setStep('Trouble')
}

/**
 * Ok, this little flow got little bit complicated and maybe some
 * proper flow manager or state machine lib would help,
 * so to keep me sane I wrote the steps little into this dictionary
 * where every step has an action to next steps without the logic.
 *
 * It uses `setStep` function as callback.
 *
 * The flow goes like (steps are strings in the single quotes)
 *
 * 'WaitingForUserData'
 *    \
 *     \
 *     +-- [Onboarding]
 *     |      \
 *     |    'NewStudentIntro' -> 'NewStudentPairOzobot' ->
 *     |                                          'NewStudentConnected'
 *     |
 *     +-- [Reccuring]
 *            |
 *            +-- [Simplified Connect]
 *            |         \
 *            |       'BotConnectionHidden' -> 'BotConnectionConnected'
 *            |
 *            +-- [Normal Connect]
 *                      \
 *                    'BotConnectionIntro' -> 'BotConnectionConnected'
 *
 *      -- [TroubleShooting] (from any step)
 *            \
 *           'Trouble'
 *              \
 *            (cliking on Pair Ozobot)
 *                \
 *                +-- [Onboarding] -> 'NewStudentConnected'
 *                |
 *                +-- [Reccuring]  -> 'BotConnectionConnected'
 *
 */
const ModalStateFlow = {
  WaitingForUserData: {
    onReccuring: setStep => {
      setStep('BotConnectionIntro')
    },
    onReccuringSimplified: setStep => {
      setStep('BotConnectionHidden')
    },
    onNewStudent: setStep => {
      setStep('NewStudentIntro')
    },
    onTrouble,
  },
  NewStudentIntro: {
    onPairOzobot: setStep => {
      setStep('NewStudentPairOzobot')
    },
    onTrouble,
  },
  NewStudentPairOzobot: {
    onBack: setStep => {
      setStep('NewStudentIntro')
    },
    onConnected: setStep => {
      setStep('NewStudentConnected')
    },
    onTrouble,
  },
  NewStudentConnected: {
    onTrouble,
  },
  BotConnectionIntro: {
    onConnected: setStep => {
      setStep('BotConnectionConnected')
    },
    onTrouble,
  },
  BotConnectionHidden: {
    onConnected: setStep => {
      setStep('BotConnectionConnected')
    },
  },
  BotConnectionConnected: {
    onTrouble,
  },
  Trouble: {
    onNewStudentConnected: setStep => {
      setStep('NewStudentConnected')
    },
    onStudentConencted: setStep => {
      setStep('BotConnectionConnected')
    },
  },
}

/**
 * Bot connection dropdown.
 *
 * The dropdown state is determined from states of user settings and
 * bot connection status
 *
 *  - [connected] bot connection status
 *  - [onBoarded] is user onboarded
 *  - [simplifiedConnect] does user have simplified connect
 */
export default function BotConnectionDropdown({ closeModal }) {
  const [step, setStep] = useState('WaitingForUserData')
  const dispatch = useDispatch()

  const connected = useSelector(
    ({ botz }) => !!Object.values(botz.byIds).find(bot => bot.connected)
  )
  const botsAreUpdating = useSelector(botzBotsAreUpdating)

  // const onBoardedFromStore = useSelector(({ user }) => user.isOnboarded)

  const simplifiedConnect = useSelector(
    ({ user }) => user.simplifiedOzobotConnect
  )

  // we cache the user onboarding state on first step,
  // so change will be reflected after re-opening
  // const [onBoarded] = useState(onBoardedFromStore)
  const onBoarded = true // In 2.2, connecting users are anonymous

  // handle if student is onboarded
  useEffect(() => {
    if (step === 'WaitingForUserData') {
      if (!onBoarded) {
        ModalStateFlow.WaitingForUserData.onNewStudent(setStep)
      } else {
        ModalStateFlow.WaitingForUserData.onReccuring(setStep)
      }
    }
  }, [step, onBoarded])

  // handle if ozobot is connected for non simplified connect
  useEffect(() => {
    if (connected && !simplifiedConnect) {
      if (onBoarded) {
        setStep('BotConnectionConnected')
        ModalStateFlow.BotConnectionIntro.onConnected(setStep)
      } else {
        // TODO API write this to USER table
        dispatch(userSetOnboarded(true))
        ModalStateFlow.NewStudentPairOzobot.onConnected(setStep)
      }
    }
  }, [connected, onBoarded, simplifiedConnect, dispatch])

  // handle student simplified connect
  // eg. skip BotConnectionIntro and connect directly
  // we presume user is onboarded implicitly
  useEffect(() => {
    if (simplifiedConnect && !botsAreUpdating) {
      if (!connected) {
        ModalStateFlow.WaitingForUserData.onReccuringSimplified(setStep)
        dispatch(botzConnectToBotWithBLE())
      } else {
        ModalStateFlow.BotConnectionHidden.onConnected(setStep)
      }
    }
  }, [connected, simplifiedConnect, dispatch, botsAreUpdating])

  useEffect(() => {
    const unsubscribe = pubsub.sub('_BOTZ_BLE_CONNECTION_ERROR', () => {
      closeModal()
    })

    return unsubscribe
  })

  return (
    (step === 'NewStudentIntro' && (
      <NewStudentIntro
        onNext={() => ModalStateFlow.NewStudentIntro.onPairOzobot(setStep)}
        onTrouble={() => ModalStateFlow.NewStudentIntro.onTrouble(setStep)}
        onOzobotClick={closeModal}
      />
    )) ||
    (step === 'NewStudentPairOzobot' && (
      <NewStudentPairOzobot
        onBack={() => ModalStateFlow.NewStudentPairOzobot.onBack(setStep)}
        onTrouble={() => ModalStateFlow.NewStudentPairOzobot.onTrouble(setStep)}
        onOzobotClick={closeModal}
      />
    )) ||
    (step === 'NewStudentConnected' && (
      <NewStudentConnected
        onTrouble={() => ModalStateFlow.NewStudentConnected.onTrouble(setStep)}
        onOzobotClick={closeModal}
      />
    )) ||
    (step === 'BotConnectionIntro' && (
      <BotConnectionIntro
        onTrouble={() => ModalStateFlow.BotConnectionIntro.onTrouble(setStep)}
        onSimplifiedConnect={() =>
          dispatch(userSetSimplifiedOzobotConnect(true))
        }
        onOzobotClick={closeModal}
      />
    )) ||
    (step === 'BotConnectionConnected' && (
      <BotConnectionConnected
        onTrouble={() =>
          ModalStateFlow.BotConnectionConnected.onTrouble(setStep)
        }
        onDisconnect={() => {
          dispatch(botzDisconnectAllPeripherals())
          closeModal()
        }}
        onOzobotClick={closeModal}
      />
    )) ||
    (step === 'Trouble' && (
      <TroubleStep
        onOzobotClick={closeModal}
        onDevicesLinkClick={() => {
          closeModal()
          dispatch(push('/devices'))
        }}
      />
    ))
  )
}

/**
 * First step of new student onboarding.
 */
function NewStudentIntro({ onNext, onTrouble, onOzobotClick }) {
  const dispatch = useDispatch()
  function DisplayModal() {
    dispatch(
      modalShow(
        <DynamicModal
          title="I don’t have Ozobots"
          closeButton="Ok"
          size="medium"
        >
          <View>
            <Text style={[s.f24, s.textBold, s.textCenter, s.my45]}>
              No Ozobots?{' '}
              <TextLink
                onPress={() => openURL('mailto:sales@ozobot.com', true)}
              >
                Contact Sales
              </TextLink>{' '}
              for a demo or quote. You can still explore lessons without a bot.
            </Text>
          </View>
        </DynamicModal>
      )
    )
  }

  const dontHeaveBot = "I don't have an Evo"
  return (
    <BotConnDialog>
      <StatusBarRow onHelp={onTrouble} onOzobotClick={onOzobotClick} />
      <DotsBar step={1} />
      <HeadingRow>Get your Ozobot Evo!</HeadingRow>
      <CenterRow style={[s.mb24]}>
        <LottieView
          style={{ width: 270, height: 148 }}
          source={lottie.up}
          autoPlay
          loop
        />
      </CenterRow>
      <HLine height={2} style={[s.mb20]} />
      <Row style={[s.justifyBetween, s.alignCenter]}>
        <TouchableOpacity onPress={DisplayModal}>
          <Text style={[s.f14, s.textBold, s.bBottom1, s.textTeal, s.bTeal]}>
            {dontHeaveBot}
          </Text>
        </TouchableOpacity>
        <Button text="Next" onPress={onNext} />
      </Row>
    </BotConnDialog>
  )
}

/**
 * Second step of new student onboarding.
 */
function NewStudentPairOzobot({ onBack, onTrouble, onOzobotClick }) {
  const dispatch = useDispatch()

  return (
    <BotConnDialog>
      <StatusBarRow onHelp={onTrouble} onOzobotClick={onOzobotClick} />
      <DotsBar step={2} />
      <HeadingRow>
        {'         '}Power Evo on.{'\n'}
        (must be fully charged)
      </HeadingRow>
      <CenterRow style={[s.mb24]}>
        <LottieView
          style={{ width: 270, height: 148 }}
          source={lottie.on}
          autoPlay
          loop
        />
      </CenterRow>
      <HLine height={2} style={[s.mb20]} />
      <Row style={[s.justifyBetween, s.alignCenter]}>
        <TouchableOpacity onPress={() => onBack()}>
          <Image
            source={require('images/bot-connection-back-arrow.svg')}
            style={[{ width: 25, height: 20 }]}
          />
        </TouchableOpacity>
        <Button
          text="Pair Ozobot"
          onPress={() => dispatch(botzConnectToBotWithBLE())}
        />
      </Row>
    </BotConnDialog>
  )
}

/**
 * Final step of new student onboarding.
 */
function NewStudentConnected({ onTrouble, onOzobotClick }) {
  return (
    <BotConnDialog>
      <StatusBarRow onHelp={onTrouble} onOzobotClick={onOzobotClick} />
      <DotsBar step={3} />
      <HeadingRow>Evo is connected!</HeadingRow>
      <CenterRow style={[s.mb24]}>
        <LottieView
          style={{ width: 270, height: 148 }}
          source={lottie.connected}
          autoPlay
          loop
        />
      </CenterRow>
    </BotConnDialog>
  )
}

function CircleNumber({ number, radius, style }) {
  style = castArray(style)

  return (
    <View
      style={[
        s.b1,
        s.bGrayDarker,
        {
          borderRadius: radius,
          width: radius * 2,
          height: radius * 2,
        },
        ...style,
      ]}
    >
      <Text style={[s.f14, s.textBold, s.textGrayDarker, s.textCenter]}>
        {number}
      </Text>
    </View>
  )
}

/**
 * First step for other students.
 */
function BotConnectionIntro({ onTrouble, onOzobotClick, onSimplifiedConnect }) {
  const dispatch = useDispatch()

  return (
    <BotConnDialog>
      <StatusBarRow onHelp={onTrouble} onOzobotClick={onOzobotClick} />
      <Row style={[s.mb32]}>
        <View style={[s.pr16]}>
          <Image
            source={require('images/bot-connection-evo-icon.svg')}
            style={{ width: 80, height: 80 }}
          />
        </View>
        <View style={[s.pl16]}>
          <Row style={[s.mb16]}>
            <CircleNumber number="1" radius={10} style={[s.mr16]} />
            <Text style={[s.f14, { color: '#252932' }]}>
              Power Evo on{'\n'}
              (must be fully charged)
            </Text>
          </Row>
          <Row>
            <CircleNumber number="2" radius={10} style={[s.mr16]} />
            <Text style={[s.f14, { color: '#252932' }]}>
              Click “Pair Ozobot”
            </Text>
          </Row>
        </View>
      </Row>
      <Row style={[s.mb16]}>
        <Button
          text="Pair Ozobot"
          onPress={() => dispatch(botzConnectToBotWithBLE())}
        />
      </Row>
      <Row>
        <TextLink onPress={() => onSimplifiedConnect()}>
          Got It! Don’t show me this again
        </TextLink>
      </Row>
    </BotConnDialog>
  )
}

/**
 * Final step for other students.
 */
function BotConnectionConnected({ onTrouble, onDisconnect, onOzobotClick }) {
  const bot = useSelector(({ botz }) =>
    Object.values(botz.byIds).find(bot => bot.connected)
  )

  // Hide window when bot disconnects
  React.useEffect(() => {
    if (!bot) {
      onOzobotClick()
    }
  }, [bot, onOzobotClick])

  if (!bot) {
    return null
  }

  return (
    <BotConnDialog>
      <StatusBarRow onHelp={onTrouble} onOzobotClick={onOzobotClick} />
      <Row style={[s.mb32]}>
        <View style={[s.pr16]}>
          <Image
            source={require('images/bot-connection-evo-icon-active.svg')}
            style={{ width: 80, height: 80 }}
          />
        </View>
        <View style={[s.pl16, s.alignSelfCenter]}>
          <Text style={[s.f16, s.textBold, s.textGrayDarkest]}>{bot.name}</Text>
        </View>
      </Row>
      <Row style={[s.justifyBetween]}>
        <View>
          <Row>
            <View style={bcStyle.col90}>
              <Text style={[s.f14, s.textGrayDarkest]}>Battery</Text>
            </View>
            <Row style={[s.flexGrow1, s.justifyEnd]}>
              <Text style={[s.f14, s.textGrayDarkest]}>{bot.battery}%</Text>
            </Row>
            <View style={bcStyle.col20}></View>
          </Row>
          <Row>
            <View style={bcStyle.col90}>
              <Text style={[s.f14, s.textGrayDarkest]}>Firmware</Text>
            </View>
            <Row style={[s.flexGrow1, s.justifyEnd]}>
              <Text style={[s.f14, s.textGrayDarkest]}>{bot.firmware}</Text>
            </Row>
            <View style={bcStyle.col20}>
              <Image
                source={require('images/bot-connection-icon-check.svg')}
                style={{ width: 20, height: 20 }}
              />
            </View>
          </Row>
        </View>
        <View style={[s.alignSelfEnd]}>
          <TextLink onPress={() => onDisconnect()}>Disconnect</TextLink>
        </View>
      </Row>
    </BotConnDialog>
  )
}

/**
 * Troubleshooting step
 */
function TroubleStep({ onOzobotClick, onDevicesLinkClick }) {
  const dispatch = useDispatch()

  return (
    <BotConnDialog>
      <StatusBarRow onOzobotClick={onOzobotClick} />
      <Row style={[s.mb16]}>
        <Text style={[s.f18, s.textBold, s.textGrayDarkest]}>
          Having trouble connecting to Evo?
        </Text>
      </Row>
      <RollListItem
        head={
          <Text style={[s.f16, s.textGrayDarkest]}>
            Is Evo charged &amp; powered on?
          </Text>
        }
        body={
          <Text style={[s.f14, s.textGrayDarkest]}>
            A full charge takes about 60 min. You can charge Evo’s LiPo battery
            with the micro USB cable that comes with single robots or with a
            charging base that comes with Classroom Kits.
            {'\n\n'}
            To power Evo on, quickly press the Power Button. Quickly press again
            to power off.
          </Text>
        }
      />
      <RollListItem
        head={
          <Text style={[s.f16, s.textGrayDarkest]}>
            Are all your other Evos unplugged?
          </Text>
        }
        body={
          <Text style={[s.f14, s.textGrayDarkest]}>
            These steps are for connecting to a single Evo. Make sure other Evos
            in the room are unplugged and powered off.
          </Text>
        }
      />

      <RollListItem
        head={
          <Text style={[s.f16, s.textGrayDarkest]}>
            Are you sure your bot is an Evo?
          </Text>
        }
        body={
          <Text style={[s.f14, s.textGrayDarkest]}>
            Only Ozobot Evo connects to Classroom. If you have the original
            Ozobot, Bit, you can still use the instructions on this page to
            complete your lesson. But you won’t be able to connect to Bit.
          </Text>
        }
      />

      <RollListItem
        head={
          <Text style={[s.f16, s.textGrayDarkest]}>
            Is your device compatible?
          </Text>
        }
        body={
          <Text style={[s.f14, s.textGrayDarkest]}>
            Evo and Ozobot Classroom are compatible with most computers
            (including Chromebooks) with the Chrome or Edge browser.{' '}
            <TextLink
              onPress={() => openURL('https://ozobot.com/support/faq', true)}
            >
              See Compatibility Chart
            </TextLink>
          </Text>
        }
      />

      <RollListItem
        head={
          <Text style={[s.f16, s.textGrayDarkest]}>
            For Educators – is your Classroom Communicator unplugged?
          </Text>
        }
        body={
          <Text style={[s.f14, s.textGrayDarkest]}>
            The Ozobot Classroom Communicator is for use when connecting and
            updating firmware for a full classroom set of bots. Keep it
            unplugged when not in use.
          </Text>
        }
      />
      <CenterRow style={[s.mb8]}>
        <View>
          <Button
            text="Pair Ozobot"
            onPress={() => dispatch(botzConnectToBotWithBLE())}
          />
        </View>
      </CenterRow>
      <CenterRow>
        <TextLink
          onPress={() => openURL('https://ozobot.com/support/faq', true)}
        >
          More Help
        </TextLink>
      </CenterRow>
    </BotConnDialog>
  )
}

/**
 * Cast any value to array, if it's not an array.
 */
function castArray(val) {
  if (!arguments.length) {
    return []
  }
  return Array.isArray(val) ? val : [val]
}

/**
 * View with flex: row.
 */
function Row({ children, style }) {
  style = castArray(style)

  return <View style={[s.flexRow, ...style]}>{children}</View>
}

/**
 * View with flex: row and centered columns.
 */
function CenterRow({ children, style }) {
  style = castArray(style)

  return <Row style={[s.justifyCenter, ...style]}>{children}</Row>
}

function TextLink({ children, onPress, style }) {
  return (
    <TouchableOpacity onPress={onPress}>
      <UnderLineTealText style={style}>{children}</UnderLineTealText>
    </TouchableOpacity>
  )
}

function UnderLineTealText({ children, style }) {
  style = castArray(style)

  return (
    <Text style={[s.f14, s.textGreen, s.textBold, s.textUnderline, ...style]}>
      {children}
    </Text>
  )
}

/**
 * Horizontal line
 */
function HLine({ height, color, style }) {
  if (isUndefined(height)) {
    height = 1
  }
  height = Number(height)

  if (isUndefined(color)) {
    color = '#ECF1F4'
  }

  style = castArray(style)

  return (
    <View
      style={[{ borderBottomWidth: height, borderColor: color }, ...style]}
    ></View>
  )
}

/**
 * Graphical dot.
 */
function Dot({ radius, color, style }) {
  style = castArray(style)
  radius = Number(radius)

  return (
    <View
      style={[
        {
          borderWidth: radius,
          borderRadius: radius,
          borderColor: color,
        },
        ...style,
      ]}
    />
  )
}

/**
 * Help button.
 */
function HelpButton({ style, onPress }) {
  style = castArray(style)

  return (
    <TouchableOpacity onPress={() => onPress()}>
      <Image
        style={[{ width: 21, height: 21 }, ...style]}
        source={require('images/bot-connection-question-icon.svg')}
      />
    </TouchableOpacity>
  )
}

/**
 * Bot connection status indicator.
 */
function OzobotStatus({ style, onOzobotClick }) {
  style = castArray(style)

  // TODO clicking on this could close the dialog ??

  const connected = useSelector(
    ({ botz }) => !!Object.values(botz.byIds).find(bot => bot.connected)
  )

  const iconConnected = require('images/bot-connection-evo-icon-active.svg')
  const iconDisconnected = require('images/bot-connection-evo-icon.svg')

  return (
    <TouchableOpacity onPress={() => onOzobotClick()}>
      <Image
        style={[{ width: 45, height: 45 }, ...style]}
        source={connected ? iconConnected : iconDisconnected}
      />
    </TouchableOpacity>
  )
}

/**
 * Row with bot connection stastus indicator and help button.
 */
function StatusBarRow({ onHelp, onAskATeacher, onOzobotClick }) {
  return (
    <Row style={[s.justifyEnd, s.alignCenter, s.mb16]}>
      {onHelp && <HelpButton style={[s.mr16]} onPress={onHelp} />}

      <OzobotStatus onOzobotClick={onOzobotClick} />
    </Row>
  )
}

/**
 * Progress bar from dots.
 */
function DotsBar({ step }) {
  const active = '#313847'
  const future = '#ECF1F4'

  const stepColor = i => (i <= step ? active : future)

  return (
    <CenterRow>
      <Dot radius={3} color={stepColor(1)} style={[s.mr4]} />
      <Dot radius={3} color={stepColor(2)} style={[s.mr4]} />
      <Dot radius={3} color={stepColor(3)} />
    </CenterRow>
  )
}

/**
 * Heading text in a centered row view.
 */
function HeadingRow({ children }) {
  return (
    <CenterRow>
      <Text style={[s.textGrayDarkest, s.f18, s.textBold, s.mt16, s.mb16]}>
        {children}
      </Text>
    </CenterRow>
  )
}

/**
 * Dialog frame.
 */
function BotConnDialog({ children }) {
  return (
    <View style={[bcStyle.container, s.bgWhite, s.px24, s.py4, s.pb20]}>
      {children}
    </View>
  )
}

/**
 * Bold text.
 */
function Bold({ style, children }) {
  style = castArray(style)

  return (
    <Text style={[s.textBold, s.textGrayDarkest, ...style]}>{children}</Text>
  )
}

/**
 * Expandable QA component.
 */
function RollListItem({ head, body }) {
  const [showContent, setShowContent] = useState(false)

  return (
    <View style={[s.bWhiteDarkest, s.bBottom2, s.pb8, s.mb16, s.px4]}>
      <TouchableOpacity onPress={() => setShowContent(!showContent)}>
        <Row style={[s.justifyBetween]}>
          <Text style={[s.f16, s.textGrayDarkest]}>{head}</Text>
          <View>
            <Image
              source={require('images/bot-connection-chevron-down.svg')}
              style={[{ width: 16, height: 8, marginTop: 7 }, s.ml8]}
            />
          </View>
        </Row>
      </TouchableOpacity>
      {showContent && <Row style={[s.mt8]}>{body}</Row>}
    </View>
  )
}
