import * as React from 'react'
import { Text, View, ViewStyle } from 'react-native'
import { Link, useLocation } from 'components/Router'
import { Column, Row } from 'react-native-web-ui-components'

import s from 'styles'
import { useDetectDeviceType } from 'hooks/useDetectDeviceType'
interface PillButtonProps {
  url: string,
  text: string,
  isActive?: boolean,
}
export interface NavToggleProps {
  options: Array<PillButtonProps>,
  style?: ViewStyle,
}

const PillButton: React.FC<PillButtonProps> = ({ url, text, isActive }: PillButtonProps) => {
  return (
    <Link to={url}>
      <View key={url} style={[s.p4, s.w100p, s.bBottom3, isActive ? s.bGreen : s.bGrayLightest]}>
        <Text style={[s.f16, s.textBold, s.px8]}>
          {text}
        </Text>
      </View>
    </Link>
  )
}

export const NavToggle: React.FC<NavToggleProps> = (props: NavToggleProps): JSX.Element => {
  const location = useLocation()
  const { isDesktop } = useDetectDeviceType()

  return (
    <View style={[s.flexRow, s.alignSelfStart, s.bgWhite, s.br100, isDesktop ? s.w60p : s.w90p, s.my32, s.mAuto].concat(props.style)}>
      <Row style={[s.justifyBetween]}>
        {props.options.map((option, key) => 
          <Column key={key} xs={6} style={[s.textCenter]}>
            <PillButton key={key} isActive={location.pathname === option.url} {...option} />
          </Column>,
        )}
      </Row>
    </View>
  )
}

export default NavToggle
