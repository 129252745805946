import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { openRoute } from './../utils'
import s from 'styles'

export interface LessonTitleProps {
  id: string
  title: string
}
export default function TitleLink({id, title }: LessonTitleProps): JSX.Element {
  // We have to use TouchableOpacity here instead of "Link", to all for text resizing issue...
  const url = `/lessons/${id}`
  return (
    <TouchableOpacity
      onPress={() => openRoute(url)}
      style={[s.mr8, s.flexRow, s.flex1]}
    >
      <Text
        numberOfLines={1}
        ellipsizeMode={'tail'}
        style={[s.f14, s.textGreen, s.textBold]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  )
}
