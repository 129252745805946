import * as React from 'react'
import { Text, View } from 'react-native'
import s from 'styles'


export default function OrSeparator () {
  const GrayLine = () => <View style={[s.b1, s.bWhiteDarkest, { height: 0, width: '42%' }]}/>

  return (<View style={[s.flexRow, s.alignCenter, s.justifyCenter, s.w100p, s.my16]}>
    <GrayLine />
    <Text style={[s.f16, s.textBold, s.px8, s.textGrayDarker]}>Or</Text>
    <GrayLine />
  </View>)
}
