import * as React from 'react'
import SuccessScreen from './../components/SuccessScreen'
import { RouteComponentProps } from 'react-router-dom';

const Success: React.FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
  return (
    <SuccessScreen
      text={'Email verified!'}
      isSignedIn={false}
      {...props}
    />
  )
}

export default Success
