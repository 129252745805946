import React, { useRef } from 'react';
import { TouchableOpacity, Animated } from 'react-native';

const ToggleSwitch = ({ value, onChange }) => {
  const circlePosition = useRef(new Animated.Value(value ? 1 : 0)).current;

  const toggleSwitch = () => {
    const newValue = !value;
    Animated.timing(circlePosition, {
      toValue: newValue ? 1 : 0,
      duration: 200, // Adjust the animation duration as needed
      useNativeDriver: false, // Set to true if possible for performance
    }).start();
    onChange(newValue);
  };

  const circleTranslateX = circlePosition.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 20], // Adjust the distance you want the circle to move
  });

  const backgroundColor = circlePosition.interpolate({
    inputRange: [0, 1],
    outputRange: ['#C7C7CC', 'white'], // Change colors as needed
  });

  return (
    <TouchableOpacity
      onPress={toggleSwitch}
      style={{
        width: 40,
        height: 20,
        borderRadius: 10,
        backgroundColor: backgroundColor,
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: 2,
        borderWidth: 1, // Add a border to make the switch visible
        borderColor: '#C7C7CC', // Border color when off
      }}
    >
      <Animated.View
        style={{
          width: 16,
          height: 16,
          borderRadius: 8,
          backgroundColor: 'gray', // Ash color
          transform: [{ translateX: circleTranslateX }],
          zIndex: 1, // Ensure the circle is above the border
        }}
      />
    </TouchableOpacity>
  );
};

export default ToggleSwitch;
